import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, useMotionValue } from 'framer-motion';
import classNames from 'classnames';

import * as Styled from './UVReveal.styled';

export type Rect = {
  top: number;
  left: number;
  bottom: number;
};

interface Props {
  visible: boolean;
  draggable?: boolean;
  rect: Rect;
  bgSrc: string;
  bgFadedSrc?: string;
  width: number;
  scaleRatio?: number;
}

export const UVReveal: React.FC<Props> = ({
  visible,
  rect = { left: 0, top: 0, bottom: 300 },
  width,
  bgSrc,
  bgFadedSrc,
  draggable = true,
  scaleRatio = 1,
}) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const maskMarginRatio = 0.5;
  const [maskBounds, setMaskBounds] = useState({ left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0 });
  const [lightBounds, setLightBounds] = useState({ left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0 });

  const lightRef = useRef(null);
  const maskRef = useRef(null);

  const updateMask = useCallback(() => {
    if (maskRef.current) {
      const { left, top } = maskBounds;
      maskRef.current.style.webkitMaskPosition = `${x.get() - left - 25}px ${y.get() - top - 25}px`;
    }
  }, [maskBounds, x, y]);

  const onDragMove = (e: PointerEvent) => {
    updateMask();
  };

  useEffect(() => {
    updateMask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMask, rect, visible]);

  useEffect(() => {
    if (maskRef.current) {
      const { left, top, width, height, right, bottom } = maskRef.current.getBoundingClientRect();
      setMaskBounds({ left, top, width, height, right, bottom });
    }
    if (lightRef.current) {
      const { left, top, width, height, right, bottom } = lightRef.current.getBoundingClientRect();
      setLightBounds({ left, top, width, height, right, bottom });
    }
  }, [visible]);

  return (
    <AnimatePresence>
      {visible ? (
        <>
          <Styled.Background initial={{ opacity: 0 }} animate={{ opacity: 0.8 }} exit={{ opacity: 0 }} />
          <Styled.UvBackgroundImg initial={{ opacity: 0 }} animate={{ opacity: 0.8 }} exit={{ opacity: 0 }} />
          {draggable && (
            <Styled.Light
              ref={lightRef}
              style={{ x, y }}
              onDrag={onDragMove}
              onUpdate={updateMask}
              drag
              dragMomentum={false}
              dragConstraints={{
                left: -maskBounds.left - lightBounds.width * maskMarginRatio,
                right: maskBounds.width - lightBounds.width * maskMarginRatio,
                top: -maskBounds.top,
                bottom: maskBounds.bottom,
              }}
            />
          )}
          <Styled.RevealElement
            scaleRatio={scaleRatio}
            bgSrc={bgSrc}
            ref={maskRef}
            w={width}
            rect={rect}
            draggable={draggable}
          />
          {bgFadedSrc && <Styled.RevealFaded bgSrc={bgFadedSrc} rect={rect} w={width} />}
        </>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(UVReveal);
