import { GameVersionState } from 'constants/enum';
import { menuGuideState } from '../Menu';

export const CONFIG = () => {
  return {
    [GameVersionState.MAIN]: [
      {
        label: 'menuButtons.exitDashboard',
        type: 'exitDashboard',
      },
      {
        label: 'menuButtons.restartTraining',
        type: 'restartTraining',
      },

      {
        label: 'menuButtons.about',
        type: 'whatsMurderIsland',
      },
    ],
    [GameVersionState.GAME_ONE]: [
      {
        label: 'gameOneMenu.gameGuide',
        type: 'openGameMenu',
        gameGuide: menuGuideState.GAME_GUIDE_ONE,
      },
    ],
    [GameVersionState.GAME_TWO]: [
      {
        label: 'gameTwoMenu.gameGuide',
        type: 'openGameMenu',
        gameGuide: menuGuideState.GAME_GUIDE_TWO,
      },
    ],
    [GameVersionState.GAME_THREE]: [
      {
        label: 'gameThreeMenu.gameGuide',
        type: 'openGameMenu',
        gameGuide: menuGuideState.GAME_GUIDE_THREE,
      },
    ],
  };
};
