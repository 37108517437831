import React, {
  memo,
  forwardRef,
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './GameDetectiveVideo.styled';

import { HeaderContext, HeaderContextType } from 'context/header';

import CtaYellow from 'components/CtaYellow';
import { VideoCall } from 'components/VideoCall';

import SvgPlayFilled from 'svgs/PlayFilled.svg';

export const PlayVideoMessage = () => {
  const { t } = useTranslation();
  return (
    <Styled.PlayVideo tabIndex={0}>
      <SvgPlayFilled />
      <span>{t('detectiveCall.seeVideo')}</span>
    </Styled.PlayVideo>
  );
};

interface Props {
  className?: string;
  poster: string;
  src: string;
}

export interface GameDetectiveVideoRef {
  play: (onStop?: () => void) => void;
  stop: () => void;
}

export const GameDetectiveVideo = forwardRef<GameDetectiveVideoRef, Props>(({ className, src, poster }, ref) => {
  const { t } = useTranslation();
  const { header, setHeader }: HeaderContextType = useContext(HeaderContext);

  const [playing, setPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const onStopRef = useRef<() => void>(() => {});
  const prevHeaderRef = useRef(header);

  const stop = useCallback(() => {
    setPlaying(false);
    setHeader(prevHeaderRef.current);
    onStopRef.current?.();
    videoRef.current.pause();
    videoRef.current.removeEventListener('ended', stop);
  }, [setHeader]);

  const play = useCallback(
    onStop => {
      onStopRef.current = onStop;
      prevHeaderRef.current = header;
      setPlaying(true);
      setHeader({ ...prevHeaderRef.current, isBackButton: false, isHidden: true });
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      videoRef.current.addEventListener('ended', stop);
    },
    [header, setHeader, stop]
  );

  useImperativeHandle(ref, () => ({
    play: onStop => play(onStop),
    stop: () => stop(),
  }));

  useEffect(() => {
    const video = videoRef.current;
    return () => {
      video.removeEventListener('ended', stop);
    };
  }, [stop]);

  return (
    <Styled.Wrapper
      className={className}
      transition={{ duration: 0.3, delay: 0.1 }}
      animate={{
        opacity: playing ? 1 : 0,
        pointerEvents: playing ? 'auto' : 'none',
        transitionEnd: playing ? undefined : { display: 'none' },
        display: playing ? 'flex' : undefined,
      }}
    >
      <VideoCall className="video" ref={videoRef} videoSrc={src} posterSrc={poster} />
      <CtaYellow className="skip" label={t('skip')} onClick={stop} />
    </Styled.Wrapper>
  );
});

export default memo(GameDetectiveVideo);
