import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { rect } from 'styles/mixins';
import { fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.black};
  overflow: hidden;
  z-index: 0;
`;
export const Preloader = styled(motion.p)`
  ${fontChannel4Chadwick(14, 500)};
  z-index: 3;
  display: block;
  overflow: hidden;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
`;

Preloader.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  transition: {
    ease: 'easeInOut',
    duration: 1,
    type: 'tween',
    delay: 1,
  },
};

export const Video = styled.video<{ disableRemotePlayback?: boolean }>`
  width: 100%;
  height: 100%;
  background: ${colors.black};
  object-fit: cover;
  display: flex;
  z-index: 1;
  pointer-events: none;

  ${({ disableRemotePlayback }) =>
    disableRemotePlayback &&
    css`
      &::-internal-media-controls-overlay-cast-button {
        display: none;
      }
    `};
`;

export const Content = styled.div`
  ${rect('absolute', 0, 0, '100%', '100%')};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Frame = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  touch-action: none;
  pointer-events: none;
  z-index: 3;
`;

export const VideoBlackout = styled.div`
  ${rect('absolute', 0, 0, '100%', '100%')};
  background: ${colors.black};
  z-index: 2;
`;
