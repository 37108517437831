import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { absoluteCenter, box, gameOneZIndex, px, rect, uvBase, vh } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  padding: ${px(90)} ${px(16)} ${px(200)};
  overflow: auto;
  > .uv {
    ${uvBase()}
    position: fixed;
  }

  > .grid {
    position: relative;
    display: grid;
    grid-gap: ${px(10)};
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    .item {
      display: block;
      position: relative;

      &:nth-child(12n + 1),
      &:nth-child(12n + 8) {
        grid-column: auto / span 2;
        grid-row: auto / span 2;
      }

      .button {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 100%;

        .thumbnail {
          ${absoluteCenter()}
          ${box('100%')};
          display: block;
          background: ${colors.rollingStone};
        }

        .uv {
          ${uvBase()}
          transform: translate3d(0, 0, 0) scale(1.01);

          .frame {
            ${absoluteCenter()}
            ${box('105%', '110%')}
            background: url(${require('assets/images/game-1-phone-uv-frame.png')});
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
`;

export const ArrowWrapper = styled(motion.section)`
  position: absolute;
  right: ${px(13)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;
