import styled from 'styled-components';
import { fontChannel4Chadwick, fontChannel4Horseferry } from 'styles/typography';

import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { px, rect, vh } from 'styles/mixins';

export const Background = styled(motion.div)`
  ${rect('absolute', 0, 0, '100%', '100%')}
  background: ${colors.black};
  overflow: hidden;
  z-index: 10;
`;
export const Header = styled(motion.h2)`
  ${fontChannel4Chadwick(18, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  top: ${px(86)};
  left: 50%;
  text-align: center;
  z-index: 11;
  transform: translateX(-50%);
`;

export const Counter = styled(motion.span)`
  ${fontChannel4Horseferry(124, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  color: ${colors.yellow};
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%) translateY(-50%);
`;
