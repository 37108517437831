import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { fontChannel4Chadwick, typography14Caps } from 'styles/typography';
import { box, flexCenter, gameOneZIndex, px, rect, uvBase, vh } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${gameOneZIndex('picture')};
  ${rect('absolute', 0, 0, '100%', vh(100))}
  background: ${colors.graye5};
  background-position: bottom;
  background-size: cover;
  overflow: hidden;

  .close {
    ${flexCenter()}
    position: fixed;
    left: 50%;
    top: ${px(72)};
    height: ${px(34)};
    transform: translate3d(-50%, 0, 0);
    border-radius: ${px(16)};
    padding: 0 ${px(15)};
    background: ${colors.yellow};
    color: ${colors.blackPearl};
    box-shadow: 0 ${px(6)} ${px(9)} rgba(0, 0, 0, 0.19);
    z-index: 1;

    .icon {
      ${box(px(12))}
      margin: 0 ${px(6)} 0 0;
    }

    .label {
      ${fontChannel4Chadwick(16, 700, 1)}
      margin: ${px(2)} 0 0 0;
    }
  }

  .date {
    ${typography14Caps};
    position: fixed;
    bottom: ${px(27)};
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    color: ${colors.white};
    text-shadow: 0 0 ${px(3)} rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .picture {
    position: relative;
    width: 100%;

    .img {
      ${box('100%')}
      object-fit: contain;
    }
  }

  .uv {
    ${uvBase()}
    z-index: 1;

    .img {
      position: relative;
      /* mix-blend-mode: initial; */
    }
  }
`;
