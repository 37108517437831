import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { fontChannel4Chadwick } from 'styles/typography';
import { gameOneZIndex, px, rect, uvBase, vh } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  overflow: hidden;
  color: ${colors.white};

  > .time {
    text-align: center;
    padding: ${px(95)} 0 0 0;

    .number {
      ${fontChannel4Chadwick(150, 300, 0.77)}
      letter-spacing: ${px(-10)};
    }
  }

  > .uv {
    ${uvBase()}
  }

  > .uv-fingerprint {
    ${gameOneZIndex('uv')}
    ${rect('absolute', 0, 0, '100%', '100%')}
    background: url(${require('assets/images/game-1-phone-uv-clock.png')});
    background-position: bottom;
    background-size: cover;
    mix-blend-mode: hard-light;
  }
`;
