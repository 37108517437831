import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/vars';
import gsap from 'gsap';
import AdobeAnalytics from 'utils/adobeAnalytics';

import { PageProps } from 'types/page';
import ShareIcon from 'components/Icons/ShareIcon';
import * as Styled from './WhatsMurderIsland.styles';
import CtaYellow from 'components/CtaYellow';
import { Button } from 'components/Button';
import playerImage from 'assets/images/whats-murder-island-player-video.png';
import { HistoryContext, HistoryContextType } from 'context/history';
import { HeaderContext, HeaderContextType } from 'context/header';
import { RedirectsContext, RedirectsContextType } from 'context/redirects';
import { latestVisitedGamePage } from 'constants/historyPages';
import { useViewportScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { PageState } from 'constants/enum';

interface WhatsMurderIslandProps extends PageProps {}

const WhatsMurderIsland: React.FC<WhatsMurderIslandProps> = ({ visible, setPageCallback }) => {
  const { t } = useTranslation();

  const [titleIntersectionRef, titleInView] = useInView({ threshold: 1 });
  const { history } = useContext<HistoryContextType>(HistoryContext);
  const { setRedirects } = useContext<RedirectsContextType>(RedirectsContext);
  const { setHeader } = useContext<HeaderContextType>(HeaderContext);

  const [videoPlay, setVideoPlay] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoPlayerRef = useRef<HTMLDivElement>(null);

  const playVideo = useCallback(() => {
    setVideoPlay(true);
    videoRef.current.play();
  }, [videoRef]);

  const onVideoEnded = useCallback(() => {
    setVideoPlay(false);
  }, []);

  const goBack = useCallback(() => {
    const latestGamePage = latestVisitedGamePage(history) || '1';
    const lastGameState = latestGamePage || null;

    setPageCallback(parseInt(latestGamePage));
    setRedirects({ gameState: lastGameState.split('.')[1] });
  }, [history, setPageCallback, setRedirects]);

  const goBackToLandingPage = useCallback(() => setPageCallback(PageState.LANDING_PAGE), [setPageCallback]);

  const handleButtonWatch = () => {
    window.open('https://www.channel4.com/programmes/murder-island', '_blank');
  };

  useEffect(() => {
    const timeline = gsap.timeline();
    const video = videoRef.current;
    const videoButton = videoPlayerRef.current;

    if (videoPlay) {
      timeline.set(video, { opacity: 0, zIndex: 0 }, 0).to(video, { opacity: 1, zIndex: 2, duration: 0.5 }, 0);
      timeline.set(videoButton, { opacity: 1 }, 0).to(videoButton, { opacity: 0, zIndex: 0, duration: 0.5 }, 0);
    } else {
      timeline.set(video, { opacity: 0, zIndex: 0 }, 0);
      timeline.set(videoButton, { opacity: 0 }, 0).to(videoButton, { opacity: 1, zIndex: 2, duration: 0.5 }, 0);
    }

    return () => {
      timeline.kill();
    };
  }, [videoPlay]);

  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener('ended', onVideoEnded);
    return () => {
      video.removeEventListener('ended', onVideoEnded);
    };
  }, [onVideoEnded]);

  useEffect(() => {
    setHeader({ isWhite: titleInView, hasMask: !titleInView, isBackButton: true, onBack: goBack });
  }, [setHeader, titleInView, goBack]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('murder_Island', 'pageview');
  }, []);

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <div className="content">
        <div className="background-wrapper">
          <div className="video-wrapper">
            <div className="background-image"></div>
            <div className="title-wrapper">
              <h1 ref={titleIntersectionRef}>{parse(t('whatsMurderIsland.title'))}</h1>
            </div>
            <video ref={videoRef} controls={true} preload="metadata" playsInline>
              <source src="https://murderisland.channel4.com/movies/MI_Crime_HubPromo.mp4" type="video/mp4" />
            </video>
            <div className="player-wrapper" ref={videoPlayerRef} onClick={playVideo}>
              <img src={playerImage} alt={`${parse(t('whatsMurderIsland.title'))}`} />
              <p>{parse(t('whatsMurderIsland.watch'))}</p>
            </div>
          </div>
          <p className="first-paragraph">{parse(t('whatsMurderIsland.firstParagraph'))}</p>
        </div>
        <p className="second-paragraph">{parse(t('whatsMurderIsland.secondParagraph'))}</p>
        <div className="back-button-wrapper">
          <CtaYellow label={`${parse(t('whatsMurderIsland.backButton'))}`} onClick={goBackToLandingPage} big />
        </div>
        <div className="watch-button-wrapper">
          <Button
            label={`${parse(t('whatsMurderIsland.watchButton'))}`}
            onClick={handleButtonWatch}
            iconRight={<ShareIcon color={colors.blackBeluga} />}
          />
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default memo(WhatsMurderIsland);
