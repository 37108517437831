import styled from 'styled-components';
import { motion } from 'framer-motion';

import { box, px } from 'styles/mixins';
import { colors } from 'styles/vars';
import { fontChannel4Horseferry } from 'styles/typography';

type StyledButtonProps = {
  icon?: string;
  revertColor: boolean;
};

export const Wrapper = styled.button<StyledButtonProps>`
  ${box(px(168), px(56))}
  background-color:${({ revertColor }) => (revertColor === true ? colors.white : colors.black)};
  color: ${({ revertColor }) => (revertColor === true ? colors.black : colors.white)};
  border: 0.1rem solid ${({ revertColor }) => (revertColor === true ? colors.black : colors.white)};
  > .container {
    display: flex;
    margin-left: ${px(18)};
    align-items: center;

    .title {
      ${fontChannel4Horseferry(15, 700, 0.84)}
      text-transform: uppercase;
      margin: 0 0 0 ${px(18)};
      justify-content: center;
      display: table-caption;
    }

    .icon {
      ${box(px(25), px(25))};
      display: flex;
      align-items: center;
      filter: ${({ revertColor }) =>
        revertColor === true ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));' : 'none;'};
    }
  }
`;
