import React, { memo, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SplitText from 'gsap/dist/SplitText';
import parse from 'html-react-parser';
import gsap from 'gsap';

import * as Styled from './GameTwoIntro.styled';

import { HeaderContext, HeaderContextType } from 'context/header';
import { ProgressContext } from 'context/progress';

import AdobeAnalytics from 'utils/adobeAnalytics';

import CtaYellow from 'components/CtaYellow';
import DetectiveChat, { DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import GameIntroLanding, { GameIntroLandingRef } from 'components/GameIntroLanding/GameIntroLanding';
import { GameDetectiveVideoRef, PlayVideoMessage } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import GameTwoStripeContainer from 'components/GameTwoBrowserContainer';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { PageState } from 'constants/enum';
import { isDoubleTapEnabledBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';

const imagesToPreload = [
  require('assets/images/game-2-intro-uv-landing.png'),
  require('assets/images/game-2-intro-uv-tutorial1.png'),
  require('assets/images/game-2-intro-tutorial-media-uv.png'),
  require('assets/images/game-2-intro-tutorial-flashlight-uv.png'),
  require('assets/images/game-2-intro-tutorial-flashlight-uv-mode.png'),
  require('assets/images/game-2-intro-uv-tutorial3.png'),
  require('assets/images/game-2-intro-tutorial-paper-uv.png'),
];

interface Props {
  uvLight: boolean;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onExit: () => void;
  onComplete: () => void;
}

enum State {
  LANDING,
  TUTORIAL,
}

export const GameTwoIntro: React.FC<Props> = ({ uvLight, detectiveVideoRef, onComplete, onExit }) => {
  const { t } = useTranslation();
  const { setHeader }: HeaderContextType = useContext(HeaderContext);
  const { setProgress } = useContext(ProgressContext);

  const [state, setState] = useState(State.LANDING);
  const [slide, setSlide] = useState(0);

  const landingRef = useRef<GameIntroLandingRef>(null);
  const tutorialRef = useRef<HTMLDivElement>(null);
  const tutorialBgTextRef = useRef<HTMLDivElement>(null);
  const slide1Ref = useRef<HTMLDivElement>(null);
  const slide2Ref = useRef<HTMLDivElement>(null);
  const slide3Ref = useRef<HTMLDivElement>(null);
  const videoPlayedRef = useRef(false);
  const detectiveChatRef = useRef<DetectiveChatRef>(null);

  const showDetectiveChat = useMemo(() => state === State.TUTORIAL && slide !== 0, [slide, state]);

  const handleCtaClick = useCallback(() => {
    if (state === State.LANDING) {
      setState(State.TUTORIAL);
      videoPlayedRef.current = true;
      detectiveVideoRef.current.play(() => {
        setSlide(1);
      });
    }
  }, [detectiveVideoRef, state]);

  const handleNextClick = useCallback(() => {
    if (state === State.TUTORIAL) setSlide(s => Math.min(3, s + 1));
  }, [state]);

  const handleStartClick = useCallback(() => {
    if (state === State.TUTORIAL) onComplete();
  }, [onComplete, state]);

  const handleBackClick = useCallback(() => {
    if (state === State.LANDING) {
      onExit();
    } else if (slide === 1) {
      setSlide(0);
      setState(State.LANDING);
    } else if (slide === 0) {
      detectiveVideoRef.current.stop();
      setState(State.LANDING);
      setSlide(0);
    } else {
      setSlide(s => s - 1);
    }
  }, [onExit, slide, state, detectiveVideoRef]);

  useEffect(() => {
    setProgress({ lastState: PageState.GAME_TWO });
  }, [setProgress]);

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    let timeout;
    if (showDetectiveChat) {
      timeout = setTimeout(() => {
        detectiveChatRef.current.addMessage({
          text: () => <PlayVideoMessage />,
          onClick: () => detectiveVideoRef.current.play(),
        });
        detectiveChatRef.current.addMessage({
          text: isDoubleTapEnabledBrowser() ? t('gameTwo.detective.afterCall') : t('gameTwo.detective.afterCallHold'),
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [detectiveVideoRef, showDetectiveChat, t]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game_2_intro', 'pageview', 'game2');
  }, []);

  useEffect(() => {
    if (showDetectiveChat) {
      setHeader({
        isWhite: false,
        isBackButton: true,
        children: <DetectiveChat ref={detectiveChatRef} showArrows={isSocialGestureMinimizedBrowser()} />,
        onBack: handleBackClick,
      });
    } else {
      setHeader({
        isWhite: true,
        isBackButton: true,
        onBack: handleBackClick,
      });
    }
  }, [showDetectiveChat, setHeader, handleBackClick]);

  // main transition
  useEffect(() => {
    const timeline = gsap.timeline();
    if (state === State.LANDING) {
      timeline
        .add(landingRef.current.animateIn(), 0)
        .to(tutorialRef.current, { duration: 0.5, autoAlpha: 0 }, 0)
        .add(() => setSlide(0), 0.5);
    } else {
      timeline //
        .add(landingRef.current.animateOut())
        .to(tutorialRef.current, { duration: 1, autoAlpha: 1 });
    }
    return () => {
      timeline.kill();
    };
  }, [state]);

  // slides transitions
  useEffect(() => {
    const slide1 = slide1Ref.current;
    const slide1Text = slide1.querySelector('.description');
    const slide1Media = slide1.querySelector('.media');
    const slide1TextSplit = new SplitText(slide1Text, { type: 'words,chars' });
    const slide2 = slide2Ref.current;
    const slide2Text = slide2.querySelector('.description');
    const slide2Media = slide2.querySelector('.media');
    const slide2TextSplit = new SplitText(slide2Text, { type: 'words,chars' });
    const slide3 = slide3Ref.current;
    const slide3Text = slide3.querySelector('.description');
    const slide3Media = slide3.querySelector('.media');
    const slide3TextSplit = new SplitText(slide3Text, { type: 'words,chars' });

    const bgTextSplit = new SplitText(tutorialBgTextRef.current, { type: 'words' });

    const timeline = gsap.timeline();

    if (slide === 1) {
      timeline
        .set(slide1Text, { x: '30%' }, 0)
        .set(slide1Media, { y: '-30%' }, 0)
        .to(slide1, { duration: 0.4, alpha: 1 }, 0.4)
        .to(slide1Text, { duration: 1, x: 0, ease: 'expo.out' }, 0.4)
        .to(slide1Media, { duration: 1, y: 0, ease: 'expo.out' }, 0.4)
        .fromTo(slide1TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    } else {
      timeline.to(slide1, { duration: 0.4, alpha: 0 }, 0);
    }

    if (slide === 2) {
      timeline
        .set(slide2Text, { x: '30%' }, 0)
        .set(slide2Media, { scale: 1.2 }, 0)
        .to(slide2, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide2Text, { duration: 1, x: 0, ease: 'expo.out' }, 0.4)
        .to(slide2Media, { duration: 1, scale: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide2TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4)
        .to(bgTextSplit.words[0], { duration: 1, scrambleText: bgTextSplit.words[0].textContent }, 1);
    } else {
      timeline.to(slide2, { duration: 0.4, autoAlpha: 0 }, 0);
    }

    if (slide === 3) {
      timeline
        .set(slide3Text, { x: '30%' }, 0)
        .set(slide3Media, { scale: 1.2 }, 0)
        .to(slide3, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide3Text, { duration: 1, x: 0, ease: 'expo.out' }, 0.4)
        .to(slide3Media, { duration: 1, scale: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide3TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.2)
        .to(bgTextSplit.words[1], { duration: 1, scrambleText: bgTextSplit.words[1].textContent }, 1);
    } else {
      timeline.to(slide3, { duration: 0.4, autoAlpha: 0 }, 0);
    }

    return () => {
      timeline.kill();
      bgTextSplit.revert();
      slide1TextSplit.revert();
      slide2TextSplit.revert();
      slide3TextSplit.revert();
    };
  }, [state, slide]);

  return (
    <Styled.Wrapper transition={{ duration: 0.1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <GameIntroLanding
        cta={t('gameTwo.intro.landing.cta')}
        title={t('gameTwo.intro.landing.case')}
        number={t('gameTwo.intro.landing.number')}
        subtitle={t('gameTwo.intro.landing.subtitle')}
        description={t('gameTwo.intro.landing.description')}
        videoSrc={require('assets/videos/teaser2-slice-2.mp4')}
        posterSrc={require('assets/images/teaser2-slice-2.jpg')}
        onCtaClick={handleCtaClick}
        ref={landingRef}
      />

      <div className="tutorial" ref={tutorialRef}>
        <div className="slides">
          <div className="slide" ref={slide1Ref}>
            <div className="media">
              <div className="media-inner-wrapper">
                <GameTwoStripeContainer updateUVBounds={() => {}} state={slide} type={'animation'} />
              </div>
            </div>
            <p className="description" aria-label={t('gameTwo.intro.tutorial.slide1.description')}>
              <span className="underline1" />
              {parse(t('gameTwo.intro.tutorial.slide1.descriptionHtml'))}
            </p>
          </div>

          <div className="slide" ref={slide2Ref}>
            <div className="media">
              <div className="flashlight" />
            </div>
            <p className="description" aria-label={t('gameTwo.intro.tutorial.slide2.description')}>
              <span className="underline2" />
              {parse(t('gameTwo.intro.tutorial.slide2.descriptionHtml'))}
            </p>
          </div>

          <div className="slide" ref={slide3Ref}>
            <div className="media">
              <div className="paper" />
            </div>
            <p className="description" aria-label={t('gameTwo.intro.tutorial.slide3.description')}>
              {parse(t('gameTwo.intro.tutorial.slide3.descriptionHtml'))}
            </p>
          </div>
        </div>

        <p className="bgtext" ref={tutorialBgTextRef}>
          {parse(t('gameTwo.intro.tutorial.bg'))}
        </p>

        <div className="footer">
          <div className="buttons">
            <CtaYellow
              className={classNames('start', { show: slide === 3 })}
              label={t('gameTwo.intro.tutorial.start')}
              onClick={handleStartClick}
              big
            />
            <CtaYellow
              className={classNames('next', { show: slide !== 3 })}
              label={t('gameTwo.intro.tutorial.next')}
              onClick={handleNextClick}
            />
          </div>
          <div className="progress">
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 1 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 2 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 3 })} />
            </div>
          </div>
          <div className="page">{Math.max(1, slide)}/3</div>
        </div>
      </div>
      {uvLight ? (
        <div className="uv">
          {state === State.LANDING ? (
            <div className="landing" />
          ) : (
            <>
              {slide === 1 ? (
                <div className="tutorial1">
                  <div className="uv-item">
                    <div className="media-uv" />
                  </div>
                </div>
              ) : null}
              {slide === 2 ? (
                <div className="tutorial2">
                  <div className="uv-item">
                    <div className="flashlight-uv" />
                  </div>
                </div>
              ) : null}
              {slide === 3 ? (
                <div className="tutorial3">
                  <div className="uv-item">
                    <div className="paper-uv" />
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </Styled.Wrapper>
  );
};

export default memo(GameTwoIntro);
