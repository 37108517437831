export const moveArray = (arr: any, fromIndex: number, toIndex: number) => {
  const startIndex = fromIndex < 0 ? arr.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < arr.length) {
    const endIndex = toIndex < 0 ? arr.length + toIndex : toIndex;

    const [item] = arr.splice(fromIndex, 1);
    arr.splice(endIndex, 0, item);
    const array = [...arr];
    return array;
  }
  return arr;
};

export const shuffleArray = arr => {
  const array = [...arr];

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
