import React, {
  forwardRef,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './GameOneDetective.styled';

import { PhoneState } from 'components/GameOnePhone/GameOnePhone';
import { GameDetectiveVideoRef, PlayVideoMessage } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import DetectiveChat, { DetectiveChatMessage, DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import { isDoubleTapEnabledBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';

enum QuizMode {
  HELP,
  ANSWER,
}
interface Props {
  phoneState: PhoneState;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onComplete: (accuracy: number) => void;
}

export interface GameOneDetectiveRef {
  addMessage: (message: DetectiveChatMessage) => void;
}

const GameOneDetective = forwardRef<GameOneDetectiveRef, Props>(
  ({ phoneState, detectiveVideoRef, onComplete }, ref) => {
    const { t } = useTranslation();

    const quizModeRef = useRef(QuizMode.HELP);
    const lastMessageRef = useRef<DetectiveChatMessage>(null);
    const chatTimeoutRef = useRef(null);
    const detectiveChatRef = useRef<DetectiveChatRef>(null);
    const visitedPhoneStatesRef = useRef<PhoneState[]>([phoneState]);

    const [caseClosed, setCaseClosed] = useState(0);
    const [quizAnswers, setQuizAnswers] = useState([]);

    const help = useMemo(() => t('gameOne.detective.help', { returnObjects: true }), [t]) as any;
    const quiz = useMemo(() => t('gameOne.detective.quiz', { returnObjects: true }), [t]) as any[];

    const clockHintsRef = useRef(t('gameOne.detective.hints.clock', { returnObjects: true }) as string[]);
    const messageHintsRef = useRef(t('gameOne.detective.hints.message', { returnObjects: true }) as string[]);
    const galleryHintsRef = useRef(
      t(isDoubleTapEnabledBrowser() ? 'gameOne.detective.hints.gallery' : 'gameOne.detective.hintsHold.gallery', {
        returnObjects: true,
      }) as string[]
    );
    const voicemailHintsRef = useRef(t('gameOne.detective.hints.voicemail', { returnObjects: true }) as string[]);

    const addMessage = useCallback(
      (message: DetectiveChatMessage) => {
        if (!caseClosed) {
          detectiveChatRef.current.addMessage(message);
          detectiveChatRef.current.hideCta();
          lastMessageRef.current = message;
        }
      },
      [caseClosed]
    );

    const addHintMessage = useCallback(() => {
      if (!caseClosed) {
        let hint = '';
        if (phoneState === PhoneState.CLOCK) {
          hint = clockHintsRef.current.shift() || t('gameOne.detective.noMoreHints.clock');
        }
        if (phoneState === PhoneState.MESSAGE) {
          hint = messageHintsRef.current.shift() || t('gameOne.detective.noMoreHints.message');
        }
        if (phoneState === PhoneState.GALLERY) {
          hint = galleryHintsRef.current.shift() || t('gameOne.detective.noMoreHints.gallery');
        }
        if (phoneState === PhoneState.VOICEMAIL) {
          hint = voicemailHintsRef.current.shift() || t('gameOne.detective.noMoreHints.voicemail');
        }
        addMessage({ text: hint });
        addMessage({ text: '', options: help.options });
      }
    }, [addMessage, caseClosed, help.options, phoneState, t]);

    const toggleChat = useCallback(() => {
      detectiveChatRef.current.toggleChat();
      detectiveChatRef.current.hideCta();
    }, []);

    const handleCtaClick = useCallback(() => {
      if (caseClosed) {
        onComplete(caseClosed);
      } else {
        toggleChat();
      }
    }, [caseClosed, onComplete, toggleChat]);

    const onQuizAnswer = useCallback(
      answer => {
        switch (quizModeRef.current) {
          case QuizMode.ANSWER:
            setQuizAnswers([...quizAnswers, answer]);
            break;
          // QuizMode.HELP
          default:
            if (answer === 0) {
              addHintMessage();
            }
            if (answer === 1) {
              quizModeRef.current = QuizMode.ANSWER;
              setQuizAnswers([]);
              addMessage({ text: quiz[0].question, options: quiz[0].options, correctOption: quiz[0].correct });
            }
            if (answer > 1) {
              toggleChat();
              addMessage({ text: '', options: help.options });
            }
            break;
        }
      },
      [addHintMessage, addMessage, help.options, quiz, quizAnswers, toggleChat]
    );

    useEffect(() => {
      if (quizAnswers.length) {
        if (quizAnswers.length === quiz.length) {
          quizModeRef.current = QuizMode.HELP;
          clearTimeout(chatTimeoutRef.current);
          chatTimeoutRef.current = setTimeout(() => {
            const correctAnswers = detectiveChatRef.current.correctQuiz();
            clearTimeout(chatTimeoutRef.current);
            chatTimeoutRef.current = setTimeout(() => {
              if (correctAnswers > 1) {
                setCaseClosed(correctAnswers);
                detectiveChatRef.current.showCta();
              } else {
                if (correctAnswers === 0) {
                  addMessage({ text: t('gameOne.detective.allWrong') });
                } else if (correctAnswers === 1) {
                  addMessage({ text: t('gameOne.detective.twoWrong') });
                }
                addMessage({ text: '', options: help.options });
                clearTimeout(chatTimeoutRef.current);
                chatTimeoutRef.current = setTimeout(() => detectiveChatRef.current.showCta(), 1000);
              }
            }, Math.random() * 1000 + 1000);
          }, 500);
        } else {
          addMessage({
            text: quiz[quizAnswers.length].question,
            options: quiz[quizAnswers.length].options,
            correctOption: quiz[quizAnswers.length].correct,
          });
        }
      }
      return () => {
        clearTimeout(chatTimeoutRef.current);
      };
    }, [addMessage, help.options, onComplete, quiz, quizAnswers, t]);

    useEffect(() => {
      const lastState = visitedPhoneStatesRef.current.slice(-1)[0];

      if (!lastMessageRef.current?.options) {
        if (phoneState === PhoneState.LOCK) {
          clearTimeout(chatTimeoutRef.current);
          chatTimeoutRef.current = setTimeout(() => {
            addMessage({ text: () => <PlayVideoMessage />, onClick: () => detectiveVideoRef.current.play() });
            addMessage({
              text: isDoubleTapEnabledBrowser()
                ? t('gameOne.detective.afterCall')
                : t('gameOne.detective.afterCallHold'),
            });
            addMessage({
              text: isDoubleTapEnabledBrowser() ? t('gameOne.detective.password') : t('gameOne.detective.passwordHold'),
            });
          }, 1000);
        }

        if (lastState === PhoneState.LOCK && phoneState === PhoneState.CLOCK) {
          setTimeout(() => {
            addMessage({ text: t('gameOne.detective.passwordUnlocked') });
            addMessage({ text: '', options: help.options });
          }, 1000);
        }
      }

      visitedPhoneStatesRef.current.push(phoneState);

      return () => {
        clearTimeout(chatTimeoutRef.current);
      };
    }, [addMessage, detectiveVideoRef, help.options, phoneState, t]);

    useImperativeHandle(ref, () => ({
      addMessage: message => addMessage(message),
    }));

    return (
      <Styled.Wrapper>
        <DetectiveChat
          onQuizAnswer={onQuizAnswer}
          onCtaClick={handleCtaClick}
          ctaLabel={caseClosed ? t('gameOne.detective.caseClosedCta') : t('gameOne.detective.returnCta')}
          cacheId="GameOneDetective"
          ref={detectiveChatRef}
          showArrows={isSocialGestureMinimizedBrowser()}
        />
      </Styled.Wrapper>
    );
  }
);

export default memo(GameOneDetective);
