import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import React, { memo, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import SplitText from 'gsap/dist/SplitText';
import * as Styled from './GameTwoStartCountdown.styled';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';
export interface Props {
  onComplete?: () => void;
}

export const GameTwoStartCountdown: React.FC<Props> = ({ onComplete }) => {
  const counterRef = useRef(null);
  const titleRef = useRef();
  const [counter, setCounter] = useState<number>(3);
  const { t } = useTranslation();

  useEffect(() => {
    const timeline = gsap.timeline();
    const initial = titleRef.current;

    const initialDescriptionSplit = new SplitText(initial, { type: 'lines,chars' });

    timeline
      .set(initialDescriptionSplit.chars, { opacity: 0 }, 0)
      .to(initialDescriptionSplit.chars, { duration: 0, stagger: 0.05, opacity: 1 }, 0.5);

    return () => {
      const dur = timeline.duration();
      timeline.seek(dur);
      timeline.kill();
    };
  }, []);

  useEffect(() => {
    gsap.fromTo(
      counterRef.current,
      {
        scale: 0.25,
        opacity: 0,
      },
      {
        opacity: 1,
        scale: 1,
        delay: 1,
        duration: 1,
        ease: 'sine.inOut',
        repeat: 3,
        onStart: () => {
          AudioManager.instance.playSound(sfx.countdown);
        },
        onRepeat: () => {
          setCounter(prev => {
            if (prev <= 1) {
              gsap.killTweensOf(counterRef.current);
              onComplete();
            } else {
              AudioManager.instance.playSound(sfx.countdown);
            }
            return (prev as number) - 1;
          });
        },
      }
    );
  }, [onComplete]);

  return (
    <>
      <Styled.Header key="countdown-header" ref={titleRef}>
        {parse(t('gameTwo.countdown.getReady'))}
      </Styled.Header>
      <Styled.Counter key="counter" ref={counterRef}>
        {counter}
      </Styled.Counter>
      <Styled.Background key="countdown-bg" initial={{ opacity: 0 }} animate={{ opacity: 0.6 }} exit={{ opacity: 0 }} />
    </>
  );
};

export default memo(GameTwoStartCountdown);
