import * as React from 'react';
import { colors } from 'styles/vars';

import SVGIconBase from './SVGIconBase';

const UncheckedIcon = ({ color }: { color?: string }) => {
  const newColor = color || colors.blackBeluga;
  const width = 20;
  const height = 20;

  return (
    <SVGIconBase width={width} height={height}>
      <g>
        <circle cx="10" cy="10" r="9" stroke={newColor} fill="none" strokeWidth="2" />
      </g>
    </SVGIconBase>
  );
};

UncheckedIcon.displayName = 'UncheckedIcon';

export default UncheckedIcon;

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
