import React, {
  memo,
  forwardRef,
  MutableRefObject,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import AdobeAnalytics from 'utils/adobeAnalytics';

import * as Styled from './GameTwoDetective.styled';

import { GameState } from 'components/GameTwoMystery/GameTwoMystery';
import { GameDetectiveVideoRef, PlayVideoMessage } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import DetectiveChat, { DetectiveChatMessage, DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import { isDoubleTapEnabledBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';

enum QuizMode {
  HELP,
  ANSWER,
  SHARE,
}
interface Props {
  gameState: GameState;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onComplete: (accuracy: number) => void;
  onToggle: (opened: boolean) => void;
}

export interface GameTwoDetectiveRef {
  addMessage: (message: DetectiveChatMessage) => void;
  reset: () => void;
  toggleChat: () => void;
}

const GameTwoDetective = forwardRef<GameTwoDetectiveRef, Props>(
  ({ gameState, detectiveVideoRef, onComplete, onToggle }, ref) => {
    const { t } = useTranslation();

    const [caseClosed, setCaseClosed] = useState(false);
    const [caseFinished, setCaseFinished] = useState(false);

    const [quizAnswers, setQuizAnswers] = useState([]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const lastMessageRef = useRef<DetectiveChatMessage>(null);
    const chatTimeoutRef = useRef(null);
    const detectiveChatRef = useRef<DetectiveChatRef>(null);

    const help = useMemo(() => t('gameTwo.detective.help', { returnObjects: true }), [t]) as any;
    const quiz = useMemo(() => t('gameTwo.detective.quiz', { returnObjects: true }), [t]) as any[];

    const hintsRef = useRef(t('gameTwo.detective.hints', { returnObjects: true }) as string[]);
    const quizModeRef = useRef(QuizMode.HELP);

    const addMessage = useCallback((message: DetectiveChatMessage, showNotification = true) => {
      detectiveChatRef.current.addMessage(message, showNotification);
      lastMessageRef.current = message;
    }, []);

    const addHintMessage = useCallback(() => {
      addMessage({ text: hintsRef.current.shift() || t('gameTwo.detective.noMoreHints') });
      addMessage({ text: '', options: help.options });
    }, [addMessage, help.options, t]);

    const toggleChat = useCallback(() => {
      detectiveChatRef.current.toggleChat();
      detectiveChatRef.current.hideCta();
    }, []);

    const resetAnswers = useCallback(() => {
      if (lastMessageRef.current.options) {
        delete lastMessageRef.current.options;
      }
    }, []);

    const handleCtaClick = useCallback(() => {
      if (caseClosed) {
        onComplete(caseFinished ? 3 : 2);
        toggleChat();
      } else {
        toggleChat();
      }
    }, [caseClosed, caseFinished, onComplete, toggleChat]);

    const onQuizAnswer = useCallback(
      answer => {
        switch (quizModeRef.current) {
          case QuizMode.ANSWER:
            setQuizAnswers([...quizAnswers, answer]);
            break;
          default:
            if (answer === 0) {
              addHintMessage();
            }
            if (answer === 1) {
              quizModeRef.current = QuizMode.ANSWER;
              setQuizAnswers([]);
              addMessage({ text: quiz[0].question, options: quiz[0].options, correctOption: quiz[0].correct });
            }
            if (answer > 1) {
              toggleChat();
              addMessage({ text: '', options: help.options });
            }
            break;
        }
      },
      [addHintMessage, addMessage, help.options, quiz, quizAnswers, toggleChat]
    );

    const handleYoutubeClick = useCallback(
      (e?) => {
        e?.preventDefault();
        // TODO add adobe analytics event
        window.open(t('comingSoon.youtubeLink'), '_blank');
      },
      [t]
    );

    useEffect(() => {
      if (quizAnswers.length) {
        if (quizAnswers.length === quiz.length) {
          quizModeRef.current = QuizMode.HELP;
          clearTimeout(chatTimeoutRef.current);
          chatTimeoutRef.current = setTimeout(() => {
            const correctAnswers = detectiveChatRef.current.correctQuiz();
            clearTimeout(chatTimeoutRef.current);
            chatTimeoutRef.current = setTimeout(() => {
              if (correctAnswers > 1) {
                setCaseClosed(true);
                detectiveChatRef.current.showCta();
                quizModeRef.current = QuizMode.SHARE;
              } else {
                if (correctAnswers === 0) {
                  addMessage({ text: t('gameTwo.detective.allWrong') });
                } else if (correctAnswers === 1) {
                  addMessage({ text: t('gameTwo.detective.twoWrong') });
                }

                addMessage({ text: '', options: help.options });
                clearTimeout(chatTimeoutRef.current);
                chatTimeoutRef.current = setTimeout(() => detectiveChatRef.current.showCta(), 1000);
              }
              if (correctAnswers > 2) {
                setCaseFinished(true);
              }
            }, Math.random() * 1000 + 1000);
          }, 500);
        } else {
          addMessage({
            text: quiz[quizAnswers.length].question,
            options: quiz[quizAnswers.length].options,
            correctOption: quiz[quizAnswers.length].correct,
          });
        }
      }
      return () => {
        clearTimeout(chatTimeoutRef.current);
      };
    }, [addMessage, handleYoutubeClick, help.options, onComplete, quiz, quizAnswers, t]);

    useEffect(() => {
      if (gameState === GameState.INIT) {
        addMessage({ text: () => <PlayVideoMessage />, onClick: () => detectiveVideoRef.current.play() }, false);
        addMessage(
          {
            text: isDoubleTapEnabledBrowser() ? t('gameTwo.detective.afterCall') : t('gameTwo.detective.afterCallHold'),
          },
          false
        );
      }
      if (!lastMessageRef.current?.options) {
        if (gameState === GameState.GAME_FINISHED) {
          clearTimeout(chatTimeoutRef.current);
          chatTimeoutRef.current = setTimeout(() => {
            addMessage({ text: t('gameTwo.detective.unlockedLetter') });
            addMessage({ text: '', options: help.options });
          }, 1000);
        }
      }

      return () => {
        clearTimeout(chatTimeoutRef.current);
      };
    }, [addMessage, detectiveVideoRef, gameState, help.options, t]);

    useEffect(() => {
      if (gameState === GameState.GAME) {
        hintsRef.current = t('gameTwo.detective.hints', { returnObjects: true }) as string[];
      }
    }, [gameState, t]);

    useImperativeHandle(ref, () => ({
      addMessage: message => addMessage(message),
      reset: () => resetAnswers(),
      toggleChat: () => toggleChat(),
    }));

    return (
      <>
        <Styled.Wrapper ref={wrapperRef}>
          <DetectiveChat
            onQuizAnswer={onQuizAnswer}
            onChatClose={() => onToggle(false)}
            onChatOpen={() => onToggle(true)}
            onCtaClick={handleCtaClick}
            showArrows={isSocialGestureMinimizedBrowser()}
            ctaLabel={caseClosed ? t('gameTwo.detective.caseClosedCta') : t('gameTwo.detective.returnCta')}
            cacheId="GameTwoDetective"
            ref={detectiveChatRef}
          />
        </Styled.Wrapper>
      </>
    );
  }
);

export default memo(GameTwoDetective);
