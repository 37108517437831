import { BoundsType } from 'components/GameTwoBrowserContainer/GameTwoBrowserContainer';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';

import * as Styled from './GameTwoElement.styled';
import { Position } from 'components/GameTwoContainer/GameTwoContainer';

export interface Props {
  i: number;
  id: number;
  bgSrc: string;
  width: number;
  setPosition: (i: number, offset: Position) => void;
  moveItem: (i: number, dragOffset: number) => void;
  setBounds?: React.Dispatch<React.SetStateAction<BoundsType>>;
  onActive: (id) => void;
  rotation: number;
  margin: number;
  rotatable: boolean;
  selectable: boolean;
  vScale?: number;
  isFinished: boolean;
}

export const GameTwoElement: React.FC<Props> = ({
  bgSrc,
  width,
  setPosition,
  setBounds,
  moveItem,
  onActive,
  i,
  id,
  margin,
  vScale,
  rotation,
  rotatable,
  selectable,
  isFinished,
}) => {
  const initialIndex = useRef(i).current;
  const ref = useRef(null);
  const dragTimeoutRef = useRef(null);
  const [isDragging, setDragging] = useState(false);

  let xoRef = useRef(0).current;
  let prevIndexRef = useRef(i).current;

  const onTop = { scale: rotatable ? 1.07 : 1, zIndex: rotatable ? 5 : 2, rotate: rotation };
  const flat = {
    scale: rotatable ? 1.07 : 1,
    zIndex: rotatable ? 5 : 1,
    transition: { delay: 0.2 },
    rotate: rotation,
  };

  useEffect(() => {
    setPosition(i, {
      width: ref.current.offsetWidth,
      left: ref.current.offsetLeft,
    });
  });

  const updateBounds = useCallback(() => {
    const { top, left, bottom } = (ref.current as HTMLElement).getBoundingClientRect();
    setBounds(prev => {
      return { ...prev, top, left, bottom };
    });
  }, [setBounds]);

  const onAnimationComplete = useCallback(() => {
    if (i === 0) updateBounds();
  }, [i, updateBounds]);

  useEffect(() => {
    if (initialIndex === 0 && id === 0) {
      updateBounds();
    }
  }, [id, initialIndex, updateBounds]);

  useEffect(() => {
    return () => {
      if (dragTimeoutRef.current) clearTimeout(dragTimeoutRef.current);
    };
  }, []);

  return (
    <Styled.Stripe
      ref={ref}
      initial={false}
      animate={isDragging ? onTop : flat}
      drag={isFinished ? false : 'x'}
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={1}
      // onTap={() => onActive(i)}
      onClick={() => {
        if (!isDragging) onActive(i);
      }}
      onAnimationComplete={onAnimationComplete}
      onDragStart={() => {
        AudioManager.instance.playSound(sfx[`page${Math.ceil(Math.random() * 5)}`]);
        xoRef = 0;
        onActive(-1);
        setDragging(true);
      }}
      onDragEnd={() => {
        dragTimeoutRef.current = setTimeout(() => {
          setDragging(false);
        }, 150);
      }}
      layout
      onDrag={(e, { offset }) => {
        if (i !== prevIndexRef) {
          xoRef = offset.x;
          prevIndexRef = i;
        }
        moveItem(i, -xoRef + offset.x);
      }}
      margin={margin}
      vScale={vScale}
      width={width}
      bgSrc={bgSrc}
    />
  );
};

export default GameTwoElement;
