import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { px, rect, vh } from 'styles/mixins';
import { fontChannel4Horseferry } from 'styles/typography';

export const Wrapper = styled(motion.ul)<{ vScale: number; active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-top: ${props => px(props.vScale * 160)};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
`;

export const ButtonWrapper = styled(motion.div)<{ active: boolean }>`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 5;
  padding-top: ${px(30)};
  justify-content: center;
  pointer-events: ${props => (props.active ? 'all' : 'none')};
`;

export const RotateButton = styled(motion.button)`
  ${fontChannel4Horseferry(20, 700)}
  text-transform: uppercase;
  display: flex;
  p {
    padding-left: ${px(10)};
  }
`;

export const Background = styled(motion.div)<{ active }>`
  ${rect('fixed', 0, 0, '100vw', '100vh')};
  z-index: 1;
  background: ${colors.black};
  pointer-events: ${props => (props.active ? 'all' : 'none')};
`;

export const DotWrapper = styled(motion.ul)<{ vScale: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 5;
  margin-top: ${props => px(props.vScale * 160)};
  pointer-events: none;
`;

export const Dot = styled(motion.div)<{ width: number; margin: number; vScale: number; active: boolean }>`
  height: calc(116vw * ${props => props.vScale});
  width: ${props => props.width}vw;
  margin-left: ${props => -props.margin}vw;
  position: relative;
  z-index: 2;
  pointer-events: ${props => (props.active ? 'all' : 'none')};

  &:before {
    content: '';
    width: ${px(11)};
    height: ${px(11)};
    border-radius: ${px(5.5)};
    background: ${colors.white};
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 50;
  }
`;
