import React, {
  memo,
  ReactNode,
  forwardRef,
  useRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';
import gsap from 'gsap';

import * as Styled from './DetectiveChat.styled';

import useClickAway from 'hooks/useClickAway';

import CtaYellow from 'components/CtaYellow';

import SvgClose from 'svgs/Close.svg';
import SvgCheckmark from 'svgs/Checkmark.svg';
import SvgExclamation from 'svgs/Exclamation.svg';

import ImgDetective from 'assets/images/detective-chat-avatar.jpg';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';
import ScrollArrows from 'components/ScrollArrows';

export interface Props {
  className?: string;
  cacheId?: string;
  ctaLabel?: string;
  showArrows?: boolean;
  onChatOpen?: () => void;
  onChatClose?: () => void;
  onCtaClick?: () => void;
  onQuizAnswer?: (optionIndex: number) => void;
}

export interface DetectiveChatMessage {
  text: string | (() => ReactNode);
  date?: string;
  options?: string[];
  corrected?: boolean;
  correctOption?: number;
  onClick?: () => void;
}

export interface DetectiveChatRef {
  showCta: () => void;
  hideCta: () => void;
  openChat: () => void;
  clearChat: () => void;
  closeChat: () => void;
  toggleChat: () => void;
  correctQuiz: () => number;
  addMessage: (message: DetectiveChatMessage, showNotification?: boolean) => void;
}

const quizAnswersCache = {};

const DetectiveChat = forwardRef<DetectiveChatRef, Props>(
  (
    {
      cacheId,
      ctaLabel,
      className,
      showArrows = false,
      onCtaClick = () => {},
      onChatOpen = () => {},
      onChatClose = () => {},
      onQuizAnswer = () => {},
    },
    ref
  ) => {
    const { t } = useTranslation();

    const titleRef = useRef<HTMLDivElement>(null);
    const ctaRef = useRef<HTMLButtonElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const messagesRef = useRef<HTMLDivElement>(null);

    const openRef = useRef(false);

    const [cta, setCta] = useState(false);
    const [scrollButonsNeeded, setScrollButonsNeeded] = useState(false);
    const [open, setOpen] = useState(openRef.current);
    const [messages, setMessages] = useState<DetectiveChatMessage[]>([]);
    const [quizAnswers, setQuizAnswers] = useState<number[]>(
      cacheId && quizAnswersCache[cacheId] ? quizAnswersCache[cacheId] : []
    );
    const [notification, setNotification] = useState('');
    const [notificationBubble, setNotificationBubble] = useState(false);

    const scrollToLastMessage = useCallback(() => {
      gsap.to(messagesRef.current, {
        duration: 0.6,
        ease: 'cubic.inOut',
        scrollTo: { x: 0, y: messagesRef.current.scrollHeight - messagesRef.current.clientHeight, autoKill: false },
      });
    }, []);

    const scrollVertical = useCallback((up: boolean) => {
      gsap.to(messagesRef.current, {
        duration: 0.6,
        ease: 'cubic.inOut',
        scrollTo: {
          x: 0,
          y: up
            ? messagesRef.current.scrollTop - messagesRef.current.clientHeight
            : messagesRef.current.scrollTop + messagesRef.current.clientHeight,
          autoKill: false,
        },
      });
    }, []);

    const openChat = useCallback(() => {
      setNotification('');
      setNotificationBubble(false);
      setOpen(true);
      onChatOpen();
    }, [onChatOpen]);

    const closeChat = useCallback(() => {
      setOpen(false);
      onChatClose();
    }, [onChatClose]);

    const toggleChat = useCallback(() => {
      if (open) {
        closeChat();
      } else {
        openChat();
      }
    }, [closeChat, open, openChat]);

    const clearChat = useCallback(() => {
      setMessages([]);
    }, []);

    const dismissBubble = useCallback(() => {
      setNotificationBubble(false);
    }, []);

    const handleQuizAnswer = useCallback(
      (messageIndex: number, selectedIndex: number) => {
        if (quizAnswers[messageIndex] === undefined) {
          const next = [...quizAnswers];
          next[messageIndex] = selectedIndex;
          setQuizAnswers(next);
          onQuizAnswer(selectedIndex);
        }
      },
      [onQuizAnswer, quizAnswers]
    );

    const correctQuiz = useCallback(() => {
      let numCorrectAnswers = 0;
      const next = messages.map((message, i) => {
        if (message.options && !message.corrected && message.correctOption !== undefined) {
          if (quizAnswers[i] === message.correctOption) numCorrectAnswers++;
          message.corrected = true;
        }
        return message;
      });
      setMessages(next);
      return numCorrectAnswers;
    }, [messages, quizAnswers]);

    useClickAway(wrapperRef, dismissBubble);

    useEffect(() => {
      openRef.current = open;
      if (messagesRef.current) {
        setScrollButonsNeeded(messagesRef.current.scrollHeight > messagesRef.current.clientHeight);
      }
      if (open) scrollToLastMessage();
    }, [messages.length, open, scrollToLastMessage]);

    useEffect(() => {
      let timeout;
      if (open && ctaLabel) {
        if (cta) {
          gsap.set(ctaRef.current, { y: 0, autoAlpha: 1, pointerEvents: 'auto' });
          gsap.set(messagesRef.current, { height: `calc(100% - 7rem - ${ctaRef.current.clientHeight}px)` });
          timeout = setTimeout(() => scrollToLastMessage(), 300);
        } else {
          gsap.set(ctaRef.current, { clearProps: true });
          gsap.set(messagesRef.current, { clearProps: true });
        }
      }
      return () => {
        clearTimeout(timeout);
      };
    }, [open, cta, scrollToLastMessage, ctaLabel]);

    useImperativeHandle(ref, () => ({
      hideCta: () => setCta(false),
      showCta: () => setCta(true),
      openChat: () => openChat(),
      closeChat: () => closeChat(),
      clearChat: () => clearChat(),
      toggleChat: () => toggleChat(),
      correctQuiz: () => correctQuiz(),
      addMessage: (message: DetectiveChatMessage, showNotification = true) => {
        if (message.text || message.options?.length) {
          const d = new Date();
          const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
          const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
          const hours = d.getHours().toString().padStart(2, '0');
          const minutes = d.getMinutes().toString().padStart(2, '0');
          message.date = `${days[d.getDay()]} ${d.getDate()} ${months[d.getMonth()]}, ${hours}:${minutes}`;
          setMessages(current => [...current, message]);
          if (showNotification) {
            setTimeout(() => {
              if (!openRef.current && !message.options && typeof message.text === 'string') {
                AudioManager.instance.playSound(sfx.notification);
                setNotification(message.text);
                setNotificationBubble(true);
              }
            });
          }
        }
      },
    }));

    useEffect(() => {
      if (open) {
        titleRef?.current?.focus();
      }
    }, [open]);

    return (
      <Styled.Wrapper ref={wrapperRef} className={className}>
        <button
          className={classNames('toggle', { blue: notification || open })}
          onClick={messages.length ? toggleChat : null}
          aria-label={open ? 'Close chat with detective' : 'Open chat with detective'}
        >
          <AnimatePresence>
            {open ? (
              <motion.div
                key="svg"
                transition={{ duration: 0.6, ease: 'backInOut' }}
                initial={{ opacity: 0, scale: 0.5, x: '-50%', y: '-50%' }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                className="icon"
              >
                <SvgClose />
              </motion.div>
            ) : (
              <motion.img
                key="img"
                transition={{ duration: 0.6, ease: 'backInOut' }}
                initial={{ opacity: 0, scale: 0.5, x: '-50%', y: '-50%' }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                className="avatar"
                src={ImgDetective}
                alt="Detective"
              />
            )}
          </AnimatePresence>

          {notification ? (
            <div className="exclamation">
              <SvgExclamation />
            </div>
          ) : null}
        </button>

        <AnimatePresence>
          {notification && notificationBubble ? (
            <motion.button
              className="notification"
              onClick={toggleChat}
              transition={{ duration: 0.6, ease: 'backInOut' }}
              initial={{ opacity: 0, x: '5%', scale: 0.5 }}
              animate={{ opacity: 1, x: '0%', scale: 1 }}
              exit={{ opacity: 0, x: '5%', scale: 0.5 }}
              aria-label="Open chat with detective"
            >
              {parse(notification)}
            </motion.button>
          ) : null}
        </AnimatePresence>

        <AnimatePresence>
          {open ? (
            <motion.div
              className="chat"
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              initial={{ opacity: 0, x: '-50%', y: '5%' }}
              animate={{ opacity: 1, x: '-50%', y: '0%' }}
              exit={{ opacity: 0, x: '-50%', y: '5%' }}
            >
              <div className="title" ref={titleRef} tabIndex={-1}>
                <img className="avatar" src={ImgDetective} alt="" />
                <p className="name">{parse(t('detectiveChat.name'))}</p>
              </div>
              <div className="messages" ref={messagesRef}>
                {showArrows && scrollButonsNeeded && (
                  <div className="arrow-wrapper">
                    <ScrollArrows onUp={() => scrollVertical(true)} onDown={() => scrollVertical(false)} />
                  </div>
                )}
                {messages.map((message, i) => {
                  if (message.options) {
                    return (
                      <div key={i} className="quiz">
                        <p className="question">
                          {typeof message.text === 'string' ? parse(message.text) : message.text()}
                        </p>
                        <div className="options">
                          {message.options.map((option, j) => {
                            const selected = message.options.length === 1 || quizAnswers[i] === j;
                            const correct = selected && message.corrected && message.correctOption === j;
                            const wrong = selected && message.corrected && message.correctOption !== j;
                            return (
                              <button
                                key={`${i}-${j}`}
                                className={classNames('option', {
                                  hasCorrectAnswer: message.correctOption !== undefined,
                                  selected,
                                  correct,
                                  wrong,
                                })}
                                onClick={() =>
                                  message.options.length === 1 ? onQuizAnswer(0) : handleQuizAnswer(i, j)
                                }
                              >
                                {correct ? <SvgCheckmark /> : null}
                                {wrong ? <SvgClose /> : null}
                                <div className="text">{parse(option)}</div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        className="message"
                        onClick={message.onClick}
                        tabIndex={message.onClick ? 0 : undefined}
                      >
                        <img className="picture" src={ImgDetective} alt="" />
                        <div className="content">
                          <div className="date">{message.date}</div>
                          <div className="text">
                            {typeof message.text === 'string' ? parse(message.text) : message.text()}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {ctaLabel ? <CtaYellow className="cta" label={ctaLabel} onClick={onCtaClick} big ref={ctaRef} /> : null}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Styled.Wrapper>
    );
  }
);

export default memo(DetectiveChat);
