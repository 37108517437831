import { BoundsType } from 'components/GameTwoBrowserContainer/GameTwoBrowserContainer';
import { useMotionValue } from 'framer-motion';
import { useGesture } from '@use-gesture/react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';

import * as Styled from './GameTwoBrowserElement.styled';

export interface Props {
  id: number;
  state: AnimationState;
  interactive: boolean;
  isFinished: boolean;
  bgSrc: string;
  width: number;
  margin: number;
  baseX?: number;
  baseY?: number;
  baseR?: number;
  isFirst?: boolean;
  bounds?: BoundsType;
  setBounds?: React.Dispatch<React.SetStateAction<BoundsType>>;
  newIndex?: () => string;
  checkComplete?: (ready: boolean, id: number) => void;
  vScale: number;
}

export enum AnimationState {
  IN = 1,
  OUT = 2,
}

export const GameTwoBrowserElement: React.FC<Props> = ({
  id,
  state,
  interactive = true,
  isFinished,
  bgSrc,
  width,
  margin,
  isFirst,
  bounds,
  baseX,
  baseY,
  baseR,
  newIndex,
  setBounds,
  checkComplete,
  vScale,
}) => {
  const elementRef = useRef(null);
  const [ready, setReady] = useState(false);
  const { clientWidth, clientHeight } = document.body;
  const offsetRef = useRef({ offsetLeft: 0, offsetTop: 0, offsetBottom: 0, offsetWidth: 0, offsetHeight: 0 });

  const baseWidth = useRef(0);

  const [stacked, setStacked] = useState(false);
  const [finalRotation, setfinalRotation] = useState<number>(0);

  const xm = useMotionValue(baseX);
  const ym = useMotionValue(baseY);
  const rotatem = useMotionValue(baseR);

  const dragMargin = 25;
  const boundMargin = 20;
  const rotateMargin = 10;
  const baseZIndex = 2;

  const updateBounds = useCallback(() => {
    if (isFirst) {
      const { top, left, bottom } = (elementRef.current as HTMLElement).getBoundingClientRect();

      setBounds(prev => {
        return { ...prev, top, left, bottom, mx: xm.get(), my: ym.get() };
      });
    }
  }, [isFirst, setBounds, xm, ym]);

  const onAnimationComplete = useCallback(() => {
    if (isFirst && isFinished) updateBounds();
  }, [isFinished, isFirst, updateBounds]);

  const checkIfStacked = useCallback(() => {
    const rotationReady = Math.abs(rotatem.get()) < rotateMargin || Math.abs(rotatem.get()) > 360 - rotateMargin;
    const positionReady = Math.abs(xm.get() - bounds.mx) < dragMargin && Math.abs(ym.get() - bounds.my) < dragMargin;

    if (rotationReady) {
      if (rotatem.get() < -rotateMargin) {
        setfinalRotation(-360);
      } else if (rotatem.get() > rotateMargin) {
        setfinalRotation(360);
      } else {
        setfinalRotation(0);
      }
    }
    if (isFirst && rotationReady) {
      updateBounds();
      setStacked(true);
      checkComplete(true, id);
      console.log('first ready');
      return;
    }
    if (positionReady && rotationReady) {
      setStacked(true);
      checkComplete(true, id);
      console.log(id, 'ready');
    } else {
      checkComplete(false, id);
      setStacked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds.mx, bounds.my, checkComplete, id, isFirst, updateBounds]);

  useEffect(() => {
    updateBounds();
    checkIfStacked();
    const { width } = elementRef.current.getBoundingClientRect();
    baseWidth.current = width;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeline = gsap.timeline();
    const el = elementRef.current;
    if (state === AnimationState.IN) {
      const delay = Math.random() * 0.25;
      if (interactive) {
        timeline
          .set(el, { x: baseX, y: '-90vh', alpha: 1, rotate: baseR > 180 ? 360 : 0 }, 0)
          .to(el, { duration: 2, y: baseY, ease: 'expo.inOut' }, 0.2 + delay)
          .to(el, { duration: 0.75, rotate: baseR, ease: 'expo.inOut' }, 0.5 + delay);

        timeline.eventCallback('onComplete', () => setReady(true));
      } else {
        timeline
          .set(el, { x: baseX, y: '-90vh', alpha: 1, rotate: baseR > 180 ? 360 : 0 }, 0)
          .to(el, { duration: 1.5, y: baseY, rotate: baseR, ease: 'circ.out' }, 0.2 + delay);
      }
    }

    if (state === AnimationState.OUT) {
      const { width } = el.getBoundingClientRect();
      timeline.to(el, { duration: 2, x: -width * (id + 1), ease: 'expo.inOut' }, 0.4);
    }
    return () => {
      timeline.kill();
    };
  }, [baseR, baseY, state, setReady, baseX, id, interactive]);

  useEffect(() => {
    (elementRef.current as HTMLElement).style.zIndex = baseZIndex.toString();
  }, [isFinished]);

  useEffect(() => {
    if (ready) {
      const { offsetLeft, offsetTop, offsetBottom, offsetWidth, offsetHeight } = elementRef.current;
      offsetRef.current = { offsetLeft, offsetTop, offsetBottom, offsetWidth, offsetHeight };
    }
  }, [ready]);
  const bindEvents = useGesture(
    interactive && !isFinished
      ? {
          onDragStart: e => {
            (e.target as HTMLElement).style.zIndex = newIndex();
            AudioManager.instance.playSound(sfx[`page${Math.ceil(Math.random() * 5)}`]);
          },
          onPinchEnd: () => {
            checkIfStacked();
          },
          onDragEnd: e => {
            checkIfStacked();
            if (isFirst) updateBounds();
          },
          onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
            if (pinching) return cancel();
            if (
              x > -offsetRef.current.offsetLeft &&
              x < clientWidth - offsetRef.current.offsetLeft - offsetRef.current.offsetWidth
            ) {
              xm.set(x);
            }
            if (
              y > -offsetRef.current.offsetTop &&
              y < clientHeight - offsetRef.current.offsetTop - offsetRef.current.offsetHeight
            ) {
              ym.set(y);
            }
          },
          onPinch: ({ first, offset: [_, a], memo }) => {
            if (first) {
              memo = [rotatem.get(), a];
            }

            rotatem.set((memo[0] - memo[1] + a) % 360);
            return memo;
          },
        }
      : { onDrag: () => null },
    {
      target: elementRef,
      drag: { from: () => [xm.get(), ym.get()] },
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    }
  );

  return (
    <Styled.Stripe
      {...bindEvents}
      ref={elementRef}
      key={`stripe-${id}`}
      style={ready ? { x: xm, y: ym, rotate: rotatem } : {}}
      vScale={vScale}
      bgSrc={bgSrc}
      width={width}
      margin={margin}
      onAnimationComplete={onAnimationComplete}
      animate={
        ready &&
        (isFinished
          ? {
              rotate: finalRotation,
              x: 0,
              y: 0,
            }
          : stacked
          ? {
              rotate: isFirst ? 0 : finalRotation,
              x: isFirst ? xm.get() : bounds.mx,
              y: isFirst ? ym.get() : bounds.my,
            }
          : { x: xm.get(), y: ym.get(), rotate: rotatem.get() })
      }
    ></Styled.Stripe>
  );
};

export default memo(GameTwoBrowserElement);
