import { motion } from 'framer-motion';
import styled from 'styled-components';
import { box, px } from 'styles/mixins';

export const Wrapper = styled(motion.div)<{ bg: string }>`
  ${box(px(150))};
  padding: ${px(28)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.bg});
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  border: ${px(5)} solid white;
`;
