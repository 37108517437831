import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { px, rect, vh, fullHeight } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${fullHeight};
  position: relative;
  background: transparent url(${require('assets/images/game-1-phone-background.jpg')});
  background-position: bottom;
  background-size: cover;
  width: 100%;
  z-index: 10;
  touch-action: none;
  overflow: hidden;
`;
export type TimerType = 'top' | 'bottom';

export const FlipWrapper = styled(motion.div)<{ type: TimerType }>`
  position: absolute;
  ${props =>
    props.type === 'top'
      ? css`
          top: ${px(60)};
        `
      : css`
          bottom: ${px(60)};
        `};
  left: 50%;
  z-index: 1;
`;

export const Background = styled(motion.div)`
  ${rect('absolute', 0, 0, '100%', '100%')}
  background: transparent url(${require('assets/images/game2/game2-background.jpg')});
  background-size: cover;
`;

export const StripeWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${px(120)};
`;
