import styled from 'styled-components';

type WrapperProps = {
  bgColor?: string;
};
export const Wrapper = styled.div<WrapperProps>`
  height: auto;
  background-color: ${props => props.bgColor || 'transparent'};
  isolation: isolate;
`;
