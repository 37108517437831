import styled from 'styled-components';
import { colors } from 'styles/vars';

export const DotList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0.5rem 0;
`;

export const Dot = styled.li<{ isActive: boolean }>`
  background-color: ${({ isActive: active }) => (active ? colors.shark : colors.silver)};
  border-radius: 50%;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  border: 1px solid transparent;
  &:first-child {
    margin-left: 0;
  }
`;

export const KeyboardList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 2rem;
  padding: 0.5rem 0;
`;

export const Key = styled.li`
  background-color: none;
  border: 1px solid ${colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17vw;
  height: 17vw;
  margin: 4vw;
  position: relative;
  &:first-child {
    order: 1;
  }
`;
