import React, { forwardRef } from 'react';

import * as Styled from './VideoCall.styles';
interface VideoCallProps {
  className?: string;
  posterSrc?: string;
  videoSrc: string;
}
export const VideoCall = forwardRef<HTMLVideoElement, VideoCallProps>(({ videoSrc, posterSrc }, ref) => {
  return (
    <Styled.Wrapper>
      <Styled.Video ref={ref} controls={false} preload="metadata" poster={posterSrc} playsInline>
        <source src={videoSrc} type="video/mp4" />
      </Styled.Video>
    </Styled.Wrapper>
  );
});

VideoCall.displayName = 'VideoCall';
