import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { typography12Caps } from 'styles/typography';
import { box, flexCenter, px, rect } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${box('100%', px(144))}
  position: absolute;
  bottom: 0;
  width: 100%;

  &.uv {
    pointer-events: none;

    > .background {
      opacity: 0.5;
      filter: brightness(0.3);
    }

    > .buttons {
      filter: brightness(0.5) grayscale(0.9);
    }
  }

  > .background {
    ${rect('absolute', 0, 0, '100%', '100%')}
    background: ${rgba(colors.white, 0.4)};
    backdrop-filter: blur(${px(15)});
    opacity: 0;
    transition: opacity 0.3s;

    &.show {
      opacity: 1;
    }
  }

  > .buttons {
    ${rect('absolute', 0, 0, '100%', '100%')}
    ${flexCenter()};
    align-items: baseline;
    padding: ${px(10)} 0 ${px(20)};

    &.dark {
      .button {
        .icon {
          color: ${rgba(colors.blackBeluga, 0.8)};
        }
        .text {
          color: ${colors.blackBeluga};
        }
      }
    }

    .button {
      ${flexCenter('column')}
      text-align: center;
      width: ${px(72)};
      margin: 0 ${px(4)};

      &.active {
        .icon {
          background: ${colors.yellow};
        }
      }

      .icon {
        ${box(px(60))}
        ${flexCenter()}
        background: ${rgba(colors.white, 0.8)};
        border-radius: ${px(16)};
        margin: 0 0 ${px(9)};
        color: ${rgba(colors.blackBeluga, 0.4)};

        svg {
          ${box('100%')}
        }
      }

      .text {
        ${typography12Caps}
        color: ${colors.white};
      }
    }
  }
`;
