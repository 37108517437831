import React, { memo, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';

import * as Styled from './GameTwoMystery.styled';

import { HeaderContext, HeaderContextType } from 'context/header';

import AudioManager from 'services/audiomanager.service';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { FlipTimer } from 'components/FlipTimer/FlipTimer';
import { UVReveal } from 'components/UVReveal/UVReveal';
import { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import { GameTwoBrowserContainer } from 'components/GameTwoBrowserContainer/GameTwoBrowserContainer';
import GameTwoDetective, { GameTwoDetectiveRef } from 'components/GameTwoDetective/GameTwoDetective';
import { GameTwoStartCountdown } from 'components/GameTwoStartCountdown/GameTwoStartCountdown';
import { AnimationState } from 'components/GameTwoBrowserElement/GameTwoBrowserElement';
import GameTwoCongratulations from 'components/GameTwoCongratulations';
import { Progress, ProgressContext } from 'context/progress';
import useDoubleTap from 'services/doubleTap.service';

const imagesToPreload = [
  require('assets/images/reveal-light.png'),
  require('assets/images/reveal-mask.png'),
  require('assets/images/game2/uv-hint.png'),
];

import GameTwoContainer from 'components/GameTwoContainer';
import { useTranslation } from 'react-i18next';
import { RedirectsContext } from 'context/redirects';
import { HistoryContext } from 'context/history';
import { PageState } from 'constants/enum';
import { SettingsContext } from 'context/settings';
import { isSocialGestureMinimizedBrowser } from 'utils/platform';

interface Props {
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  showLanding?: () => void;
  showCountdown?: boolean;
  uvLight?: boolean;
  onContinueTraining?: () => void;
  onComplete: (accuracy: number, time: string) => void;
  type: GameType;
  progress: Progress;
  showBadge: () => void;
}

export interface GameTwoRef {
  showCongratulations: () => void;
  onGameComplete: () => void;
}

export enum GameState {
  INIT,
  COUNTDOWN,
  GAME,
  GAME_FINISHED,
  GAME_RESULTS,
}

export type GameType = 'browser' | 'social';

export const GameTwoMystery: React.FC<Props> = ({
  detectiveVideoRef,
  onComplete,
  showLanding,
  onContinueTraining,
  showCountdown = true,
  uvLight,
  type,
  progress,
  showBadge,
}) => {
  const { setSettings } = useContext(SettingsContext);
  const { t } = useTranslation();
  const { redirects } = useContext(RedirectsContext);
  const { setHistory } = useContext(HistoryContext);
  const finishTimeoutRef = useRef(null);

  const screenRatio = document.body.clientWidth / document.body.clientHeight;
  const scaleRatio =
    screenRatio < 0.52 ? Math.min(1, document.body.clientHeight / 720) : Math.min(1, 1.9 - screenRatio * 1.75);

  const {
    header: { isMenuOpen },
    setHeader,
  }: HeaderContextType = useContext(HeaderContext);
  const [isGameReady, setIsGameReady] = useState(false);
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const { setProgress } = useContext(ProgressContext);

  const [accuracy, setAccuracy] = useState(2);
  const [time, setTime] = useState(0);

  const [rect, setRect] = useState();
  const [state, setState] = useState(GameState.INIT);
  const [animationState, setAnimationState] = useState(AnimationState.IN);

  const gameTwoDetectiveRef = useRef<GameTwoDetectiveRef>(null);

  const onDetectiveComplete = useCallback(
    (acc: number) => {
      setProgress({ gameTwo: true });
      setAccuracy(acc);
      setIsGameReady(false);
      setState(GameState.GAME_RESULTS);
    },
    [setProgress]
  );

  const onChatToggle = useCallback(
    (open: boolean) => {
      if (!isGameFinished) {
        setIsTimerRunning(!open);
      }
    },
    [isGameFinished]
  );

  const onTryAgain = useCallback(() => {
    setState(GameState.COUNTDOWN);
    gameTwoDetectiveRef.current.reset();
  }, []);

  const onGameComplete = () => {
    setIsGameFinished(true);
    setIsTimerRunning(false);
    finishTimeoutRef.current = setTimeout(() => {
      setState(GameState.GAME_FINISHED);
    }, 500);
  };

  const onCountdownComplete = useCallback(() => {
    setIsGameReady(true);
    setIsTimerRunning(true);
    setState(GameState.GAME);
    setIsGameFinished(false);

    if (type === 'social' && gameTwoDetectiveRef.current) {
      gameTwoDetectiveRef.current.addMessage({ text: t('gameTwo.detective.socialHint') });
    }
  }, [setIsGameReady, type, t]);

  const startGame = useCallback(() => {
    if (!showCountdown) {
      onCountdownComplete();
    } else {
      setState(GameState.COUNTDOWN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState, showCountdown]);

  useEffect(() => {
    if (redirects?.gameState) {
      const lastGameStep = parseInt(redirects.gameState);
      if (lastGameStep) {
        setState(lastGameStep);
      }
    }
  }, [redirects]);

  useEffect(() => {
    setHistory(`${PageState.GAME_TWO}.${state}`);
    setSettings({ showUV: state === GameState.GAME_FINISHED });
  }, [setHistory, setSettings, state]);

  useEffect(() => {
    if (isGameFinished === false) {
      setIsTimerRunning(!isMenuOpen);
    }
  }, [isMenuOpen, isGameFinished]);

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    setHeader({
      isWhite: true,
      isMenuOpen,
      children: (
        <GameTwoDetective
          gameState={state}
          onComplete={onDetectiveComplete}
          detectiveVideoRef={detectiveVideoRef}
          onToggle={onChatToggle}
          ref={gameTwoDetectiveRef}
        />
      ),
    });
  }, [detectiveVideoRef, isMenuOpen, onChatToggle, onComplete, onDetectiveComplete, setHeader, state, type]);

  const handleUserKeyPress = useCallback(e => {
    // press f to finish game
    if (e.keyCode === 70) onGameComplete();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    startGame();
  }, [startGame]);

  useEffect(() => {
    return () => {
      if (finishTimeoutRef.current) clearTimeout(finishTimeoutRef.current);
    };
  }, []);

  return (
    <>
      <Styled.Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <UVReveal
          key="uv-reveal"
          bgSrc={require('assets/images/game2/uv-hint.png')}
          visible={isGameFinished && uvLight}
          rect={rect}
          width={91.9}
          draggable={!isSocialGestureMinimizedBrowser()}
          scaleRatio={scaleRatio}
        />
        {isGameReady && (
          <>
            <Styled.FlipWrapper
              type={type === 'browser' ? 'bottom' : 'top'}
              key="flip-wrapper"
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              initial={{ opacity: 0, scale: 1.1, x: '-50%' }}
              animate={{ opacity: 1, scale: type === 'browser' ? 0.7 : 0.5, x: '-50%' }}
              exit={{ opacity: 0, scale: 1.1 }}
            >
              <FlipTimer
                key="flip-timer"
                startTime={0}
                running={isTimerRunning}
                updateTime={newTime => setTime(newTime)}
              />
            </Styled.FlipWrapper>
            {type === 'browser' ? (
              <GameTwoBrowserContainer
                key="stripe-container"
                updateUVBounds={setRect}
                isFinished={isGameFinished}
                state={animationState}
                type={'game'}
                scaleRatio={scaleRatio}
                onComplete={onGameComplete}
              />
            ) : (
              <GameTwoContainer
                key="stripe-container"
                updateUVBounds={setRect}
                isFinished={isGameFinished}
                state={animationState}
                type={'game'}
                scaleRatio={scaleRatio}
                onComplete={onGameComplete}
              />
            )}
          </>
        )}
        {state === GameState.COUNTDOWN && <GameTwoStartCountdown key="countdown" onComplete={onCountdownComplete} />}
        <Styled.Background />
        {state === GameState.GAME_RESULTS && (
          <GameTwoCongratulations
            progress={progress}
            showBadge={showBadge}
            key="congratulations"
            accuracy={accuracy}
            time={time}
            visible={state === GameState.GAME_RESULTS}
            onContinue={onContinueTraining}
            tryAgain={onTryAgain}
            backToCases={showLanding}
          />
        )}
      </Styled.Wrapper>
    </>
  );
};

export default memo(GameTwoMystery);
