import parse from 'html-react-parser';
import { Button } from 'components/Button';
import React from 'react';
import { colors } from 'styles/vars';
import * as Styled from './DashboardButtonUv.styles';
import { useTranslation } from 'react-i18next';
import YoutubeIcon from 'components/Icons/Youtube';

interface DashboardButtonUvProps {
  label: string;
  title: string;
  caseNumber: string;
  firstParagraph: string;
  secondParagraph: string;
}

export const DashboardButtonUv: React.FC<DashboardButtonUvProps> = ({
  label,
  title,
  caseNumber,
  firstParagraph,
  secondParagraph,
}) => {
  const { t } = useTranslation();

  return (
    <Styled.OuterWrapper>
      <Styled.IconWrapper></Styled.IconWrapper>

      <Styled.Wrapper>
        <Styled.Title>{parse(t(title))}</Styled.Title>
        <Styled.Description>
          <span>{parse(t(caseNumber))}</span>
          <Styled.ParagraphWrapper>
            <p>{parse(t(firstParagraph))}</p>
            <p>{parse(t(secondParagraph))}</p>
          </Styled.ParagraphWrapper>
        </Styled.Description>
        <Styled.ButtonWrapper>
          <a
            href={t('youtubeLink')}
            onClick={e => {
              e.preventDefault();
              window.open(t('youtubeLink'), '_blank');
            }}
          >
            <Button centered label={`${parse(t(label))}`} bgColor={colors.aqua} iconLeft={<YoutubeIcon />} />
          </a>
        </Styled.ButtonWrapper>
      </Styled.Wrapper>
    </Styled.OuterWrapper>
  );
};
