import styled from 'styled-components';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';

export const MenuButton = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
`;

export const ImageWrapper = styled.img`
  height: 48vh;
  width: auto;
  margin-bottom: 5rem;
`;

const Paragraph = styled.p`
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`;

export const Title = styled.h2`
  ${fontChannel4Chadwick(14, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const Subtitle = styled(Paragraph)`
  ${fontChannel4Horseferry(24, 700, 1.1)}
  letter-spacing: -0.03rem;
  width: 68vw;
  margin-bottom: 1rem;
`;

export const Description = styled(Paragraph)`
  ${fontChannel4Chadwick(14, 500)}
  letter-spacing: 0.075rem;
  width: 66vw;
  margin-bottom: 2.8rem;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 4rem;

  button {
    padding: 1rem 6.4rem;
  }
`;
