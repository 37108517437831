const CONFIG = () => {
  return [
    {
      label: 'finger_ext1',
      img: require('assets/images/game-3-fingerprint-part-1.png'),
    },
    {
      label: 'finger_ext2',
      img: require('assets/images/game-3-fingerprint-part-3-wrong.png'),
      imgWrong: require('assets/images/game-3-fingerprint-part-3-wrong-red.png'),
    },
    {
      label: 'finger_ext3',
      img: require('assets/images/game-3-fingerprint-part-2-wrong.png'),
      imgWrong: require('assets/images/game-3-fingerprint-part-2-wrong-red.png'),
    },
    {
      label: 'finger_ext4',
      img: require('assets/images/game-3-fingerprint-part-3.png'),
    },
    {
      label: 'finger_ext5',
      img: require('assets/images/game-3-fingerprint-part-1-wrong.png'),
      imgWrong: require('assets/images/game-3-fingerprint-part-1-wrong-red.png'),
    },
    {
      label: 'finger_ext6',
      img: require('assets/images/game-3-fingerprint-part-2.png'),
    },
  ];
};

export default CONFIG;
