import * as React from 'react';

import SVGIconBase from './SVGIconBase';
import { colors } from 'styles/vars';

const BackIcon = () => {
  const newColor = colors.white;
  const width = 17;
  const height = 25;

  return (
    <SVGIconBase color={'none'} width={width} height={height}>
      <g clipPath="url(#clip0)">
        <path d="M10 1L3 12.5L10 24" stroke="white" strokeWidth="4" fill="none" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="21" fill="none" transform="translate(0 2)" />
        </clipPath>
      </defs>
    </SVGIconBase>
  );
};

BackIcon.displayName = 'BackIcon';

export default BackIcon;
