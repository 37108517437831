import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { autoAlpha, box, flexCenter, gameOneZIndex, px, rect, uvBase, vh } from 'styles/mixins';
import { fontChannel4Chadwick, typography14Text, typography18Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  overflow: hidden;
  color: ${colors.blackBeluga};

  &.uv {
    > .back {
      pointer-events: none;
      filter: brightness(0.5) grayscale(0.9);
    }
  }

  > .back {
    ${gameOneZIndex('back')};
    ${autoAlpha(0, 0.3)}
    ${flexCenter()}
    position: fixed;
    left: 50%;
    top: ${px(72)};
    height: ${px(34)};
    transform: translate3d(-50%, 0, 0);
    border-radius: ${px(16)};
    padding: 0 ${px(15)};
    background: ${colors.yellow};
    color: ${colors.blackPearl};
    box-shadow: 0 ${px(6)} ${px(9)} rgba(0, 0, 0, 0.19);

    &.show {
      ${autoAlpha(1)}
    }

    .icon {
      ${box(px(16))}
      margin: 0 ${px(6)} 0 0;
    }

    .label {
      ${fontChannel4Chadwick(16, 700, 1)}
      margin: ${px(2)} 0 0 0;
    }
  }

  > .list {
    ${rect('absolute', 0, 0, '100%', '100%')}
    overflow: auto;
    padding: ${px(90)} ${px(16)} ${px(200)};

    .title {
      ${fontChannel4Chadwick(24, 700, 1.1)}
      letter-spacing: ${px(-0.5)};
      text-align: center;
      margin: 0 0 ${px(28)};
    }

    .item {
      display: flex;
      align-items: stretch;
      width: 100%;
      margin: 0 0 ${px(10)};
      padding: ${px(15)} ${px(13)};
      border-radius: ${px(6)};
      background: ${colors.white};
      box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);

      .picture {
        ${box(px(50))}
        flex: none;
        object-fit: cover;
        border-radius: 50%;
      }

      .text {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 ${px(16)};

        .name {
          ${typography18Caps}
        }

        .message {
          ${typography14Text}
          opacity: 0.5;
        }

        .date {
          ${fontChannel4Chadwick(10, 500, 1)}
          letter-spacing: 0.05em;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  > .chat {
    ${rect('absolute', 0, 0, '100%', '100%')}
    overflow: auto;
    padding: ${px(120)} ${px(16)} ${px(200)};

    .message {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 0 0 ${px(17)};

      &.in {
        justify-content: flex-start;

        .content {
          text-align: left;

          .text,
          .voicenote {
            color: ${colors.black};
            background: ${colors.white};
          }

          .missed,
          .call {
            background: ${rgba(colors.white, 0.5)};
          }
        }
      }

      &.centered {
        justify-content: center;

        .content {
          text-align: center;
        }
      }

      .picture {
        ${box(px(25))}
        flex: none;
        object-fit: cover;
        border-radius: 50%;
        margin: ${px(19)} ${px(8)} 0 0;
      }

      .content {
        text-align: right;

        .date {
          ${fontChannel4Chadwick(10, 500, 1)}
          letter-spacing: 0.05em;
          margin: 0 0 ${px(8)};
          opacity: 0.6;
        }

        .text,
        .voicenote {
          display: inline-block;
          text-align: left;
          padding: ${px(15)} ${px(20)};
          max-width: ${px(280)};
          border-radius: ${px(6)};
          color: ${colors.white};
          background: ${colors.tapa};
          box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);

          &:not(:last-child) {
            margin: 0 0 ${px(8)};
          }
        }

        .attachment {
          .preview {
            max-width: ${px(250)};
            max-height: ${px(210)};
            border-radius: ${px(5)};
            box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);
          }
        }

        .missed,
        .call {
          ${flexCenter()}
          ${fontChannel4Chadwick(14, 500, 1)}
          padding: ${px(5)} ${px(20)} ${px(5)} ${px(5)};
          max-width: ${px(280)};
          border-radius: ${px(6)};
          box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);
          background: ${rgba(colors.white, 0.5)};

          &:not(:last-child) {
            margin: 0 0 ${px(8)};
          }

          .icon {
            ${box(px(30))}
            margin: 0 ${px(5)} 0;
          }
        }

        .missed {
          color: ${colors.callRed};
        }

        .call {
          color: ${colors.outerSpace};
        }
      }
    }
  }

  > .uv {
    ${uvBase()}
  }
`;

export const ArrowWrapper = styled(motion.section)`
  position: absolute;
  overflow: visible;
  right: ${px(13)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;
