import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors, ease } from 'styles/vars';
import { absoluteCenter, gameOneZIndex, px, rect, box, uvBase, vh, fullHeight } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  ${fullHeight};
  position: relative;
  background: ${colors.graye5};
  color: ${colors.blackBeluga};
  overflow: hidden;
  width: 100%;

  > .wallpaper {
    ${rect('absolute', 0, 0, '100%', '100%')}
    background: ${colors.black} url(${require('assets/images/game-1-phone-wallpaper.jpg')});
    background-position: center;
    background-size: cover;
    pointer-events: none;
    opacity: 0;
    transform: scale(1.2);
    transition: opacity 0.3s, transform 0.3s ${ease.inOutCubic};

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }

  > .gradient {
    ${gameOneZIndex('gradient')};
    ${rect('absolute', 0, 0, '100%', px(90))}
    pointer-events: none;
    background: linear-gradient(180deg, ${colors.graye5} 0%, ${rgba(colors.graye5, 0)} 100%);
    opacity: 0;
    transition: opacity 0.3s;

    &.show {
      opacity: 1;
    }

    &.uv {
      background: linear-gradient(180deg, ${colors.mako} 0%, ${rgba(colors.mako, 0)} 100%);
    }
  }

  > .shatter {
    ${gameOneZIndex('shatter')};
    ${rect('absolute', 0, 0, '100%', '100%')}
    background: transparent url(${require('assets/images/game-1-phone-shatter.png')});
    background-position: bottom;
    background-size: cover;
    pointer-events: none;
    opacity: 0.9;

    &.black {
      filter: invert();
      opacity: 0.6;
    }
  }
`;
