import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flip from 'gsap/dist/Flip';
import gsap from 'gsap';

import AdobeAnalytics from 'utils/adobeAnalytics';

import * as Styled from './CallPage.styles';

import { PageProps } from 'types/page';

import { colors } from 'styles/vars';

import { sfx } from 'constants/assets';
import { CallState, PageState } from 'constants/enum';

import RejectIcon from 'components/Icons/RejectIcon';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { VideoCall } from 'components/VideoCall';
import { CallButton } from 'components/CallButton';
import { CookieNotice } from 'components/CookieNotice/CookieNotice';
import { DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';

import { HeaderContext } from 'context/header';
import { getNextGame, ProgressContext } from 'context/progress';

import AudioManager from 'services/audiomanager.service';

import detectiveSrc from 'assets/images/detectiveAvatar.png';

import SvgPlayFilled from 'svgs/PlayFilled.svg';
import { AnimatePresence } from 'framer-motion';
import { isDoubleTapEnabledBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';
import SvgClose from 'svgs/Close.svg';

type CallPageType = 'start' | 'feedback';

interface CallPageProps extends PageProps {
  type: CallPageType;
}

export const CallPage: React.FC<CallPageProps> = ({ visible, setPageCallback, type }) => {
  const { setHeader } = useContext(HeaderContext);
  const { progress, setProgress } = useContext(ProgressContext);
  const skipType = type === 'feedback' ? 'close' : 'skip';

  const videoSrcRef = useRef(
    type === 'start'
      ? 'https://murderisland.channel4.com/movies/inal_training_1_cr.mp4'
      : require('assets/videos/game-feedback.mp4')
  );

  const [callState, setCallState] = useState(
    progress?.lastState === undefined || type === 'feedback' ? CallState.CALLING : CallState.CHAT
  );
  const [answered, setAnswered] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const endedAvatarRef = useRef<HTMLDivElement>(null);
  const detectiveChatRef = useRef<DetectiveChatRef>(null);
  const skipButtonRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef(null);
  const { t } = useTranslation();

  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      videoRef.current?.play();
    }
  }, [videoRef]);

  const pauseVideo = useCallback(() => {
    videoRef.current?.pause();
  }, [videoRef]);

  const onVideoEnded = useCallback(() => {
    const timeline = gsap.timeline();
    const video = videoRef.current;
    const skipButton = skipButtonRef.current;

    switch (type) {
      case 'start':
        timeline.to(video, { opacity: 0, duration: 1 }, 0).to(skipButton, { opacity: 0, duration: 1 }, 0);
        setTimeout(() => {
          setCallState(CallState.CALL_ENDED);
        }, 2000);
        break;
      case 'feedback':
        setPageCallback(PageState.LANDING_PAGE);
        break;
    }

    return () => {
      timeline.kill();
    };
  }, [setPageCallback, type]);

  const callCallback = useCallback(
    async answered => {
      AudioManager.instance.stopSound();
      setAnswered(answered);
      setCallState(answered ? CallState.CALL_ANSWERED : CallState.CALL_ENDED);
      if (answered) {
        await videoRef.current?.play();
        await videoRef.current?.pause();
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
        }
        timeoutRef.current = setTimeout(() => {
          playVideo();
        }, 1500);
      } else {
        if (type === 'feedback') {
          setPageCallback(PageState.LANDING_PAGE);
        }
      }
    },
    [playVideo, setPageCallback, type]
  );

  const onCloseChat = useCallback(() => {
    if (progress?.lastState === undefined) {
      setProgress({ lastState: PageState.GAME_ONE });
    }
    setPageCallback(PageState.LANDING_PAGE);
  }, [progress, setPageCallback, setProgress]);

  const onCtaClick = useCallback(() => {
    if (progress?.lastState === undefined) {
      setProgress({ lastState: PageState.GAME_ONE });
      setPageCallback(PageState.GAME_ONE);
    } else {
      setPageCallback(getNextGame(progress));
    }
  }, [progress, setPageCallback, setProgress]);

  const onChatAnswer = useCallback(
    option => {
      if (option === 0) {
        setPageCallback(progress.lastState);
      }
      if (option === 1) {
        setCallState(CallState.CALLING);
        setProgress({ gameOne: false, gameTwo: false, gameThree: false, lastState: undefined });
      }
    },
    [progress, setProgress, setPageCallback]
  );

  useEffect(() => {
    if (callState === CallState.CHAT) {
      setHeader({
        isWhite: true,
        children: (
          <Styled.DetectiveChat
            ref={detectiveChatRef}
            ctaLabel={t('detectiveCall.start')}
            onChatClose={onCloseChat}
            onCtaClick={onCtaClick}
            onQuizAnswer={onChatAnswer}
            showArrows={isSocialGestureMinimizedBrowser()}
          />
        ),
      });

      setTimeout(() => {
        detectiveChatRef.current?.clearChat();
        if (progress?.lastState === undefined) {
          detectiveChatRef.current?.openChat();
          detectiveChatRef.current?.addMessage({
            text: () => (
              <Styled.PlayMessage tabIndex={0}>
                <SvgPlayFilled />
                <span>{t('detectiveCall.seeVideo')}</span>
              </Styled.PlayMessage>
            ),
            onClick: () => callCallback(true),
          });
          detectiveChatRef.current?.addMessage({
            text: t('detectiveCall.message1'),
          });
          detectiveChatRef.current?.addMessage({
            text: t('detectiveCall.message2'),
          });
          detectiveChatRef.current?.addMessage({
            text: isDoubleTapEnabledBrowser() ? t('detectiveCall.message3') : t('detectiveCall.message3Hold'),
          });
          setTimeout(() => {
            detectiveChatRef.current?.showCta();
          }, 500);
        } else {
          detectiveChatRef.current?.openChat();
          detectiveChatRef.current?.addMessage({
            text: t('detectiveCall.backMessage'),
          });
          detectiveChatRef.current?.addMessage({
            text: t('detectiveCall.backQuestion'),
            options: [t('detectiveCall.continue'), t('detectiveCall.restart')],
          });
        }
      });
    } else {
      setHeader({
        isWhite: true,
        onMenuOpen: () => {
          pauseVideo();
          AudioManager.instance.stopSound();
        },
        onMenuClose: () => {
          if (callState === CallState.CALL_ANSWERED) playVideo();
          if (callState === CallState.CALLING) AudioManager.instance.playSound(sfx.phone_call, true, true);
        },
      });
    }
  }, [callState, setHeader, playVideo, pauseVideo, t, onCloseChat, onChatAnswer, progress, callCallback, onCtaClick]);

  useEffect(() => {
    if (callState === CallState.CALLING) {
      AudioManager.instance.playSound(sfx.phone_call, true, true);
    }
  }, [callCallback, callState, progress, t]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('phone_call ', 'pageview');
    return () => {
      AudioManager.instance.stopSound();
    };
  }, []);

  useEffect(() => {
    const timeline = gsap.timeline();
    const skipButton = skipButtonRef.current;
    const video = videoRef.current;

    timeline.set(video, { opacity: 0 }, 0);

    if (callState === CallState.CALL_ANSWERED) {
      timeline
        .set(video, { opacity: 0 }, 0)
        .set(skipButton, { opacity: 0 }, 0)
        .to(video, { opacity: 1, duration: 2 }, 1)
        .to(skipButton, { opacity: 1, duration: 2 }, 3);
    }

    return () => {
      timeline.kill();
    };
  }, [callState]);

  useEffect(() => {
    const video = videoRef.current;

    if (callState === CallState.CALL_ANSWERED) {
      video.addEventListener('ended', onVideoEnded);
    }
    return () => {
      video.removeEventListener('ended', onVideoEnded);
    };
  }, [callState, onVideoEnded]);

  useEffect(() => {
    let timeline;

    if (callState === CallState.CALL_ENDED) {
      const children = Array.from(endedAvatarRef.current.children);
      const image = children[0];
      const state = Flip.getState(image);
      let transition: gsap.core.Timeline;
      timeline = gsap
        .timeline({ delay: 1 })
        .to(children.slice(1), { delay: 1, duration: 0.1, opacity: 0 })
        .add(() => {
          gsap.set(image, {
            top: '0.75rem',
            right: '1.5rem',
            scale: 0.37,
            position: 'absolute',
            transformOrigin: '100% 0',
          });
          transition = Flip.from(state, {
            ease: 'power3.inOut',
            scale: true,
            duration: 0.5,
            onComplete: () => {
              setCallState(CallState.CHAT);
            },
          });
        });
    }

    return () => {
      if (callState === CallState.CALL_ENDED) {
        timeline?.progress(0).kill();
      }
    };
  }, [callCallback, callState, setPageCallback, setProgress, t]);

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <Styled.Section animate={callState === CallState.CALLING ? 'visible' : 'hidden'}>
        <div />
        <Styled.AvatarWrapper>
          <Avatar image={detectiveSrc} bounce={true} />
          <Styled.Header>{t('detectiveCall.detective')}</Styled.Header>
          <Styled.Title danger={false}>{t('detectiveCall.calling')}</Styled.Title>
        </Styled.AvatarWrapper>

        <Styled.CallButtonWrapper>
          <CallButton onClick={callCallback} type={'decline'} />
          <CallButton onClick={callCallback} type={'accept'} />
        </Styled.CallButtonWrapper>
      </Styled.Section>

      <Styled.Section animate={callState === CallState.CALL_ANSWERED ? 'visible' : 'hidden'}>
        {/* typo in the movie name on server inal_training_1_cr do not change it  */}
        <VideoCall ref={videoRef} videoSrc={videoSrcRef.current} />
        <Styled.SkipButtonWrapper ref={skipButtonRef} type={skipType}>
          <Button
            color={colors.black}
            bgColor={skipType === 'close' ? 'none' : colors.yellow}
            onClick={() => {
              pauseVideo();
              clearTimeout(timeoutRef.current);
              if (videoRef.current) {
                videoRef.current.muted = true;
              }
              setTimeout(() => {
                switch (type) {
                  case 'start':
                    setCallState(CallState.CALL_ENDED);
                    break;
                  case 'feedback':
                    setPageCallback(PageState.LANDING_PAGE);
                    break;

                  default:
                    break;
                }
              }, 0);
            }}
            label={skipType === 'close' ? '' : t('detectiveCall.skipCall')}
          >
            {skipType === 'close' && <SvgClose />}
          </Button>
        </Styled.SkipButtonWrapper>
      </Styled.Section>
      <AnimatePresence>
        {callState === CallState.CALL_ENDED && (
          <Styled.Section animate={callState === CallState.CALL_ENDED ? 'visible' : 'hidden'}>
            <div />
            <Styled.AvatarWrapper ref={endedAvatarRef}>
              <div>
                <Avatar image={detectiveSrc} />
              </div>
              <Styled.Header>{t('detectiveCall.detective')}</Styled.Header>
              <Styled.Title danger={!answered}>
                <Styled.EndStatusWrapper>
                  {answered ? (
                    <p>{t('detectiveCall.ended')}</p>
                  ) : (
                    <>
                      <RejectIcon />
                      <p>{t('detectiveCall.rejected')}</p>
                    </>
                  )}
                </Styled.EndStatusWrapper>
              </Styled.Title>
            </Styled.AvatarWrapper>
            <Styled.CallButtonSpacer />
          </Styled.Section>
        )}
      </AnimatePresence>

      {type === 'start' && <CookieNotice />}
    </Styled.Wrapper>
  );
};

export default CallPage;
