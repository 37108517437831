import { PhoneState } from 'components/GameOnePhone/GameOnePhone';
import { GameState as GameTwoState } from 'components/GameTwoMystery/GameTwoMystery';
import { State as GameThreeState } from 'components/GameThree/GameThree';
import { PageState } from './enum';

const historyGamesPages = () => {
  return [
    `${PageState.LANDING_PAGE}`,
    `${PageState.GAME_ONE}`,
    `${PageState.GAME_ONE}.${PhoneState.LOCK}`,
    `${PageState.GAME_ONE}.${PhoneState.CLOCK}`,
    `${PageState.GAME_ONE}.${PhoneState.MESSAGE}`,
    `${PageState.GAME_ONE}.${PhoneState.GALLERY}`,
    `${PageState.GAME_ONE}.${PhoneState.VOICEMAIL}`,
    `${PageState.GAME_TWO}`,
    `${PageState.GAME_TWO}.${GameTwoState.COUNTDOWN}`,
    `${PageState.GAME_TWO}.${GameTwoState.GAME}`,
    `${PageState.GAME_THREE}`,
    `${PageState.GAME_THREE}.${GameThreeState.INTRO}`,
    `${PageState.GAME_THREE}.${GameThreeState.FINGERPRINTS}`,
    `${PageState.GAME_THREE}.${GameThreeState.GAME}`,
  ];
};
export const latestVisitedGamePage = (history: string[]): string =>
  [...history].reverse().filter(page => historyGamesPages().find(element => element === page))[0];
