import styled, { css } from 'styled-components';
import { box, px } from 'styles/mixins';

import { colors } from 'styles/vars';
import { GameThreeIndicatorsProps } from './GameThreeIndicators';

export const Wrapper = styled.div`
  position: relative;
  ${box(px(130), px(69))}
  margin-bottom: ${px(16)}
`;

export const IndicatorsBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
`;

export const Indicator = styled.div<{ round?: GameThreeIndicatorsProps['round'] }>`
  ${box(px(18), px(18))}
  position: absolute;
  top: ${px(12)};

  ${({ round }) =>
    round === 0 &&
    css`
      left: ${px(20.5)};
    `};

  ${({ round }) =>
    round === 1 &&
    css`
      left: ${px(62)};
    `};

  ${({ round }) =>
    round === 2 &&
    css`
      left: ${px(103.5)};
    `};

  svg {
    width: 100%;
    height: 100%;
  }
`;
