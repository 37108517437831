import React, { memo } from 'react';
import * as Styled from './ScrollArrows.styled';
import Arrow from 'svgs/upArrow.svg';

interface Props {
  onUp: () => void;
  onDown: () => void;
}

export const ScrollArrows: React.FC<Props> = ({ onUp, onDown }) => {
  return (
    <Styled.Wrapper>
      <Styled.BubbleButton onClick={onUp}>
        <Arrow />
      </Styled.BubbleButton>
      <Styled.BubbleButton onClick={onDown}>
        <Arrow style={{ transform: 'rotate(180deg)' }} />
      </Styled.BubbleButton>
    </Styled.Wrapper>
  );
};

export default memo(ScrollArrows);
