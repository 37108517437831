import React, { memo, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SplitText from 'gsap/dist/SplitText';
import parse from 'html-react-parser';
import gsap from 'gsap';

import * as Styled from './GameOneIntro.styled';

import { HeaderContext, HeaderContextType } from 'context/header';

import AdobeAnalytics from 'utils/adobeAnalytics';
import { isDoubleTapEnabledBrowser, isSocialBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';

import CtaYellow from 'components/CtaYellow';
import DetectiveChat, { DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import GameIntroLanding, { GameIntroLandingRef } from 'components/GameIntroLanding/GameIntroLanding';
import { GameDetectiveVideoRef, PlayVideoMessage } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { ProgressContext } from 'context/progress';
import { PageState } from 'constants/enum';

const victimImage = require('assets/images/game-1-intro-tutorial-victim.jpg');

const suspectImages = [
  require('assets/images/game-1-intro-tutorial-suspect1.jpg'),
  require('assets/images/game-1-intro-tutorial-suspect2.jpg'),
  require('assets/images/game-1-intro-tutorial-suspect3.jpg'),
];

const imagesToPreload = [
  require('assets/images/game-1-intro-uv-landing.png'),
  require('assets/images/game-1-intro-uv-tutorial1.png'),
  require('assets/images/game-1-intro-uv-tutorial2.png'),
  require('assets/images/game-1-intro-uv-tutorial3.png'),
  require('assets/images/game-1-intro-tutorial-phone-uv.png'),
  require('assets/images/game-1-intro-tutorial-suspects-text-uv.png'),
  require('assets/images/game-1-intro-tutorial-paper-uv.png'),
];

interface Props {
  uvLight: boolean;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onExit: () => void;
  onComplete: () => void;
}

enum State {
  LANDING,
  TUTORIAL,
}

export const GameOneIntro: React.FC<Props> = ({ uvLight, detectiveVideoRef, onComplete, onExit }) => {
  const { t } = useTranslation();
  const { setHeader }: HeaderContextType = useContext(HeaderContext);
  const { setProgress } = useContext(ProgressContext);

  const [state, setState] = useState(State.LANDING);
  const [slide, setSlide] = useState(0);

  const landingRef = useRef<GameIntroLandingRef>(null);
  const tutorialRef = useRef<HTMLDivElement>(null);
  const tutorialBgTextRef = useRef<HTMLDivElement>(null);
  const slide1Ref = useRef<HTMLDivElement>(null);
  const slide2Ref = useRef<HTMLDivElement>(null);
  const slide3Ref = useRef<HTMLDivElement>(null);
  const videoPlayedRef = useRef(false);
  const detectiveChatRef = useRef<DetectiveChatRef>(null);

  const showDetectiveChat = useMemo(() => state === State.TUTORIAL && slide !== 0, [slide, state]);

  const handleCtaClick = useCallback(() => {
    if (state === State.LANDING) {
      setState(State.TUTORIAL);
      videoPlayedRef.current = true;
      detectiveVideoRef.current.play(() => {
        setSlide(1);
      });
    }
  }, [detectiveVideoRef, state]);

  const handleNextClick = useCallback(() => {
    if (state === State.TUTORIAL) setSlide(s => Math.min(3, s + 1));
  }, [state]);

  const handleStartClick = useCallback(() => {
    if (state === State.TUTORIAL) onComplete();
  }, [onComplete, state]);

  const handleBackClick = useCallback(() => {
    if (state === State.LANDING) {
      onExit();
    } else if (slide === 1) {
      setSlide(0);
      setState(State.LANDING);
    } else if (slide === 0) {
      detectiveVideoRef.current.stop();
      setState(State.LANDING);
      setSlide(0);
    } else {
      setSlide(s => s - 1);
    }
  }, [onExit, slide, state, detectiveVideoRef]);

  useEffect(() => {
    setProgress({ lastState: PageState.GAME_ONE });
  }, [setProgress]);

  useEffect(() => {
    let timeout;
    if (showDetectiveChat) {
      timeout = setTimeout(() => {
        detectiveChatRef.current.addMessage({
          text: () => <PlayVideoMessage />,
          onClick: () => detectiveVideoRef.current.play(),
        });
        detectiveChatRef.current.addMessage({
          text: isDoubleTapEnabledBrowser() ? t('gameOne.detective.afterCall') : t('gameOne.detective.afterCallHold'),
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [detectiveVideoRef, showDetectiveChat, t]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game_1_intro', 'pageview', 'game1');
  }, []);

  useEffect(() => {
    if (showDetectiveChat) {
      setHeader({
        isWhite: false,
        isBackButton: true,
        children: <DetectiveChat ref={detectiveChatRef} showArrows={isSocialGestureMinimizedBrowser()} />,
        onBack: handleBackClick,
      });
    } else {
      setHeader({
        isWhite: true,
        isBackButton: true,
        onBack: handleBackClick,
      });
    }
  }, [showDetectiveChat, setHeader, handleBackClick]);

  // main transition
  useEffect(() => {
    const timeline = gsap.timeline();
    if (state === State.LANDING) {
      timeline
        .add(landingRef.current.animateIn(), 0)
        .to(tutorialRef.current, { duration: 0.5, autoAlpha: 0 }, 0)
        .add(() => setSlide(0), 0.5);
    } else {
      timeline //
        .add(landingRef.current.animateOut())
        .to(tutorialRef.current, { duration: 1, autoAlpha: 1 });
    }
    return () => {
      timeline.kill();
    };
  }, [state]);

  // slides transitions
  useEffect(() => {
    const slide1 = slide1Ref.current;
    const slide1Text = slide1.querySelector('.description');
    const slide1Media = slide1.querySelector('.media');
    const slide1TextSplit = new SplitText(slide1Text, { type: 'words,chars' });
    const slide2 = slide2Ref.current;
    const slide2Text = slide2.querySelector('.description');
    const slide2Media = slide2.querySelector('.media');
    const slide2TextSplit = new SplitText(slide2Text, { type: 'words,chars' });
    const slide3 = slide3Ref.current;
    const slide3Text = slide3.querySelector('.description');
    const slide3TextSplit = new SplitText(slide3Text, { type: 'words,chars' });

    const bgTextSplit = new SplitText(tutorialBgTextRef.current, { type: 'words' });

    const xPercent = (1 - slide) * 100;

    const timeline = gsap
      .timeline()
      .to(slide1.children, { duration: 1, stagger: -0.1, xPercent, ease: 'expo.inOut' }, 0)
      .to(slide2.children, { duration: 1, stagger: -0.1, xPercent, ease: 'expo.inOut' }, 0)
      .to(slide3.children, { duration: 1, stagger: -0.1, xPercent, ease: 'expo.inOut' }, 0);

    if (slide === 1) {
      timeline
        .set(slide1Media, { rotate: 15 }, 0)
        .to(slide1, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide1Media, { duration: 1, rotate: 0, ease: 'expo.out' }, 0.4)
        .fromTo(slide1TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    } else {
      timeline.to(slide1, { duration: 0.4, autoAlpha: 0 }, 0.8);
    }

    if (slide === 2) {
      timeline
        .set(slide2Media.children, { scale: 1.5 }, 0)
        .to(slide2, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide2Media.children, { duration: 1.5, stagger: -0.15, scale: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide2TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4)
        .to(bgTextSplit.words[0], { duration: 1, scrambleText: bgTextSplit.words[0].textContent }, 1);
    } else {
      timeline.to(slide2, { duration: 0.4, autoAlpha: 0 }, 0.8);
    }

    if (slide === 3) {
      timeline
        .to(slide3, { duration: 0.4, autoAlpha: 1 }, 0)
        .fromTo(slide3TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.2)
        .to(bgTextSplit.words[1], { duration: 1, scrambleText: bgTextSplit.words[1].textContent }, 1);
    } else {
      timeline.to(slide3, { duration: 0.4, autoAlpha: 0 }, 0.8);
    }

    return () => {
      timeline.kill();
      bgTextSplit.revert();
      slide1TextSplit.revert();
      slide2TextSplit.revert();
      slide3TextSplit.revert();
    };
  }, [state, slide]);

  usePreloadedImages(imagesToPreload);

  return (
    <Styled.Wrapper transition={{ duration: 0.1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <GameIntroLanding
        cta={t('gameOne.intro.landing.cta')}
        title={t('gameOne.intro.landing.case')}
        number={t('gameOne.intro.landing.number')}
        subtitle={t('gameOne.intro.landing.subtitle')}
        description={t('gameOne.intro.landing.description')}
        videoSrc={require('assets/videos/teaser2-slice-1.mp4')}
        posterSrc={require('assets/images/teaser2-slice-1.jpg')}
        onCtaClick={handleCtaClick}
        ref={landingRef}
      />

      <div className="tutorial" ref={tutorialRef}>
        <p
          className={`bgtext ${isSocialBrowser() ? 'social-browser' : ''}`}
          ref={tutorialBgTextRef}
          aria-label={t('gameOne.intro.tutorial.bg')}
          role="heading"
        >
          <span>{parse(t('gameOne.intro.tutorial.bgHtml'))}</span>
        </p>

        <div className="slides">
          <div className="slide" ref={slide1Ref}>
            <div className="media">
              <div className="victim">
                <img className="picture" alt={t('gameOne.intro.tutorial.slide2.victim.name')} src={victimImage} />
                <p className="name">{parse(t('gameOne.intro.tutorial.slide2.victim.name'))}</p>
                <p className="role">{parse(t('gameOne.intro.tutorial.slide2.victim.role'))}</p>
              </div>
              <div className="phone" />
            </div>
            <p className="description" aria-label={t('gameOne.intro.tutorial.slide1.description')} role="heading">
              <span className="underline1" />
              <span aria-hidden="true">{parse(t('gameOne.intro.tutorial.slide1.descriptionHtml'))}</span>
            </p>
          </div>

          <div className="slide" ref={slide2Ref}>
            <div className="media">
              {[...Array(3)].map((_, i) => (
                <div className="suspect" key={`suspect-${i}`}>
                  <img
                    className="picture"
                    alt={t(`gameOne.intro.tutorial.slide2.suspect${i + 1}.name`)}
                    src={suspectImages[i]}
                    aria-hidden="true"
                  />
                  <p className="name">{parse(t(`gameOne.intro.tutorial.slide2.suspect${i + 1}.name`))}</p>
                  <p className="role">{parse(t(`gameOne.intro.tutorial.slide2.suspect${i + 1}.role`))}</p>
                </div>
              ))}
            </div>
            <p className="description" aria-label={t('gameOne.intro.tutorial.slide2.description')} role="heading">
              <span className="underline2" />
              <span aria-hidden="true">{parse(t('gameOne.intro.tutorial.slide2.descriptionHtml'))}</span>
            </p>
          </div>

          <div className="slide" ref={slide3Ref}>
            <div className="media">
              <div className="paper" aria-label={t('gameOne.intro.tutorial.slide3.imageTranscription')} role="img" />
            </div>
            <p className="description" aria-label={t('gameOne.intro.tutorial.slide3.description')} role="heading">
              <span className="underline3" />
              <span aria-hidden="true">{parse(t('gameOne.intro.tutorial.slide3.descriptionHtml'))}</span>
            </p>
          </div>
        </div>

        <div className="footer">
          <div className="buttons">
            <CtaYellow
              className={classNames('start', { show: slide === 3 })}
              label={t('gameOne.intro.tutorial.start')}
              onClick={handleStartClick}
              big
            />
            <CtaYellow
              className={classNames('next', { show: slide !== 3 })}
              label={t('gameOne.intro.tutorial.next')}
              onClick={handleNextClick}
            />
          </div>
          <div className="progress">
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 1 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 2 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 3 })} />
            </div>
          </div>
          <div className="page">{Math.max(1, slide)}/3</div>
        </div>
      </div>

      {uvLight ? (
        <div className="uv">
          {state === State.LANDING ? (
            <div className="landing" />
          ) : (
            <>
              {slide === 1 ? (
                <>
                  <div className="tutorial1" />
                  <div className="uv-item">
                    <div className="phone-uv" />
                  </div>
                </>
              ) : null}
              {slide === 2 ? (
                <>
                  <div className="tutorial2" />
                  <div className="uv-item">
                    <div className={`suspects-uv ${isSocialBrowser() ? 'social-browser' : ''}`} />
                  </div>
                </>
              ) : null}
              {slide === 3 ? (
                <>
                  <div className="tutorial3" />
                  <div className="uv-item">
                    <div className={`paper-uv ${isSocialBrowser() ? 'social-browser' : ''}`} />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      ) : null}
    </Styled.Wrapper>
  );
};

export default memo(GameOneIntro);
