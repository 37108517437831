import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';

import * as Styled from './GameOnePhoneGallery.styled';

import { gallery } from 'constants/game-one';

import AsyncImage from 'components/AsyncImage';
import { PhonePicture } from 'components/GameOnePhone/GameOnePhone';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { ScrollArrows } from 'components/ScrollArrows/ScrollArrows';
import { remToPixels } from 'utils/dom';

const imagesToPreload = [
  require('assets/images/game-1-phone-attachment-cake-uv.jpg'),
  require('assets/images/game-1-phone-attachment-lillies-uv.jpg'),
  require('assets/images/game-1-phone-gallery-08-27-1730-uv.jpg'),
  require('assets/images/game-1-phone-gallery-08-26-1902-uv.jpg'),
  require('assets/images/game-1-phone-gallery-08-25-1502-uv.jpg'),
  require('assets/images/game-1-phone-gallery-08-21-1402-uv.jpg'),
  require('assets/images/game-1-phone-gallery-08-21-1402-uv.jpg'),
  require('assets/images/game-1-phone-uv-frame.png'),
];
interface Props {
  uvLight: boolean;
  showArrows: boolean;
  setPicture: (p: PhonePicture) => void;
}

export const GameOnePhoneGallery: React.FC<Props> = ({ uvLight, setPicture, showArrows }) => {
  const timeoutRef = useRef(null);
  const wrapperRef = useRef(null);
  const topLayersHeight = useMemo(() => remToPixels(23.5), []);

  const handleImageClick = useCallback(
    image => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (!uvLight) {
          setPicture(image);
        }
      }, 200);
    },
    [setPicture, uvLight]
  );
  const firstPhotoRef = useRef<HTMLButtonElement>(null);

  const scrollVertical = useCallback(
    (up: boolean) => {
      gsap.to(wrapperRef.current, {
        duration: 0.6,
        ease: 'cubic.inOut',
        scrollTo: {
          x: 0,
          y: up
            ? wrapperRef.current.scrollTop - wrapperRef.current.clientHeight + topLayersHeight
            : wrapperRef.current.scrollTop + wrapperRef.current.clientHeight - topLayersHeight,
          autoKill: false,
        },
      });
    },
    [topLayersHeight]
  );

  useEffect(() => {
    if (uvLight) {
      clearTimeout(timeoutRef.current);
    }
  }, [uvLight]);

  useEffect(() => {
    firstPhotoRef?.current?.focus();
  }, []);

  usePreloadedImages(imagesToPreload, true);

  return (
    <>
      {showArrows && (
        <Styled.ArrowWrapper>
          <ScrollArrows onUp={() => scrollVertical(true)} onDown={() => scrollVertical(false)} />
        </Styled.ArrowWrapper>
      )}
      <Styled.Wrapper
        ref={wrapperRef}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.1 }}
        className={classNames({ uv: uvLight })}
      >
        {uvLight ? <div className="uv" /> : null}
        <div className="grid">
          {gallery.map((image, i) => (
            <div className="item" key={i}>
              <button
                className="button"
                onClick={() => handleImageClick(image)}
                aria-label="Open picture"
                ref={i === 0 ? firstPhotoRef : undefined}
              >
                <AsyncImage className="thumbnail" src={image.src} alt={image.alt} />
                {uvLight ? (
                  <div className="uv">
                    {image.srcUv ? (
                      <>
                        <AsyncImage className="thumbnail" src={image.srcUv} alt={`${image.alt} - uv layer`} />
                        <div className="frame" />
                      </>
                    ) : null}
                  </div>
                ) : null}
              </button>
            </div>
          ))}
        </div>
      </Styled.Wrapper>
    </>
  );
};

export default memo(GameOnePhoneGallery);
