import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Stripe = styled(motion.div)<{ bgSrc: string; width: number; margin: number; vScale: number }>`
  position: relative;
  background: ${props => `transparent url(${props.bgSrc}) no-repeat`};
  background-position: center;
  background-size: contain;
  overflow: hidden;
  height: calc(116vw * ${props => props.vScale});
  position: relative;
  opacity: 0;
  width: ${props => props.width}vw;
  margin-left: ${props => -props.margin}vw;
  z-index: 2;
  cursor: grab;
`;
