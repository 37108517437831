import { clamp, distance } from 'utils/math';
import { Position } from './GameTwoContainer';

const buffer = 5;

export const findIndex = (i: number, xOffset: number, positions: Position[]) => {
  let target = i;
  const { left, width } = positions[i];
  const bottom = left + width;

  if (xOffset > 0) {
    const nextItem = positions[i + 1];
    if (nextItem === undefined) return i;

    const swapOffset = distance(bottom, nextItem.left + nextItem.width / 2) + buffer;
    if (xOffset > swapOffset) target = i + 1;
  } else if (xOffset < 0) {
    const prevItem = positions[i - 1];
    if (prevItem === undefined) return i;

    const prevLeft = prevItem.left + prevItem.width;
    const swapOffset = distance(left, prevLeft - prevItem.width / 2) + buffer;
    if (xOffset < -swapOffset) target = i - 1;
  }

  return clamp(0, positions.length, target);
};
