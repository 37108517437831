import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';
import gsap from 'gsap';

import * as Styled from './GameOnePhoneMessage.styled';

import { attachments, avatars, voicenotes } from 'constants/game-one';

import AudioPlayer from 'components/AudioPlayer';
import { PhonePicture } from 'components/GameOnePhone/GameOnePhone';

import SvgArrowLeft from 'svgs/ArrowLeft.svg';
import SvgCallAccept from 'svgs/CallAccept.svg';
import usePreloadedImages from 'hooks/usePreloadedImages';
import ScrollArrows from 'components/ScrollArrows';
import { remToPixels } from 'utils/dom';
import useWindowVisible from 'hooks/useWindowVisible';

const imagesToPreload = [
  require('assets/images/game-1-phone-attachment-cake-uv.jpg'),
  require('assets/images/game-1-phone-attachment-lillies-uv.jpg'),
];

interface Props {
  uvLight: boolean;
  setPicture: (p: PhonePicture) => void;
  showArrows: boolean;
}

export const GameOnePhoneMessage: React.FC<Props> = ({ uvLight, setPicture, showArrows = true }) => {
  const { t } = useTranslation();
  const isWindowVisible = useWindowVisible();
  const firstMessageRef = useRef<HTMLButtonElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const interactedRef = useRef(false);
  const topLayersHeight = useMemo(() => remToPixels(23.5), []);

  const [chat, setChat] = useState<string>(null);

  const chats = useMemo(() => t('gameOne.phone.message.chats', { returnObjects: true }), [t]);

  const handleBackClick = useCallback(() => setChat(null), []);

  const handleAttachmentClick = useCallback(attachment => setPicture(attachments[attachment]), [setPicture]);

  const handleChatClick = useCallback((key: string) => {
    setChat(key);
    interactedRef.current = true;
    setTimeout(() => {
      gsap.set(chatRef.current, {
        scrollTo: { x: 0, y: chatRef.current.scrollHeight - chatRef.current.clientHeight, autoKill: false },
      });
    }, 10);
  }, []);

  const scrollVertical = useCallback(
    (up: boolean) => {
      gsap.to(chatRef.current, {
        duration: 0.6,
        ease: 'cubic.inOut',
        scrollTo: {
          x: 0,
          y: up
            ? chatRef.current.scrollTop - chatRef.current.clientHeight + topLayersHeight
            : chatRef.current.scrollTop + chatRef.current.clientHeight - topLayersHeight,
          autoKill: false,
        },
      });
    },
    [topLayersHeight]
  );

  useEffect(() => {
    firstMessageRef?.current?.focus();
  }, []);

  usePreloadedImages(imagesToPreload);

  return (
    <Styled.Wrapper
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      initial={{ opacity: 0, scale: 1.1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
      className={classNames({ uv: uvLight })}
    >
      <button
        className={classNames('back', { show: !!chat })}
        onClick={handleBackClick}
        aria-label="Back to messages list"
      >
        <SvgArrowLeft className="icon" />
        <span className="label">{parse(t('gameOne.phone.message.back'))}</span>
      </button>

      <AnimatePresence>
        {chat ? (
          <>
            {showArrows && (
              <Styled.ArrowWrapper>
                <ScrollArrows onUp={() => scrollVertical(true)} onDown={() => scrollVertical(false)} />
              </Styled.ArrowWrapper>
            )}
            <motion.div
              className="chat"
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              initial={{ x: '100%' }}
              animate={{ x: '0' }}
              exit={{ x: '100%' }}
              key="chat"
              ref={chatRef}
            >
              {chats[chat].messages.map((message, i) => (
                <div
                  key={i}
                  className={classNames('message', { in: message.in, centered: message.missed || message.call })}
                >
                  {message.in && !message.missed && !message.call ? (
                    <img className="picture" src={avatars[chat].src} alt={avatars[chat].alt} />
                  ) : null}
                  <div className="content">
                    <div className="date">{message.date}</div>
                    {message.message ? <div className="text">{parse(message.message)}</div> : null}
                    {message.attachment ? (
                      <button
                        className="attachment"
                        onClick={() => handleAttachmentClick(message.attachment)}
                        aria-label="Open attachment"
                        tabIndex={0}
                      >
                        <img
                          className="preview"
                          src={attachments[message.attachment].src}
                          alt={attachments[message.attachment].alt}
                        />
                      </button>
                    ) : null}
                    {message.voicenote ? (
                      <div className="voicenote">
                        <AudioPlayer src={voicenotes[message.voicenote]} muted={!isWindowVisible} />
                      </div>
                    ) : null}
                    {message.missed ? (
                      <div className="missed">
                        <SvgCallAccept className="icon" />
                        <div>
                          {parse(
                            message.in ? t('gameOne.phone.message.missedIn') : t('gameOne.phone.message.missedOut')
                          )}
                        </div>
                      </div>
                    ) : null}
                    {message.call ? (
                      <div className="call">
                        <SvgCallAccept className="icon" />
                        <div>{parse(message.call)}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </motion.div>
          </>
        ) : (
          <motion.div
            className="list"
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            initial={{ x: interactedRef.current ? '-100%' : '0%' }}
            animate={{ x: '0%' }}
            exit={{ x: '-100%' }}
            key="list"
          >
            <p className="title">{parse(t('gameOne.phone.message.title'))}</p>
            {Object.keys(chats).map((key, i) => {
              const name = chats[key].name;
              const picture = avatars[key];
              const lastMessage = chats[key].messages.filter(m => !!m.message).slice(-1)[0];
              return (
                <button
                  className="item"
                  key={key}
                  onClick={() => handleChatClick(key)}
                  aria-label="Open chat"
                  tabIndex={0}
                  ref={i === 0 ? firstMessageRef : undefined}
                >
                  <img className="picture" src={picture.src} alt={picture.alt} />
                  <div className="text">
                    <p className="name">{name}</p>
                    <p className="message">{parse(lastMessage.message)}</p>
                    <p className="date">{lastMessage.date}</p>
                  </div>
                </button>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>

      {uvLight ? <div className="uv"></div> : null}
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhoneMessage);
