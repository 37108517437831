import AvatarIcon from 'components/Icons/AvatarIcon';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Wrapper } from './styled/Wrapper';

interface AvatarProps {
  image: string;
  bounce?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({ image, bounce }) => {
  const spinTransition = {
    loop: Infinity,
    duration: 1.5,
    ease: 'easeInOut',
  };

  const animate = {
    scale: [1, 0.85, 1],
  };

  return (
    <AnimatePresence>
      <Wrapper bg={image} initial={{ scale: 1 }} animate={bounce ? animate : ''} transition={spinTransition}></Wrapper>
    </AnimatePresence>
  );
};
