import React, { memo, useCallback, useEffect, useMemo, useContext, useRef, useState, MutableRefObject } from 'react';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import gsap from 'gsap';

import * as Styled from './GameOnePhone.styled';

import { HistoryContext, HistoryContextType } from 'context/history';
import { HeaderContext, HeaderContextType } from 'context/header';
import { RedirectsContext, RedirectsContextType } from 'context/redirects';

import GameOnePhoneLock from 'components/GameOnePhoneLock';
import GameOnePhoneClock from 'components/GameOnePhoneClock';
import GameOnePhoneNavbar from 'components/GameOnePhoneNavbar';
import GameOnePhoneMessage from 'components/GameOnePhoneMessage';
import GameOnePhoneGallery from 'components/GameOnePhoneGallery';
import GameOnePhonePicture from 'components/GameOnePhonePicture';
import GameOnePhoneVoicemail from 'components/GameOnePhoneVoicemail';
import { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import GameOneDetective, { GameOneDetectiveRef } from 'components/GameOneDetective/GameOneDetective';
import { PageState } from 'constants/enum';
import { isSocialGestureMinimizedBrowser } from 'utils/platform';

interface Props {
  uvLight: boolean;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onComplete: (accuracy: number) => void;
}

export enum PhoneState {
  LOCK,
  CLOCK,
  MESSAGE,
  GALLERY,
  VOICEMAIL,
}

export interface PhonePicture {
  src: string;
  srcUv: string;
  date: string;
  alt?: string;
}

export const GameOnePhone: React.FC<Props> = ({ uvLight, detectiveVideoRef, onComplete }) => {
  const { setHeader }: HeaderContextType = useContext(HeaderContext);
  const { history, setHistory }: HistoryContextType = useContext(HistoryContext);
  const { redirects, setRedirects }: RedirectsContextType = useContext(RedirectsContext);

  const [state, setState] = useState(PhoneState.LOCK);
  const [picture, setPicture] = useState<PhonePicture>(null);

  const gameOneDetectiveRef = useRef<GameOneDetectiveRef>(null);

  const navbarVisible = useMemo(() => state !== PhoneState.LOCK, [state]);

  const gradientVisible = useMemo(() => {
    return [PhoneState.MESSAGE, PhoneState.GALLERY, PhoneState.VOICEMAIL].includes(state);
  }, [state]);

  const wallpaperVisible = useMemo(() => {
    return !picture && [PhoneState.LOCK, PhoneState.CLOCK].includes(state);
  }, [picture, state]);

  const blackShatter = useMemo(() => {
    return [PhoneState.MESSAGE, PhoneState.GALLERY, PhoneState.VOICEMAIL].includes(state);
  }, [state]);

  const handleSetState = useCallback((state: PhoneState) => {
    setState(currentState => (currentState === state ? PhoneState.CLOCK : state));
  }, []);

  useEffect(() => {
    if (redirects?.gameState) {
      const lastGameStep = parseInt(redirects.gameState);
      if (lastGameStep) {
        setState(lastGameStep);
      }
    }
  }, [redirects]);

  useEffect(() => {
    setHistory(`${PageState.GAME_ONE}.${state}`);

    if (state === PhoneState.LOCK || state === PhoneState.CLOCK) {
      setHeader({
        isWhite: true,
        children: (
          <GameOneDetective
            phoneState={state}
            onComplete={onComplete}
            detectiveVideoRef={detectiveVideoRef}
            ref={gameOneDetectiveRef}
          />
        ),
      });
    } else {
      setHeader({
        isWhite: false,
        hasMask: true,
        children: (
          <GameOneDetective
            phoneState={state}
            onComplete={onComplete}
            detectiveVideoRef={detectiveVideoRef}
            ref={gameOneDetectiveRef}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setHeader, onComplete, detectiveVideoRef]);

  return (
    <Styled.Wrapper
      transition={{ duration: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={classNames('wallpaper', { show: wallpaperVisible })} />
      <div className={classNames('gradient', { show: gradientVisible, uv: uvLight })} />
      <AnimatePresence>
        {state === PhoneState.LOCK ? (
          <GameOnePhoneLock
            key="lock"
            uvLight={uvLight}
            setState={handleSetState}
            gameOneDetectiveRef={gameOneDetectiveRef}
          />
        ) : null}
        {state === PhoneState.CLOCK ? <GameOnePhoneClock key="clock" uvLight={uvLight} /> : null}
        {state === PhoneState.MESSAGE ? (
          <GameOnePhoneMessage
            key="message"
            uvLight={uvLight}
            setPicture={setPicture}
            showArrows={isSocialGestureMinimizedBrowser()}
          />
        ) : null}
        {state === PhoneState.GALLERY ? (
          <GameOnePhoneGallery
            key="gallery"
            uvLight={uvLight}
            setPicture={setPicture}
            showArrows={isSocialGestureMinimizedBrowser()}
          />
        ) : null}
        {state === PhoneState.VOICEMAIL ? <GameOnePhoneVoicemail key="voicemail" uvLight={uvLight} /> : null}
        {picture ? (
          <GameOnePhonePicture key="picture" picture={picture} uvLight={uvLight} setPicture={setPicture} />
        ) : null}
      </AnimatePresence>
      <GameOnePhoneNavbar state={state} visible={navbarVisible} uvLight={uvLight} setState={handleSetState} />
      <div className={classNames('shatter', { black: blackShatter })} />
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhone);
