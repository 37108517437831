import styled from 'styled-components';
import { motion } from 'framer-motion';

import { box } from 'styles/mixins';

export const Wrapper = styled(motion.div)`
  position: relative;

  &.loaded {
    img {
      opacity: 1;
    }
  }

  img {
    ${box('100%')};
    position: absolute;
    object-fit: cover;
    object-position: center center;
    opacity: 0;
    transition: opacity 0.3s;
  }
`;
