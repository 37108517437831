import * as THREE from 'three';
import { GLTFLoader } from '../utils/GLTFLoader';
import { gsap } from 'gsap';

import badge from 'assets/models/badge.glb';

export class Main extends THREE.Scene {
  rafId: number;
  badgeScene: THREE.Scene;
  touch = { x: 0, y: 0 };
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;

  constructor(camera: THREE.PerspectiveCamera, renderer: THREE.WebGLRenderer) {
    super();
    this.camera = camera;
    this.renderer = renderer;
    this.loadModel(badge);
  }

  loadModel = url => {
    const loader = new GLTFLoader().load(
      url,
      gltf => {
        this.add(gltf.scene);
        this.badgeScene = gltf.scene;
        this.badgeScene.position.z = 3;
        gsap.to(this.badgeScene.position, { z: -1.5, duration: 2, ease: 'circ.inOut' });

        if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
          window.addEventListener('touchmove', this.onTouchMove);
        } else {
          window.addEventListener('mousemove', this.onMouseMove);
        }

        this.dispatchEvent({ type: 'loaded' });
        this.init();
      },
      null,
      null
    );
  };

  onTouchMove = (e: TouchEvent) => {
    this.touch.x = (e.touches[0].clientX / window.innerWidth) * 2 - 1;
    this.touch.y = -(e.touches[0].clientY / window.innerHeight) * 2 + 1;
  };

  onMouseMove = (e: MouseEvent) => {
    this.touch.x = (e.clientX / window.innerWidth) * 2 - 1;
    this.touch.y = -(e.clientY / window.innerHeight) * 2 + 1;
  };

  init = () => {
    this.rafId = requestAnimationFrame(this.update);
  };

  update = () => {
    if (!this.renderer) return;
    this.rafId = requestAnimationFrame(this.update);
    if (this.badgeScene) {
      const q = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(-this.touch.y, this.touch.x, 0), Math.PI / 2);
      this.badgeScene.quaternion.slerp(q, 0.1);
    }
    this.renderer.render(this, this.camera);
  };

  sceneTraverse = (obj, fn) => {
    if (!obj) return;
    fn(obj);

    if (obj.children && obj.children.length > 0) {
      obj.children.forEach(o => {
        this.sceneTraverse(o, fn);
      });
    }
  };

  dispose = () => {
    cancelAnimationFrame(this.rafId);
    window.removeEventListener('touchmove', this.onTouchMove);
    this.sceneTraverse(this, o => {
      if (o.geometry) {
        o.geometry.dispose();
      }

      if (o.material) {
        if (o.material.length) {
          for (let i = 0; i < o.material.length; ++i) {
            o.material[i].dispose();
          }
        } else {
          o.material.dispose();
        }
      }
    });

    this.camera = null;
    this.renderer && this.renderer.renderLists.dispose();
    this.renderer = null;
  };
}
