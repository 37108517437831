import Badge from 'components/Badge';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PageProps } from 'types/page';
import * as Styled from './BadgePage.styled';

interface BadgePageProps extends PageProps {
  onComplete: () => void;
}

export const BadgePage: React.FC<BadgePageProps> = ({ visible, onComplete }) => {
  const { t } = useTranslation();

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <Badge visible={visible} onComplete={onComplete} />
    </Styled.Wrapper>
  );
};

export default BadgePage;
