import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rect, vh, fullHeight } from 'styles/mixins';

import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';
import bgImage from 'assets/images/congratulation-background.jpg';
import bgPaperImage from 'assets/images/congratulation-paper-background.png';
import { colors } from 'styles/vars';

export const Wrapper = styled(motion.div)`
  ${fullHeight};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${bgImage});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    text-transform: uppercase;

    h1 {
      ${fontChannel4Horseferry(20, 700, 1.1)};
      color: ${colors.white};
      width: 70%;
      margin-bottom: 2rem;
    }

    .accuracy-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2.6rem;

      &.new-margin {
        margin-bottom: 3rem;
      }

      .points-wrapper {
        background-color: ${colors.white};
        padding: 1rem 1.2rem 1rem 1.4rem;
        margin-right: 1rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        ${fontChannel4Chadwick(29, 700, 1.1)}
        letter-spacing: 0.1rem;
        color: ${colors.blackBeluga};
      }

      .points-description-wrapper {
        display: flex;
        flex-direction: column;
        text-align: left;
        ${fontChannel4Horseferry(32, 700, 0.84)};
        letter-spacing: -0.06em;
        color: ${colors.white};
      }
    }
  }

  .description-points {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    letter-spacing: 0.075rem;
    width: 68%;
    margin-bottom: -0.5rem;

    &.new-margin {
      margin-bottom: 1.7rem;
    }
  }

  .action-wrapper {
    width: 100%;
    min-height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: url(${bgPaperImage});
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    p {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      letter-spacing: 0.075rem;
      color: ${colors.blackBeluga};
      width: 78%;
      margin-bottom: 2.2rem;

      &.new-margin {
        margin-bottom: 3rem;
      }
    }

    .try-again {
      ${fontChannel4Chadwick(18, 700, 1.1)}
      letter-spacing: 0.1rem;
      color: ${colors.blackBeluga};
      margin-bottom: 3.4rem;
    }
  }

  .button-shere {
    margin-bottom: 2.3rem;
    padding-top: 2.3rem;

    &.new-margin {
      padding: 0;
      margin-bottom: 1.5rem;
    }

    button {
      ${fontChannel4Horseferry(18, 700, 1.67)};
      background-color: ${colors.blue};
      color: ${colors.white};
      padding: 0.8rem 5.5rem;
    }
  }

  .button-continue-wrapper {
    margin-bottom: 2.5rem;

    &.new-margin {
      margin-bottom: 4.6rem;
    }

    button {
      padding: 1.6rem 5.3rem;

      span {
        ${fontChannel4Horseferry(24, 700, 1.1)};
        letter-spacing: -0.05rem;
      }
    }
  }
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
