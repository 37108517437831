import React, { memo, useState, useCallback, useRef, useContext, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './GameThree.styled';

import GameDetectiveVideo, { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import GameThreeIntro from 'components/GameThreeIntro';
import GameThreeVideoSet from 'components/GameThreeVideoSet';
import GameThreeFingerprints from 'components/GameThreeFingerprints';
import { RedirectsContext } from 'context/redirects';
import { SettingsContext } from 'context/settings';
import { HistoryContext } from 'context/history';
import { PageState } from 'constants/enum';

interface Props {
  uvLight: boolean;
  onExit: () => void;
  onComplete: (accuracy: number) => void;
}

export enum State {
  INTRO,
  FINGERPRINTS,
  GAME,
}

export const GameThree: React.FC<Props> = ({ uvLight, onComplete, onExit }) => {
  const detectiveVideoRef = useRef<GameDetectiveVideoRef>(null);
  const { setSettings } = useContext(SettingsContext);

  const [state, setState] = useState(State.INTRO);
  const { redirects } = useContext(RedirectsContext);
  const { history, setHistory } = useContext(HistoryContext);

  useEffect(() => {
    if (redirects.restartTraining) {
      setState(State.INTRO);
    }

    if (redirects?.gameState) {
      const lastGameStep = parseInt(redirects.gameState);
      if (lastGameStep) {
        setState(lastGameStep);
      }
    }

    if (redirects.tryAgain) {
      setState(State.GAME);
    }
  }, [redirects]);

  const handleIntroComplete = useCallback(() => {
    setState(State.FINGERPRINTS);
  }, []);

  const handleFingerprintsComplete = useCallback(() => {
    setState(State.GAME);
  }, []);

  useEffect(() => {
    setSettings({ showUV: state === State.INTRO });
  }, [setSettings, state]);

  useEffect(() => {
    setHistory(`${PageState.GAME_THREE}.${state}`);
  }, [state, setHistory]);

  return (
    <Styled.Wrapper>
      <AnimatePresence exitBeforeEnter>
        {state === State.INTRO && (
          <GameThreeIntro
            key="intro"
            uvLight={uvLight}
            onComplete={handleIntroComplete}
            onExit={onExit}
            detectiveVideoRef={detectiveVideoRef}
          />
        )}
        {state === State.FINGERPRINTS && (
          <GameThreeFingerprints visible={state === State.FINGERPRINTS} onComplete={handleFingerprintsComplete} />
        )}
        {state === State.GAME && <GameThreeVideoSet detectiveVideoRef={detectiveVideoRef} onComplete={onComplete} />}
      </AnimatePresence>
      <GameDetectiveVideo
        ref={detectiveVideoRef}
        src={require('assets/videos/game3-intro.mp4')}
        poster={require('assets/images/teaser1-slice-2.jpg')}
      />
    </Styled.Wrapper>
  );
};

export default memo(GameThree);
