import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import gsap from 'gsap';
import SplitText from 'gsap/dist/SplitText';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import * as Styled from './GameGuideTwo.styles';
import BackIcon from 'components/Icons/BackIcon';
import CtaYellow from 'components/CtaYellow';
import RectangleIcon from 'components/Icons/RectangleIcon';
import DashedCircle from 'svgs/DashedCircle.svg';

interface GameGuideTwoProps {
  handleMenuChange: () => void;
  onResume?: () => void;
}

export const GameGuideTwo: React.FC<GameGuideTwoProps> = ({ handleMenuChange, onResume }) => {
  const { t } = useTranslation();
  const [fillAll, setFillAll] = useState<boolean>(false);
  const [slide, setSlide] = useState(0);

  const slide1Ref = useRef<HTMLDivElement>(null);
  const rectangle1Ref = useRef<SVGElement>(null);
  const rectangle2Ref = useRef<SVGElement>(null);
  const rectangle3Ref = useRef<SVGElement>(null);
  const rectangle4Ref = useRef<HTMLDivElement>(null);
  const rectangle5Ref = useRef<SVGElement>(null);
  const rectangle6Ref = useRef<SVGElement>(null);

  const handleCtaClick = useCallback(() => {
    setSlide(s => {
      if (s < 3) {
        return Math.min(3, s + 1);
      } else {
        onResume();
        return s;
      }
    });
  }, [onResume]);

  useEffect(() => {
    const timeline = gsap.timeline();

    const slide1 = slide1Ref.current;
    const slide1Img = slide1.querySelector('.phone-wrapper');
    const doubleTap = slide1.querySelector('.double-tap');
    const doubleTapPointer = doubleTap.querySelectorAll('.pointer');

    const rect1 = rectangle1Ref.current;
    const rect2 = rectangle2Ref.current;
    const rect3 = rectangle3Ref.current;
    const rect4 = rectangle4Ref.current;
    const rect4Arrows = rect4.querySelectorAll('.arrow');
    const rect4ArrowsVertical = rect4.querySelectorAll('.arrow-vertical');
    const rect4ArrowsHorizontal = rect4.querySelectorAll('.arrow-horizontal');
    const rect4Rotate = rect4.querySelectorAll('.arrow-rotate');
    const rect4Circle = rect4.querySelectorAll('.rotate-circle');
    const rect5 = rectangle5Ref.current;
    const rect6 = rectangle6Ref.current;

    if (slide === 0) {
      timeline
        .set(slide1Img, { scale: 1.5, x: '-50%' }, 0)
        .set(rect1, { y: 19 })
        .set(rect2, { y: -17 })
        .set(rect3, { y: -4 })
        .set(rect4, { y: -30 })
        .set(rect5, { y: -30 })
        .set(rect6, { y: -4 })
        .set(rect4Arrows, { autoAlpha: 0, duration: 0 }, 0)
        .set(rect4ArrowsVertical, { autoAlpha: 1, duration: 0.5 }, 0)
        .set(rect4Rotate, { autoAlpha: 0, duration: 0 }, 0)
        .set(rect4Circle, { autoAlpha: 0, duration: 0 }, 0)
        .set(doubleTap, { autoAlpha: 0, duration: 0 }, 0)
        .to(doubleTapPointer, { x: -30, y: 25, duration: 0 }, 0)
        .to(slide1, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide1Img, { duration: 1, scale: 1, ease: 'expo.power3' }, 0.4)
        .to(rect4, { y: 10, duration: 1, ease: 'expo.power3' }, 0.5)
        .to(rect4, { y: -30, duration: 1, ease: 'easy.power3' }, 1.5)
        .to(rect4, { y: 10, duration: 1, ease: 'easy.power3' }, 2.5)
        .to(rect4ArrowsVertical, { autoAlpha: 0, duration: 0.5 }, 3.5);
    }

    if (slide === 1) {
      timeline
        .to(rect4Rotate, { autoAlpha: 1, duration: 0.5 }, 0)
        .to(rect4Circle, { autoAlpha: 1, duration: 0 }, 0)
        .to(rect4, { rotate: -180, duration: 1, ease: 'easy.power3' }, 0)
        .to(rect4Circle, { rotate: -180, duration: 1, ease: 'easy.power3' }, 1)
        .to(rect4Circle, { autoAlpha: 0, duration: 0.3 }, 1)
        .to(rect4Circle, { autoAlpha: 1, duration: 0.2 }, 1.3)
        .to(rect4, { rotate: 0, duration: 1, ease: 'easy.power3' }, 1.5)
        .to(rect4Circle, { rotate: 0, duration: 1, ease: 'easy.power3' }, 1.5)
        .to(rect4Rotate, { autoAlpha: 0, duration: 0.5 }, 1.5)
        .to(rect4Circle, { autoAlpha: 0, duration: 0.5 }, 1.5);
    }

    if (slide === 2) {
      timeline
        .to(rect4ArrowsHorizontal, { autoAlpha: 1, duration: 0.5 }, 0)
        .to(rect4, { x: -25, duration: 0.5, ease: 'easy.power3' }, 0.5)
        .to(rect3, { x: 25, duration: 0.5, ease: 'easy.power3' }, 0.8)
        .to(rect4, { x: 0, duration: 0.5, ease: 'easy.power3' }, 1.5)
        .to(rect3, { x: 0, duration: 0.5, ease: 'easy.power3' }, 1.8)
        .to(rect4ArrowsHorizontal, { autoAlpha: 0, duration: 0.5 }, 2);
    }

    if (slide === 3) {
      timeline
        .to(doubleTap, { autoAlpha: 1, duration: 0.5 }, 0)
        .to(rect1, { y: 0, duration: 0.2 }, 0.5)
        .to(rect2, { y: 0, duration: 0.2 }, 0.5)
        .to(rect3, { y: 0, duration: 0.2 }, 0.5)
        .to(rect4, { y: 0, duration: 0.2 }, 0.5)
        .to(rect5, { y: 0, duration: 0.2 }, 0.5)
        .to(rect6, { y: 0, duration: 0.2 }, 0.5)
        .to(doubleTap, { autoAlpha: 1, duration: 0.3 }, 0.7)
        .to(doubleTap, { autoAlpha: 1, duration: 0.3 }, 0.7)
        .to(doubleTapPointer, { x: -18, y: 10, duration: 0.5 }, 1)
        .fromTo(doubleTapPointer, { scale: 1 }, { scale: 1.1, duration: 0.3, repeat: 2 }, 1.5)
        .fromTo(
          doubleTapPointer,
          { scale: 1.1 },
          { scale: 1, duration: 0.3, repeat: 2, onComplete: () => setFillAll(true) },
          1.8
        );
    }

    return () => timeline.totalProgress(1).kill();
  }, [slide]);

  useEffect(() => {
    const timeline = gsap.timeline();

    const slide1 = slide1Ref.current;
    const description1 = slide1.querySelector('.description-1');
    const description1TextSplit = new SplitText(description1, { type: 'words,chars' });
    const description2 = slide1.querySelector('.description-2');
    const description2TextSplit = new SplitText(description2, { type: 'words,chars' });
    const description3 = slide1.querySelector('.description-3');
    const description3TextSplit = new SplitText(description3, { type: 'words,chars' });
    const description4 = slide1.querySelector('.description-4');
    const description4TextSplit = new SplitText(description4, { type: 'words,chars' });

    timeline
      .set(description1, { autoAlpha: 0 }, 0)
      .set(description2, { autoAlpha: 0 }, 0)
      .set(description3, { autoAlpha: 0 }, 0)
      .set(description4, { autoAlpha: 0 }, 0);

    if (slide === 0) {
      timeline
        .to(description1, { autoAlpha: 1 }, 0)
        .fromTo(description1TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    }

    if (slide === 1) {
      timeline
        .to(description2, { autoAlpha: 1 }, 0)
        .fromTo(description2TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    }

    if (slide === 2) {
      timeline
        .to(description3, { autoAlpha: 1 }, 0)
        .fromTo(description3TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    }

    if (slide === 3) {
      timeline
        .to(description4, { autoAlpha: 1 }, 0)
        .fromTo(description4TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    }

    return () => {
      timeline.kill();
      description1TextSplit.revert();
      description2TextSplit.revert();
      description3TextSplit.revert();
      description4TextSplit.revert();
    };
  }, [slide]);

  return (
    <>
      <Styled.MenuButton onClick={handleMenuChange}>
        <BackIcon />
      </Styled.MenuButton>
      <Styled.Title>{t('gameGuideTwo.title')}</Styled.Title>
      <Styled.Content>
        <div className="slides">
          <div className="slide" ref={slide1Ref}>
            <div className="phone-wrapper">
              <RectangleIcon setRef={rectangle1Ref} className={classNames('rectangle', { fill: fillAll })} />
              <RectangleIcon setRef={rectangle2Ref} className={classNames('rectangle', { fill: fillAll })} />
              <RectangleIcon setRef={rectangle3Ref} className={classNames('rectangle', { fill: fillAll })} />
              <div className="active-rectangle" ref={rectangle4Ref}>
                <span className="arrow arrow-up arrow-vertical"></span>
                <span className="arrow arrow-left arrow-horizontal"></span>
                <RectangleIcon className={classNames('rectangle', { fill: true })} />
                <span className="arrow arrow-down arrow-vertical"></span>
                <span className="arrow arrow-right arrow-horizontal"></span>
                <span className="arrow-rotate"></span>
                <DashedCircle className="rotate-circle" />
              </div>
              <RectangleIcon setRef={rectangle5Ref} className={classNames('rectangle', { fill: fillAll })} />
              <RectangleIcon setRef={rectangle6Ref} className={classNames('rectangle', { fill: fillAll })} />
              <div className="double-tap">
                <p>
                  Double
                  <br />
                  Tap
                </p>
                <div className="double-tap-background"></div>
                <div className="pointer"></div>
              </div>
            </div>
            <p className="description description-1">{parse(t('gameGuideTwo.slide1.description'))}</p>
            <p className="description description-2">{parse(t('gameGuideTwo.slide2.description'))}</p>
            <p className="description description-3">{parse(t('gameGuideTwo.slide3.description'))}</p>
            <p className="description description-4">{parse(t('gameGuideTwo.slide4.description'))}</p>
          </div>
        </div>
        <div className="footer">
          <div className="button-wrapper">
            <CtaYellow
              label={slide === 3 ? t('gameGuideTwo.resume') : t('gameGuideTwo.next')}
              onClick={handleCtaClick}
            />
          </div>
          <div className="progress">
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 0 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 1 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 2 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 3 })} />
            </div>
          </div>
          <div className="page">{Math.max(1, slide + 1)}/4</div>
        </div>
      </Styled.Content>
    </>
  );
};

export default memo(GameGuideTwo);
