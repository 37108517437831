import * as React from 'react';
import { useState } from 'react';

import { Dot, DotList, Key, KeyboardList } from './styled';

interface UnlockScreenProps {
  password: string;
  inputCallback: (correct: boolean) => void;
}

export const UnlockScreen: React.FC<UnlockScreenProps> = ({ password, inputCallback }) => {
  const passwordLength = 4;
  const [inputData, setInputData] = useState<number[]>([]);
  const [dataLength, setDataLength] = useState<number>(inputData.length ?? 0);
  const [isCorrect, setIsCorrect] = useState(false);

  const handleKeyClick = React.useCallback(
    keyIndex => () => {
      setInputData(prevState => {
        prevState.push(keyIndex);
        if (prevState.length < passwordLength) {
          setDataLength(prevState.length);
          return prevState;
        } else if (prevState.length === passwordLength) {
          const pass = prevState.join('');
          inputCallback(pass === password);
          setIsCorrect(pass === password);
          if (pass !== password) {
            setDataLength(4);
            return [];
          } else {
            setDataLength(prevState.length);
            return prevState;
          }
        } else {
          setDataLength(1);
          return [keyIndex];
        }
      });
    },
    [inputCallback, password]
  );

  return (
    <>
      <p>password: {password}</p>
      {isCorrect ? <p>password correct</p> : <p>password not correct</p>}
      <DotList>
        {[...Array(4)].map((_, index) => (
          <Dot key={`Dot-${index}`} isActive={dataLength > index} />
        ))}
      </DotList>
      <KeyboardList>
        {[...Array(10)].map((_, index) => (
          <Key key={`Key-${index}`} onClick={handleKeyClick(index)}>
            {index}
          </Key>
        ))}
      </KeyboardList>
    </>
  );
};
