import React, { memo, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './GameTwoBrowserContainer.styled';

import GameTwoStripe from 'components/GameTwoBrowserElement';
import { vw } from 'utils/dom';
import { isTouchDevice } from 'utils/platform';

interface Props {
  onComplete?: () => void;
  type: 'game' | 'animation';
  updateUVBounds?: (rect) => void;
  isFinished?: boolean;
  state?: number;
  scaleRatio?: number;
}
export type BoundsType = {
  top: number;
  left: number;
  bottom: number;
  right: number;
  mx: number;
  my: number;
};

export const GameTwoBrowserContainer: React.FC<Props> = ({
  onComplete,
  state,
  type,
  isFinished,
  updateUVBounds,
  scaleRatio = 1,
}) => {
  const [lastIndex, setLastIndex] = useState(0);
  const wrapperRef = useRef(null);
  const [bounds, setBounds] = useState<BoundsType>({ top: 0, left: 0, bottom: 0, right: 0, mx: 0, my: 0 });
  const getNewIndex = (): string => {
    let newIndex;
    setLastIndex(prev => {
      newIndex = 2 + prev + 1;
      return newIndex;
    });
    return newIndex.toString();
  };

  const stripes = useRef([
    {
      w: 16.9 * scaleRatio,
      mx: 0,
      baseX: vw(15.5 * 5) * scaleRatio,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      baseY: (-20 + Math.random() * 40) * scaleRatio,
      img: require('assets/images/game2/stripe1.png'),
      first: true,
    },
    {
      w: 18.3 * scaleRatio,
      mx: 2 * scaleRatio,
      baseX: vw(15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      img: require('assets/images/game2/stripe2.png'),
    },
    {
      w: 18.7 * scaleRatio,
      mx: 4 * scaleRatio,
      baseX: vw(15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      img: require('assets/images/game2/stripe3.png'),
    },
    {
      w: 17 * scaleRatio,
      mx: 2 * scaleRatio,
      baseX: vw(-15.5 * 3) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      img: require('assets/images/game2/stripe4.png'),
    },
    {
      w: 18.3 * scaleRatio,
      mx: 3 * scaleRatio,
      baseX: vw(-15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      img: require('assets/images/game2/stripe5.png'),
    },
    {
      w: 18.7 * scaleRatio,
      mx: 3 * scaleRatio,
      baseX: vw(-15.5 * 4) * scaleRatio,
      baseY: -15 + Math.random() * 30,
      baseR: isTouchDevice() ? (Math.random() > 0.5 ? 175 + Math.random() * 10 : -5 + Math.random() * 10) : 0,
      img: require('assets/images/game2/stripe6.png'),
    },
  ]);

  const complete = useRef([...Array.from(Array(6), _ => false)]);

  const checkComplete = (ready: boolean, id: number) => {
    complete.current[id] = ready;
    if (complete.current.length === stripes.current.length && complete.current.every(v => v === true)) {
      onComplete();
    }
  };

  useEffect(() => {
    updateUVBounds({ top: bounds.top, left: bounds.left, bottom: bounds.bottom, right: bounds.right });
  }, [bounds, updateUVBounds]);

  return (
    <>
      <Styled.Wrapper
        key={'stripes-wrapper'}
        ref={wrapperRef}
        style={{ scale: type === 'animation' ? 0.85 : undefined }}
        vScale={scaleRatio}
      >
        {stripes.current.map((stripe, index) => (
          <GameTwoStripe
            id={index}
            state={state}
            isFinished={isFinished}
            isFirst={stripe.first ?? false}
            bounds={bounds}
            interactive={type === 'game'}
            baseX={stripe.baseX}
            baseR={stripe.baseR}
            baseY={stripe.baseY}
            setBounds={setBounds}
            width={stripe.w}
            checkComplete={checkComplete}
            key={`Stripe-i-${index}`}
            bgSrc={stripe.img}
            margin={stripe.mx}
            vScale={scaleRatio}
            newIndex={getNewIndex}
          />
        ))}
      </Styled.Wrapper>
    </>
  );
};

export default memo(GameTwoBrowserContainer);
