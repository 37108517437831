import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rect, vh, px } from 'styles/mixins';
import { fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';

export const Wrapper = styled(motion.div)`
  ${rect('relative', 0, 0, '100%', vh(100))}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors.black};

  .main-fingerprint {
    position: relative;
    height: 30vh;
    width: 100%;

    .fingerprint {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      p {
        ${fontChannel4Chadwick(10, 500, 1.1)};
        color: ${colors.blackCarbon};
        text-transform: uppercase;
        background-color: ${colors.aqua};
        opacity: 0;
        position: absolute;
        padding: 3px 6px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateX(-50%);
          width: ${px(35)};
          height: ${px(24)};
          background: url(${require('assets/images/game-3-fingerprint-pointer.png')}) center center no-repeat;
          background-size: contain;
        }
      }

      &-wrong {
        height: ${px(180)};
        opacity: 1;
      }

      &-0 {
        height: ${px(156)};
        width: ${px(116)};
        transform: translate(-50%, -53.5%);

        p {
          bottom: ${px(-10)};
          left: ${px(-90)};
          &:after {
            transform: scaleX(-1) translate(-98%, -100%);
          }
        }
      }

      &-1 {
        height: ${px(138)};
        width: ${px(111)};
        transform: translate(-52%, -47%);

        p {
          top: ${px(10)};
          left: ${px(-100)};
          &:after {
            transform: scale(-1, -1) translate(-100%, 0);
          }
        }
      }

      &-2 {
        height: ${px(163)};
        width: ${px(112)};
        transform: translate(-50%, -48%);

        p {
          top: ${px(-10)};
          left: ${px(-80)};
          &:after {
            transform: scale(-1, -1) translate(-100%, 0);
          }
        }
      }

      &-3 {
        height: ${px(163)};
        width: ${px(114)};
        transform: translate(-50.5%, -55.3%);

        p {
          top: ${px(90)};
          left: ${px(-100)};
          &:after {
            transform: scaleX(-1) translate(-98%, -100%);
          }
        }
      }

      &-4 {
        height: ${px(156)};
        width: ${px(109)};
        transform: translate(-47%, -53.5%);

        p {
          bottom: ${px(-10)};
          right: ${px(-70)};
          &:after {
            transform: translate(-210%, -100%);
          }
        }
      }

      &-5 {
        height: ${px(168)};
        width: ${px(116)};
        transform: translate(-50%, -46.5%);

        p {
          top: ${px(0)};
          right: ${px(-80)};
          &:after {
            transform: scaleY(-1) translate(-215%, 0%);
          }
        }
      }

      &.active {
        img {
          opacity: 1;
        }

        p {
          transition: opacity ease-in 0.2s;

          &.show {
            opacity: 1;
          }
        }
      }
    }
  }

  .title {
    ${fontChannel4Chadwick(20, 500)};
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    text-align: center;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-end;
    padding: ${px(10)} ${px(36)};
  }

  .fingerprint-box {
    position: relative;
    width: ${px(75)};
    height: auto;
    min-height: ${px(116)};
    margin: 0 ${px(39)} ${px(28)} 0;

    p {
      ${fontChannel4Chadwick(8, 500, 0.8)};
      opacity: 0.2;
      color: ${colors.white};
      text-transform: uppercase;
      position: absolute;
      bottom: ${px(23)};
      left: ${px(-35)};
      transform: rotate(-90deg);
    }

    img {
      height: auto;
      width: ${px(47)};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: url(${require('assets/images/game-3-fingerprints-border.png')}) center center no-repeat;
      background-size: contain;
      opacity: 0.2;
    }

    &-1 {
    }

    &.active {
      &:before {
        opacity: 1;
      }
      p {
        opacity: 1;
      }
    }

    &.is-wrong {
      pointer-events: none;
    }
  }

  .scanner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
