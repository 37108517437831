import styled from 'styled-components';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';
import Fingerprint from 'assets/images/ui/landing-pages/fingerPrint.png';

export const OuterWrapper = styled.div`
  z-index: 11;
  width: 94vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 20px 25px rgba(0, 0, 0, 0.15));
`;

export const Wrapper = styled.div`
  min-height: 22rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 3rem;
  background-color: ${colors.deepBlue};

  &:before {
    content: '';
    background: url(${Fingerprint}) center center no-repeat;
    background-size: cover;
    position: absolute;
    top: 3rem;
    right: 2rem;
    width: 6rem;
    height: 9.2rem;
  }
`;

export const Title = styled.h5`
  ${fontChannel4Horseferry(33, 700, 1)};
  color: ${colors.aqua};
  text-transform: uppercase;
  letter-spacing: -0.05rem;
  margin: 1.8rem 0 0 1rem;
  padding-right: 9rem;
`;

export const Description = styled.div`
  display: flex;
  margin: 2.5rem 0 0.5rem 0.6rem;

  span,
  p {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    color: ${colors.aqua};
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 60%;
  padding: 2.6rem 0 0 1.6rem;
  background-color: ${colors.deepBlue};

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    right: -0.9rem;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 2rem 0.9rem 3.1rem 0;
    border-color: transparent transparent ${colors.deepBlue} transparent;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const ParagraphWrapper = styled.div`
  margin-left: 3.5rem;
  padding-right: 1rem;
  p + p {
    margin-top: 1.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  width: 95%;
  margin: 2rem auto 0;

  button {
    ${fontChannel4Horseferry(24, 700, 1.1)};
    letter-spacing: -0.05rem;
    padding: 1.7rem 0;
  }
`;
