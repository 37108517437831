import React, { memo, useEffect, useMemo, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import * as Styled from './GameOnePhoneVoicemail.styled';

import { voicemails, avatars } from 'constants/game-one';
import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer';
import useWindowVisible from 'hooks/useWindowVisible';

interface Props {
  uvLight: boolean;
}

export const GameOnePhoneVoicemail: React.FC<Props> = ({ uvLight }) => {
  const { t } = useTranslation();
  const isWindowVisible = useWindowVisible();
  const firstVoiceMailRef = useRef<HTMLButtonElement>(null);

  const items = useMemo<{ avatar: string; name: string; date: string; voicemail: string }[]>(
    () => t('gameOne.phone.voicemail.items', { returnObjects: true }),
    [t]
  );

  useEffect(() => {
    firstVoiceMailRef?.current?.focus();
  }, []);

  return (
    <Styled.Wrapper
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      initial={{ opacity: 0, scale: 1.1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
    >
      <div className="list">
        <p className="title">{parse(t('gameOne.phone.voicemail.title'))}</p>
        {items.map((item, i) => {
          const name = item.name;
          const date = item.date;
          const audio = voicemails[item.voicemail];
          const picture = avatars[item.avatar];
          return (
            <div className="item" key={i}>
              <img className="picture" src={picture.src} alt={picture.alt} />
              <div className="text">
                <p className="name">{name}</p>
                <p className="date">{date}</p>
              </div>
              <AudioPlayer src={audio} focus={i === 0 ? true : false} muted={!isWindowVisible} />
            </div>
          );
        })}
      </div>

      {uvLight ? <div className="uv"></div> : null}
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhoneVoicemail);
