import React, { useState, useEffect, useContext } from 'react';
import * as Styled from './Menu.styles';
import CloseIcon from 'components/Icons/CloseIcon';

import { useTranslation } from 'react-i18next';
import { GameMenu, GameMenuItem } from './GameMenu/GameMenu';
import GameGuideOne from './GameGuideOne/index';
import GameGuideTwo from './GameGuideTwo/index';
import GameGuideTwoIsSocial from './GameGuideTwoIsSocial';
import GameGuideThree from './GameGuideThree';
import { GameVersionState, PageState } from 'constants/enum';
import { PageProps } from 'types/page';
import { RedirectsContext, RedirectsContextType } from 'context/redirects';
import { ProgressContext } from 'context/progress';
import { isSnap, isTikTok } from 'utils/platform';

interface MenuProps extends PageProps {
  pageState: PageState;
  open: boolean;
  handleOpenMenu: () => void;
  handleOpenPopup: () => void;
}

export enum menuGuideState {
  MENU,
  GAME_GUIDE_ONE,
  GAME_GUIDE_TWO,
  GAME_GUIDE_THREE,
}

export const Menu: React.FC<MenuProps> = ({ pageState, open, handleOpenMenu, handleOpenPopup, setPageCallback }) => {
  const { t } = useTranslation();
  const { redirects, setRedirects }: RedirectsContextType = useContext(RedirectsContext);
  const { setProgress } = useContext(ProgressContext);

  const [menuState, setMenuState] = useState<number>(menuGuideState.MENU);
  const [gameVersion, setGameVersion] = useState<number>(GameVersionState.MAIN);

  const isSocial = isSnap() || isTikTok();

  useEffect(() => {
    if (pageState === PageState.GAME_ONE) {
      setGameVersion(GameVersionState.GAME_ONE);
    } else if (pageState === PageState.GAME_TWO) {
      setGameVersion(GameVersionState.GAME_TWO);
    } else if (pageState === PageState.GAME_THREE) {
      setGameVersion(GameVersionState.GAME_THREE);
    } else {
      setGameVersion(GameVersionState.MAIN);
    }
  }, [pageState]);

  const handleClick = (item: GameMenuItem): void => {
    switch (item.type) {
      case 'openGameMenu':
        setMenuState(item.gameGuide);
        break;
      case 'whatsMurderIsland':
        setPageCallback(PageState.MURDER_ISLAND);
        handleOpenMenu();
        break;
      case 'exitDashboard':
        setPageCallback(PageState.LANDING_PAGE);
        setRedirects({ landingPageCaseFiles: true });
        handleOpenMenu();
        break;
      case 'restartTraining':
        handleOpenMenu();
        setRedirects({ restartTraining: true });
        break;
      case 'link':
        window.open(item.link, '_blank', 'noopener');
        break;
      default:
        break;
    }
  };

  const onResume = () => {
    setMenuState(menuGuideState.MENU);
    handleOpenMenu();
  };

  return (
    <Styled.Wrapper open={open}>
      <Styled.Section animate={menuState === menuGuideState.MENU ? 'visible' : 'hidden'}>
        <Styled.MenuButton onClick={handleOpenMenu}>
          <CloseIcon />
        </Styled.MenuButton>
        <Styled.Title>{t('menu.title')}</Styled.Title>
        <GameMenu gameVersion={gameVersion} handleClick={handleClick} />
      </Styled.Section>

      <Styled.SectionGuide animate={menuState !== menuGuideState.MENU ? 'visible' : 'hidden'}>
        {menuState === menuGuideState.GAME_GUIDE_ONE && (
          <GameGuideOne onResume={onResume} handleMenuChange={() => setMenuState(menuGuideState.MENU)} />
        )}
        {menuState === menuGuideState.GAME_GUIDE_TWO && !isSocial && (
          <GameGuideTwo onResume={onResume} handleMenuChange={() => setMenuState(menuGuideState.MENU)} />
        )}
        {menuState === menuGuideState.GAME_GUIDE_TWO && isSocial && (
          <GameGuideTwoIsSocial onResume={onResume} handleMenuChange={() => setMenuState(menuGuideState.MENU)} />
        )}
        {menuState === menuGuideState.GAME_GUIDE_THREE && (
          <GameGuideThree onResume={onResume} handleMenuChange={() => setMenuState(menuGuideState.MENU)} />
        )}
      </Styled.SectionGuide>
    </Styled.Wrapper>
  );
};
