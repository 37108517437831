import styled from 'styled-components';
import { motion } from 'framer-motion';

import { box, px, vh } from 'styles/mixins';
import { colors } from 'styles/vars';
import { fontChannel4Horseferry } from 'styles/typography';

export const Wrapper = styled.div`
  ${box('100%', '100%')}
  background-color: black;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  > .background {
    ${box(px(363), px(298))}
    position: absolute;
    background-image: url(${require('assets/images/ui/share/share_background.png')});
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
  }

  .title {
    ${fontChannel4Horseferry(27, 700, 0.84)}
    text-transform: uppercase;
    margin: ${px(30)} ${px(22)};
  }

  .close {
    height: ${px(34)};
    transform: translate3d(-50%, 0, 0);
    top: ${px(23)};
    right: ${px(10)};
    color: ${colors.blackPearl};
    z-index: 1;
    position: absolute;

    .icon {
      ${box(px(20))}
      margin: 0 ${px(6)} 0 0;
    }
  }

  .buttonsContainer {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 5.5rem;
    gap: 1rem;
    padding: 0.5rem;
    margin: ${px(28)} auto;
    box-sizing: border-box;
    background-image: url(${require('assets/images/ui/share/share_background.png')});
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
