import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import gsap from 'gsap';
import SplitText from 'gsap/dist/SplitText';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import * as Styled from './GameGuideTwoIsSocial.styles';
import BackIcon from 'components/Icons/BackIcon';
import CtaYellow from 'components/CtaYellow';

interface GameGuideTwoIsSocialProps {
  handleMenuChange: () => void;
  onResume?: () => void;
}

const slideImages = [
  require('assets/images/game-2-menu-social-slide-1.png'),
  require('assets/images/game-2-menu-social-slide-2.png'),
  require('assets/images/game-2-menu-social-slide-3.png'),
];

export const GameGuideTwoIsSocial: React.FC<GameGuideTwoIsSocialProps> = ({ handleMenuChange, onResume }) => {
  const { t } = useTranslation();
  const [slide, setSlide] = useState(0);

  const slide1Ref = useRef<HTMLDivElement>(null);
  const slide2Ref = useRef<HTMLDivElement>(null);
  const slide3Ref = useRef<HTMLDivElement>(null);

  const handleCtaClick = useCallback(() => {
    setSlide(s => {
      if (s < 2) {
        return Math.min(2, s + 1);
      } else {
        onResume();
        return s;
      }
    });
  }, [onResume]);

  useEffect(() => {
    const timeline = gsap.timeline();

    const slide1 = slide1Ref.current;
    const slide1Img = slide1.querySelector('img');
    const slide1Text = slide1.querySelector('.description');
    const slide1TextSplit = new SplitText(slide1Text, { type: 'words,chars' });
    const slide2 = slide2Ref.current;
    const slide2Img = slide2.querySelector('img');
    const slide2Text = slide2.querySelector('.description');
    const slide2TextSplit = new SplitText(slide2Text, { type: 'words,chars' });
    const slide3 = slide3Ref.current;
    const slide3Img = slide3.querySelector('img');
    const slide3Text = slide3.querySelector('.description');
    const slide3TextSplit = new SplitText(slide3Text, { type: 'words,chars' });

    if (slide === 0) {
      timeline
        .set(slide2, { autoAlpha: 0 }, 0)
        .set(slide3, { autoAlpha: 0 }, 0)
        .to(slide1, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide1Img, { duration: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide1TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    } else {
      timeline.to(slide1, { duration: 0.4, autoAlpha: 0 }, 0.4);
    }

    if (slide === 1) {
      timeline
        .set(slide1, { autoAlpha: 0 }, 0)
        .set(slide3, { autoAlpha: 0 }, 0)
        .to(slide2, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide2Img, { duration: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide2TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    } else {
      timeline.to(slide2, { duration: 0.4, autoAlpha: 0 }, 0.4);
    }

    if (slide === 2) {
      timeline
        .set(slide1, { autoAlpha: 0 }, 0)
        .set(slide2, { autoAlpha: 0 }, 0)
        .to(slide3, { duration: 0.4, autoAlpha: 1 }, 0.4)
        .to(slide3Img, { duration: 1, ease: 'expo.out' }, 0.4)
        .fromTo(slide3TextSplit.chars, { opacity: 0 }, { duration: 0.01, stagger: 0.02, opacity: 1 }, 0.4);
    } else {
      timeline.to(slide3, { duration: 0.4, autoAlpha: 0 }, 0.4);
    }

    return () => {
      timeline.kill();
      slide1TextSplit.revert();
      slide2TextSplit.revert();
      slide3TextSplit.revert();
    };
  }, [slide]);

  return (
    <>
      <Styled.MenuButton onClick={handleMenuChange}>
        <BackIcon />
      </Styled.MenuButton>
      <Styled.Title>{t('gameGuideTwo.title')}</Styled.Title>
      <Styled.Content>
        <div className="slides">
          <div className="slide" ref={slide1Ref}>
            <img alt="" src={slideImages[0]} />
            <p className="description">{parse(t('gameGuideTwo.social.slide1.description'))}</p>
          </div>

          <div className="slide" ref={slide2Ref}>
            <img alt="" src={slideImages[1]} />
            <p className="description">{parse(t('gameGuideTwo.social.slide2.description'))}</p>
          </div>

          <div className="slide" ref={slide3Ref}>
            <img alt="" src={slideImages[2]} />
            <p className="description">{parse(t('gameGuideTwo.social.slide3.description'))}</p>
          </div>
        </div>
        <div className="button-wrapper">
          <CtaYellow label={slide === 2 ? t('gameGuideTwo.resume') : t('gameGuideTwo.next')} onClick={handleCtaClick} />
        </div>

        <div className="footer">
          <div className="progress">
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 0 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 1 })} />
            </div>
            <div className="bar">
              <div className={classNames('fill', { active: slide >= 2 })} />
            </div>
          </div>
          <div className="page">{Math.max(1, slide + 1)}/3</div>
        </div>
      </Styled.Content>
    </>
  );
};

export default memo(GameGuideTwoIsSocial);
