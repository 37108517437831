const preloadImages = (array: Array<string>, waitForOtherResources?: boolean, timeout?: number) => {
  const imgs = array.slice(0);
  const t = timeout || 15 * 1000;
  const list = [];

  let loaded = false;
  let timer: NodeJS.Timeout;

  const loadNow = () => {
    if (!loaded) {
      loaded = true;
      for (let i = 0; i < imgs.length; i++) {
        const img = new Image();
        img.onload = img.onerror = img.onabort = () => {
          const index = list.indexOf(img);
          if (index !== -1) {
            // remove image from the array once it's loaded
            // for memory consumption reasons
            list.splice(index, 1);
          }
        };
        list.push(img);
        img.src = imgs[i];
      }
    }
  };

  if (!waitForOtherResources || document.readyState === 'complete') {
    loadNow();
  } else {
    window.addEventListener('load', () => {
      clearTimeout(timer);
      loadNow();
    });
    // in case window.addEventListener doesn't get called (sometimes some resource gets stuck)
    // then preload the images anyway after some timeout time
    timer = setTimeout(loadNow, t);
  }
};

export default preloadImages;
