import * as React from 'react';

import SVGIconBase from './SVGIconBase';
import { colors } from 'styles/vars';

const RejectIcon = () => {
  const color = colors.callRed;
  const width = 20;
  const height = 20;

  return (
    <SVGIconBase width={width} height={height}>
      <path d="M2.92896 3.14209L17.0711 17.2842" stroke={color} strokeWidth="3" />
      <path d="M2.92896 17.1421L17.0711 2.99995" stroke={color} strokeWidth="3" />
    </SVGIconBase>
  );
};

RejectIcon.displayName = 'RejectIcon';

export default RejectIcon;
