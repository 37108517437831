import React, { ReactElement } from 'react';
import UncheckedIcon from 'components/Icons/UncheckedIcon';
import CheckedIcon from 'components/Icons/CheckedIcon';
import LockedIcon from 'components/Icons/LockedIcon';
import { DashboardButton } from './DashboardButton';
import { colors } from 'styles/vars';

interface DashboardButtonWrapperProps {
  phase: number;
  label?: string;
  description?: string;
  subtitle?: string;
  img?: string;
}

const getIconLabel = (phase: number): string => ['Locked', 'Open', 'Solved'][phase];

const getBgColor = (phase: number): string => [colors.silver, colors.vistaWhite, colors.tide][phase];

const getIcon = (phase: number): ReactElement => {
  switch (phase) {
    case 1:
      return <UncheckedIcon />;
    case 2:
      return <CheckedIcon />;
    default:
      return <LockedIcon />;
  }
};

export const DashboardButtonWrapper: React.FC<DashboardButtonWrapperProps> = ({
  phase,
  label,
  description,
  subtitle,
  img,
}) => (
  <DashboardButton
    phase={phase}
    label={label}
    subtitle={subtitle}
    description={description}
    img={img}
    icon={getIcon(phase)}
    iconLabel={getIconLabel(phase)}
    iconLabelColor={phase === 1 || phase === 2 ? colors.blackPearl : colors.rollingStone}
    bgColor={getBgColor(phase)}
  />
);
