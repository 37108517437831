import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { absoluteCenter, autoAlpha, box, flexCenter, px, rect, uvBase, vh, fullHeight } from 'styles/mixins';
import {
  fontChannel4Horseferry,
  fontChannel4Chadwick,
  typography14Caps,
  typography18Caps,
  typography24Caps,
} from 'styles/typography';
import { mediaHeightMediumMobile, mediaHeightLargeMobile, mediaOnlySafari } from 'styles/responsive';

export const Wrapper = styled(motion.section)`
  ${fullHeight};
  position: relative;
  overflow: hidden;
  width: 100%;

  > .landing {
    ${rect('absolute', 0, 0, '100%', '100%')}
    ${autoAlpha(0)}
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .background {
      ${rect('absolute', 0, 0, '100%', '100%')}

      .bg1 {
        ${rect('absolute', 0, 0, '100%', '100%')}
        object-fit: cover;
        transform-origin: 50% 100%;
      }

      .bg2 {
        ${rect('absolute', 0, 0, '100%', '100%')}
        background: url(${require('assets/images/game-1-intro-landing-bg-top.png')});
        background-size: contain;
        background-repeat: no-repeat;
        transform-origin: 50% 0%;
      }
    }

    .top {
      position: relative;
      padding: ${px(60)} ${px(16)};

      .title {
        ${fontChannel4Horseferry(82, 700, 0.84)}
        text-transform: uppercase;
        letter-spacing: -0.06em;
        margin: 0 0 ${px(18)};

        &,
        div {
          display: flex;
          justify-content: space-between;

          span {
            ${typography18Caps}
            margin: ${px(10)} ${px(5)} 0;
          }
        }
      }

      .line {
        ${box('100%', '2px')}
        background: ${colors.white};
        margin: 0 0 ${px(11)};
      }

      .subtitle {
        ${typography24Caps}
      }
    }

    .bottom {
      position: relative;
      text-align: center;
      padding: 0 ${px(16)} ${px(30)};

      .description {
        ${typography18Caps}
        margin: 0 0 ${px(20)};
      }

      .cta {
        width: 100%;
      }
    }
  }

  > .tutorial {
    ${rect('absolute', 0, 0, '100%', '100%')}
    ${flexCenter('column')}
    ${autoAlpha(0)}
    background: url(${require('assets/images/game-1-intro-tutorial-bg.jpg')});
    background-size: cover;
    color: ${colors.black};

    .bgtext {
      ${fontChannel4Horseferry(55, 700, 0.9)}
      text-transform: uppercase;
      letter-spacing: -0.06em;
      word-break: break-all;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      left: 0;
      top: 0;
      padding: ${px(70)} ${px(10)};
      ${mediaHeightMediumMobile(`
        font-size: ${px(60)};
        padding: ${px(85)} ${px(10)};
      `)}
      ${mediaHeightLargeMobile(`font-size: ${px(66)}`)}

      br {
        display: none;
      }

      div {
        &:last-child {
          align-self: flex-end;
        }
      }
    }

    .slides {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      width: 100%;

      .slide {
        ${flexCenter('column')}
        width: 100%;
        flex: none;

        .media {
          position: relative;
          flex: 1 1 auto;
          width: 100%;

          .fingerprint {
            ${box(px(300), px(270))}
            position: absolute;
            bottom: ${px(10)};
            left: 50%;
            transform: translateX(-50%);
            background: url(${require('assets/images/game3/fingerprint.png')}) center no-repeat;
            background-size: contain;

            ${mediaHeightMediumMobile(box(px(450), px(405)))}

            .scanner {
              background: url(${require('assets/images/game3/scanner.png')}) center no-repeat;
              ${box(px(230), px(103.5))}
              position: absolute;
              top: ${px(10)};
              left: 50%;
              transform: translateX(-50%);
              background-size: contain;

              ${mediaHeightMediumMobile(box(px(345), px(155.25)))}
            }
          }

          .tvs {
            ${box(px(266.66), px(200))}
            font-size: 0.666rem;
            position: absolute;
            bottom: ${px(10)};
            left: 50%;
            transform: translateX(-50%);

            ${mediaHeightMediumMobile(box(px(400), px(300)))}
            ${mediaHeightMediumMobile('font-size: 1rem')}

            .tv1 {
              ${box('24em', '24em')}
              position: absolute;
              top: 3em;
              left: 2em;
              background: url(${require('assets/images/game3/tv-left.png')}) center no-repeat;
              background-size: contain;
              transform: translateX(8rem);
            }

            .tv2 {
              ${box('16.5em', '15em')}
              position: absolute;
              right: 2em;
              background: url(${require('assets/images/game3/tv-up.png')}) center no-repeat;
              background-size: contain;
            }

            .tv3 {
              ${box('16.75em', '17em')}
              position: absolute;
              right: 2.5em;
              bottom: -0.25rem;
              background: url(${require('assets/images/game3/tv-bottom.png')}) center no-repeat;
              background-size: contain;
              transform: translateX(10rem);
            }
          }
        }

        .description {
          ${typography18Caps}
          flex: none;
          width: 100%;
          padding: 0 ${px(34)};
          position: relative;
          text-align: center;
        }
      }
    }

    .footer {
      position: relative;
      width: 100%;
      flex: none;
      padding: 0 ${px(16)} ${px(30)};

      .buttons {
        position: relative;
        height: ${px(100)};
        width: 100%auto;

        .start,
        .next {
          ${absoluteCenter()}
          ${autoAlpha(0, 0.16)}

          &.show {
            ${autoAlpha(1)}
          }
        }

        .start {
          width: 100%;
        }
      }

      .progress {
        display: flex;
        justify-content: space-between;
        margin: 0 0 ${px(6)};

        .bar {
          width: ${px(167)};
          height: 1px;
          background: ${rgba(colors.black, 0.3)};

          .fill {
            ${box('100%')}
            background: ${colors.black};
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 1s ease;

            &.active {
              transform: scale(1);
            }
          }
        }
      }

      .page {
        ${typography14Caps}
      }
    }
  }

  > .uv {
    ${uvBase()}

    .landing {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-uv-landing.png')});
      background-position: center center;
      background-size: cover;
    }

    .tutorial1 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game3/uv-1.png')});
      background-position: bottom;
      background-size: cover;
      mix-blend-mode: color-dodge;

      .tvs {
        ${box(px(266.66), px(200))}
        font-size: 0.666rem;
        position: absolute;
        bottom: ${px(220)};
        left: 50%;
        transform: translateX(-50%);
        mix-blend-mode: screen;

        ${mediaHeightMediumMobile(box(px(400), px(300)))}
        ${mediaHeightMediumMobile('font-size: 1rem')}

        .tv1 {
          ${box('16em', '7.10em')}
          position: absolute;
          top: 3em;
          left: 6.9em;
          background: url(${require('assets/images/game3/tv-left-uv.png')}) top no-repeat;
          background-size: contain;
        }

        .tv2 {
          ${box('16.5em', '7.385em')}
          position: absolute;
          left: 22.8em;
          top: 0.2em;
          background: url(${require('assets/images/game3/tv-up-uv.png')}) top no-repeat;
          background-size: contain;
        }
      }
    }

    .tutorial2 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game3/uv-2.png')});
      background-position: bottom;
      background-size: cover;
      mix-blend-mode: color-dodge;

      .fingerprint {
        ${box(px(300), px(270))}
        position: absolute;
        bottom: ${px(241)};
        left: 50%;
        transform: translateX(-50%);
        background: url(${require('assets/images/game3/fingerprint-uv.png')}) center no-repeat;
        background-size: contain;
        mix-blend-mode: screen;

        ${mediaHeightMediumMobile(box(px(450), px(405)))}
      }
    }

    .uv-item {
      ${rect('absolute', 'auto', 0, '100%', '100%')}
      display: flex;
      right: 0;
      justify-content: center;
      align-items: flex-end;
      bottom: ${px(126)};
      flex: 1;

      .text-1 {
        ${box('100%', 'calc(100vw / 4.10196078)')}
        width: 100%;
        margin-bottom: ${px(10)};
        background: url(${require('assets/images/game3/uv-text-1.png')}) center bottom no-repeat;
        background-size: contain;
        ${mediaOnlySafari(`
          margin-left: ${px(-3)};
        `)};
      }

      .text-2 {
        ${box('100%', 'calc(100vw / 4.37656904)')}
        width: 100%;
        margin-bottom: ${px(22)};
        background: url(${require('assets/images/game3/uv-text-2.png')}) center bottom no-repeat;
        background-size: contain;
        ${mediaOnlySafari(`
          margin-left: ${px(-5)};
        `)};
      }
    }
  }
`;
