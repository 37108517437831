import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageProps } from 'types/page';
import { CallState } from 'constants/enum';
import Wrapper from './styled/Wrapper';
import Section from './styled/Section';
import { UnlockScreen } from 'components/UnlockScreen';

interface UnlockPageProps extends PageProps {}

export const UnlockPage: React.FC<UnlockPageProps> = ({ visible, setPageCallback }) => {
  const [callState, setCallState] = useState(CallState.CALLING);
  const [answered, setAnswered] = useState(false);
  const { t } = useTranslation();

  return (
    <Wrapper animate={visible ? 'visible' : 'hidden'}>
      <Section animate={callState === CallState.CALLING ? 'visible' : 'hidden'}>
        <UnlockScreen password="1234" inputCallback={correct => {}} />
      </Section>
    </Wrapper>
  );
};

export default UnlockPage;
