import { useState, useEffect } from 'react';

function useWindowVisible() {
  const [visible, setVisible] = useState(!document.hidden);
  useEffect(() => {
    const checkVisibility = e => {
      if (e && e.type === 'blur') {
        setVisible(false);
        return;
      }
      setVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', checkVisibility);
    window.addEventListener('focus', checkVisibility);
    window.addEventListener('blur', checkVisibility);
    return () => {
      document.removeEventListener('visibilitychange', checkVisibility);
      window.removeEventListener('focus', checkVisibility);
      window.removeEventListener('blur', checkVisibility);
    };
  }, []);

  return visible;
}

export default useWindowVisible;
