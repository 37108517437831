import styled from 'styled-components';

import { colors } from 'styles/vars';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: ${colors.white};
`;
