import styled from 'styled-components';

import { colors } from 'styles/vars';

export default styled.div`
  display: block;
  width: 100%;
  background: ${colors.rollingStone};
  color: ${colors.white};
`;
