import React, { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomWiggle from 'gsap/dist/CustomWiggle';
import classNames from 'classnames';
import parse from 'html-react-parser';
import gsap from 'gsap';

import AdobeAnalytics from 'utils/adobeAnalytics';

import * as Styled from './GameOnePhoneLock.styled';

import { sfx } from 'constants/assets';

import AudioManager from 'services/audiomanager.service';

import { PhoneState } from 'components/GameOnePhone/GameOnePhone';
import { GameOneDetectiveRef } from 'components/GameOneDetective/GameOneDetective';

import SvgLock from 'svgs/Lock.svg';
import SvgBullet from 'svgs/Bullet.svg';
import SvgDelete from 'svgs/Delete.svg';
import usePreloadedImages from 'hooks/usePreloadedImages';

interface Props {
  uvLight: boolean;
  setState: (s: PhoneState) => void;
  gameOneDetectiveRef: MutableRefObject<GameOneDetectiveRef>;
}

const keys = [
  { n: '1', l: '&nbsp;', c: 'digit' },
  { n: '2', l: 'ABC', c: 'digit' },
  { n: '3', l: 'DEF', c: 'digit' },
  { n: '4', l: 'GHI', c: 'digit' },
  { n: '5', l: 'JKL', c: 'digit' },
  { n: '6', l: 'MNO', c: 'digit' },
  { n: '7', l: 'PQRS', c: 'digit' },
  { n: '8', l: 'TUV', c: 'digit' },
  { n: '9', l: 'WXYZ', c: 'digit' },
  { n: '&nbsp;', l: '&nbsp;', c: 'empty' },
  { n: '0', l: '&nbsp;', c: 'digit' },
  { n: '&nbsp;', l: '&nbsp;', c: 'delete' },
];

if (typeof window !== 'undefined') {
  CustomWiggle.create('wrongpassword', { wiggles: 15 });
}

const imagesToPreload = [
  require('assets/images/game-1-phone-uv-lock.png'),
  require('assets/images/game-1-phone-uv-lock-1.png'),
  require('assets/images/game-1-phone-uv-lock-2.png'),
  require('assets/images/game-1-phone-uv-lock-3.png'),
  require('assets/images/game-1-phone-uv-lock-4.png'),
  require('assets/images/game-1-phone-uv-lock-5.png'),
];

export const GameOnePhoneLock: React.FC<Props> = ({ uvLight, setState, gameOneDetectiveRef }) => {
  const { t } = useTranslation();

  const uvLockRef = useRef<HTMLDivElement>(null);
  const numbersRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<GSAPTimeline>(null);
  const wrongAttemptsRef = useRef(0);

  const [numbers, setNumbers] = useState<number[]>([]);
  const [pinIsCorrect, setPinIsCorrect] = useState(false);

  const handleDigitClick = useCallback(
    (e, number: string) => {
      e.preventDefault();
      if (!pinIsCorrect) {
        AudioManager.instance.playSound(sfx.lock_screen_tap);
        setNumbers(n => [...n, +number]);
      }
    },
    [pinIsCorrect]
  );

  const handleDeleteClick = useCallback(
    e => {
      e.preventDefault();
      if (!pinIsCorrect) {
        setNumbers(n => n.slice(0, -1));
      }
    },
    [pinIsCorrect]
  );

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    let to;
    const combination = t('gameOne.phone.lock.combination');
    if (numbers.length === combination.length) {
      if (numbers.join('') === t('gameOne.phone.lock.combination')) {
        setPinIsCorrect(true);
        to = setTimeout(() => {
          setState(PhoneState.CLOCK);
          AudioManager.instance.playSound(sfx.correct_password);
        }, 500);
      } else {
        wrongAttemptsRef.current++;
        setNumbers([]);
        timelineRef.current?.kill();
        timelineRef.current = gsap
          .timeline()
          .to(numbersRef.current, { duration: 1, xPercent: 15, ease: 'wrongpassword' })
          .add(() => {
            if (wrongAttemptsRef.current === 3) {
              gameOneDetectiveRef.current?.addMessage({
                text: t('gameOne.detective.passwordCombination'),
              });
            }
          });
      }
    }
    return () => {
      clearTimeout(to);
    };
  }, [gameOneDetectiveRef, numbers, setState, t]);

  useEffect(() => {
    let timeline;
    if (uvLight) {
      timeline = gsap
        .timeline()
        .to(uvLockRef.current.children, { duration: 0.8, stagger: 0.15, opacity: 0 }, 2)
        .to(uvLockRef.current.children, { duration: 0.8, stagger: 0.3, opacity: 1 }, 3);
    }
    return () => {
      timeline?.kill();
    };
  }, [uvLight]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game_1_lockscreen', 'pageview', 'game1');
    return () => {
      timelineRef.current?.kill();
    };
  }, []);

  return (
    <Styled.Wrapper
      transition={{ duration: 0.6, ease: 'easeOut' }}
      initial={{ scale: 1.2 }}
      animate={{ scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
    >
      <SvgLock className="lock" />
      <p className="passcode">{parse(t('gameOne.phone.lock.passcode'))}</p>
      <div className="numbers" ref={numbersRef}>
        {numbers.map((_, i) => (
          <SvgBullet key={i} className="bullet" />
        ))}
      </div>
      <div className="digits">
        {keys.map((k, i) => (
          <div
            key={i}
            className={classNames('key', k.c)}
            onClick={k.c === 'digit' ? e => handleDigitClick(e, k.n) : handleDeleteClick}
            onTouchStart={() => {}}
          >
            {k.c === 'delete' ? (
              <SvgDelete className="icon" />
            ) : (
              <>
                <div className="number">{parse(k.n)}</div>
                <div className="letter">{parse(k.l)}</div>
              </>
            )}
          </div>
        ))}
      </div>
      {uvLight ? (
        <div className="uv">
          <div className="lock" ref={uvLockRef}>
            <div className="f1" />
            <div className="f2" />
            <div className="f3" />
            <div className="f4" />
            <div className="f5" />
          </div>
        </div>
      ) : null}
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhoneLock);
