import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { autoAlpha, box, px, rect } from 'styles/mixins';
import { fontChannel4Horseferry, typography24Caps, typography18Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', '100%')}
  ${autoAlpha(0)}
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .background {
    ${rect('absolute', 0, 0, '100%', '100%')}

    .bg1 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      object-fit: cover;
      transform-origin: 50% 100%;
    }

    .bg2 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-landing-bg-top.png')});
      background-size: contain;
      background-repeat: no-repeat;
      transform-origin: 50% 0%;
    }
  }

  > .top {
    position: relative;
    padding: ${px(60)} ${px(16)};

    .title {
      ${fontChannel4Horseferry(82, 700, 0.84)}
      text-transform: uppercase;
      letter-spacing: -0.06em;
      margin: 0 0 ${px(18)};

      &,
      div {
        display: flex;
        justify-content: space-between;

        span {
          ${typography18Caps}
          margin: ${px(10)} ${px(5)} 0;
        }
      }
    }

    .line {
      ${box('100%', '2px')}
      background: ${colors.white};
      margin: 0 0 ${px(11)};
    }

    .subtitle {
      ${typography24Caps}
    }
  }

  > .bottom {
    position: relative;
    text-align: center;
    padding: 0 ${px(16)} ${px(30)};

    .description {
      ${typography18Caps}
      margin: 0 0 ${px(20)};
    }

    .cta {
      width: 100%;
    }
  }
`;
