import styled from 'styled-components';
import { box, px } from 'styles/mixins';
import { fontChannel4Chadwick, typography16Text } from 'styles/typography';

import { colors } from 'styles/vars';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: ${colors.white};
`;

export const YoutubeMessage = styled.div`
  > .thumb {
    ${box(px(242), px(147))}
    object-fit: cover;
    margin: 0 0 ${px(8)};
  }

  > .dotcom {
    ${fontChannel4Chadwick(10, 500, 1.1)}
    opacity: 0.6;
  }

  > .label {
    ${typography16Text}
  }
`;
