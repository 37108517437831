import React, { memo, useState, useEffect, useCallback, useRef, useContext } from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './GameTwo.styled';

import GameTwoIntro from 'components/GameTwoIntro';
import { GameTwoMystery } from 'components/GameTwoMystery/GameTwoMystery';
import GameDetectiveVideo, { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';

import AdobeAnalytics from 'utils/adobeAnalytics';
import { RedirectsContext } from 'context/redirects';
import { isSocialGestureMinimizedBrowser } from 'utils/platform';
import { Progress } from 'context/progress';
import { SettingsContext } from 'context/settings';

interface Props {
  uvLight: boolean;
  onExit: () => void;
  onContinueTraining: () => void;
  showLanding: () => void;
  onComplete: (accuracy: number, time: string) => void;
  progress: Progress;
  showBadge: () => void;
}

enum State {
  INTRO,
  GAME,
}

export const GameTwo: React.FC<Props> = ({
  uvLight,
  onComplete,
  onContinueTraining,
  showLanding,
  onExit,
  progress,
  showBadge,
}) => {
  const detectiveVideoRef = useRef<GameDetectiveVideoRef>(null);
  const [state, setState] = useState(State.INTRO);
  const { setSettings } = useContext(SettingsContext);
  const { redirects } = useContext(RedirectsContext);

  useEffect(() => {
    if (redirects.restartTraining) {
      setState(State.INTRO);
    }

    if (redirects.gameState || redirects.tryAgain) {
      setState(State.GAME);
    }
  }, [redirects]);

  useEffect(() => {
    if (state === State.GAME) AdobeAnalytics.trackEvent('game_2_shredded', 'pageview', 'game2');
    setSettings({ showUV: state === State.INTRO });
  }, [setSettings, state]);

  const handleIntroComplete = useCallback(() => setState(State.GAME), []);

  return (
    <Styled.Wrapper>
      <AnimatePresence exitBeforeEnter>
        {state === State.INTRO ? (
          <GameTwoIntro
            key="intro"
            uvLight={uvLight}
            onComplete={handleIntroComplete}
            onExit={onExit}
            detectiveVideoRef={detectiveVideoRef}
          />
        ) : (
          <GameTwoMystery
            key="mystery"
            showLanding={showLanding}
            onContinueTraining={onContinueTraining}
            onComplete={onComplete}
            detectiveVideoRef={detectiveVideoRef}
            type={isSocialGestureMinimizedBrowser() ? 'social' : 'browser'}
            progress={progress}
            showBadge={showBadge}
            uvLight={uvLight}
          />
        )}
      </AnimatePresence>
      <GameDetectiveVideo
        ref={detectiveVideoRef}
        src={require('assets/videos/game2-intro.mp4')}
        poster={require('assets/images/teaser2-slice-2.jpg')}
        className="video"
      />
    </Styled.Wrapper>
  );
};

export default memo(GameTwo);
