import styled from 'styled-components';
import { fontChannel4Chadwick } from 'styles/typography';
import { px, box } from 'styles/mixins';
import { rgba } from 'polished';
import { colors } from 'styles/vars';

export const MenuButton = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
`;

export const Title = styled.h2`
  ${fontChannel4Chadwick(14, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const Content = styled.div`
  .slides {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 auto ${px(25)};

    .slide {
      width: 100%;
      flex: none;
      text-align: center;
    }
  }

  img {
    position: absolute;
    bottom: ${px(93)};
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: ${px(352)};
  }

  .description {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    text-align: center;
    width: 54%;

    position: absolute;
    bottom: ${px(0)};
    left: 50%;
    transform: translateX(-50%);
  }

  .button-wrapper {
    margin-bottom: ${px(35)};

    button {
      margin: 0 auto;
    }
  }

  .footer {
    position: relative;
    width: 100vw;
    flex: none;
    padding: 0 ${px(16)} ${px(15)};

    .progress {
      display: flex;
      justify-content: space-between;
      margin: 0 0 ${px(6)};

      .bar {
        width: ${px(112)};
        height: 1px;
        background: ${rgba(colors.white, 0.3)};

        .fill {
          ${box('100%')}
          background: ${colors.white};
          transform: scaleX(0);
          transform-origin: 0 0;
          transition: transform 1s ease;

          &.active {
            transform: scale(1);
          }
        }
      }
    }
  }
`;
