import styled from 'styled-components';
import { motion } from 'framer-motion';
import { vh, fullHeight } from 'styles/mixins';
import { colors } from 'styles/vars';
import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';

import bgImage from 'assets/images/background.png';
import videoImage from 'assets/images/whats-murder-island-image-video.png';

export const Wrapper = styled(motion.div)`
  width: 100%;
  min-height: ${vh(100)};
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: ${colors.carrara};

    .background-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url(${bgImage});
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .video-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      video {
        ${fullHeight};
        width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
        background-color: ${colors.black};
      }

      .background-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(${videoImage});
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 4rem;
      }
    }

    .title-wrapper {
      z-index: 1;
      margin: 10rem auto 0;
      padding-bottom: 32rem;
      width: 96%;

      h1 {
        padding-top: 6rem;
        ${fontChannel4Horseferry(75, 700, 0.8)};
        text-align: left;
        letter-spacing: -0.06em;

        span {
          display: block;
          text-align: right;
        }
      }
    }

    .player-wrapper {
      z-index: 2;
      position: absolute;
      bottom: 17%;
      left: 50%;
      transform: translateX(-50%);

      p {
        ${fontChannel4Chadwick(18, 500, 1.1)};
        letter-spacing: 0.1rem;
        margin-top: 2rem;
      }
    }

    .first-paragraph {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      letter-spacing: 0.075rem;
      color: ${colors.blackChocolate};
      margin: 4rem 0 4.2rem;
      width: 88%;
    }

    .second-paragraph {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      letter-spacing: 0.075rem;
      color: ${colors.blackChocolate};
      margin-bottom: 4.6rem;
      width: 88%;
    }

    .back-button-wrapper {
      margin-bottom: 2.3rem;
    }

    .watch-button-wrapper {
      margin-bottom: 2.5rem;

      button {
        ${fontChannel4Chadwick(18, 700, 1.1)};
        letter-spacing: 0.1rem;
        color: ${colors.blackBeluga};
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;

        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
