import React, { memo, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';
import AdobeAnalytics from 'utils/adobeAnalytics';
import * as Styled from './GameOnePhoneNavbar.styled';

import { PhoneState } from 'components/GameOnePhone/GameOnePhone';

import SvgMessage from 'svgs/Message.svg';
import SvgGallery from 'svgs/Gallery.svg';
import SvgVoicemail from 'svgs/Voicemail.svg';

interface Props {
  visible: boolean;
  uvLight: boolean;
  state: PhoneState;
  setState: (s: PhoneState) => void;
}

export const GameOnePhoneNavbar: React.FC<Props> = ({ visible, uvLight, state, setState }) => {
  const { t } = useTranslation();

  const handleMessageClick = useCallback(() => {
    AdobeAnalytics.trackEvent('game_1_chat', 'pageview', 'game1');
    setState(PhoneState.MESSAGE);
  }, [setState]);
  const handleGalleryClick = useCallback(() => {
    AdobeAnalytics.trackEvent('game_1_gallery', 'pageview', 'game1');
    setState(PhoneState.GALLERY);
  }, [setState]);
  const handleVoicemailClick = useCallback(() => {
    AdobeAnalytics.trackEvent('game_1_voicemail', 'pageview', 'game1');
    setState(PhoneState.VOICEMAIL);
  }, [setState]);

  return (
    <AnimatePresence>
      {visible ? (
        <Styled.Wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classNames({ uv: uvLight })}
        >
          <div className={classNames('background', { show: state !== PhoneState.CLOCK })}></div>
          <div className={classNames('buttons', { dark: state !== PhoneState.CLOCK })}>
            <button
              className={classNames('button', { active: state === PhoneState.MESSAGE })}
              onClick={handleMessageClick}
              aria-label="Messages"
            >
              <div className="icon">
                <SvgMessage />
              </div>
              <div className="text">{parse(t('gameOne.phone.navbar.message'))}</div>
            </button>
            <button
              className={classNames('button', { active: state === PhoneState.GALLERY })}
              onClick={handleGalleryClick}
              aria-label="Gallery"
            >
              <div className="icon">
                <SvgGallery />
              </div>
              <div className="text">{parse(t('gameOne.phone.navbar.gallery'))}</div>
            </button>
            <button
              className={classNames('button', { active: state === PhoneState.VOICEMAIL })}
              onClick={handleVoicemailClick}
              aria-label="Voicemails"
            >
              <div className="icon">
                <SvgVoicemail />
              </div>
              <div className="text">{parse(t('gameOne.phone.navbar.voicemail'))}</div>
            </button>
          </div>
        </Styled.Wrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(GameOnePhoneNavbar);
