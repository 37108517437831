import React, { memo, forwardRef, useRef, useState, useEffect } from 'react';
import { MotionStyle } from 'framer-motion';
import classNames from 'classnames';

import * as Styled from './AsyncImage.styled';

import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface Props {
  className?: string;
  style?: MotionStyle;
  src: string;
  alt?: string;
  animated?: boolean;
  onLoad?: () => void;
}

const AsyncImage = forwardRef<HTMLDivElement, Props>(
  ({ className, style, src, animated = true, alt = '', onLoad = () => {}, ...props }, ref) => {
    const [imgSrc, setImgSrc] = useState<string>(null);
    const [loaded, setLoaded] = useState(false);

    const imgRef = useRef<HTMLImageElement>(null);

    const isOnScreen = useIntersectionObserver(imgRef);

    useEffect(() => {
      if (isOnScreen) setImgSrc(src);
    }, [src, isOnScreen]);

    useEffect(() => {
      const img = imgRef.current;
      const handleLoad = () => {
        img.removeEventListener('load', handleLoad);
        img.removeEventListener('loadedmetadata', handleLoad);
        setLoaded(true);
        onLoad();
      };
      if (!loaded && imgSrc) {
        const loaded = Boolean(img.complete);
        if (loaded) {
          setLoaded(true);
          onLoad();
        } else {
          img.addEventListener('load', handleLoad);
          img.addEventListener('loadedmetadata', handleLoad);
        }
      }
      return () => {
        img.removeEventListener('load', handleLoad);
        img.removeEventListener('loadedmetadata', handleLoad);
      };
    }, [imgSrc, loaded, onLoad]);

    return (
      <Styled.Wrapper className={classNames(className, { loaded: loaded })} style={style} ref={ref}>
        <img decoding="async" src={imgSrc} alt={alt} {...props} ref={imgRef} />
      </Styled.Wrapper>
    );
  }
);

export default memo(AsyncImage);
