import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import AdobeAnalytics from 'utils/adobeAnalytics';
import parse from 'html-react-parser';
import 'i18n/config';
import gsap from 'gsap';
import SplitText from 'gsap/dist/SplitText';
import { AnimatePresence, motion, useTransform, useViewportScroll } from 'framer-motion';

import { useTranslation } from 'react-i18next';
import { colors } from 'styles/vars';

import { PageProps } from 'types/page';

import Logo from 'svgs/Logo.svg';
import Badge from 'svgs/BadgeLight.svg';
import * as Styled from './Landing.styled';
import buttonBg from 'assets/images/ui/button.png';
import { Button } from 'components/Button/index';
import { CONFIG } from './config';
import { DashboardButtonWrapper } from 'components/DashboardButton/DashboardButtonWrapper';
import { DashboardButtonUv } from 'components/DashboardButtonUv/DashboardButtonUv';
import { PageState } from 'constants/enum';

import { HeaderContext } from 'context/header';
import { ProgressContext } from 'context/progress';
import { RedirectsContext } from 'context/redirects';

import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';
import usePreloadedImages from 'hooks/usePreloadedImages';

const imagesToPreload = [
  require('assets/images/ui/landing-pages/uvBackground.png'),
  require('assets/images/ui/landing-pages/gradientBG1.png'),
  require('assets/images/ui/landing-pages/gradientBG2.png'),
  require('assets/images/ui/landing-pages/gradientBG3.png'),
  require('assets/images/ui/landing-pages/wanted.png'),
  require('assets/images/ui/landing-pages/fingerPrint.png'),
];

interface LandingContainerProps extends PageProps {
  uvLight?: boolean;
}

const LandingContainer: React.FC<LandingContainerProps> = ({ visible, setPageCallback, uvLight }) => {
  const { t } = useTranslation();
  const { redirects } = useContext(RedirectsContext);
  const { setHeader } = useContext(HeaderContext);
  const { progress } = useContext(ProgressContext);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const wrapperInitRef = useRef<HTMLDivElement>(null);
  const wrapperVideoRef = useRef<HTMLDivElement>(null);
  const wrapperCasesRef = useRef<HTMLDivElement>(null);
  const casesFilesRef = useRef<Array<HTMLDivElement | null>>([]);

  const { scrollYProgress } = useViewportScroll();
  const initButtonOpacity = useTransform(scrollYProgress, [0, 0.1, 0.2], [1, 1, 0]);
  const initButtonY = useTransform(scrollYProgress, [0, 0.2], ['translateY(0px)', 'translateY(-40px)']);
  const initDescOpacity = useTransform(scrollYProgress, [0, 0.1, 0.2], [1, 1, 0]);
  const videoDescOpacity = useTransform(scrollYProgress, [0, 0.1, 0.2], [0, 0, 1]);
  const initDescY = useTransform(scrollYProgress, [0, 0.1, 0.2], [0, 0, -20]);
  const backgroundPositionY = useTransform(scrollYProgress, [0, 0.5], ['0px', '-40px']);

  // Commented out due to https://unit9ltd.atlassian.net/browse/MI4C-192
  // const initTitle1Y = useTransform(scrollYProgress, [0, 0.3], [0, -90]);
  // const initTitle2Y = useTransform(scrollYProgress, [0.05, 0.4], [0, -60]);

  const animatedState = useRef([false, false, false, false]);
  const [initIntersectionRef, initInView] = useInView();
  const [videoIntersectionRef, videoInView] = useInView({ triggerOnce: true, threshold: 0.25 });
  const [evidenceIntersectionRef, evidenceInView] = useInView({ threshold: 0.25 });
  const [casesIntersectionRef, casesInView] = useInView();

  const startYPosition = useRef<number>(0);

  const animateExpanded = useCallback(() => {
    const timeline = gsap.timeline();
    const caseFiles = casesFilesRef.current;

    let top = 0;
    let topMargin = 0;
    const bottomMargin = 200;
    const windowTopMargin = 16;

    caseFiles.forEach((item, index) => {
      const h = (item.firstChild as Element).clientHeight;
      if (index === 0) {
        topMargin = item.getBoundingClientRect().top;
      }

      timeline
        .set(item.firstChild, { y: 40 * index, rotateX: 0 }, 0)
        .to(item.firstChild, { y: top, duration: 1, ease: 'power1.inOut', delay: index * 0.2 }, 0);
      top += h - 64;
    });
    gsap.to(wrapperCasesRef.current, { height: `${topMargin + top + bottomMargin}px` });
  }, []);

  const handleTouchStart = useCallback(event => (startYPosition.current = event.touches[0].clientY), []);
  const handleTouchEnd = useCallback(
    event => {
      const endClientY = event.changedTouches[0].clientY;
      if (startYPosition.current > endClientY) {
        if (wrapperCasesRef.current.getBoundingClientRect().top < 15 && animatedState.current[3] && !isExpanded) {
          setIsExpanded(true);
          animateExpanded();
        }
      }
    },
    [animateExpanded, isExpanded]
  );

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    if (visible) {
      setHeader({ hasMask: true, isWhite: videoInView, isHidden: !evidenceInView && !casesInView });
    } else {
      animatedState.current.forEach((item, index) => {
        animatedState.current[index] = false;
      });
    }
  }, [visible, initInView, casesInView, videoInView, evidenceInView, setHeader]);

  useEffect(() => {
    if (visible) {
      AdobeAnalytics.trackEvent('dashboard', 'pageview');
    }
  }, [visible]);

  const killTimeline = timeline => {
    if (timeline) {
      const dur = timeline.duration();
      timeline.seek(dur);
      timeline.kill();
    }
  };

  useEffect(() => {
    if (isScrolled) scrollToCases();

    if (redirects.landingPageCaseFiles) {
      setTimeout(() => {
        scrollToCases();
      }, 1000);
    }
  }, [isScrolled, redirects]);

  const scrollToCases = () => {
    if (wrapperCasesRef.current) {
      setIsExpanded(false);
      const scrollPos = wrapperCasesRef.current.getBoundingClientRect().top + window.scrollY;

      gsap.to(window, {
        scrollTo: {
          y: scrollPos,
        },
        ease: 'sine.inOut',
        duration: 1.5,
      });
    }
  };

  useEffect(() => {
    let timeline;
    if (initInView && animatedState.current[0] === false) {
      timeline = gsap.timeline();
      const initial = wrapperInitRef.current;

      const initialTitle = initial.querySelector('.title');
      const initialTitleOne = initialTitle.children[0];
      const initialTitleTwo = initialTitle.children[1];
      const initialCase = initialTitle.lastChild;
      const initialBadge = initial.querySelector('.badge');
      const initialDescription = initial.querySelector('.description');
      const initialDescriptionSplit = new SplitText(initialDescription, { type: 'lines,chars' });
      const initialButton = initial.querySelector('.button-wrapper');

      timeline
        .set(initial, { scale: 5, y: 500 }, 0)
        .set(initialCase, { opacity: 0 }, 0)
        .set(initialBadge, { scale: 1.5, x: 50, y: -50 }, 0)
        .set(initialDescriptionSplit.chars, { opacity: 0 }, 0)
        .set(initialButton, { opacity: 0 }, 0)
        .set(initialTitleOne, { scale: 2.5 }, 0)
        .set(initialTitleTwo, { scale: 2 }, 0)
        .to(initialTitle, { y: 0, duration: 2 }, 0)
        .to(initialTitleTwo, { duration: 2.5, scale: 1, ease: 'power4.out' }, 0.5)
        .to(initialTitleOne, { duration: 2.25, scale: 1, ease: 'power4.out' }, 0.5)
        .to(initial, { duration: 1.5, scale: 1, y: 0, ease: 'power4.out' }, 0.5)
        .to(initialBadge, { duration: 2, scale: 1, x: 0, y: 0, ease: 'power4.out' }, 0.5)
        .to(initialCase, { duration: 0, opacity: 1, y: 0 }, 1)
        .to(initialCase, { duration: 0.85, scrambleText: initialCase.textContent }, 1)
        .to(initialDescriptionSplit.chars, { duration: 0, stagger: 0.04, opacity: 1 }, 0.5)
        .to(initialButton, { duration: 1, opacity: 1 }, 2);
      animatedState.current[0] = true;
    }
    return () => {
      killTimeline(timeline);
    };
  }, [initInView]);

  useEffect(() => {
    const timeline = gsap.timeline();
    if (videoInView) {
      const initial = wrapperInitRef.current;
      const descriptionBottomWrapper = initial.querySelector('.description-bottom-wrapper');
      const descriptionBottomWrapperContent = initial.querySelector('.description-bottom-wrapper > .content');
      const initialParagraphs = initial.querySelectorAll('.description-bottom-wrapper p');
      const initialParagraphsSplit = new SplitText(initialParagraphs, { type: 'lines,chars' });
      timeline
        .set(initialParagraphsSplit.chars, { opacity: 0 }, 0)
        .set(descriptionBottomWrapper, { opacity: 0 }, 0)
        .set(descriptionBottomWrapperContent, { opacity: 1 }, 0.25)
        .to(initialParagraphsSplit.chars, { duration: 0, stagger: 0.05, opacity: 1 }, 0.5)
        .add(() => {
          initialParagraphsSplit.revert();
        });

      animatedState.current[1] = true;
    } else {
      timeline.kill();
    }
    return () => {
      killTimeline(timeline);
    };
  }, [videoInView]);

  useEffect(() => {
    const timeline = gsap.timeline();
    if (evidenceInView && !animatedState.current[2]) {
      const video = wrapperVideoRef.current;
      const content = video.querySelector('.content');
      const videoParagraphs = video.querySelectorAll('.text-outer-wrapper > p');
      const videoParagraphsSplit = new SplitText(videoParagraphs, { type: 'lines,chars' });

      timeline
        .set(videoParagraphsSplit.chars, { opacity: 0 }, 0)
        .set(content, { opacity: 1 }, 0)
        .to(videoParagraphsSplit.chars, { duration: 0, stagger: 0.05, opacity: 1 }, 0)
        .add(() => {
          videoParagraphsSplit.revert();
        });
      animatedState.current[2] = true;
    } else {
      killTimeline(timeline);
    }
    return () => {
      killTimeline(timeline);
    };
  }, [evidenceInView]);

  useEffect(() => {
    const timeline = gsap.timeline();
    const wrapper = document.getElementById('__next');

    if (casesInView && animatedState.current[3] === false) {
      const timeline = gsap.timeline();
      const cases = wrapperCasesRef.current;
      const casesDescription = cases.querySelector('.description');
      const casesDescriptionSplit = new SplitText(casesDescription, { type: 'lines,chars' });
      const caseFiles = casesFilesRef.current;
      timeline
        .set(casesDescriptionSplit.chars, { opacity: 0 }, 0)
        .to(casesDescriptionSplit.chars, { duration: 0, stagger: 0.04, opacity: 1 }, 0);
      caseFiles.forEach((item, index) => {
        timeline
          .set(item.firstChild, { y: 100 + 100 * index, rotateX: -5 }, 0)
          .to(item.firstChild, { y: 40 * index, rotateX: 0, duration: 1, ease: 'power1.inOut', delay: index * 0.2 }, 1);
      });
      setIsExpanded(false);
      timeline.eventCallback('onComplete', () => {
        animatedState.current[3] = true;
      });
    }
    if (casesInView) {
      wrapper.addEventListener('touchstart', handleTouchStart, { passive: true });
      wrapper.addEventListener('touchend', handleTouchEnd, { passive: true });
    }
    return () => {
      killTimeline(timeline);
      wrapper.removeEventListener('touchstart', handleTouchStart);
      wrapper.removeEventListener('touchend', handleTouchEnd);
    };
  }, [casesInView, handleTouchEnd, handleTouchStart]);

  useEffect(() => {
    setIsExpanded(false);
  }, []);

  const caseClickHandler = index => {
    if (index === 2) {
      setPageCallback(PageState.GAME_ONE);
      AudioManager.instance.playSound(sfx.case1_open);
    }
    if (index === 1) {
      setPageCallback(PageState.GAME_TWO);
      AudioManager.instance.playSound(sfx.case1_open);
    }
    if (index === 0) {
      setPageCallback(PageState.GAME_THREE);
      AudioManager.instance.playSound(sfx.case1_open);
    }
    setIsExpanded(false);
  };

  return (
    <AnimatePresence>
      <Styled.UvBackgroundColor key={'UvBackgroundColor'} visible={uvLight && visible} />
      {visible ? (
        <>
          <Styled.Wrapper ref={initIntersectionRef} key="init" animate={visible ? 'visible' : 'hidden'}>
            {/* initial section */}
            {uvLight && (
              <>
                <Styled.UvBackgroundImg />
                <Styled.UvTitle />
                <Styled.UvBackgroundGradient />
              </>
            )}
            <Styled.WrapperInit ref={wrapperInitRef} style={{ backgroundPositionY }}>
              <Styled.DotBg />
              <Styled.LogoWrapper>
                <Logo role="img" aria-label="4 Murder Island" />
              </Styled.LogoWrapper>
              <div className="badge">
                <Badge role="img" aria-label="Police badge" />
              </div>
              <div className="title">
                <motion.span>{parse(t('landingPageInit.title1'))}</motion.span>
                <motion.span>{parse(t('landingPageInit.title2'))}</motion.span>
                <motion.span>{parse(t('landingPageInit.title3'))}</motion.span>
                <motion.span>{parse(t('landingPageInit.title4'))}</motion.span>
              </div>
              <motion.p className="description">{parse(t('landingPageInit.description'))}</motion.p>
              <Styled.BackgroundSection2>
                <motion.div className="description-wrapper">
                  <Styled.InitButtonWrapper style={{ opacity: initButtonOpacity, transform: initButtonY }}>
                    <Button
                      label={`${parse(t('landingPageInit.button'))}`}
                      onClick={() => {
                        scrollToCases();
                      }}
                      bgColor={colors.yellow}
                      bgImage={buttonBg}
                    />
                  </Styled.InitButtonWrapper>
                </motion.div>

                <motion.div
                  style={{ display: videoInView ? 'block' : 'none', opacity: videoDescOpacity }}
                  className="description-bottom-wrapper"
                >
                  <div className="content">
                    <Styled.TextWrapper>
                      <p>{parse(t('landingPageVideo.caseName'))}</p>
                      <p>{parse(t('landingPageVideo.caseNumber'))}</p>
                    </Styled.TextWrapper>
                    <Styled.TextWrapper>
                      <p>{parse(t('landingPageVideo.place'))}</p>
                      <p>{parse(t('landingPageVideo.time'))}</p>
                    </Styled.TextWrapper>
                  </div>
                </motion.div>
              </Styled.BackgroundSection2>
            </Styled.WrapperInit>
          </Styled.Wrapper>

          {/* video section */}
          <Styled.Wrapper
            overflowVisible
            ref={videoIntersectionRef}
            key="video"
            id={'video'}
            animate={visible ? 'visible' : 'hidden'}
          >
            {uvLight && (
              <>
                <Styled.UvBackground2Gradient />
                <Styled.UvBackground3Gradient />
              </>
            )}

            <Styled.WrapperVideo ref={wrapperVideoRef} onClick={() => {}}>
              <div className="content">
                <Styled.TextWrapper ref={evidenceIntersectionRef} className="text-outer-wrapper">
                  <p>{parse(t('landingPageVideo.evidence'))}</p>
                  <p>{parse(t('landingPageVideo.evidenceNumber'))}</p>
                </Styled.TextWrapper>
              </div>
            </Styled.WrapperVideo>
            <Styled.VideoBottomBg />
          </Styled.Wrapper>

          {/* case file section */}
          <Styled.Wrapper ref={casesIntersectionRef} key="case" animate={visible ? 'visible' : 'hidden'}>
            <Styled.WrapperCases ref={wrapperCasesRef}>
              <Styled.ContentCases>
                <Styled.TitleCases>{parse(t('landingPageCases.title'))}</Styled.TitleCases>
                <Styled.WrapperDescriptionCases>
                  <Styled.NumberCases>{parse(t('landingPageCases.numberOfCases'))}</Styled.NumberCases>
                  <p className="description">{parse(t('landingPageCases.description'))}</p>
                </Styled.WrapperDescriptionCases>
                <Styled.ListCases>
                  {CONFIG(t, progress).map((item, i) => {
                    return (
                      <div
                        className="file-wrapper"
                        ref={e => (casesFilesRef.current[i] = e)}
                        key={`file-${i}`}
                        onClick={() => caseClickHandler(i)}
                      >
                        <DashboardButtonWrapper
                          phase={item.phase}
                          label={item.label}
                          subtitle={item.subtitle}
                          description={item.description}
                          img={item.imgSrc}
                        />
                      </div>
                    );
                  })}
                </Styled.ListCases>
                <Styled.ButtonWrapperCases>
                  <Button
                    label={`${parse(t('landingPageCases.button'))}`}
                    onClick={() => setPageCallback(PageState.MURDER_ISLAND)}
                  ></Button>
                </Styled.ButtonWrapperCases>
              </Styled.ContentCases>
              {uvLight && (
                <>
                  <DashboardButtonUv
                    label="youtube"
                    title="landingPageCases.uvButtonTitle"
                    caseNumber="landingPageCases.uvCaseNumber"
                    firstParagraph="landingPageCases.uvFirstParagraph"
                    secondParagraph="landingPageCases.uvSecondParagraph"
                  />
                </>
              )}
            </Styled.WrapperCases>
          </Styled.Wrapper>
        </>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(LandingContainer);
