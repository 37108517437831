import { PhonePicture } from 'components/GameOnePhone/GameOnePhone';

export const avatars = {
  mum: { src: require('assets/images/game-1-phone-avatar-mum.jpg'), alt: 'Old woman' },
  rory: { src: require('assets/images/game-1-phone-avatar-rory.jpg'), alt: 'Alley in the park' },
  rose: { src: require('assets/images/game-1-phone-avatar-unkown.jpg'), alt: 'Question mark' },
  vanna: { src: require('assets/images/game-1-phone-avatar-vanna.jpg'), alt: 'Young woman' },
  delivery: { src: require('assets/images/game-1-phone-avatar-unkown.jpg'), alt: 'Question mark' },
};

// mind the key order for the gallery!
export const gallery: PhonePicture[] = [
  // 28
  {
    src: require('assets/images/game-1-phone-attachment-angry.gif'),
    srcUv: '',
    date: '28 AUG, 9:38 PM',
    alt: 'Fight of the angry creatures',
  },
  {
    src: require('assets/images/game-1-phone-attachment-knife.jpg'),
    srcUv: '',
    date: '28 AUG, 9:35 PM',
    alt: 'Knife in gift box on table',
  },
  {
    src: require('assets/images/game-1-phone-attachment-cake.jpg'),
    srcUv: require('assets/images/game-1-phone-attachment-cake-uv.jpg'),
    date: '28 AUG, 9:16 PM',
    alt: 'Man holding a piece of cake standing in kitchen',
  },
  {
    src: require('assets/images/game-1-phone-attachment-package.jpg'),
    srcUv: '',
    date: '28 AUG, 12:04 AM',
    alt: 'A parcel lying on the stairs',
  },
  {
    src: require('assets/images/game-1-phone-attachment-lillies.jpg'),
    srcUv: require('assets/images/game-1-phone-attachment-lillies-uv.jpg'),
    date: '28 AUG, 11:14 AM',
    alt: 'Woman holding a bouquet of lilies',
  },

  // 27
  {
    src: require('assets/images/game-1-phone-gallery-08-27-2303.jpg'),
    srcUv: '',
    date: '27 AUG, 11:03 PM',
    alt: 'Park alley in the evening',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-27-2302.jpg'),
    srcUv: '',
    date: '27 AUG, 11:02 PM',
    alt: 'A man standing in an illuminated alley in a park at night',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-27-1730.jpg'),
    srcUv: require('assets/images/game-1-phone-gallery-08-27-1730-uv.jpg'),
    date: '27 AUG, 5:30 PM',
    alt: 'Boats at the port',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-27-0830.jpg'),
    srcUv: '',
    date: '27 AUG, 8:30 AM',
    alt: 'Shoe rack',
  },
  // 26
  {
    src: require('assets/images/game-1-phone-gallery-08-26-2020.jpg'),
    srcUv: '',
    date: '26 AUG, 8:20 PM',
    alt: 'A man standing at the counter outside in the evening',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-26-1902.jpg'),
    srcUv: require('assets/images/game-1-phone-gallery-08-26-1902-uv.jpg'),
    date: '26 AUG, 7:02 PM',
    alt: 'A man walking through a forest alley',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-26-1415.jpg'),
    srcUv: '',
    date: '26 AUG, 2:15 PM',
    alt: 'Close-up of a shake cup held in a glove',
  },
  // 25
  {
    src: require('assets/images/game-1-phone-attachment-cat.gif'),
    srcUv: '',
    date: '25 AUG, 11:45 PM',
    alt: 'A cat trying to climbing',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-25-1730.jpg'),
    srcUv: '',
    date: '25 AUG, 5:30 PM',
    alt: 'A man standing among trees on a sunny day',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-25-1545.jpg'),
    srcUv: '',
    date: '25 AUG, 3:45 PM',
    alt: 'Electricity pole and fence among the trees',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-25-1513.jpg'),
    srcUv: '',
    date: '25 AUG, 3:13 PM',
    alt: 'House with lake in the background',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-25-1502.jpg'),
    srcUv: require('assets/images/game-1-phone-gallery-08-25-1502-uv.jpg'),
    date: '25 AUG, 3:02 PM',
    alt: 'Woman crossing a bridge in a forest',
  },
  // 23
  {
    src: require('assets/images/game-1-phone-gallery-08-23-1802.jpg'),
    srcUv: '',
    date: '23 AUG, 6:02 PM',
    alt: 'Man standing on a wooden pier',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-23-1500.jpg'),
    srcUv: '',
    date: '23 AUG, 3:00 PM',
    alt: 'Close-up of a crab held in the hand',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-23-1134.jpg'),
    srcUv: '',
    date: '23 AUG, 11:34 AM',
    alt: 'Boat against a background of houses and mountains',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-23-1120.jpg'),
    srcUv: '',
    date: '23 AUG, 11:20 AM',
    alt: 'Coast and mountains',
  },
  {
    src: require('assets/images/game-1-phone-gallery-08-23-0715.jpg'),
    srcUv: '',
    date: '23 AUG, 7:15 AM',
    alt: 'Smiling man with backpack',
  },
  // 21
  {
    src: require('assets/images/game-1-phone-gallery-08-21-1402.jpg'),
    srcUv: require('assets/images/game-1-phone-gallery-08-21-1402-uv.jpg'),
    date: '21 AUG, 14:02 AM',
    alt: 'Selfie of a woman with a man in the kitchen',
  },
];

export const attachments: { [key: string]: PhonePicture } = {
  cake: {
    src: require('assets/images/game-1-phone-attachment-cake.jpg'),
    srcUv: require('assets/images/game-1-phone-attachment-cake-uv.jpg'),
    date: '',
    alt: 'Man holding a piece of cake standing in kitchen',
  },
  lillies: {
    src: require('assets/images/game-1-phone-attachment-lillies.jpg'),
    srcUv: require('assets/images/game-1-phone-attachment-lillies-uv.jpg'),
    date: '',
    alt: 'Woman holding a bouquet of lilies',
  },
  gift: {
    src: require('assets/images/game-1-phone-attachment-gift.jpg'),
    srcUv: '',
    date: '',
    alt: 'Knife in gift box on table',
  },
  park: { src: require('assets/images/game-1-phone-attachment-park.jpg'), srcUv: '', date: '', alt: 'Map pin - Park' },
  knife: {
    src: require('assets/images/game-1-phone-attachment-knife.jpg'),
    srcUv: '',
    date: '',
    alt: 'Knife in gift box on table',
  },
  //TODO change the angry gif to correct/new one
  angry: {
    src: require('assets/images/game-1-phone-attachment-angry.gif'),
    srcUv: '',
    date: '',
    alt: 'Fight of the angry creatures',
  },
  house: {
    src: require('assets/images/game-1-phone-attachment-house.jpg'),
    srcUv: '',
    date: '',
    alt: 'Map pin - Pier',
  },
  bridge: {
    src: require('assets/images/game-1-phone-attachment-bridge.jpg'),
    srcUv: '',
    date: '',
    alt: 'Map pin - Bridge',
  },
  package: {
    src: require('assets/images/game-1-phone-attachment-package.jpg'),
    srcUv: '',
    date: '',
    alt: 'A parcel lying on the stairs',
  },
  cat_gif: {
    src: require('assets/images/game-1-phone-attachment-cat.gif'),
    srcUv: '',
    date: '',
    alt: 'A cat trying to climbing',
  },
};

export const voicenotes = {
  phone_breaking: require('assets/sounds/phone-breaking.mp3').default,
  vanna_answeryourphone: require('assets/sounds/vanna-answeryourphone.mp3').default,
  scott_someoneisfollowingme: require('assets/sounds/scott-someoneisfollowingme.mp3').default,
  vanna_missed_call: require('assets/sounds/vanna_missed_call.mp3').default,
  rory_tipsy: require('assets/sounds/rory_tipsy.mp3').default,
  scott_where_are_you: require('assets/sounds/scott_where_are_you.mp3').default,
  scott_last_breath: require('assets/sounds/scott_last_breath.mp3').default,
  rory_isnt_funny_scott: require('assets/sounds/rory_isnt_funny_scott.mp3').default,
  sounds_of_rose: require('assets/sounds/sounds_of_rose.mp3').default,
};

export const voicemails = {
  beach_sounds: require('assets/sounds/beach-sounds.mp3').default,
  vanna_weareover: require('assets/sounds/vanna-weareover.mp3').default,
  mum_iknowyourebusy: require('assets/sounds/mum-iknowyourebusy.mp3').default,
  rory_answeryourphone: require('assets/sounds/rory-answeryourphone.mp3').default,
  rory_voicemail: require('assets/sounds/rory_voicemail.mp3').default,
  vanna_voicemail: require('assets/sounds/vanna_voicemail.mp3').default,
  unknown_voicemail: require('assets/sounds/unknown_voicemail.mp3').default,
  mum_voicemail: require('assets/sounds/mum_voicemail.mp3').default,
};
