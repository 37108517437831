import styled from 'styled-components';
import { lighten, rgba } from 'polished';

import { colors, ease } from 'styles/vars';
import { absoluteCenter, autoAlpha, box, flexCenter, px, vh } from 'styles/mixins';
import { fontChannel4Chadwick, typography14Text, typography18Caps } from 'styles/typography';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: ${colors.white};
  text-transform: initial;
  pointer-events: all;
  .arrow-wrapper {
    position: absolute;
    right: ${px(4)};
    top: 50%;
    transform: translateY(-50%);
  }
  > .toggle {
    ${box(px(54))}
    position: absolute;
    display: block;
    right: ${px(15)};
    top: ${px(7.5)};
    border-radius: 50%;
    background: ${colors.white};
    transition: background 0.3s;

    &.blue {
      background: ${colors.blue};
    }

    .avatar {
      ${box('92%')}
      ${absoluteCenter()}
      display: block;
      object-fit: cover;
      border-radius: 50%;
    }

    .icon {
      ${absoluteCenter()}

      svg {
        ${box(px(17))}
      }
    }

    .exclamation {
      ${box(px(24))}
      ${flexCenter()}
      position: absolute;
      top: -10%;
      right: -10%;
      border-radius: 50%;
      background: ${colors.blue};

      > svg {
        ${box(px(14))}
      }
    }
  }

  > .notification {
    ${typography14Text}
    position: absolute;
    right: ${px(80)};
    top: ${px(16)};
    max-width: ${px(250)};
    background: ${colors.blue};
    padding: ${px(15)} ${px(20)};
    border-radius: ${px(6)};
    box-shadow: 0 ${px(6)} ${px(9)} rgba(0, 0, 0, 0.19);
    transform-origin: 100% ${px(18)};

    &::before {
      ${box(px(7))}
      content: '';
      top: ${px(18)};
      right: ${px(-3)};
      position: absolute;
      transform: rotate(45deg);
      background: inherit;
    }
  }

  > .chat {
    position: absolute;
    top: ${px(80)};
    width: ${px(345)};
    height: calc(${vh(100)} - ${px(100)});
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    filter: drop-shadow(0 ${px(6)} ${px(9)} rgba(0, 0, 0, 0.4));

    .title {
      display: flex;
      align-items: center;
      padding: ${px(15)} ${px(12)};
      background: ${colors.mako};
      border-radius: ${px(6)} ${px(6)} 0 0;

      &::before {
        ${box(px(15))}
        content: '';
        top: ${px(-4)};
        right: ${px(18)};
        position: absolute;
        transform: rotate(45deg);
        background: inherit;
      }

      .avatar {
        ${box(px(31))}
        flex: none;
        margin: 0 ${px(15)} 0 0;
        display: block;
        object-fit: cover;
        border-radius: 50%;
      }

      .name {
        ${fontChannel4Chadwick(16, 500, 1.45)}
        flex: 1 1 auto;
      }
    }

    .messages {
      padding: ${px(15)} ${px(12)};
      background: ${lighten(0.05, colors.mako)};
      border-radius: 0 0 ${px(6)} ${px(6)};
      height: calc(100% - ${px(70)});
      overflow: auto;
      transition: height 0.3s ${ease.inOutCubic};

      .quiz {
        margin: ${px(30)} 0;
        text-align: center;

        .question {
          ${typography18Caps}
          margin: 0 0 ${px(25)};
        }

        .options {
          ${flexCenter('column')}

          .option {
            ${flexCenter()}
            ${box(px(250), px(45))}
            border: 2px solid ${colors.blue};
            border-radius: ${px(4)};
            margin: 0 0 ${px(8)};

            &.hasCorrectAnswer {
              padding: 0 ${px(44)};
            }

            &.selected {
              background: ${colors.blue};
            }

            &.correct {
              color: ${colors.quizGreenText};
              background: ${rgba(colors.quizGreenBackground, 0.2)};
              border-color: transparent;
              padding-left: ${px(17)};
            }

            &.wrong {
              color: ${colors.quizRedText};
              background: ${rgba(colors.quizRedBackground, 0.2)};
              border-color: transparent;
              padding-left: ${px(17)};
            }

            svg {
              ${box(px(10))}
              margin: 0 ${px(17)} 0 0;
              flex: none;
            }

            .text {
              flex: 1 1 auto;
            }
          }
        }
      }

      .message {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0 0 ${px(17)};

        .picture {
          ${box(px(25))}
          flex: none;
          object-fit: cover;
          border-radius: 50%;
          margin: ${px(19)} ${px(8)} 0 0;
        }

        .content {
          .date {
            ${fontChannel4Chadwick(10, 500, 1)}
            letter-spacing: 0.05em;
            margin: 0 0 ${px(8)};
            opacity: 0.6;
          }

          .text {
            display: inline-block;
            text-align: left;
            padding: ${px(15)} ${px(20)};
            max-width: ${px(280)};
            border-radius: ${px(6)};
            background: ${colors.mako};
            box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);
          }
        }
      }

      .return {
        width: 100%;
      }
    }

    .cta {
      ${autoAlpha(0)}
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translate3d(0, 100%, 0);
      transition: opacity 0.3s, visibility 0.3s, transform 0.3s ${ease.inOutExpo};
      pointer-events: none;
    }
  }
`;
