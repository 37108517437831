import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { absoluteCenter, autoAlpha, box, flexCenter, px, rect, uvBase, vh, fullHeight } from 'styles/mixins';
import { mediaHeightMediumMobile, mediaHeightLargeMobile, mediaOnlySafari } from 'styles/responsive';
import { fontChannel4Horseferry, fontChannel4Chadwick, typography14Caps, typography18Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${fullHeight};
  position: relative;
  overflow: hidden;
  width: 100%;

  > .tutorial {
    z-index: 1;
    ${rect('absolute', 0, 0, '100%', '100%')}
    ${flexCenter('column')}
    ${autoAlpha(0)}
    background: url(${require('assets/images/game-1-intro-tutorial-bg.jpg')});
    background-size: cover;
    color: ${colors.black};

    .bgtext {
      ${fontChannel4Horseferry(50, 700, 0.9)}
      text-transform: uppercase;
      letter-spacing: -0.06em;
      word-break: break-all;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 20;
      left: 0;
      top: 0;
      padding: ${px(85)} ${px(15)};
      ${mediaHeightMediumMobile(`font-size: ${px(60)}`)}
      ${mediaHeightLargeMobile(`font-size: ${px(66)}`)}

      br {
        display: none;
      }

      div {
        &:last-child {
          align-self: flex-end;
        }
      }
    }

    .slides {
      position: relative;
      flex: 1 1 auto;
      width: 100%;
      z-index: 0;

      .slide {
        ${rect('absolute', 0, 0, '100%', '100%')}
        ${flexCenter('column')}
        justify-content: flex-end;
        width: 100%;
        flex: none;
        pointer-events: none;

        .media {
          position: relative;
          flex: 1 1 auto;
          width: 100%;
          z-index: 20;

          .media-inner-wrapper {
            ${flexCenter()}
            height: 100%;

            transform: scale(0.55) translateY(50%);
            ${mediaHeightMediumMobile(`
              transform: scale(1);
            `)}
          }

          .flashlight {
            ${box('100%', vh(100))}
            position: absolute;
            top: ${px(50)};
            left: 50%;
            transform: translateX(-50%);
            background: url(${require('assets/images/game-2-intro-tutorial-flashlight.png')});
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
          }

          .paper {
            ${box(px(360), px(270))}
            position: absolute;
            bottom: -1.5rem;
            left: 50%;
            transform: translateX(-50%);
            background: url(${require('assets/images/game-2-intro-tutorial-paper.png')}) center no-repeat;
            background-size: contain;
            ${mediaHeightMediumMobile(box(px(550), px(420)))};
          }
        }

        .description {
          ${typography18Caps}
          flex: none;
          width: 100%;
          padding: 0 ${px(20)};
          position: relative;
          text-align: center;
          z-index: 21;

          .underline1 {
            ${box(px(180), px(2))}
            position: absolute;
            background-image: url(${require('assets/images/game-2-intro-tutorial-underline1.png')});
            background-size: 100% 100%;
            bottom: ${px(18)};
            left: ${px(140)};
          }

          .underline2 {
            ${box(px(111), px(7))}
            position: absolute;
            background-image: url(${require('assets/images/game-2-intro-tutorial-underline2.png')});
            background-size: 100% 100%;
            bottom: ${px(-6)};
            left: ${px(132)};
          }
        }
      }
    }

    .footer {
      position: relative;
      width: 100%;
      flex: none;
      padding: 0 ${px(16)} ${px(30)};
      z-index: 1;

      .buttons {
        position: relative;
        height: ${px(100)};
        width: 100%auto;

        .start,
        .next {
          ${absoluteCenter()}
          ${autoAlpha(0, 0.16)}

          &.show {
            ${autoAlpha(1)}
          }
        }

        .start {
          width: 100%;
        }
      }

      .progress {
        display: flex;
        justify-content: space-between;
        margin: 0 0 ${px(6)};

        .bar {
          width: ${px(109)};
          height: 1px;
          background: ${rgba(colors.black, 0.3)};

          .fill {
            ${box('100%')}
            background: ${colors.black};
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 1s ease;

            &.active {
              transform: scale(1);
            }
          }
        }
      }

      .page {
        ${typography14Caps}
      }
    }
  }

  > .uv {
    z-index: 2;
    ${uvBase()}

    .landing {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-2-intro-uv-landing.png')});
      background-position: center center;
      background-size: cover;
    }

    .uv-item {
      ${rect('absolute', 'auto', 0, '100%', '100%')}
      display: flex;
      right: 0;
      justify-content: center;
      align-items: flex-end;
      bottom: ${px(126)};
      flex: 1;
    }

    .tutorial1 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-2-intro-uv-tutorial1.png')});
      background-position: bottom;
      background-size: cover;

      .media-uv {
        ${box('100%', px(53))}
        width: 100%;
        margin-bottom: ${px(29.5)};
        background: url(${require('assets/images/game-2-intro-tutorial-media-uv.png')}) center bottom no-repeat;
        background-size: contain;
        margin-left: ${px(86)};
      }
    }

    .tutorial2 {
      ${box('100%', vh(100))}
      position: absolute;
      top: ${px(50)};
      left: 50%;
      transform: translateX(-50%);
      background: url(${require('assets/images/game-2-intro-tutorial-flashlight-uv.png')});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;

      .flashlight-uv {
        ${box('100%', px(53))}
        width: 100%;
        margin-bottom: ${px(60)};
        background: url(${require('assets/images/game-2-intro-tutorial-flashlight-uv-mode.png')}) center bottom
          no-repeat;
        background-size: contain;
        margin-left: ${px(-7)};
      }
    }

    .tutorial3 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-2-intro-uv-tutorial3.png')});
      background-position: bottom;
      background-size: cover;

      .paper-uv {
        ${box(px(360), px(270))}
        position: absolute;
        bottom: ${px(50)};
        left: 50%;
        transform: translateX(-50%);
        background: url(${require('assets/images/game-2-intro-tutorial-paper-uv.png')}) center no-repeat;
        background-size: contain;
        ${mediaHeightMediumMobile(box(px(550), px(420)))};
      }
    }
  }
`;
