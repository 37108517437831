import React, { useContext, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import HamburgerIcon from 'components/Icons/HamburgerIcon';
import Logo from 'components/Logo';
import * as Styled from './Header.styles';
import SvgChevronLeftDouble from 'svgs/ChevronLeftDouble.svg';
import { Menu } from 'components/Menu/Menu';
import { Popup } from 'components/Popup/Popup';
import { PageProps } from 'types/page';
import { PageState } from 'constants/enum';

import { HeaderContext, HeaderContextType } from 'context/header';

interface HeaderProps extends PageProps {
  pageState: PageState;
}

export const Header: React.FC<HeaderProps> = ({ pageState, setPageCallback }) => {
  const { header, setHeader } = useContext<HeaderContextType>(HeaderContext);

  const [openPopup, setOpenPopup] = useState(false);

  const { t } = useTranslation();

  const handleOpenMenu = () => {
    setHeader({ ...header, isMenuOpen: !header.isMenuOpen });
  };

  const handleOpenPopup = () => setOpenPopup(!openPopup);

  useEffect(() => {
    if (header.isMenuOpen && header?.onMenuOpen) header.onMenuOpen();
    if (!header.isMenuOpen && header?.onMenuClose) header.onMenuClose();
  }, [header, setHeader]);

  return (
    <>
      <Styled.Wrapper
        animate={!header.isHidden || header.isBackButton ? 'visible' : 'hidden'}
        isCenter={header.isSkipButton}
        isWhite={header.isWhite}
        hasMask={header.hasMask}
      >
        {header.isBackButton ? (
          <Styled.HamburgerWrapper isBackMenu={header.isBackButton} isWhite={header.isWhite} onClick={header.onBack}>
            <SvgChevronLeftDouble />
          </Styled.HamburgerWrapper>
        ) : (
          <Styled.HamburgerWrapper isWhite={header.isWhite} onClick={handleOpenMenu}>
            <HamburgerIcon isWhite={header.isWhite} />
          </Styled.HamburgerWrapper>
        )}
        <div className="logo-wrapper ">
          <Logo light={header.isWhite} />
        </div>

        {header.isSkipButton && (
          <Styled.SkipButton isWhite={header.isWhite} onClick={header.onSkip}>
            {parse(t('skip'))}
          </Styled.SkipButton>
        )}
        {header.children && header.children}
      </Styled.Wrapper>
      <Menu
        pageState={pageState}
        open={header.isMenuOpen}
        handleOpenMenu={handleOpenMenu}
        handleOpenPopup={handleOpenPopup}
        setPageCallback={setPageCallback}
      />
      <Styled.Overlay isActive={openPopup} />
      <Popup
        open={openPopup}
        handleOpenPopup={handleOpenPopup}
        title={`${parse(t('popup.title'))}`}
        description={`${parse(t('popup.description'))}`}
        acceptText={`${parse(t('popup.acceptText'))}`}
        rejectText={`${parse(t('popup.rejectText'))}`}
      />
    </>
  );
};
