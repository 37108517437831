import React, { memo, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';

import { RedirectsContext, RedirectsContextType } from 'context/redirects';

import GameOneIntro from 'components/GameOneIntro';
import GameOnePhone from 'components/GameOnePhone';
import GameDetectiveVideo, { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';

import * as Styled from './GameOne.styled';

interface Props {
  uvLight: boolean;
  onExit: () => void;
  onComplete: (accuracy: number) => void;
}

enum State {
  INTRO,
  PHONE,
}

export const GameOne: React.FC<Props> = ({ uvLight, onComplete, onExit }) => {
  const detectiveVideoRef = useRef<GameDetectiveVideoRef>(null);

  const { redirects } = useContext(RedirectsContext);
  const [state, setState] = useState(State.INTRO);

  const handleIntroComplete = useCallback(() => setState(State.PHONE), []);

  useEffect(() => {
    if (redirects.detectiveVideo) {
      detectiveVideoRef.current.play();
    }

    if (redirects.gameState) {
      handleIntroComplete();
    }

    if (redirects.restartTraining) {
      setState(State.INTRO);
    }

    if (redirects.tryAgain) {
      setState(State.PHONE);
    }
  }, [redirects, handleIntroComplete]);

  return (
    <Styled.Wrapper>
      <AnimatePresence exitBeforeEnter>
        {state === State.INTRO ? (
          <GameOneIntro
            key="intro"
            uvLight={uvLight}
            onComplete={handleIntroComplete}
            onExit={onExit}
            detectiveVideoRef={detectiveVideoRef}
          />
        ) : (
          <GameOnePhone key="phone" uvLight={uvLight} onComplete={onComplete} detectiveVideoRef={detectiveVideoRef} />
        )}
      </AnimatePresence>
      <GameDetectiveVideo
        ref={detectiveVideoRef}
        src="https://storage.googleapis.com/creative4-3136-fb10-videos/final_detective_cr.mp4"
        poster={require('assets/images/teaser2-slice-1.jpg')}
      />
    </Styled.Wrapper>
  );
};

export default memo(GameOne);
