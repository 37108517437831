import { useState, useEffect, MutableRefObject } from 'react';

function useIntersectionObserver(element: MutableRefObject<any>, triggerOnce = true) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (element) {
      const el = element.current;
      const options = {
        threshold: 0.0,
        triggerOnce: Boolean(triggerOnce),
        rootMargin: '0px',
      };
      observer = new IntersectionObserver(entries => {
        if (options.triggerOnce) {
          if (entries[0].isIntersecting) {
            setIntersecting(true);
            observer.unobserve(el);
          }
        } else {
          setIntersecting(entries[0].isIntersecting);
        }
      }, options);
      observer.observe(el);
    }

    return () => {
      observer?.disconnect();
    };
  }, [element, triggerOnce]);

  return isIntersecting;
}

export default useIntersectionObserver;
