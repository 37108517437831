import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Stripe = styled(motion.li)<{ bgSrc: string; width: number; margin: number; vScale: number }>`
  padding: 0;
  margin: 0;
  position: relative;
  float: left;
  background: ${props => `transparent url(${props.bgSrc}) no-repeat`};
  background-position: center;
  background-size: contain;
  overflow: hidden;
  height: calc(116vw * ${props => props.vScale});
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  width: ${props => props.width}vw;
  margin-left: ${props => -props.margin}vw;
  cursor: grab;
`;
