import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from 'styles/vars';
import { fontChannel4Horseferry } from 'styles/typography';
import { rgba } from 'polished';

type OverlayProps = {
  isActive: boolean;
};

export const Wrapper = styled(motion.header)<{
  hasMask: boolean;
  isWhite: boolean;
  isCenter: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.isCenter ? 'space-between' : 'left')};
  align-items: center;
  position: fixed;
  z-index: 13;
  top: 0;
  width: 100vw;
  padding: 1.5rem 1rem;
  pointer-events: none;

  ${props =>
    props.hasMask &&
    css`
      backdrop-filter: blur(1.5rem);
    `}

  ${props =>
    props.hasMask &&
    !props.isWhite &&
    css`
      background: ${rgba(colors.white, 0.4)};
    `}

  .logo-wrapper {
    margin-left: ${props => (props.isCenter ? '1rem' : '8.7rem')};
  }
`;

export const HamburgerWrapper = styled.div<{ isWhite: boolean; isBackMenu?: boolean }>`
  margin-left: 0.3rem;
  pointer-events: all;
  svg {
    width: 2.6rem;
    height: 2.6rem;
    color: ${props => (props.isWhite ? `${colors.white}` : `${colors.black}`)};
  }
`;

export const SkipButton = styled.button<{ isWhite: boolean }>`
  ${fontChannel4Horseferry(20, 700, 1.1)};
  text-transform: uppercase;
  color: ${props => (props.isWhite ? `${colors.white}` : `${colors.black}`)};
`;

export const Overlay = styled.div<OverlayProps>`
  content: '';
  display: ${props => (props.isActive ? 'block' : 'none')};
  z-index: 11;
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
