import * as React from 'react';

import { SVGContainer } from './styled';
import { colors } from 'styles/vars';

export type SVGIconBaseProps = {
  children: React.ReactNode;
  color?: string;
  width?: number;
  height?: number;
  viewbox?: string;
};

const SVGIconBase = ({ children, color, width, height, viewbox, ...rest }: SVGIconBaseProps) => {
  const fillColor = color ? color : colors.white;
  const svgHeight = height ?? 15;
  const svgWidth = width ?? 15;
  const svgViewBox = viewbox ?? `0 0 ${svgWidth} ${svgHeight}`;

  return (
    <SVGContainer
      height={height}
      width={width}
      fill={fillColor}
      viewBox={svgViewBox}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
    </SVGContainer>
  );
};

export default SVGIconBase;
