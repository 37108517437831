import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import * as Styled from './CookieNotice.styled';

import localStorage from 'utils/localStorage';

import SvgCheckmark from 'svgs/Checkmark.svg';
import SvgChevronLeftDouble from 'svgs/ChevronLeftDouble.svg';
import SvgExclamationFilled from 'svgs/ExclamationFilled.svg';

interface Props {}

export const CookieNotice: React.FC<Props> = () => {
  const { t } = useTranslation();

  const titleRef = useRef(null);
  const titleRef2 = useRef(null);
  const interactedRef = useRef(false);

  const [show, setShow] = useState(process.env.STORYBOOK || !localStorage.get('cookies'));
  const [essential, setEssential] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const [ageConfirmation, setAgeConfirmation] = useState(false);

  const handleEssentialClick = useCallback(() => {
    setEssential(true);
    interactedRef.current = true;
  }, []);

  const handleBackClick = useCallback(() => {
    setEssential(false);
  }, []);

  const handleContinueClick = useCallback(() => {
    if (!ageConfirmation) return;
    localStorage.set('cookies', { saveProgress });
    setShow(false);
  }, [ageConfirmation, saveProgress]);

  useEffect(() => {
    if (essential) {
      setTimeout(() => {
        titleRef2?.current?.focus();
      }, 600);
    } else {
      titleRef?.current?.focus();
    }
  }, [essential]);

  return (
    <AnimatePresence>
      {show ? (
        <Styled.Wrapper
          transition={{ duration: ageConfirmation ? 0.3 : 0.6, ease: 'easeInOut' }}
          initial={{ y: '100%' }}
          animate={{ y: '0%' }}
          exit={{ y: '100%' }}
          key="root"
          role="dialog"
          tabIndex={-1}
          aria-modal="true"
        >
          <div className="background" />
          <AnimatePresence>
            {essential ? (
              <motion.div
                className="essential"
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                initial={{ x: '100%' }}
                animate={{ x: '0' }}
                exit={{ x: '100%' }}
                key="essential"
                role="region"
              >
                <div className="top">
                  <button className="back" onClick={handleBackClick}>
                    <SvgChevronLeftDouble />
                    <span>{parse(t('cookieNotice.essential.back'))}</span>
                  </button>
                  <h1 className="title" tabIndex={-1} ref={titleRef2}>
                    {parse(t('cookieNotice.essential.title'))}
                  </h1>
                  <p className="description">{parse(t('cookieNotice.essential.description'))}</p>
                  <ul>
                    <li>
                      <div
                        className="checkbox selected disabled"
                        role="checkbox"
                        aria-checked={true}
                        aria-disabled={true}
                        tabIndex={-1}
                      >
                        <div className="icon">
                          <SvgCheckmark />
                        </div>
                        <div>
                          <p className="labelTitle">{parse(t('cookieNotice.essential.statistics'))}</p>
                          <p className="labelDescription">{parse(t('cookieNotice.essential.policy'))}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ctas">
                  <button className="cta save" onClick={handleBackClick}>
                    {parse(t('cookieNotice.essential.cta'))}
                  </button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                className="initial"
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                initial={{ x: interactedRef.current ? '-100%' : '0%' }}
                animate={{ x: '0%' }}
                exit={{ x: '-100%' }}
                key="list"
                role="region"
              >
                <div className="top">
                  <h1 className="title" tabIndex={-1} id="DialogName" ref={titleRef}>
                    {parse(t('cookieNotice.title'))}
                  </h1>
                  <p className="description">{parse(t('cookieNotice.description'))}</p>
                  <h2 className="options">{parse(t('cookieNotice.options'))}</h2>
                  <ul className="list">
                    <li>
                      <div
                        className={classNames('checkbox', { selected: saveProgress })}
                        role="checkbox"
                        aria-checked={saveProgress}
                        tabIndex={0}
                        onClick={() => setSaveProgress(s => !s)}
                      >
                        <div className="icon">
                          <SvgCheckmark />
                        </div>
                        <p className="labelDescription">{parse(t('cookieNotice.saveProgress'))}</p>
                      </div>
                    </li>
                    <li>
                      <div
                        className={classNames('checkbox', {
                          selected: ageConfirmation,
                          error: !ageConfirmation,
                        })}
                        role="checkbox"
                        aria-checked={ageConfirmation}
                        tabIndex={0}
                        onClick={() => setAgeConfirmation(s => !s)}
                      >
                        <div className="icon">
                          <SvgCheckmark />
                        </div>
                        <p className="labelDescription">{parse(t('cookieNotice.ageConfirmation'))}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ctas">
                  <button className="cta essential" onClick={handleEssentialClick}>
                    {parse(t('cookieNotice.ctaEssential'))}
                  </button>
                  <button
                    className={classNames('cta continue', { disabled: !ageConfirmation })}
                    onClick={handleContinueClick}
                  >
                    {parse(t('cookieNotice.ctaContinue'))}
                  </button>
                </div>
                <div className="bottom">
                  {!ageConfirmation ? (
                    <div className="errorAge">
                      <SvgExclamationFilled />
                      <span>{parse(t('cookieNotice.errorAge'))}</span>
                    </div>
                  ) : null}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </Styled.Wrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(CookieNotice);
