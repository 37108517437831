import React, { memo, useEffect, useState } from 'react';

import * as Styled from './GameOnePhoneClock.styled';
import usePreloadedImages from 'hooks/usePreloadedImages';

const imagesToPreload = [require('assets/images/game-1-phone-uv-clock.png')];
interface Props {
  uvLight: boolean;
}

export const GameOnePhoneClock: React.FC<Props> = ({ uvLight }) => {
  const [hour, setHour] = useState(new Date().getHours().toString().padStart(2, '0'));
  const [minute, setMinute] = useState(new Date().getMinutes().toString().padStart(2, '0'));

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    const setTime = () => {
      const date = new Date();
      setHour(date.getHours().toString().padStart(2, '0'));
      setMinute(date.getMinutes().toString().padStart(2, '0'));
    };
    const interval = setInterval(setTime, 1000);
    setTime();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Styled.Wrapper
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      initial={{ opacity: 0, scale: 1.1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
    >
      <div className="time">
        <p className="number">{hour}</p>
        <p className="number">{minute}</p>
      </div>

      {uvLight ? <div className="uv"></div> : null}
      {uvLight ? <div className="uv-fingerprint" /> : null}
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhoneClock);
