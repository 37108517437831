import styled from 'styled-components';
import { motion } from 'framer-motion';

import { box, flexCenter, px, rect, vh } from 'styles/mixins';
import { colors } from 'styles/vars';
import { fontChannel4Chadwick, fontChannel4Horseferry } from 'styles/typography';

export const Wrapper = styled(motion.div)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  color: ${colors.black};
  overflow: hidden;
  z-index: 20;

  .checkbox {
    display: flex;
    margin: 0 0 ${px(12)};
    cursor: pointer;

    &.selected {
      .icon {
        background: ${colors.blackPearl};

        svg {
          opacity: 1;
        }
      }
    }

    &.error {
      color: ${colors.guardsmanRed};

      .icon {
        border: 1px solid ${colors.guardsmanRed};
      }
    }

    &.disabled {
      pointer-events: none;

      .icon {
        opacity: 0.5;
      }
    }

    .icon {
      ${box(px(18))}
      ${flexCenter()}
      flex: none;
      margin: 0 ${px(9)} 0 0;
      border: 1px solid ${colors.blackPearl};
      border-radius: ${px(3)};

      svg {
        ${box(px(9))}
        color: ${colors.carrara};
        opacity: 0;
      }
    }

    .labelTitle {
      ${fontChannel4Horseferry(15, 700, 1.35)}
      text-transform: uppercase;
      margin: 0 0 ${px(6)};
    }

    .labelDescription {
      ${fontChannel4Chadwick(13, 400, 1.38)}
      margin: ${px(1)} 0 0 0;
    }
  }

  .cta {
    ${flexCenter()};
    ${fontChannel4Chadwick(14, 700, 1.1)}
    text-transform: uppercase;
    border: 1px solid ${colors.blackPearl};
    height: ${px(56)};
    padding: ${px(10)};

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }

    &.essential {
      color: ${colors.blackPearl};
      background: transparent;
    }

    &.continue {
      color: ${colors.white};
      background: ${colors.blackPearl};
    }

    &.save {
      width: 100%;
      color: ${colors.white};
      background: ${colors.blackPearl};
    }
  }

  > .background {
    ${rect('absolute', 0, 0, '100%', '100%')}
    background: url(${require('assets/images/cookie-notice-background.png')});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }

  > .essential {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: ${px(110)};
    padding: 0 0 ${px(30)};
    overflow: auto;

    .top {
      padding: 0 ${px(30)};
      margin: 0 0 ${px(26)};

      .back {
        ${fontChannel4Horseferry(15, 700, 1)}
        text-transform: uppercase;
        align-items: center;
        display: flex;
        margin: 0 0 ${px(22)};

        svg {
          height: ${px(25)};
          margin: 0 ${px(15)} 0 0;
        }
      }

      .title {
        ${fontChannel4Horseferry(22, 700, 1.82)}
        text-transform: uppercase;
        margin: 0 0 ${px(12)};
      }

      .description {
        ${fontChannel4Chadwick(13, 400, 1.38)}
        margin: 0 0 ${px(12)};
      }

      .subTitle {
        ${fontChannel4Horseferry(15, 700, 2.67)}
        text-transform: uppercase;
        margin: 0 0 ${px(7)};
      }

      .noTurnOff {
        ${fontChannel4Chadwick(13, 400, 1.38)}
        margin: 0 0 ${px(18)};
      }
    }

    .ctas {
      padding: 0 ${px(16)};
    }
  }

  > .initial {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: ${px(110)};
    padding: 0 0 ${px(30)};
    overflow: auto;

    .top {
      padding: 0 ${px(30)};

      .title {
        ${fontChannel4Horseferry(22, 700, 1.82)}
        text-transform: uppercase;
        margin: 0 0 ${px(12)};
      }

      .description {
        ${fontChannel4Chadwick(13, 400, 1.38)}
        margin: 0 0 ${px(12)};
      }

      .options {
        ${fontChannel4Horseferry(15, 700, 2.67)}
        text-transform: uppercase;
        margin: 0 0 ${px(7)};
      }

      .list {
        margin: 0 0 ${px(28)};
      }
    }

    .ctas {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${px(8)};
      padding: 0 ${px(16)};
      margin: 0 0 ${px(22)};
    }

    .bottom {
      padding: 0 ${px(30)};

      .errorAge {
        ${fontChannel4Chadwick(12, 700, 1.1)}
        display: flex;
        align-items: center;
        color: ${colors.guardsmanRed};
        text-transform: uppercase;
        margin: 0 0 ${px(22)};

        svg {
          ${box(px(18))}
          flex: none;
          margin: 0 ${px(10)} 0 0;
        }
      }

      .policy {
        ${fontChannel4Chadwick(11, 400, 1.27)}
        opacity: 0.5;
      }
    }
  }
`;
