import React, { memo } from 'react';

import indicatorsBg from 'assets/images/game3/indicators.png';
import Indicator from 'svgs/Indicator.svg';

import * as Styled from './GameThreeIndicators.styled';

export type GameThreeIndicatorsProps = {
  round?: number;
};

const GameThreeIndicators: React.FC<GameThreeIndicatorsProps> = ({ round }) => {
  return (
    <Styled.Wrapper>
      <Styled.IndicatorsBackground src={indicatorsBg} />
      {round !== undefined && (
        <Styled.Indicator round={round} aria-label={`${round + 1} set of videos`}>
          <Indicator />
        </Styled.Indicator>
      )}
    </Styled.Wrapper>
  );
};

export default memo(GameThreeIndicators);
