import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { box, px, rect, vh, fullHeight } from 'styles/mixins';
import { fontChannel4Chadwick, fontChannel4Horseferry } from 'styles/typography';

import DetectiveChatComponent from 'components/DetectiveChat';

import bgSrc from 'assets/images/bg-call.jpg';

export const Header = styled.h2`
  ${fontChannel4Horseferry(24, 700)};
  text-transform: uppercase;
  max-width: 80vw;
  color: ${colors.white};
  text-align: center;
  margin-top: 1.75rem;
`;

export const Title = styled.h3<{ danger: boolean }>`
  ${fontChannel4Chadwick(16, 500)}
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin-top: 0.813rem;
  text-align: center;
  color: ${({ danger }) => (danger ? colors.callRed : colors.white)};
`;

export const Wrapper = styled(motion.div)`
  ${fullHeight};
  background-image: url(${bgSrc});
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const AvatarWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CallButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 4.8rem;
  justify-content: space-evenly;
  width: 100vw;
`;

export const CallButtonSpacer = styled.div`
  height: 16.5rem;
`;

export const SkipButtonWrapper = styled(motion.div)<{ type: 'close' | 'skip' }>`
  ${fontChannel4Horseferry(20, 700)};
  min-height: ${px(40)};
  position: absolute;
  text-transform: uppercase;
  z-index: 10;
  ${props =>
    props.type === 'close'
      ? css`
          top: ${px(20)};
          right: ${px(24)};
          background: none;
          & button {
            padding: 0;
          }
        `
      : css`
          width: 50vw;
          bottom: ${px(60)};
          bottom: 1rem;
        `};

  & button svg {
    width: ${px(24)};
    height: ${px(24)};
    color: ${colors.white};
  }
`;

export const EndStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: ${px(8)};
  }
`;

export const Section = styled(motion.div)`
  ${rect('absolute', 0, 0, '100%', 'auto')};
  ${fullHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const DetectiveChat = styled(DetectiveChatComponent)`
  z-index: 10;
`;

export const PlayMessage = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    ${box(px(20))}
    margin: 0 ${px(10)} 0 0;
  }
`;

Section.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      zIndex: 0,
      transition: {
        duration: 1.5,
      },
    },
    visible: {
      opacity: 1,
      zIndex: 1,
      transition: {
        duration: 1.5,
      },
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
