import styled from 'styled-components';
import { fontChannel4Chadwick, fontChannel4Horseferry } from 'styles/typography';
import { colors } from 'styles/vars';
import { px } from 'styles/mixins';

const List = styled.ul`
  list-style: none;
  margin: -0.7rem 0 0 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  li {
    color: ${colors.white};
  }
`;

export const MainList = styled(List)`
  width: 74%;
  margin-top: 3rem;

  li {
    ${fontChannel4Chadwick(20, 700)};
    margin-bottom: 2rem;
  }
`;

export const PolicyList = styled(List)`
  height: 12%;

  a {
    ${fontChannel4Chadwick(14, 500)};

    &:first-child {
      margin-bottom: 2rem;
    }
  }
`;

export const Divider = styled.div`
  width: 60%;
  height: ${px(2)};
  margin: 2rem 0 1rem 0;
  background: url(${require('assets/images/menu-divider.png')}) center center no-repeat;
  background-size: contain;
`;

export const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 3rem;

  a,
  p {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 49vw;
  }

  span {
    ${fontChannel4Chadwick(12, 500, 1.1)}
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.white};
    width: 43%;
  }

  .all4 {
    height: ${px(32)};
    width: auto;
  }

  .youtube {
    height: ${px(27)};
    width: auto;
  }

  hr {
    height: 4rem;
    opacity: 0.5;
    margin: 0;
  }
`;
