import React, { memo, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { sfx } from 'constants/assets';
import AudioManager from 'services/audiomanager.service';

import { HeaderContext } from 'context/header';

import * as Styled from './GameThreeVideoSet.styled';
import GameThreeVideo from 'components/GameThreeVideo';
import GameThreeDetective from 'components/GameThreeDetective';
import { GameDetectiveVideoRef } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import { GameThreeDetectiveRef } from 'components/GameThreeDetective/GameThreeDetective';
import GameThreeIndicators from 'components/GameThreeIndicators';
import { PageState } from 'constants/enum';
import { RedirectsContext } from 'context/redirects';
import { HistoryContext } from 'context/history';
import { useTimer } from 'use-timer';

const VIDEOS: Array<Array<string>> = [
  [
    require('assets/videos/game3/FOREST.mp4'),
    require('assets/videos/game3/JUMP.mp4'),
    require('assets/videos/game3/CAR.mp4'),
  ],
  [
    require('assets/videos/game3/ZONE.mp4'),
    require('assets/videos/game3/CATS.mp4'),
    require('assets/videos/game3/BEACH.mp4'),
  ],
  [
    require('assets/videos/game3/CARD.mp4'),
    require('assets/videos/game3/SWING.mp4'),
    require('assets/videos/game3/BAG.mp4'),
  ],
];

const VIDEOS_RUNNING_TIME = 10;

export enum GameState {
  ROUND1,
  ROUND2,
  ROUND3,
}
interface GameThreeVideoSetProps {
  className?: string;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onComplete: (accuracy: number) => void;
}

const GameThreeVideoSet: React.FC<GameThreeVideoSetProps> = ({ detectiveVideoRef, onComplete }) => {
  const {
    setHeader,
    header: { isMenuOpen },
  } = useContext(HeaderContext);
  const { redirects } = useContext(RedirectsContext);
  const { setHistory } = useContext(HistoryContext);

  const mainVideoRef = useRef<HTMLVideoElement>(null);
  const leftVideoRef = useRef<HTMLVideoElement>(null);
  const rightVideoRef = useRef<HTMLVideoElement>(null);
  const gameThreeDetectiveRef = useRef<GameThreeDetectiveRef>(null);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [state, setState] = useState(GameState.ROUND1);
  const [round, setRound] = useState(0);
  const [running, setRunning] = useState(true);
  const [videoRefs, setVideoRefs] = useState({
    main: { ref: mainVideoRef, video: VIDEOS[round][0], loaded: false },
    left: { ref: leftVideoRef, video: VIDEOS[round][1], loaded: false },
    right: { ref: rightVideoRef, video: VIDEOS[round][2], loaded: false },
  });

  const allVideosLoaded = useMemo(() => Object.values(videoRefs).every(({ loaded }) => loaded), [videoRefs]);

  const { time, start, pause, reset, status } = useTimer();

  const onVideoLoad = useCallback((position: string) => {
    setVideoRefs(prev => ({ ...prev, [position]: { ...prev[position], loaded: true } }));
  }, []);

  const shouldBeRunning = useMemo(() => time < VIDEOS_RUNNING_TIME, [time]);

  useEffect(() => {
    if (!shouldBeRunning) {
      setRunning(prev => {
        if (prev) {
          gameThreeDetectiveRef.current.startQuiz();
        }
        return false;
      });
    }
  }, [shouldBeRunning]);

  const onOverlayToggle = useCallback((open: boolean) => {
    setIsChatOpen(open);
  }, []);

  useEffect(() => {
    isChatOpen ? AudioManager.instance.playSound(sfx.tv_ambient, true, true, 0.8) : AudioManager.instance.stopSound();
  }, [isChatOpen]);

  useEffect(() => {
    if (allVideosLoaded) {
      setRunning(shouldBeRunning && !isChatOpen && !isMenuOpen);
    }
  }, [allVideosLoaded, isChatOpen, isMenuOpen, shouldBeRunning]);

  useEffect(() => {
    setHistory(`${PageState.GAME_THREE}.${GameState.ROUND1}`);
  }, [setHistory]);

  useEffect(() => {
    setHeader({
      isWhite: true,
      onMenuOpen: () => AudioManager.instance.stopSound(),
      onMenuClose: () => {
        isChatOpen
          ? AudioManager.instance.playSound(sfx.tv_ambient, true, true, 0.8)
          : AudioManager.instance.stopSound();
      },
      children: (
        <GameThreeDetective
          gameState={state}
          setGameState={setState}
          onComplete={onComplete}
          onToggle={onOverlayToggle}
          detectiveVideoRef={detectiveVideoRef}
          ref={gameThreeDetectiveRef}
        />
      ),
    });
  }, [detectiveVideoRef, onOverlayToggle, onComplete, setHeader, state, isChatOpen]);

  useEffect(() => {
    setRound(prev => {
      switch (state) {
        case GameState.ROUND1:
          return 0;
        case GameState.ROUND2:
          return 1;
        case GameState.ROUND3:
          return 2;
        default:
          return prev;
      }
    });
  }, [state]);

  useEffect(() => {
    setVideoRefs({
      main: { ref: mainVideoRef, video: VIDEOS[round][0], loaded: false },
      left: { ref: leftVideoRef, video: VIDEOS[round][1], loaded: false },
      right: { ref: rightVideoRef, video: VIDEOS[round][2], loaded: false },
    });
    reset();
  }, [reset, round]);

  useEffect(() => {
    Object.values(videoRefs).map(({ ref, loaded }) => {
      if (loaded) {
        if (running) {
          ref?.current?.play();
        } else {
          ref?.current?.pause();
        }
      }
    });
  }, [running, videoRefs]);

  useEffect(() => {
    if (running) {
      start();
    } else {
      pause();
    }
  }, [pause, running, start]);

  return (
    <Styled.Wrapper>
      <Styled.CamerasWrapper>
        <Styled.Videos>
          {Object.entries(videoRefs).map(([position, { ref, video }]) => (
            <div className={`square ${position}`} key={position}>
              <Styled.VideoWrapper>
                <GameThreeVideo
                  ref={ref}
                  videoSrc={video}
                  onLoad={() => {
                    onVideoLoad(position);
                  }}
                />
              </Styled.VideoWrapper>
            </div>
          ))}
        </Styled.Videos>
      </Styled.CamerasWrapper>
      <GameThreeIndicators round={round} />
    </Styled.Wrapper>
  );
};

GameThreeVideoSet.displayName = 'GameThreeVideoSet';

export default memo(GameThreeVideoSet);
