import * as React from 'react';

import SVGIconBase from './SVGIconBase';
import { colors } from 'styles/vars';

const CloseIcon = () => {
  const color = colors.white;
  const width = 24;
  const height = 24;

  return (
    <SVGIconBase width={width} height={height}>
      <path d="M1.92896 2.14209L16.0711 16.2842" stroke={color} strokeWidth="3" />
      <path d="M1.92896 16.1421L16.0711 1.99995" stroke={color} strokeWidth="3" />
    </SVGIconBase>
  );
};

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
