import React, { ReactElement } from 'react';
import * as Styled from './ShareButton.styled';
import { ShareType } from 'constants/enum';
import { BooleanValueNode } from 'graphql';
import ShareService from 'services/share.service';

interface ShareButtonProps {
  type: ShareType;
  revertColor: boolean;
  icon: ReactElement;
}

export const ShareButton: React.FC<ShareButtonProps> = ({ type, revertColor, icon }) => {
  const getValue = name => {
    const max = Object.keys(ShareType).filter(x => x !== name);
    return max;
  };

  return (
    <Styled.Wrapper onClick={() => ShareService.instance.share(type)} revertColor={revertColor}>
      <div className="container">
        <div className="icon">{icon}</div>
        <span className="title">{type}</span>
      </div>
    </Styled.Wrapper>
  );
};
