import styled from 'styled-components';

import { px, rect, vh, fullHeight } from 'styles/mixins';
import { mediaHeightMediumMobile, mediaHeightLargeMobile } from 'styles/responsive';
import { colors } from 'styles/vars';

export const Wrapper = styled.div`
  ${fullHeight};
  position: relative;
  background: url(${require('assets/images/game3/background.jpg')}) no-repeat center bottom;
  background-size: cover;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CamerasWrapper = styled.div`
  padding: ${px(64)} 0 ${px(24)};
  position: relative;
  max-width: 100vw;
  max-height: ${vh(100)};

  ${mediaHeightMediumMobile(`font-size: ${px(60)}`)}
  ${mediaHeightLargeMobile(`font-size: ${px(66)}`)}
`;

export const Videos = styled.div`
  position: relative;
  width: min(100vw, calc((${vh(100)} - ${px(180)}) * 2 / 3));
  max-height: 100%;
  max-height: calc(${vh(100)} - ${px(180)});

  display: grid;
  grid-template-areas:
    'a a'
    'b c';

  .square {
    aspect-ratio: 1/1;
    max-width: 100%;
    position: relative;
    mask: url('/video-frame-mask.svg');
    mask-size: 100% 100%;
  }

  .main {
    grid-area: a;
  }

  .left {
    grid-area: b;
  }

  .right {
    grid-area: c;
  }
`;

const Square = styled.div`
  aspect-ratio: 1/1;

  max-width: 100%;
  position: relative;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const VideoOne = styled(Square)`
  grid-area: a;
`;

export const VideoTwo = styled(Square)`
  grid-area: b;
`;

export const VideoThree = styled(Square)`
  grid-area: c;
`;
