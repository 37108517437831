import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import * as Styled from './GameTwoContainer.styled';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';

import GameTwoElement from 'components/GameTwoElement';
import { vw } from 'utils/dom';
import { findIndex } from './utils';
import { moveArray, shuffleArray } from 'utils/array';

import SvgRotate from 'svgs/rotate.svg';

export interface Position {
  left: number;
  width: number;
}

interface Props {
  onComplete?: () => void;
  type: 'game' | 'animation';
  updateUVBounds?: (rect) => void;
  isFinished?: boolean;
  state?: number;
  scaleRatio?: number;
}
export type BoundsType = {
  top: number;
  left: number;
  bottom: number;
  right: number;
  mx: number;
  my: number;
};

export const GameTwoContainer: React.FC<Props> = ({
  onComplete,
  state,
  type,
  isFinished,
  updateUVBounds,
  scaleRatio = 1,
}) => {
  const wrapperRef = useRef(null);
  const [bounds, setBounds] = useState<BoundsType>({ top: 0, left: 0, bottom: 0, right: 0, mx: 0, my: 0 });
  const [editMode, setEditMode] = useState(false);
  const [locked, setLocked] = useState(false);

  const initialStripes = useRef([
    {
      id: 0,
      w: 16.9 * scaleRatio,
      mx: 0,
      baseX: vw(15.5 * 5) * scaleRatio,
      rotation: Math.random() > 0.5 ? 180 : 0,
      baseY: (-20 + Math.random() * 40) * scaleRatio,
      img: require('assets/images/game2/stripe1.png'),
      rotatable: false,
      selectable: false,
    },
    {
      id: 1,
      w: 18.3 * scaleRatio,
      mx: 2 * scaleRatio,
      baseX: vw(15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      rotation: Math.random() > 0.5 ? 180 : 0,
      img: require('assets/images/game2/stripe2.png'),
      rotatable: false,
      selectable: false,
    },
    {
      id: 2,
      w: 18.7 * scaleRatio,
      mx: 4 * scaleRatio,
      baseX: vw(15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      rotation: Math.random() > 0.5 ? 180 : 0,
      img: require('assets/images/game2/stripe3.png'),
      rotatable: false,
      selectable: false,
    },
    {
      id: 3,
      w: 17 * scaleRatio,
      mx: 2 * scaleRatio,
      baseX: vw(-15.5 * 3) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      rotation: Math.random() > 0.5 ? 180 : 0,
      img: require('assets/images/game2/stripe4.png'),
      rotatable: false,
      selectable: false,
    },
    {
      id: 4,
      w: 18.3 * scaleRatio,
      mx: 3 * scaleRatio,
      baseX: vw(-15.5 * 2) * scaleRatio,
      baseY: -20 + Math.random() * 40,
      rotation: Math.random() > 0.5 ? 180 : 0,
      img: require('assets/images/game2/stripe5.png'),
      rotatable: false,
      selectable: false,
    },
    {
      id: 5,
      w: 18.7 * scaleRatio,
      mx: 3 * scaleRatio,
      baseX: vw(-15.5 * 4) * scaleRatio,
      baseY: -15 + Math.random() * 30,
      rotation: Math.random() > 0.5 ? 180 : 0,
      img: require('assets/images/game2/stripe6.png'),
      rotatable: false,
      selectable: false,
    },
  ]).current;

  const [items, setItems] = useState(shuffleArray(initialStripes));
  const [activeItem, setActiveItem] = useState(-1);

  const positions = useRef<Position[]>([]).current;
  const setPosition = (i: number, offset: Position) => (positions[i] = offset);

  const moveItem = (i: number, dragOffset: number) => {
    const targetIndex = findIndex(i, dragOffset, positions);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (targetIndex !== i) setItems(moveArray(items, i, targetIndex));
  };

  const setActive = useCallback(
    index => {
      const temp = [...items];
      if (index >= 0) {
        temp.map((el, i) => {
          if (i === index) {
            el.rotatable = true;
            el.selectable = false;
          } else {
            el.rotatable = false;
            el.selectable = true;
          }
        });
        setEditMode(true);
      } else {
        temp.map((el, i) => {
          el.rotatable = false;
          el.selectable = false;
        });
        setEditMode(false);
      }
      setItems(temp);
      setActiveItem(index);
    },
    [items]
  );

  const deactivateElement = useCallback(() => {
    setActive(-1);
  }, [setActive]);

  const onRotate = () => {
    const temp = [...items];
    const rot = temp[activeItem].rotation;
    AudioManager.instance.playSound(sfx[`page${Math.ceil(Math.random() * 5)}`]);

    temp.map((el, i) => {
      i === activeItem ? (el.rotation = rot === 0 ? 180 : 0) : el.rotation;
      el.rotatable = false;
      el.selectable = false;
    });

    setEditMode(false);

    setItems(temp);
  };

  const swapActive = withId => {
    if (withId === -1) return;
    const temp = [...items];

    temp.map(el => {
      el.rotatable = false;
      el.selectable = false;
    });
    [temp[withId], temp[activeItem]] = [temp[activeItem], temp[withId]];
    setItems(temp);
    setActiveItem(withId);
    setEditMode(false);
  };

  const checkComplete = useCallback(() => {
    const isFinished = items.every((el, i) => el.id === i && el.rotation === 0);
    if (isFinished) {
      setLocked(true);
      setTimeout(() => {
        onComplete();
      }, 1000);
    }
  }, [items, onComplete]);

  useEffect(() => {
    checkComplete();
  }, [checkComplete, items]);

  useEffect(() => {
    updateUVBounds({ top: bounds.top, left: bounds.left, bottom: bounds.bottom, right: bounds.right });
  }, [bounds, updateUVBounds]);

  useEffect(() => {
    if (isFinished) {
      const temp = [...initialStripes];
      temp.map(el => (el.rotation = 0));
      setItems(temp);
    }
  }, [initialStripes, isFinished]);

  return (
    <>
      <Styled.Wrapper key={'stripes-wrapper'} ref={wrapperRef} vScale={scaleRatio} active={!locked}>
        {items.map((item, i) => (
          <GameTwoElement
            key={item.img}
            margin={item.mx}
            rotation={item.rotation}
            rotatable={item.rotatable}
            selectable={item.selectable}
            vScale={scaleRatio}
            onActive={setActive}
            setBounds={setBounds}
            i={i}
            id={item.id}
            width={item.w}
            setPosition={setPosition}
            moveItem={moveItem}
            bgSrc={item.img}
            isFinished={isFinished}
          />
        ))}
        <Styled.Background active={editMode} onClick={deactivateElement} animate={{ opacity: editMode ? 0.6 : 0 }} />
      </Styled.Wrapper>
      <Styled.DotWrapper key={'dot-wrapper'} ref={wrapperRef} vScale={scaleRatio}>
        {items.map((item, i) => (
          <>
            <Styled.Dot
              onClick={() => swapActive(i)}
              margin={item.mx}
              vScale={scaleRatio}
              width={item.w}
              active={item.selectable}
              animate={{ opacity: item.selectable ? 1 : 0 }}
            />
          </>
        ))}
      </Styled.DotWrapper>
      <Styled.ButtonWrapper active={editMode} animate={{ opacity: editMode ? 1 : 0 }}>
        <Styled.RotateButton onClick={onRotate}>
          <SvgRotate />
          <p>Rotate</p>
        </Styled.RotateButton>
      </Styled.ButtonWrapper>
    </>
  );
};

export default memo(GameTwoContainer);
