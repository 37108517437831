import React from 'react';
import * as Styled from './Popup.styles';
import { Button } from 'components/Button';
import { colors } from 'styles/vars';

interface PopupProps {
  open: boolean;
  handleOpenPopup: () => void;
  title: string;
  description: string;
  acceptText: string;
  rejectText: string;
}

export const Popup: React.FC<PopupProps> = ({ open, handleOpenPopup, title, description, acceptText, rejectText }) => {
  return (
    <Styled.Wrapper open={open}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      <Button label={acceptText} onClick={handleOpenPopup} color={colors.white} bgColor={colors.shark} />
      <Button label={rejectText} onClick={handleOpenPopup} color={colors.shark} isBorder />
    </Styled.Wrapper>
  );
};
