import React, { memo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTimer } from 'use-timer';
import { useTranslation } from 'react-i18next';

import * as Styled from './FlipTimer.styled';

interface Props {
  uvLight?: boolean;
  running?: boolean;
  startTime?: number;
  updateTime?: (time: number) => void;
}

export const FlipTimer: React.FC<Props> = ({ uvLight, startTime = 0, running = true, updateTime }) => {
  const { t } = useTranslation();
  const { time, start, pause } = useTimer();

  const [state, setState] = useState({
    minutesOne: 0,
    minutesTwo: 0,
    secondsOne: 0,
    secondsTwo: 0,
    secondsOneShuffle: true,
    secondsTwoShuffle: true,
    minutesOneShuffle: true,
    minutesTwoShuffle: true,
  });

  useEffect(() => {
    const date = new Date(0);
    date.setSeconds(time + startTime);
    if (running) {
      start();
    }
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const secondsOne = seconds < 10 ? 0 : parseInt(seconds.toString()[0]);
    const secondsTwo = seconds < 10 ? parseInt(seconds.toString()[0]) : parseInt(seconds.toString()[1]);
    const minutesOne = minutes < 10 ? 0 : parseInt(minutes.toString()[0]);
    const minutesTwo = minutes < 10 ? parseInt(minutes.toString()[0]) : parseInt(minutes.toString()[1]);

    setState(prev => {
      return {
        secondsOne,
        secondsTwo,
        minutesOne,
        minutesTwo,
        secondsOneShuffle: secondsOne !== prev.secondsOne ? !prev.secondsOneShuffle : prev.secondsOneShuffle,
        secondsTwoShuffle: secondsTwo !== prev.secondsTwo ? !prev.secondsTwoShuffle : prev.secondsTwoShuffle,
        minutesOneShuffle: minutesOne !== prev.minutesOne ? !prev.minutesOneShuffle : prev.minutesOneShuffle,
        minutesTwoShuffle: minutesTwo !== prev.minutesTwo ? !prev.minutesTwoShuffle : prev.minutesTwoShuffle,
      };
    });

    return () => {};
  }, [startTime, running, start, time]);

  useEffect(() => {
    if (!running && updateTime) {
      updateTime(time + startTime);
    }
  }, [running, startTime, time, updateTime]);

  useEffect(() => {
    if (running) {
      start();
    } else {
      pause();
    }
  }, [pause, running, start]);

  return (
    <Styled.Wrapper>
      <div className={'flipClock'}>
        <FlipUnitContainer digit={state.minutesOne} shuffle={state.minutesOneShuffle} />
        <FlipUnitContainer digit={state.minutesTwo} shuffle={state.minutesTwoShuffle} />
        <span className="dots">:</span>
        <FlipUnitContainer digit={state.secondsOne} shuffle={state.secondsOneShuffle} />
        <FlipUnitContainer digit={state.secondsTwo} shuffle={state.secondsTwoShuffle} />
      </div>
    </Styled.Wrapper>
  );
};

const StaticCard = ({ position, digit }) => {
  return (
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
};

const AnimatedCard = ({ animation, digit }) => {
  const variants = {
    fold: {
      rotateX: ['0deg', '-180deg'],
      transition: {
        type: 'tween',
        ease: 'backOut',
        duration: 0.6,
      },
    },
    unfold: {
      rotateX: ['180deg', '0deg'],
      transition: {
        type: 'tween',
        ease: 'backOut',
        duration: 0.6,
      },
    },
  };

  return (
    <motion.div variants={variants} animate={animation} className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </motion.div>
  );
};

const FlipUnitContainer = ({ digit, shuffle }) => {
  let previousDigit: string | number = digit - 1;

  previousDigit = previousDigit === -1 ? 9 : previousDigit;

  const digit1 = shuffle ? previousDigit : digit;
  const digit2 = !shuffle ? previousDigit : digit;

  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return (
    <div className={'flipUnitContainer'}>
      <StaticCard position={'upperCard'} digit={digit} />
      <StaticCard position={'lowerCard'} digit={previousDigit} />
      <AnimatedCard digit={digit1} animation={animation1} />
      <AnimatedCard digit={digit2} animation={animation2} />
    </div>
  );
};

export default memo(FlipTimer);
