import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { box, flexCenter, px, rect, vh } from 'styles/mixins';

export const Wrapper = styled(motion.div)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  ${flexCenter('column')}
  overflow: hidden;
  color: ${colors.white};
  background: ${rgba(colors.black, 0.6)};
  opacity: 0;
  z-index: 10;

  > .video {
    ${rect('absolute', 0, 0, '100%', '100%')}
  }

  > .skip {
    position: absolute;
    bottom: 2.5rem;
  }
`;

export const PlayVideo = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    ${box(px(20))}
    margin: 0 ${px(10)} 0 0;
  }
`;
