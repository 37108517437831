import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors } from 'styles/vars';
import { rect, px, vh, absoluteCenter, box, fullHeight } from 'styles/mixins';
import { fontChannel4Horseferry, fontChannel4Chadwick, typography16Text } from 'styles/typography';

import bgImage from 'assets/images/dirtyBackground.png';
import moreImage from 'assets/images/coming-soon-other-cases.png';
import solvedImage from 'assets/images/coming-soon-solved.png';

export const Wrapper = styled(motion.div)`
  ${fullHeight};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${bgImage});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  > .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    text-transform: uppercase;

    .title {
      margin: 0 0 ${px(47)} 0;
    }
    .image-wrapper {
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      margin: ${px(24)} 0 ${px(24)} 0;

      &.more {
        width: 92%;
        background-image: url(${moreImage});
      }

      &.solved {
        width: 100%;
        background-image: url(${solvedImage});
        padding: 0 1rem;
        margin: 0 0 3rem 0;

        h1 {
          font-size: ${px(75)};
        }
      }

      h1 {
        ${fontChannel4Horseferry(71, 700, 0.84)};
        letter-spacing: -0.06em;
        text-align: left;
        color: ${colors.blackBeluga};
        padding-top: ${px(160)};
        margin-bottom: ${px(20)};

        span {
          display: block;
          text-align: right;
        }
      }
    }

    .description {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      color: ${colors.blackPearl};
      letter-spacing: 0.075rem;
      width: 75%;
      margin-bottom: 3rem;
    }

    p {
      color: ${colors.blackPearl};

      &.more {
        ${fontChannel4Horseferry(20, 700, 1.1)};
        width: 86%;
      }

      &.solved {
        ${fontChannel4Horseferry(18, 500, 1.1)};
        letter-spacing: 0.1rem;
        width: 82%;
        margin-bottom: 0;
      }
    }

    .button-shere {
      margin-bottom: 8.7rem;

      button {
        ${fontChannel4Horseferry(18, 700, 1.67)};
        background-color: ${colors.blue};
        color: ${colors.white};
        padding: 0.8rem 5.5rem;
      }
    }

    .button-wrapper {
      margin-bottom: 4.5rem;

      button {
        padding: 1.6rem 5.3rem;

        span {
          ${fontChannel4Horseferry(24, 700, 1.1)};
          letter-spacing: -0.05rem;
        }
      }
    }
  }
  > .cta-wrapper {
    margin-bottom: ${px(45)};
  }

  > .uv-wrapper {
    ${rect('absolute', 0, 0, '100%', '100%')};

    .uv {
      ${rect('absolute', 0, 0, '100%', '100%')};
      background: url(${require('assets/images/coming-soon-uv.png')});
      background-size: cover;
      background-position: center center;
      opacity: 0.8;
      z-index: 2;

      > * {
        mix-blend-mode: hard-light;
      }

      .handwrited-text {
        ${rect('absolute', 0, 0, '100%', '100%')};
        background: url(${require('assets/images/coming-soon-handwirted-text.png')});
        background-position: 50% 40%;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .youtube-button-wrapper {
      ${absoluteCenter()}
      top: 40%;
      margin: ${px(220)} 0 0 0;
      z-index: 3;

      button {
        ${fontChannel4Horseferry(24, 700, 1.1)};
        background-color: ${colors.uv};
        color: ${colors.darkBlue};
        text-transform: uppercase;
        display: flex;
        padding: 0;

        a {
          display: flex;
          align-items: center;
          padding: 1.4rem 6.8rem;
        }

        svg {
          margin-right: 1.5rem;
        }
      }
    }
  }
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};

export const YoutubeMessage = styled.div`
  > .thumb {
    ${box(px(242), px(147))}
    object-fit: cover;
    margin: 0 0 ${px(8)};
  }

  > .dotcom {
    ${fontChannel4Chadwick(10, 500, 1.1)}
    opacity: 0.6;
  }

  > .label {
    ${typography16Text}
  }
`;
