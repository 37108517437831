import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Main } from './scenes/Main';
import parse from 'html-react-parser';
import { sfx } from 'constants/assets';
import AudioManager from 'services/audiomanager.service';
import { RoomEnvironment } from './utils/RoomEnvironment';
import { SharePopup } from 'components/SharePopup';
import AdobeAnalytics from 'utils/adobeAnalytics';
import ShareService from 'services/share.service';
import * as Styled from './Badge.styled';

interface Props {
  visible: boolean;
  uvLight?: boolean;
  onExit?: () => void;
  onComplete?: () => void;
}

export const Badge: React.FC<Props> = ({ visible, uvLight = false, onComplete }) => {
  const { t } = useTranslation();

  const camera = useRef(null);
  const renderer = useRef<THREE.WebGLRenderer>();
  const refWrapper = useRef<HTMLDivElement>();
  const [modelLoaded, setModelLoaded] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);

  const handleShareClick = useCallback(() => {
    setShareVisible(true);
  }, []);

  const handleCtaClick = () => {
    console.log('cta click');
  };
  const onModelLoaded = () => {
    setModelLoaded(true);
  };
  const onShareClick = useCallback(() => {
    ShareService.instance.setGameData(3, { badge: true });
    setShareVisible(true);
  }, []);

  const onCloseShareClick = useCallback(() => {
    setShareVisible(false);
  }, []);

  useEffect(() => {
    if (!visible) return undefined;
    camera.current = new THREE.PerspectiveCamera();
    renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.current.outputEncoding = THREE.sRGBEncoding;
    renderer.current.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.current.toneMappingExposure = 1.0;

    camera.current.position.set(0, 0, 2);
    const envScene = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
    pmremGenerator.compileCubemapShader();

    const generatedCubeRenderTarget = pmremGenerator.fromScene(envScene);
    const scene = new Main(camera.current, renderer.current);

    scene.environment = generatedCubeRenderTarget.texture;

    scene.addEventListener('loaded', onModelLoaded);

    if (refWrapper.current && !renderer.current.domElement.parentNode) {
      refWrapper.current.appendChild(renderer.current.domElement);
      renderer.current.setSize(renderer.current.domElement.clientWidth, renderer.current.domElement.clientHeight);
    }

    const onResize = () => {
      const { width, height } = refWrapper.current.getBoundingClientRect();
      renderer.current.setPixelRatio(window.devicePixelRatio);
      renderer.current.setSize(width, height);
      camera.current.aspect = width / height;
      camera.current.updateProjectionMatrix();
    };
    window.addEventListener('resize', onResize);
    if (visible) onResize();

    return () => {
      renderer.current.domElement.remove();
      scene.removeEventListener('loaded', onModelLoaded);
      scene.dispose();
      window.removeEventListener('resize', onResize);
    };
  }, [visible]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('games_completed_badge', 'pageview', 'game3');
    AudioManager.instance.playSound(sfx.success_screen);
  }, []);

  return (
    <AnimatePresence>
      {visible ? (
        <Styled.Wrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <SharePopup visible={shareVisible} closeFunc={onCloseShareClick} zIndex={11} />
          <div className="badge" ref={refWrapper} />
          <div className="landing">
            <div className="top">
              <h1 className="title">
                <div>{t('badge.title')}</div>
                <div>{t('badge.subtitle')}</div>
              </h1>
              {!modelLoaded && <p className="loading">{t('badge.loading')}</p>}
            </div>
            <div className="bottom">
              <p className="description">{parse(t('badge.description'))}</p>
              <div className={'button-share'}>
                <button onClick={onShareClick}>{t('badge.shareCta')}</button>
              </div>
              <p className="button-unlock" onClick={onComplete}>
                {t('badge.unlockCta')}
              </p>
            </div>
          </div>
        </Styled.Wrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default memo(Badge);
