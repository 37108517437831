import * as React from 'react';

import SVGIconBase from './SVGIconBase';
import { colors } from 'styles/vars';

const HandsetIcon = () => {
  const color = colors.black;
  const width = 50;
  const height = 50;

  return (
    <SVGIconBase color={color} width={width} height={height}>
      <path
        d="M33.9312 19.3484C28.1441 17.4646 21.8691 17.4782 16.0685 19.3485L16.0685 25.3117C16.0685 26.0436 15.6484 26.7077 15.0114 27.0194C12.992 28.0358 11.081 29.4047 9.40047 31.0852C8.65506 31.8307 7.4353 31.8307 6.6899 31.0852L1.95994 26.3553C1.21453 25.6099 1.21453 24.3901 1.95994 23.6447C14.6861 10.9186 35.3136 10.9186 48.0397 23.6447C48.7851 24.3901 48.7851 25.6099 48.0397 26.3553L43.2962 31.0988C42.5508 31.8442 41.3311 31.8442 40.5856 31.0988C38.8915 29.4047 36.9941 28.0494 34.9748 27.0329C34.3513 26.7077 33.9312 26.0707 33.9312 25.3117L33.9312 19.3484Z"
        fill="white"
      />
    </SVGIconBase>
  );
};

HandsetIcon.displayName = 'HandsetIcon';

export default HandsetIcon;
