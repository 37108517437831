import parse from 'html-react-parser';
import { TFunction } from 'i18next';

import { Progress } from '../../context/progress';

export const CONFIG = (t: TFunction, progress: Progress) => {
  return [
    {
      phase: progress?.gameThree ? 2 : 1,
      label: `${parse(t('landingPageCases.items.2.title'))}`,
      subtitle: `${parse(t('landingPageCases.items.2.subtitle'))}`,
      description: `${parse(t('landingPageCases.items.2.description'))}`,
      imgSrc: require('assets/images/case3-cover.jpg'),
    },
    {
      phase: progress?.gameTwo ? 2 : 1,
      label: `${parse(t('landingPageCases.items.1.title'))}`,
      subtitle: `${parse(t('landingPageCases.items.1.subtitle'))}`,
      description: `${parse(t('landingPageCases.items.1.description'))}`,
      imgSrc: require('assets/images/case2-cover.jpg'),
    },
    {
      phase: progress?.gameOne ? 2 : 1,
      label: `${parse(t('landingPageCases.items.0.title'))}`,
      subtitle: `${parse(t('landingPageCases.items.0.subtitle'))}`,
      description: `${parse(t('landingPageCases.items.0.description'))}`,
      imgSrc: require('assets/images/case1-cover.jpg'),
    },
  ];
};
