import gsap from 'gsap';

class LockBodyScroll {
  scrollPosY = 0;
  isLocked = false;
  observer = null;

  updateBodyStyle = () => {
    if (this.isLocked) {
      document.body.style.position = 'fixed';
      document.body.style.overflowY = 'auto';
      document.body.style.marginTop = `-${this.scrollPosY}px`;
    }
  };

  lock = (top = null, duration = 0, ease = 'expo.inOut') => {
    if (!this.isLocked) {
      this.isLocked = true;
      this.scrollPosY = window.pageYOffset || document.body.scrollTop;
      this.updateBodyStyle();
      if (top !== null) {
        this.scrollPosY = top;
        gsap.to(document.body, {
          marginTop: `-${top}px`,
          duration,
          ease,
          onComplete: () => {
            this.observer = new MutationObserver(() => this.updateBodyStyle());
            this.observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });
          },
        });
      } else {
        this.observer = new MutationObserver(() => this.updateBodyStyle());
        this.observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });
      }
    }
  };

  unlock = (skipPositionRestore = false) => {
    if (this.isLocked) {
      this.isLocked = false;
      this.observer?.disconnect();
      document.body.style.position = '';
      document.body.style.overflowY = '';
      document.body.style.marginTop = '';
      if (!skipPositionRestore) {
        gsap.to(window, {
          scrollTo: { x: 0, y: this.scrollPosY, autoKill: false },
          duration: 0,
          ease: 'power4.inOut',
        });
      }
    }
  };
}

export default new LockBodyScroll();
