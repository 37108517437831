import styled from 'styled-components';
import { rgba } from 'polished';

import { colors } from 'styles/vars';
import { typography14Caps } from 'styles/typography';
import { box, flexCenter, px, rect } from 'styles/mixins';

export const Wrapper = styled.div`
  ${flexCenter()}

  > .duration {
    ${typography14Caps}
    margin: 0 ${px(8)} 0 0;
  }

  > .toggle {
    ${box(px(40))}
    position: relative;

    .circle,
    .progress {
      ${rect('absolute', 0, 0, '100%', '100%')}
      transform: rotate(-90deg);
      stroke-width: ${px(4)};
      fill: none;
    }

    .circle {
      stroke: ${rgba(colors.black, 0.2)};
      opacity: 0.4;
    }

    .progress {
      stroke: ${colors.yellow};
    }

    .play,
    .pause {
      ${box(px(40))}
      ${flexCenter()}
      border-radius: 50%;

      svg {
        ${box(px(12))}
      }
    }

    .play {
      background: ${colors.yellow};
      color: ${colors.black};

      svg {
        margin: 0 0 0 ${px(1)};
      }
    }
  }
`;
