import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageProps } from 'types/page';
import AdobeAnalytics from 'utils/adobeAnalytics';

import { sfx } from 'constants/assets';
import AudioManager from 'services/audiomanager.service';

import { HeaderContext, HeaderContextType } from 'context/header';

import DetectiveChat from 'components/DetectiveChat';
import * as Styled from './GameThreeFingerprints.styled';
import CONFIG from './config';
import ScannerAnimation from './ScannerAnimation/ScannerAnimation';
import { isSocialGestureMinimizedBrowser } from 'utils/platform';

enum State {
  INTRO,
  SCANN,
}

interface GameThreeFingerprintsProps extends PageProps {
  onComplete: () => void;
}

const GameThreeFingerprints: React.FC<GameThreeFingerprintsProps> = ({ onComplete }) => {
  const { t } = useTranslation();

  const initialFingerprintsData = [
    { isCorrect: true, isChecked: false, dirty: false },
    { isCorrect: false, isChecked: false, dirty: false },
    { isCorrect: false, isChecked: false, dirty: false },
    { isCorrect: true, isChecked: false, dirty: false },
    { isCorrect: false, isChecked: false, dirty: false },
    { isCorrect: true, isChecked: false, dirty: false },
  ];

  const [state, setState] = useState(State.INTRO);
  const { setHeader } = useContext<HeaderContextType>(HeaderContext);
  const [fingerprints, setFingerprints] = useState(initialFingerprintsData);
  const [isThreeFingerprints, setIsThreeFingerprints] = useState<boolean>(false);
  const [labelActive, setLabelActive] = useState<string>('');

  const handleClick = (index, label) => {
    const newFingerprints = [...fingerprints];
    newFingerprints[index].isChecked = !fingerprints[index].isChecked;
    newFingerprints[index].dirty = true;

    setFingerprints([...newFingerprints]);
    setLabelActive(label);
  };

  const resetFingerPrints = useCallback(() => {
    const reset = fingerprints.map(item => {
      return {
        ...item,
        isChecked: false,
      };
    });
    setFingerprints(reset);
  }, [fingerprints]);

  useEffect(() => {
    setHeader({
      isWhite: true,
      children: <DetectiveChat showArrows={isSocialGestureMinimizedBrowser()} />,
    });
  }, [setHeader]);

  useEffect(() => {
    if (labelActive.length !== 0) {
      setTimeout(() => {
        setLabelActive('');
      }, 1000);
    }
  }, [labelActive]);

  useEffect(() => {
    const ifCheckedThreeFingerprints = fingerprints.filter(item => item.isChecked);
    const ifCheckedCorrectFingerprints = fingerprints.filter(item => item.isChecked && item.isCorrect);

    if (ifCheckedCorrectFingerprints.length === 3) {
      setTimeout(() => {
        setState(State.SCANN);
      }, 1000);
      return;
    }

    if (ifCheckedThreeFingerprints.length === 3) {
      setIsThreeFingerprints(true);
      setTimeout(() => {
        resetFingerPrints();
      }, 1000);
    } else {
      setIsThreeFingerprints(false);
    }
  }, [fingerprints, resetFingerPrints]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game_3_fingerprints', 'pageview', 'game3');
  }, []);

  useEffect(() => {
    if (state === State.SCANN) {
      AudioManager.instance.playSound(sfx.uv_scanner);
    }
  }, [state]);

  return (
    <Styled.Wrapper transition={{ duration: 0.1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      {state === State.INTRO && (
        <>
          <div className="main-fingerprint">
            <div className="fingerprint-wrapper">
              {isThreeFingerprints ? (
                <img
                  className="fingerprint fingerprint-wrong"
                  src={require('assets/images/game-3-fingerprint-main-red.png')}
                />
              ) : (
                <img
                  className="fingerprint fingerprint-wrong"
                  src={require('assets/images/game-3-fingerprint-main-grey.png')}
                />
              )}

              {CONFIG().map(({ label, img }, index) => (
                <div
                  key={label}
                  className={`fingerprint fingerprint-${index} ${fingerprints[index].isChecked ? 'active' : ''}`}
                >
                  <img src={img} alt={label} />
                  <p className={labelActive === label ? 'show' : ''}>{label}</p>
                </div>
              ))}
            </div>
          </div>
          <p className="title">{t('fingerprints.title')}</p>
          <div className="content">
            {CONFIG().map(({ label, img, imgWrong }, index) => {
              const isWrong = !fingerprints[index].isCorrect && fingerprints[index].dirty;
              return (
                <div
                  key={label}
                  aria-label={`Partial fingerprints ${index + 1}`}
                  className={`fingerprint-box fingerprint-box-${index}
            ${fingerprints[index].isChecked ? 'active' : ''}
            ${isWrong ? 'is-wrong' : ''}`}
                  onClick={() => handleClick(index, label)}
                >
                  <p>{label}</p>
                  <img src={!isWrong ? img : imgWrong} alt={label} />
                </div>
              );
            })}
          </div>
        </>
      )}
      {state === State.SCANN && (
        <div className="scanner-wrapper">
          <ScannerAnimation onComplete={onComplete} />
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default memo(GameThreeFingerprints);
