import * as React from 'react';
import { colors } from 'styles/vars';

const RectangleIcon = ({ color, setRef, className }: { color?: string; setRef?: any; className?: any }) => {
  const newColor = color || colors.silver;
  const width = 18;
  const height = 149;

  return (
    <svg className={className} width={width} height={height} ref={setRef}>
      <rect x="1.34595" y="0.5" width="16" height="148" stroke="white" />
    </svg>
  );
};

RectangleIcon.displayName = 'RectangleIcon';

export default RectangleIcon;
