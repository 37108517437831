import React, { memo, useCallback, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import parse from 'html-react-parser';

import * as Styled from './GameOnePhonePicture.styled';

import { PhonePicture } from 'components/GameOnePhone/GameOnePhone';

import SvgClose from 'svgs/Close.svg';
interface Props {
  uvLight: boolean;
  picture: PhonePicture;
  setPicture: (p: PhonePicture) => void;
}

export const GameOnePhonePicture: React.FC<Props> = ({ uvLight, picture, setPicture }) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleCloseClick = useCallback(() => setPicture(null), [setPicture]);

  useEffect(() => {
    // Disable pull to refresh when an image is open
    const body = document.body as any;
    const prev = body.style.overscrollBehavior;
    if (picture) body.style.overscrollBehavior = 'contain';
    return () => {
      body.style.overscrollBehavior = prev;
    };
  }, [picture]);

  useEffect(() => {
    buttonRef?.current?.focus();
  }, []);

  return (
    <Styled.Wrapper
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      initial={{ opacity: 0, scale: 1.1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.1 }}
    >
      <button className="close" onClick={handleCloseClick} aria-label="Close picture" ref={buttonRef} tabIndex={0}>
        <SvgClose className="icon" />
        <span className="label">{parse(t('gameOne.phone.picture.close'))}</span>
      </button>
      <div className="date">{picture.date}</div>
      {uvLight ? <div className="uv" /> : null}
      <TransformWrapper
        centerOnInit
        disabled={uvLight}
        doubleClick={{ disabled: true }}
        zoomAnimation={{ animationType: 'easeOutCubic' }}
        velocityAnimation={{ animationType: 'easeOutCubic' }}
        alignmentAnimation={{ animationType: 'easeInOutCubic' }}
      >
        <TransformComponent
          wrapperStyle={{ width: '100%', height: '100%', zIndex: uvLight ? 1 : 'initial' }}
          contentStyle={{ width: '100%', height: '100%' }}
        >
          <div className="picture">
            <img className="img" src={picture.src} alt={picture.alt} />
            {uvLight ? (
              <div className="uv">
                {picture.srcUv ? <img className="img" src={picture.srcUv} alt={`${picture.alt} - uv layer`} /> : null}
              </div>
            ) : null}
          </div>
        </TransformComponent>
      </TransformWrapper>
    </Styled.Wrapper>
  );
};

export default memo(GameOnePhonePicture);
