import React, {
  memo,
  forwardRef,
  MutableRefObject,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import AdobeAnalytics from 'utils/adobeAnalytics';

import * as Styled from './GameThreeDetective.styled';

import { GameState } from 'components/GameThreeVideoSet/GameThreeVideoSet';
import { GameDetectiveVideoRef, PlayVideoMessage } from 'components/GameDetectiveVideo/GameDetectiveVideo';
import DetectiveChat, { DetectiveChatMessage, DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import { SharePopup } from 'components/SharePopup';
import { isDoubleTapEnabledBrowser, isSocialGestureMinimizedBrowser } from 'utils/platform';

enum QuizMode {
  HELP,
  ANSWER,
  SHARE,
}
interface Props {
  gameState: GameState;
  setGameState: React.Dispatch<React.SetStateAction<GameState>>;
  detectiveVideoRef: MutableRefObject<GameDetectiveVideoRef>;
  onComplete: (accuracy: number) => void;
  onToggle: (opened: boolean) => void;
}

export interface GameThreeDetectiveRef {
  addMessage: (message: DetectiveChatMessage) => void;
  startQuiz: () => void;
}

const GameThreeDetective = forwardRef<GameThreeDetectiveRef, Props>(
  ({ gameState, setGameState, detectiveVideoRef, onComplete, onToggle }, ref) => {
    const { t } = useTranslation();

    const [caseClosed, setCaseClosed] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);

    const [quizAnswers, setQuizAnswers] = useState([]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const lastMessageRef = useRef<DetectiveChatMessage>(null);
    const chatTimeoutRef = useRef(null);
    const detectiveChatRef = useRef<DetectiveChatRef>(null);

    const help = useMemo(() => t('gameThree.detective.help', { returnObjects: true }), [t]) as any;
    const quiz1 = useMemo(() => t('gameThree.detective.quiz1', { returnObjects: true }), [t]) as any[];
    const quiz2 = useMemo(() => t('gameThree.detective.quiz2', { returnObjects: true }), [t]) as any[];
    const quiz3 = useMemo(() => t('gameThree.detective.quiz3', { returnObjects: true }), [t]) as any[];
    const quizSet = useMemo(
      () => ({
        [GameState.ROUND1]: quiz1,
        [GameState.ROUND2]: quiz2,
        [GameState.ROUND3]: quiz3,
      }),
      [quiz1, quiz2, quiz3]
    );
    const [points, setPoints] = useState({
      [GameState.ROUND1]: -1,
      [GameState.ROUND2]: -1,
      [GameState.ROUND3]: -1,
    });
    const sumOfPoints = useMemo(() => Object.values(points).reduce((a, b) => (b < 0 ? a : a + b), 0), [points]);
    const [currentQuiz, setCurrentQuiz] = useState(quizSet[gameState]);
    const quizModeRef = useRef(QuizMode.HELP);

    const toggleChat = useCallback(() => {
      detectiveChatRef.current.toggleChat();
      detectiveChatRef.current.hideCta();
    }, []);

    const handleShareClick = useCallback(() => {
      detectiveChatRef.current.toggleChat();
      setShareVisible(true);
    }, []);

    const handleShareCloseClick = useCallback(() => setShareVisible(false), []);

    const addMessage = useCallback((message: DetectiveChatMessage, showNotification = true) => {
      detectiveChatRef.current.addMessage(message, showNotification);
      lastMessageRef.current = message;
    }, []);

    const startQuiz = useCallback(() => {
      addMessage({ text: t('gameThree.detective.readyToNote') }, true);

      const nextQuiz = quizSet[gameState];
      quizModeRef.current = QuizMode.ANSWER;
      setQuizAnswers([]);
      addMessage({
        text: nextQuiz[0].question,
        options: nextQuiz[0].options,
        correctOption: nextQuiz[0].correct,
      });
      setCurrentQuiz(nextQuiz);
    }, [addMessage, gameState, quizSet, t]);

    const handleCtaClick = useCallback(() => {
      if (caseClosed) {
        onComplete(sumOfPoints);
      } else {
        setGameState(prev => {
          switch (prev) {
            case GameState.ROUND1:
              return GameState.ROUND2;
            case GameState.ROUND2:
              return GameState.ROUND3;
            case GameState.ROUND3: {
              setPoints({ [GameState.ROUND1]: -1, [GameState.ROUND2]: -1, [GameState.ROUND3]: -1 });

              return GameState.ROUND1;
            }
            default:
              return prev;
          }
        });
        toggleChat();
      }
      setQuizAnswers([]);
    }, [caseClosed, onComplete, setGameState, sumOfPoints, toggleChat]);

    const onQuizAnswer = useCallback(
      answer => {
        switch (quizModeRef.current) {
          case QuizMode.ANSWER:
            setQuizAnswers([...quizAnswers, answer]);
            break;
          case QuizMode.SHARE:
            handleShareClick();
            break;
          default:
            if (answer > 1) {
              toggleChat();
              addMessage({ text: '', options: help.options });
            }
            break;
        }
      },
      [addMessage, handleShareClick, help.options, quizAnswers, toggleChat]
    );

    const handleYoutubeClick = useCallback(
      (e?) => {
        e?.preventDefault();
        // TODO add adobe analytics event
        window.open(t('comingSoon.youtubeLink'), '_blank');
      },
      [t]
    );

    useEffect(() => {
      if (quizAnswers.length) {
        if (quizAnswers.length === currentQuiz.length) {
          quizModeRef.current = QuizMode.HELP;
          clearTimeout(chatTimeoutRef.current);
          chatTimeoutRef.current = setTimeout(() => {
            const correctAnswers = detectiveChatRef.current.correctQuiz();
            if (points[gameState] < 0) {
              setPoints(prev => ({ ...prev, [gameState]: correctAnswers }));
            }
            clearTimeout(chatTimeoutRef.current);
            chatTimeoutRef.current = setTimeout(() => {
              if (gameState === GameState.ROUND3) {
                if (sumOfPoints >= 6) {
                  setCaseClosed(true);
                  addMessage({ text: `${t('gameThree.detective.finalScore')} ${sumOfPoints}/9.` });
                  addMessage({ text: t('gameThree.detective.pass') });
                  // addMessage({
                  //   text: () => (
                  //     <Styled.YoutubeMessage>
                  //       <img className="thumb" src={require('assets/images/game2-youtube-thumb.jpg')} alt="" />
                  //       <div className="dotcom">YOUTUBE.COM</div>
                  //       <div className="label">{t('gameTwo.detective.messageYoutube')}</div>
                  //     </Styled.YoutubeMessage>
                  //   ),
                  //   onClick: () => handleYoutubeClick(null),
                  // });
                  // detectiveChatRef.current.addMessage({
                  //   text: t('comingSoon.chat.invite'),
                  //   options: [t('comingSoon.chat.share')],
                  // });
                  // quizModeRef.current = QuizMode.SHARE;

                  detectiveChatRef.current.showCta();
                } else {
                  addMessage({ text: `${t('gameThree.detective.finalScore')} ${sumOfPoints}/9.` });
                  setTimeout(() => {
                    addMessage({
                      text: `${t('gameThree.detective.score2')} ${t('gameThree.detective.tryAgain')}`,
                    });
                  }, 500);
                  setTimeout(() => {
                    detectiveChatRef.current.showCta();
                  }, 800);
                }
              } else {
                addMessage({
                  text: `${t('gameThree.detective.score')} ${sumOfPoints}/9. ${t('gameThree.detective.score2')}`,
                });

                let nextMessage;
                switch (gameState) {
                  case GameState.ROUND2:
                    nextMessage = t('gameThree.detective.oneMore');
                    break;
                }
                setTimeout(() => {
                  addMessage({
                    text: nextMessage,
                  });
                }, Math.random() * 1000);

                clearTimeout(chatTimeoutRef.current);
                chatTimeoutRef.current = setTimeout(
                  () => detectiveChatRef.current.showCta(),
                  Math.random() * 1000 + 1000
                );
              }
            }, Math.random() * 1000);
          }, 500);
        } else {
          addMessage({
            text: currentQuiz[quizAnswers.length].question,
            options: currentQuiz[quizAnswers.length].options,
            correctOption: currentQuiz[quizAnswers.length].correct,
          });
        }
      }
      return () => {
        clearTimeout(chatTimeoutRef.current);
      };
    }, [
      addMessage,
      gameState,
      handleYoutubeClick,
      help.options,
      onComplete,
      currentQuiz,
      quizAnswers,
      t,
      sumOfPoints,
      points,
    ]);

    useEffect(() => {
      addMessage({ text: () => <PlayVideoMessage />, onClick: () => detectiveVideoRef.current.play() }, false);
      addMessage(
        {
          text: isDoubleTapEnabledBrowser() ? t('gameOne.detective.afterCall') : t('gameOne.detective.afterCallHold'),
        },
        false
      );
    }, [addMessage, detectiveVideoRef, t]);

    useImperativeHandle(ref, () => ({
      addMessage: message => addMessage(message),
      startQuiz: () => startQuiz(),
    }));

    return (
      <>
        <Styled.Wrapper ref={wrapperRef}>
          <DetectiveChat
            onQuizAnswer={onQuizAnswer}
            onCtaClick={handleCtaClick}
            onChatClose={() => onToggle(false)}
            onChatOpen={() => onToggle(true)}
            showArrows={isSocialGestureMinimizedBrowser()}
            ctaLabel={
              caseClosed
                ? t('gameThree.detective.caseClosedCta')
                : gameState === GameState.ROUND3
                ? t('gameThree.detective.tryAgain')
                : t('gameThree.detective.returnCta')
            }
            cacheId="GameThreeDetective"
            ref={detectiveChatRef}
          />
        </Styled.Wrapper>
        <SharePopup visible={shareVisible} closeFunc={handleShareCloseClick} />
      </>
    );
  }
);

export default memo(GameThreeDetective);
