import React, { useEffect } from 'react';
import * as Styled from './SharePopup.styled';
import { ShareType } from 'constants/enum';
import { ShareButton } from 'components/ShareButton';
import SvgClose from 'svgs/Close.svg';
import AdobeAnalytics from 'utils/adobeAnalytics';
interface SharePopupProps {
  visible: boolean;
  closeFunc: () => void;
  zIndex?: number;
}

export const SharePopup: React.FC<SharePopupProps> = ({ visible, closeFunc, zIndex = 1 }) => {
  const shareButtons = [
    { type: ShareType.facebook, icon: require('svgs/facebook.svg').default },
    { type: ShareType.twitter, icon: require('svgs/twitter.svg').default },
    { type: ShareType.linkedin, icon: require('svgs/linkedin.svg').default },
    { type: ShareType.pinterest, icon: require('svgs/pinterest.svg').default },
    { type: ShareType.email, icon: require('svgs/email.svg').default },
    { type: ShareType.copylink, icon: require('svgs/copy.svg').default },
  ];

  const handleCloseClick = () => {
    closeFunc();
  };

  useEffect(() => {
    if (visible) AdobeAnalytics.trackEvent('share_popup', 'pageview');
  }, [visible]);

  return (
    <>
      {visible ? (
        <Styled.Wrapper style={{ zIndex }}>
          <div className="background">
            <div className="title">SHARE</div>
            <button className="close" onClick={handleCloseClick}>
              <SvgClose className="icon" />
            </button>
            <div className="buttonsContainer">
              {Object.values(shareButtons).map((button, i) => (
                <ShareButton key={i} type={button.type} revertColor={i >= 4} icon={<button.icon />}></ShareButton>
              ))}
            </div>
          </div>
        </Styled.Wrapper>
      ) : null}
    </>
  );
};
