import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';
import SplitText from 'gsap/dist/SplitText';
import parse from 'html-react-parser';
import gsap from 'gsap';

import * as Styled from './GameIntroLanding.styled';

import CtaYellow from 'components/CtaYellow';
interface Props {
  className?: string;
  cta: string;
  title: string;
  titleAccessability?: string;
  number: string;
  subtitle: string;
  description: string;
  videoSrc: string;
  posterSrc: string;
  onCtaClick: () => void;
}

export interface GameIntroLandingRef {
  animateIn: () => gsap.core.Timeline;
  animateOut: () => gsap.core.Timeline;
}

const GameIntroLanding = forwardRef<GameIntroLandingRef, Props>(
  (
    { cta, title, titleAccessability, number, subtitle, description, className, videoSrc, posterSrc, onCtaClick },
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const timelineRef = useRef<gsap.core.Timeline>(null);
    const landingSubtitleSplitRef = useRef<SplitText>(null);

    // play background video
    useEffect(() => {
      // safari is the new ie
      setTimeout(() => videoRef.current.load());
      videoRef.current.oncanplay = () => {
        if (videoRef.current) {
          videoRef.current.muted = true;
          videoRef.current?.play();
        }
      };
      return () => {
        timelineRef.current?.kill();
        landingSubtitleSplitRef.current?.revert();
      };
    }, []);

    useImperativeHandle(ref, () => {
      const landing = wrapperRef.current;
      return {
        animateIn: () => {
          timelineRef.current?.kill();
          landingSubtitleSplitRef.current?.revert();
          const landingBg = landing.querySelector('.background').children;
          const landingCase = landing.querySelector('.title').firstChild;
          const landingNumber = landing.querySelector('.title').lastChild;
          const landingBottom = landing.querySelector('.bottom').children;
          const landingSubtitle = landing.querySelector('.subtitle');
          landingSubtitleSplitRef.current = new SplitText(landingSubtitle, { type: 'lines,chars' });
          timelineRef.current = gsap
            .timeline()
            .set(landingBg, { scale: 1.15 }, 0)
            .set(landingCase, { opacity: 0 }, 0)
            .set(landingNumber, { opacity: 0 }, 0)
            .set(landingBottom, { opacity: 0 }, 0)
            .set(landingSubtitleSplitRef.current.chars, { opacity: 0 }, 0)
            .to(landing, { duration: 1, autoAlpha: 1 }, 0.5)
            .to(landingBg, { duration: 3, scale: 1, ease: 'power4.out' }, 0.5)
            .to(landingCase, { duration: 0, opacity: 1 }, 1)
            .to(landingCase, { duration: 1, scrambleText: landingCase.textContent }, 1)
            .to(landingNumber, { duration: 0.2, opacity: 1 }, 2)
            .to(landingSubtitleSplitRef.current.chars, { duration: 0, stagger: 0.08, opacity: 1 }, 2)
            .to(landingBottom, { duration: 1, stagger: 0.2, opacity: 1 }, 3)
            .add(() => {
              landingSubtitleSplitRef.current.revert();
            });

          return timelineRef.current;
        },
        animateOut: () => {
          timelineRef.current?.kill();
          landingSubtitleSplitRef.current?.revert();
          timelineRef.current = gsap.timeline().to(landing, { duration: 0.5, autoAlpha: 0 });
          return timelineRef.current;
        },
      };
    });

    return (
      <Styled.Wrapper className={className} ref={wrapperRef}>
        <div className="background">
          <video
            className="bg1"
            ref={videoRef}
            src={videoSrc}
            poster={posterSrc}
            preload="auto"
            playsInline
            muted
          ></video>
          <div className="bg2"></div>
        </div>
        <div className="top">
          <h1 className="title" aria-label={titleAccessability}>
            <div>{parse(title)}</div>
            <div>{parse(number)}</div>
          </h1>
          <div className="line" />
          <p className="subtitle">{parse(subtitle)}</p>
        </div>
        <div className="bottom">
          <p className="description">{parse(description)}</p>
          <CtaYellow className="cta" label={cta} onClick={onCtaClick} big />
        </div>
      </Styled.Wrapper>
    );
  }
);

export default memo(GameIntroLanding);
