import React, { memo, useEffect, useContext, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AdobeAnalytics from 'utils/adobeAnalytics';
import * as Styled from './ComingSoon.styles';

import CONFIG from './config';

import { PageProps } from 'types/page';
import { HeaderContext, HeaderContextType } from 'context/header';

import YoutubeIcon from 'components/Icons/Youtube';
import DetectiveChat, { DetectiveChatRef } from 'components/DetectiveChat/DetectiveChat';
import { SharePopup } from 'components/SharePopup';
import { PageState } from 'constants/enum';
import CtaYellow from 'components/CtaYellow';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { isSocialGestureMinimizedBrowser } from 'utils/platform';

const imagesToPreload = [
  require('assets/images/coming-soon-handwirted-text.png'),
  require('assets/images/coming-soon-uv.png'),
];

interface ComingSoonProps extends PageProps {
  status: string;
  onInstructorCall: () => void;
  showLanding?: () => void;
  uvLight: boolean;
}

interface ComingSoonItem {
  title: string;
  title2?: string;
  firstParagraph: string;
  secondParagraph: string;
  button: string;
  instagramButton?: string;
  share?: string;
  description?: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({
  visible,
  setPageCallback,
  status = 'more',
  onInstructorCall,
  showLanding,
  uvLight,
}) => {
  const { t } = useTranslation();
  const { header, setHeader }: HeaderContextType = useContext(HeaderContext);

  //only one comming soon page
  const data: ComingSoonItem = CONFIG()['otherCases'];

  const [shareVisible, setShareVisible] = useState(false);

  const timeoutRef = useRef(null);
  const messageShownRef = useRef(false);
  const detectiveChatRef = useRef<DetectiveChatRef>(null);

  const handleBackClick = useCallback(() => setPageCallback(PageState.LANDING_PAGE), [setPageCallback]);

  const handleShareClick = useCallback(() => {
    detectiveChatRef.current.toggleChat();
    setShareVisible(true);
  }, []);

  const handleShareCloseClick = useCallback(() => setShareVisible(false), []);

  const handleYoutubeClick = useCallback(
    (e?) => {
      e?.preventDefault();
      window.open(t('comingSoon.youtubeLink'), '_blank');
    },
    [t]
  );

  usePreloadedImages(imagesToPreload);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game2_comingsoon', 'pageview', 'game2');
  }, []);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      // phase 1
      // detectiveChatRef.current.addMessage({ text: t('comingSoon.chat.message1s') });

      //phase 2
      // detectiveChatRef.current.addMessage({ text: t('gameTwo.detective.afterGameFinished') });
      detectiveChatRef.current.addMessage({ text: t('comingSoon.chat.messageShare') });
      detectiveChatRef.current.addMessage({
        text: () => (
          <Styled.YoutubeMessage>
            <img className="thumb" src={require('assets/images/game-2-youtube-thumb.jpg')} alt="" />
            <div className="dotcom">YOUTUBE.COM</div>
            <div className="label">{t('gameTwo.detective.messageYoutube')}</div>
          </Styled.YoutubeMessage>
        ),
        onClick: () => handleYoutubeClick(null),
      });
      detectiveChatRef.current.addMessage({
        text: t('comingSoon.chat.invite'),
        options: [t('comingSoon.chat.share')],
      });
    }, 1000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [handleYoutubeClick, t]);

  useEffect(() => {
    setHeader({
      isWhite: false,
      children: (
        <DetectiveChat
          ref={detectiveChatRef}
          onQuizAnswer={handleShareClick}
          onCtaClick={handleBackClick}
          ctaLabel={t('comingSoon.chat.cta')}
          showArrows={isSocialGestureMinimizedBrowser()}
        />
      ),
    });
  }, [handleBackClick, handleShareClick, setHeader, t]);

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <SharePopup visible={shareVisible} closeFunc={handleShareCloseClick} />
      <div className="content">
        <div className={`image-wrapper ${status}`}>
          <h1>
            {data.firstParagraph}
            <span>{data.secondParagraph}</span>
          </h1>
        </div>
        <p className={`${status} title`}>
          {data.title}
          {data.title2 && (
            <>
              <br />
              <br />
              {data.title2}
            </>
          )}
        </p>
      </div>
      <div className="cta-wrapper">
        <CtaYellow label={data.button} onClick={showLanding} big />
      </div>
      {uvLight && (
        <div className="uv-wrapper">
          <div className="uv">
            <div className="handwrited-text"></div>
          </div>
          <div className="youtube-button-wrapper">
            <button>
              <a
                href={t('youtubeLink')}
                onClick={e => {
                  e.preventDefault();
                  window.open(t('youtubeLink'), '_blank');
                }}
              >
                <YoutubeIcon />
                {t('youtube')}
              </a>
            </button>
          </div>
        </div>
      )}
    </Styled.Wrapper>
  );
};

export default memo(ComingSoon);
