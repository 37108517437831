import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullHeight } from 'styles/mixins';

import bgSrc from 'assets/images/bg-call.jpg';

export const Wrapper = styled(motion.div)`
  ${fullHeight};
  background-image: url(${bgSrc});
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
