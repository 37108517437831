import styled from 'styled-components';
import { motion } from 'framer-motion';
import ImageBgSrc from 'assets/images/ui/menuBackground.png';
import { fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';
import { vh } from 'styles/mixins';

type NavProps = {
  open: boolean;
};

export const Wrapper = styled.nav<NavProps>`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(${ImageBgSrc});
  z-index: 13;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
`;

export const Section = styled(motion.div)`
  height: ${vh(100)};
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SectionGuide = styled(Section)`
  justify-content: flex-end;
`;

Section.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      opacity: 1,
      display: 'flex',
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};

SectionGuide.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      opacity: 1,
      display: 'flex',
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};

export const MenuButton = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
`;

export const Title = styled.h2`
  ${fontChannel4Chadwick(14, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
`;
