import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import 'i18n/config';
import { Howler } from 'howler';

import Wrapper from './styled/Wrapper';
import AdobeAnalytics from 'utils/adobeAnalytics';
import { sfx } from 'constants/assets';
import { PageState } from 'constants/enum';
import { useLongPress } from 'use-long-press';

import CallPage from 'containers/CallPage';
import BadgePage from 'containers/BadgePage';
import UnlockPage from 'containers/UnlockPage';
import LandingContainer from 'containers/LandingContainer';

import GameOne from 'components/GameOne';
import GameTwo from 'components/GameTwo';
import GameThree from 'components/GameThree';
import ComingSoon from 'components/ComingSoon';
import { Header } from 'components/Header/Header';
import Congratulations from 'components/Congratulations';
import WhatsMurderIsland from 'components/WhatsMurderIsland';

import useDoubleTap from 'services/doubleTap.service';
import AudioManager from 'services/audiomanager.service';

import lockBodyScroll from 'utils/lock-body-scroll';
import { getUrlParam } from 'utils/gup';

import { HistoryContext } from 'context/history';
import { getNextGame, ProgressContext } from 'context/progress';
import GameThreeFingerprints from 'components/GameThreeFingerprints';
import useWindowVisible from 'hooks/useWindowVisible';
import usePreloadedImages from 'hooks/usePreloadedImages';
import { latestVisitedGamePage } from 'constants/historyPages';
import { RedirectsContext } from 'context/redirects';
import { isInstagram, isIOS, isSnap } from 'utils/platform';
import { SettingsContext } from 'context/settings';
import ShareService from 'services/share.service';

const imagesToPreload = [require('assets/images/uv-base.jpg')];

const LandingPage = () => {
  const wrapperRef = useRef(null);
  const isWindowVisible = useWindowVisible();
  const { progress, setProgress } = useContext(ProgressContext);
  const { history, setHistory } = useContext(HistoryContext);
  const { redirects, setRedirects } = useContext(RedirectsContext);
  const {
    settings: { showUV },
    setSettings,
  } = useContext(SettingsContext);

  const [accuracy, setAccuracy] = useState(2);
  const [lastGame, setLastGame] = useState(0);
  const [pageState, setPageState] = useState(PageState.DETECTIVE_CALL);
  const [showGameThree, setShowGameThree] = useState(true);
  const [uvLight, setuvLight] = useState(false);

  const isUvLightSet = useMemo(() => {
    return (
      [
        PageState.LANDING_PAGE,
        PageState.GAME_ONE,
        PageState.COMING_SOON,
        PageState.GAME_TWO,
        PageState.GAME_THREE,
      ].indexOf(pageState) > -1
    );
  }, [pageState]);

  const stopUv = useCallback(
    (e?) => {
      if (!e.defaultPrevented && isUvLightSet) {
        setuvLight(false);
        AudioManager.instance.stopSound();
        document.removeEventListener('click', stopUv);
      }
    },
    [isUvLightSet]
  );

  const startUv = useCallback(() => {
    if (isUvLightSet && showUV) {
      setuvLight(true);
      AudioManager.instance.playSound(sfx.uv_light, false, true);
      document.addEventListener('click', stopUv);
    }
  }, [isUvLightSet, showUV, stopUv]);

  useEffect(() => {
    Howler.mute(!isWindowVisible);
  }, [isWindowVisible]);

  const doubleTapBind = useDoubleTap(startUv);
  const longPressBind = useLongPress(startUv);

  const showCoomingSoon = useCallback(() => setPageState(PageState.COMING_SOON), []);

  const showLanding = useCallback(() => {
    if (progress?.gameOne && progress?.gameTwo) {
      setProgress({ lastState: PageState.LANDING_PAGE });
    }
    setPageState(PageState.LANDING_PAGE);
  }, [progress, setProgress]);

  const tryAgain = useCallback(() => {
    const latestGamePage = latestVisitedGamePage(history);
    const lastGameState = latestGamePage || null;

    setPageState(parseInt(latestGamePage) || progress?.lastState || PageState.LANDING_PAGE);
    setRedirects({ tryAgain: true });
  }, [history, progress?.lastState, setRedirects]);

  const showBadge = useCallback(() => setPageState(PageState.BADGE), []);

  const showDetectiveFeedback = useCallback(() => setPageState(PageState.DETECTIVE_FEEDBACK), []);

  const setNextGame = () => {
    setPageState(getNextGame(progress));
  };

  const setBackToAllCases = useCallback(() => {
    setPageState(PageState.LANDING_PAGE);
    setRedirects({ landingPageCaseFiles: true });
  }, [setRedirects]);

  const showGameOneCongratulations = useCallback(
    (accuracy: number) => {
      setProgress({ gameOne: true });
      setAccuracy(accuracy);
      ShareService.instance.setGameData(1);
      AdobeAnalytics.trackEvent('game_1_congratulations', 'pageview', 'game1');
      setLastGame(1);
      setPageState(PageState.CONGRATULATIONS);
    },
    [setProgress]
  );

  const showGameThreeCongratulations = useCallback(
    (accuracy: number) => {
      setProgress({ gameThree: true });
      setAccuracy(accuracy);
      ShareService.instance.setGameData(3);
      AdobeAnalytics.trackEvent('game_3_congratulations1', 'pageview', 'game3');
      setLastGame(3);
      setPageState(PageState.CONGRATULATIONS);
    },
    [setProgress]
  );

  usePreloadedImages(imagesToPreload);

  const preventSwipeGesture = useCallback(e => {
    if (!isIOS()) return;
    if (e.pageX > 15 && e.pageX < window.innerWidth - 15) return;
    e.preventDefault();
  }, []);

  useEffect(() => {
    const state = getUrlParam('state', window.location.href) ?? false;
    const gameThreeParam = getUrlParam('gamethree', window.location.href) ?? false;
    if (state !== false) {
      setPageState(parseInt(state));
    }
    if (gameThreeParam !== false && gameThreeParam === 'true') {
      setShowGameThree(true);
      setPageState(PageState.GAME_THREE);
    }

    const ref = wrapperRef.current;
    ref.addEventListener('touchstart', preventSwipeGesture);
    return () => {
      ref.removeEventListener('touchstart', preventSwipeGesture);
    };
  }, [preventSwipeGesture]);

  useEffect(() => {
    setHistory(pageState.toString());
    window.scrollTo(0, 0);
    AudioManager.instance.stopSound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  useEffect(() => {
    return () => {
      stopUv({});
    };
  }, [stopUv]);

  // locks body scroll by default,
  // unlocks it only when necessary
  useEffect(() => {
    if (pageState === PageState.LANDING_PAGE || pageState === PageState.MURDER_ISLAND) {
      lockBodyScroll.unlock();
    } else {
      lockBodyScroll.lock();
    }
    setSettings({
      showUV:
        pageState === PageState.LANDING_PAGE ||
        pageState === PageState.GAME_ONE ||
        pageState === PageState.GAME_TWO ||
        pageState === PageState.GAME_THREE ||
        pageState === PageState.COMING_SOON,
    });
  }, [pageState, setSettings]);

  const uvBind = () => {
    return isSnap() ? longPressBind : doubleTapBind;
  };

  return (
    <Wrapper ref={wrapperRef} {...uvBind()}>
      <Header setPageCallback={setPageState} pageState={pageState} />
      {pageState === PageState.DETECTIVE_CALL && (
        <CallPage visible={pageState === PageState.DETECTIVE_CALL} setPageCallback={setPageState} type="start" />
      )}
      {pageState === PageState.DETECTIVE_FEEDBACK && (
        <CallPage visible={pageState === PageState.DETECTIVE_FEEDBACK} setPageCallback={setPageState} type="feedback" />
      )}
      <UnlockPage visible={pageState === PageState.UNLOCK} setPageCallback={setPageState} />
      {pageState === PageState.BADGE && (
        <BadgePage
          visible={pageState === PageState.BADGE}
          setPageCallback={setPageState}
          onComplete={showDetectiveFeedback}
        />
      )}
      <LandingContainer
        uvLight={uvLight}
        visible={pageState === PageState.LANDING_PAGE}
        setPageCallback={setPageState}
      />
      {pageState === PageState.GAME_ONE && (
        <GameOne uvLight={uvLight} onExit={showLanding} onComplete={showGameOneCongratulations} />
      )}
      {pageState === PageState.COMING_SOON && (
        <ComingSoon
          status="more"
          uvLight={uvLight}
          visible={pageState === PageState.COMING_SOON}
          showLanding={setBackToAllCases}
          setPageCallback={setPageState}
          onInstructorCall={() => {}}
        />
      )}
      {pageState === PageState.CONGRATULATIONS && (
        <Congratulations
          game={lastGame}
          accuracy={accuracy}
          visible={pageState === PageState.CONGRATULATIONS}
          setPageCallback={setPageState}
          onContinue={setBackToAllCases}
          tryAgain={tryAgain}
          showBadge={showBadge}
          progress={progress}
        />
      )}
      {pageState === PageState.MURDER_ISLAND && (
        <WhatsMurderIsland visible={pageState === PageState.MURDER_ISLAND} setPageCallback={setPageState} />
      )}
      {pageState === PageState.GAME_TWO && (
        <GameTwo
          uvLight={uvLight}
          progress={progress}
          showBadge={showBadge}
          onContinueTraining={setNextGame}
          showLanding={showLanding}
          onExit={showLanding}
          onComplete={() => {}}
        />
      )}
      {pageState === PageState.GAME_THREE && showGameThree && (
        <GameThree uvLight={uvLight} onExit={showLanding} onComplete={showGameThreeCongratulations} />
      )}
    </Wrapper>
  );
};

export default LandingPage;
