import styled from 'styled-components';

import { fullHeight } from 'styles/mixins';
import { colors } from 'styles/vars';
import { fontChannel4Chadwick } from 'styles/typography';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Video = styled.video`
  ${fullHeight};
  width: 100vw;
  object-fit: cover;

  &::cue {
    ${fontChannel4Chadwick(16, 500)};
    background-color: ${colors.black};
    color: ${colors.white};
  }
`;
