import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { px, rect, vh } from 'styles/mixins';

export const Wrapper = styled(motion.div)<{ vScale: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => px(props.vScale * 110)};
`;
