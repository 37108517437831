import React, { memo, useCallback, useEffect, useContext, useState, useMemo } from 'react';
import { PageProps } from 'types/page';

import CONFIG from './config';
import * as Styled from './Congratulations.styled';

import { sfx } from 'constants/assets';

import { HeaderContext, HeaderContextType } from 'context/header';

import AudioManager from 'services/audiomanager.service';

import AdobeAnalytics from 'utils/adobeAnalytics';

import CtaYellow from 'components/CtaYellow';
import { SharePopup } from 'components/SharePopup';
import { Progress } from 'context/progress';
import { useTranslation } from 'react-i18next';

interface CongratulationsProps extends PageProps {
  game: number;
  accuracy: number;
  progress: Progress;
  onContinue: () => void;
  tryAgain: () => void;
  showBadge: () => void;
}

interface CongratulationItem {
  title: string;
  scoredPoints?: string;
  maxPoints: string;
  titlePoints: string;
  descriptionPoints: string;
  share: string;
  descriptionTraining: string;
  continueButton: string;
  tryAgain: string;
}

export const Congratulations: React.FC<CongratulationsProps> = ({
  accuracy,
  visible,
  progress,
  onContinue,
  tryAgain,
  showBadge,
  game = 1,
}) => {
  const { t } = useTranslation();
  const { setHeader }: HeaderContextType = useContext(HeaderContext);

  const data: CongratulationItem = CONFIG(game, accuracy);
  const isFinished = accuracy === JSON.parse(data.maxPoints);
  const [shareVisible, setShareVisible] = useState(false);
  const allCasesFinished = useMemo(() => progress?.gameOne && progress?.gameTwo && progress?.gameThree, [progress]);

  const onShareClick = useCallback(() => {
    setShareVisible(true);
  }, []);

  const onCloseShareClick = useCallback(() => {
    setShareVisible(false);
  }, []);

  useEffect(() => {
    setHeader({ isWhite: true });
  }, [setHeader]);

  useEffect(() => {
    AudioManager.instance.playSound(sfx.success_screen);
  }, []);

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <SharePopup visible={shareVisible} closeFunc={onCloseShareClick} />
      <div className="content">
        <h1>{data.title}</h1>
        <div className={`accuracy-wrapper ${isFinished ? 'new-margin' : ''}`}>
          {game === 3 && (
            <>
              <div className="points-wrapper">
                <span>
                  {data.scoredPoints || accuracy}/{data.maxPoints}
                </span>
              </div>
              <div className="points-description-wrapper">
                {data.titlePoints.split(' ').map(item => (
                  <p key={item}>{item}</p>
                ))}
              </div>
            </>
          )}
        </div>
        <p className={`description-points ${isFinished ? 'new-margin' : ''}`}>{data.descriptionPoints}</p>
        <div className="action-wrapper">
          <div className={`button-shere ${isFinished ? 'new-margin' : ''}`}>
            <button onClick={onShareClick}>{data.share}</button>
          </div>
          <p className={`${isFinished ? 'new-margin' : ''}`}>{data.descriptionTraining}</p>
          <div className={`button-continue-wrapper ${isFinished ? 'new-margin' : ''}`}>
            <CtaYellow
              label={allCasesFinished ? t('congratulations.badge') : data.continueButton}
              onClick={allCasesFinished ? showBadge : onContinue}
              big
            />
          </div>
          {!isFinished && (
            <p className="try-again" role="button" onClick={tryAgain}>
              {data.tryAgain}
            </p>
          )}
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default memo(Congratulations);
