import React, { ReactElement } from 'react';
import * as Styled from './DashboardButton.styles';

interface DashboardButtonProps {
  phase: number;
  icon: ReactElement;
  iconLabel: string;
  bgColor: string;
  iconLabelColor: string;
  handleOpenPopup?: () => void;
  label?: string;
  subtitle?: string;
  description?: string;
  img?: string;
}

export const DashboardButton: React.FC<DashboardButtonProps> = ({
  phase,
  icon,
  iconLabel,
  bgColor,
  iconLabelColor,
  handleOpenPopup,
  label,
  subtitle,
  description,
  img,
}) => {
  return (
    <Styled.OuterWrapper>
      <Styled.IconWrapper bgColor={bgColor}>
        {icon}
        <Styled.IconLabel iconLabelColor={iconLabelColor}>
          {subtitle} - {iconLabel}
        </Styled.IconLabel>
      </Styled.IconWrapper>

      <Styled.Wrapper bgColor={bgColor} onClick={handleOpenPopup}>
        <>
          <Styled.Title>{label}</Styled.Title>
          <Styled.Image src={img} alt={label} />
          <Styled.Description>
            Case file:<span>{description}</span>
          </Styled.Description>
        </>
      </Styled.Wrapper>
    </Styled.OuterWrapper>
  );
};
