import React, { ReactElement } from 'react';
import { Wrapper } from './styled/Wrapper';
import { Button as Btn } from './styled/Button';
import { colors } from 'styles/vars';
import AudioManager from 'services/audiomanager.service';
import { sfx } from 'constants/assets';

interface ButtonProps {
  onClick?: () => void;
  label: string;
  color?: string;
  bgColor?: string;
  bgImage?: string;
  isBorder?: boolean;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  centered?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  color,
  bgColor,
  bgImage,
  isBorder,
  iconLeft,
  iconRight,
  centered,
  children,
}) => {
  const buttonTheme = {
    color,
    isBorder,
    bgImage,
    centered,
  };

  const clickHandler = () => {
    if (!onClick) return;
    if (bgColor === colors.yellow) {
      AudioManager.instance.playSound(sfx.yellow_btn);
    }
    onClick();
  };

  return (
    <Wrapper bgColor={bgColor}>
      <Btn {...buttonTheme} onClick={clickHandler}>
        {iconLeft}
        <span>{label}</span>
        {iconRight}
        {children}
      </Btn>
    </Wrapper>
  );
};
