import React, { useEffect, useCallback, useRef, FC } from 'react';
import { gsap } from 'gsap';

import * as Styled from './ScannerAnimation.styled';

const SPRITE_SETTINGS = {
  height: 3050,
  width: 2023,
  rows: 10,
  columns: 7,
  frames: 70,
};

type Props = {
  duration?: number;
  onComplete?: () => void;
};

const ScannerAnimation: FC<Props> = ({ duration = 3, onComplete }) => {
  const { height, width, rows, columns } = SPRITE_SETTINGS;
  const singleWidth = width / columns;
  const singleHeight = height / rows;
  const stateRef = useRef({ frame: 0 });
  const spriteRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const update = useCallback(() => {
    const context = canvasRef?.current?.getContext('2d');
    const num = stateRef.current.frame;
    const x = width * ((num % columns) / columns);
    const y = height * ((Math.floor(num / columns) % rows) / rows);

    context.save();
    context.clearRect(0, 0, singleWidth, singleHeight);
    context.drawImage(spriteRef.current, x, y, singleWidth, singleHeight, 0, 0, singleWidth, singleHeight);
    context.restore();
  }, [columns, height, rows, singleHeight, singleWidth, width]);

  const init = useCallback(() => {
    const timeline = gsap
      .timeline({ onUpdate: update, onComplete })
      .to(stateRef.current, { frame: SPRITE_SETTINGS.frames - 1, roundProps: 'frame', ease: 'none', duration }, 0)
      .to(canvasRef.current, { opacity: 0, duration: 0.2 }, duration);

    return () => {
      timeline.kill();
    };
  }, [duration, onComplete, update]);

  useEffect(() => {
    spriteRef.current = new Image();
    spriteRef.current.onload = init;
    spriteRef.current.src = require('assets/images/fingerprints-sprites.png');
  }, [init]);

  return <Styled.Canvas width={singleWidth} height={singleHeight} ref={canvasRef} />;
};

export default ScannerAnimation;
