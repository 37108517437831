import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import UvBg from 'assets/images/ui/landing-pages/uvBackground.png';

import { colors } from 'styles/vars';
import { px, rect, vh } from 'styles/mixins';
import { Rect } from './UVReveal';

export const Background = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', '100%')}
  background: ${colors.black};
  overflow: hidden;
  z-index: 10;

  > .header {
    ${rect('absolute', 0, 0, '100%', 'auto')}
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${px(12)} ${px(14)} 0;
    z-index: 1;
  }
`;
export const UvBackgroundImg = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 12;
  mix-blend-mode: screen;
  background: url(${UvBg}) center bottom no-repeat;

  background-size: cover;
  pointer-events: none;
`;

export const Light = styled(motion.div)`
  ${rect('absolute', 0, 0, '88vw', '120vw')}
  background: url(${require('assets/images/reveal-light.png')}) no-repeat;
  z-index: 12;
  background-size: contain;
  mix-blend-mode: color-dodge;
  opacity: 0.5;
`;

export const RevealElement = styled(motion.div)<{
  rect: Rect;
  bgSrc: string;
  w: number;
  scaleRatio: number;
  draggable: boolean;
}>`
  ${({ rect, bgSrc, w: width, scaleRatio }) => css`
    top: ${rect.top}px;
    left: ${rect.left}px;
    width: ${width * scaleRatio}vw;
    height: ${rect.bottom - rect.top}px;
    background: url(${bgSrc}) no-repeat;
  `};
  ${({ draggable }) =>
    draggable &&
    css`
      mask-image: url(${require('assets/images/reveal-mask.png')});
      mask-size: calc(88vw + 50px) calc(120vw + 50px);
      mask-repeat: no-repeat;
    `};
  position: absolute;
  z-index: 11;
  background-size: cover;
`;

export const RevealFaded = styled(motion.div)<{ rect: Rect; w: number; bgSrc: string }>`
  ${({ rect, bgSrc, w: width }) => css`
    top: ${rect.top}px;
    left: ${rect.left}px;
    width: ${width}vw;
    height: ${rect.bottom - rect.top}px;
    background: url(${bgSrc}) no-repeat;
  `};
  position: absolute;
  z-index: 11;
  background-size: cover;
  opacity: 0.2;
`;
