import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const CONFIG = (game: number, accuracy: number) => {
  const { t } = useTranslation();

  const translation = {
    1: {
      2: {
        title: `${parse(t('congratulations.gameOne.0.title'))}`,
        scoredPoints: `${parse(t('congratulations.gameOne.0.scoredPoints'))}`,
        maxPoints: `${parse(t('congratulations.gameOne.0.maxPoints'))}`,
        titlePoints: `${parse(t('congratulations.gameOne.0.titlePoints'))}`,
        descriptionPoints: `${parse(t('congratulations.gameOne.0.descriptionPoints'))}`,
        share: `${parse(t('congratulations.gameOne.0.share'))}`,
        descriptionTraining: `${parse(t('congratulations.gameOne.0.descriptionTraining'))}`,
        continueButton: `${parse(t('congratulations.gameOne.0.continueButton'))}`,
        tryAgain: `${parse(t('congratulations.gameOne.0.tryAgain'))}`,
      },
      3: {
        title: `${parse(t('congratulations.gameOne.1.title'))}`,
        scoredPoints: `${parse(t('congratulations.gameOne.1.scoredPoints'))}`,
        maxPoints: `${parse(t('congratulations.gameOne.1.maxPoints'))}`,
        titlePoints: `${parse(t('congratulations.gameOne.1.titlePoints'))}`,
        descriptionPoints: `${parse(t('congratulations.gameOne.1.descriptionPoints'))}`,
        share: `${parse(t('congratulations.gameOne.1.share'))}`,
        descriptionTraining: `${parse(t('congratulations.gameOne.1.descriptionTraining'))}`,
        continueButton: `${parse(t('congratulations.gameOne.1.continueButton'))}`,
        tryAgain: `${parse(t('congratulations.gameOne.1.tryAgain'))}`,
      },
    },
    3: {
      6: {
        title: `${parse(t('congratulations.gameThree.0.title'))}`,
        maxPoints: `${parse(t('congratulations.gameThree.0.maxPoints'))}`,
        titlePoints: `${parse(t('congratulations.gameThree.0.titlePoints'))}`,
        descriptionPoints: `${parse(t('congratulations.gameThree.0.descriptionPoints'))}`,
        share: `${parse(t('congratulations.gameThree.0.share'))}`,
        descriptionTraining: `${parse(t('congratulations.gameThree.0.descriptionTraining'))}`,
        continueButton: `${parse(t('congratulations.gameThree.0.continueButton'))}`,
        tryAgain: `${parse(t('congratulations.gameThree.0.tryAgain'))}`,
      },
    },
  };

  const currentTranslation = translation[game];

  if (currentTranslation[accuracy]) {
    return currentTranslation[accuracy];
  }

  const keys = Object.keys(currentTranslation)
    .map(Number)
    .filter(num => num < accuracy);

  return currentTranslation[Math.min(...keys)];
};

export default CONFIG;
