import React, { memo } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles/vars';

import * as Styled from './GameGuideOne.styles';
import BackIcon from 'components/Icons/BackIcon';
import Image from 'assets/images/ui/iPhone.png';
import { Button } from 'components/Button/index';
import ButtonImg from 'assets/images/ui/button.png';

interface GameGuideOneProps {
  handleMenuChange: () => void;
  onResume?: () => void;
}

export const GameGuideOne: React.FC<GameGuideOneProps> = ({ onResume, handleMenuChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <Styled.MenuButton onClick={handleMenuChange}>
        <BackIcon />
      </Styled.MenuButton>
      <Styled.Title>{t('gameGuideOne.title')}</Styled.Title>
      <Styled.ImageWrapper src={Image} />
      <Styled.Subtitle>{parse(t('gameGuideOne.subTitle'))}</Styled.Subtitle>
      <Styled.Description>{parse(t('gameGuideOne.description'))}</Styled.Description>
      <Styled.ButtonWrapper>
        <Button
          label={`${parse(t('gameGuideOne.buttonText'))}`}
          onClick={() => onResume()}
          bgColor={colors.yellow}
          bgImage={ButtonImg}
        ></Button>
      </Styled.ButtonWrapper>
    </>
  );
};

export default memo(GameGuideOne);
