import styled from 'styled-components';
import { fontChannel4Chadwick } from 'styles/typography';
import { px, box } from 'styles/mixins';
import { rgba } from 'polished';
import { colors } from 'styles/vars';

export const MenuButton = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 2.5rem;
`;

export const Title = styled.h2`
  ${fontChannel4Chadwick(14, 500, 1.1)};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const Content = styled.div`
  .slides {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: ${px(460)};
    margin: 0 auto ${px(25)};

    .slide {
      width: 100%;
      flex: none;
      text-align: center;
    }
  }

  .phone-wrapper {
    position: absolute;
    bottom: ${px(93)};
    left: 50%;
    ${box(px(175), px(352))}
    background-image: url(${require('assets/images/game-2-menu-phone.png')});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 ${px(10)};

    .rectangle {
      fill: none;
      transition: fill 0.4s;

      &.fill {
        fill: ${colors.white};
      }
    }
    .active-rectangle {
      position: relative;

      .arrow-up {
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: ${px(10)};
        height: ${px(13)};
        background-image: url(${require('assets/images/arrow-up.png')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .arrow-down {
        position: absolute;
        bottom: -10%;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: ${px(10)};
        height: ${px(13)};
        background-image: url(${require('assets/images/arrow-down.png')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .arrow-rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: ${px(10)};
        height: ${px(13)};
        background-image: url(${require('assets/images/arrow-rotate.png')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .rotate-circle {
        fill: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .arrow-left {
        position: absolute;
        bottom: 5%;
        left: 120%;
        display: block;
        width: ${px(10)};
        height: ${px(13)};
        background-image: url(${require('assets/images/arrow-up.png')});
        transform: rotate(90deg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .arrow-right {
        position: absolute;
        bottom: 5%;
        right: 120%;
        display: block;
        width: ${px(10)};
        height: ${px(13)};
        background-image: url(${require('assets/images/arrow-up.png')});
        transform: rotate(-90deg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    .double-tap {
      position: absolute;
      bottom: 7%;
      left: 50%;
      transform: translateX(-50%);
      width: 70%;
      display: flex;
      justify-content: right;
      align-items: center;

      p {
        ${fontChannel4Chadwick(9, 500, 0.9)};
        text-transform: uppercase;
        text-align: right;
        margin-right: 2rem;
      }
      .double-tap-background {
        width: 4rem;
        height: 6rem;
        background-image: url(${require('assets/images/game-2-menu-double-tap.png')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
      }
      .pointer {
        position: absolute;
        width: 4rem;
        height: 4rem;
        background-image: url(${require('assets/images/game-2-menu-double-tap-pointer.png')});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
      }
    }
  }

  .description {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    text-align: center;
    width: 54%;

    position: absolute;
    bottom: ${px(0)};
    left: 50%;
    transform: translateX(-50%);
  }

  .button-wrapper {
    margin-bottom: ${px(35)};

    button {
      margin: 0 auto;
    }
  }

  .footer {
    position: relative;
    width: 100vw;
    flex: none;
    padding: 0 ${px(16)} ${px(15)};

    .progress {
      display: flex;
      justify-content: space-between;
      margin: 0 0 ${px(6)};

      .bar {
        width: ${px(82)};
        height: 1px;
        background: ${rgba(colors.white, 0.3)};

        .fill {
          ${box('100%')}
          background: ${colors.white};
          transform: scaleX(0);
          transform-origin: 0 0;
          transition: transform 1s ease;

          &.active {
            transform: scale(1);
          }
        }
      }
    }
  }
`;
