import { useEffect } from 'react';
import preloadImages from 'utils/preloadImages';

const usePreloadedImages = (array: Array<string>, waitForOtherResources = false) => {
  useEffect(() => {
    preloadImages(array, waitForOtherResources);
  }, [array, waitForOtherResources]);
};

export default usePreloadedImages;
