import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { colors } from 'styles/vars';
import { box, flexCenter, px, rect, uvBase, vh } from 'styles/mixins';
import { fontChannel4Chadwick, typography18Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  overflow: hidden;

  > .list {
    ${rect('absolute', 0, 0, '100%', '100%')}
    overflow: auto;
    padding: ${px(90)} ${px(16)} ${px(200)};

    .title {
      ${fontChannel4Chadwick(24, 700, 1.1)}
      letter-spacing: ${px(-0.5)};
      text-align: center;
      margin: 0 0 ${px(28)};
    }

    .item {
      display: flex;
      align-items: stretch;
      width: 100%;
      margin: 0 0 ${px(10)};
      padding: ${px(15)} ${px(13)};
      border-radius: ${px(6)};
      background: ${colors.white};
      box-shadow: 0 ${px(3)} ${px(9)} rgba(0, 0, 0, 0.1);

      .picture {
        ${box(px(50))}
        flex: none;
        object-fit: cover;
        border-radius: 50%;
      }

      .text {
        flex: 1 1 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 ${px(16)};

        .name {
          ${typography18Caps}
          margin: 0 0 ${px(3)};
        }

        .date {
          ${fontChannel4Chadwick(10, 500, 1.1)}
          letter-spacing: 0.05em;
          opacity: 0.5;
        }
      }

      .player {
      }
    }
  }

  > .uv {
    ${uvBase()}
  }
`;
