import React, { forwardRef, memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import * as Styled from './GameThreeVideo.styled';
import videoFrame from 'assets/images/game3/video-frame.png';
import { isTikTokiOS } from 'utils/platform';
import { AnimatePresence } from 'framer-motion';

interface GameThreeVideoProps {
  className?: string;
  posterSrc?: string;
  videoSrc: string;
  onLoad: () => void;
}

const GameThreeVideo = forwardRef<HTMLVideoElement, GameThreeVideoProps>(({ videoSrc, posterSrc, onLoad }, ref) => {
  const [firstRun, setFirstRun] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoBlackoutRef = useRef(null);
  const timeline = useRef(gsap.timeline());

  const onVideoLoad = useCallback(() => {
    if (!loaded) {
      console.log('load new');
      setLoaded(true);
      onLoad();
      const video = videoRef.current;
      const blackout = videoBlackoutRef.current;

      timeline.current
        .set(video, { opacity: 0 }, 0)
        .set(blackout, { scaleY: 1, scaleX: 1, background: 'white' }, 0)
        .set(blackout, { scaleY: 0.01, scaleX: 0.1, background: 'white' }, 0.1)
        .to(blackout, { scaleX: 1, duration: 0.1 }, 0.1)
        .to(blackout, { scaleY: 1, duration: 0.2 }, 0.2)
        .set(video, { opacity: 1 }, 0.4)
        .set(blackout, { scaleY: 0, scaleX: 0, background: 'transparent', opacity: 0 }, 0.4);

      if (firstRun) {
        setFirstRun(false);
      }
    }
  }, [firstRun, loaded, onLoad]);

  useEffect(() => {
    setLoaded(false);
  }, [videoSrc]);

  useEffect(() => {
    if (!loaded) {
      if (!firstRun) {
        timeline.current.kill();
        timeline.current.fromTo(
          videoBlackoutRef.current,
          {
            opacity: 0,
            scaleY: 1,
            scaleX: 1,
            background: 'black',
            zIndex: 2,
          },
          {
            opacity: 1,
            scaleY: 1,
            scaleX: 1,
            background: 'black',
            duration: 0.3,
            onComplete: () => {
              timeline.current.kill();
              timeline.current = gsap.timeline();
              videoRef.current.load();
            },
          }
        );
      } else {
        videoRef.current.load();
      }
    }
  }, [loaded, firstRun]);

  return (
    <Styled.Wrapper>
      {!loaded && <Styled.Preloader>Loading...</Styled.Preloader>}
      <Styled.Video
        autoPlay
        ref={element => {
          (videoRef as MutableRefObject<HTMLVideoElement>).current = element;
          if (typeof ref === 'function') {
            ref(element);
          } else {
            ref.current = element;
          }
        }}
        controls={false}
        preload="metadata"
        poster={posterSrc}
        playsInline
        muted
        loop
        disablePictureInPicture
        disableRemotePlayback={!isTikTokiOS()}
        // onCanPlay={onVideoLoad}
        onCanPlayThrough={onVideoLoad}
      >
        <source src={videoSrc} type="video/mp4" />
      </Styled.Video>
      <Styled.VideoBlackout ref={videoBlackoutRef} />
      <Styled.Frame src={videoFrame} />
    </Styled.Wrapper>
  );
});

export default memo(GameThreeVideo);
