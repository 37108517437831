import React, { memo, useCallback, useEffect, useContext, useState, useMemo } from 'react';
import { PageProps } from 'types/page';

import CONFIG from './config';
import * as Styled from './GameTwoCongratulations.styled';

import { sfx } from 'constants/assets';

import { HeaderContext, HeaderContextType } from 'context/header';

import AudioManager from 'services/audiomanager.service';

import AdobeAnalytics from 'utils/adobeAnalytics';

import CtaYellow from 'components/CtaYellow';
import { SharePopup } from 'components/SharePopup';
import FlipTimer from 'components/FlipTimer';
import ShareService from 'services/share.service';
import { Progress } from 'context/progress';
import { useTranslation } from 'react-i18next';

interface CongratulationsProps extends PageProps {
  accuracy: number;
  time: number;
  onContinue: () => void;
  tryAgain: () => void;
  backToCases: () => void;
  showBadge: () => void;
  progress: Progress;
}

interface CongratulationItem {
  title: string;
  scoredPoints: string;
  maxPoints: string;
  titlePoints: string;
  descriptionPoints: string;
  share: string;
  descriptionTraining: string;
  continueButton: string;
  tryAgain: string;
  backToCases?: string;
}

export const Congratulations: React.FC<CongratulationsProps> = ({
  accuracy,
  time = 0,
  visible,
  onContinue,
  tryAgain,
  backToCases,
  showBadge,
  progress,
}) => {
  const { t } = useTranslation();

  const { setHeader }: HeaderContextType = useContext(HeaderContext);
  const allCasesFinished = useMemo(() => progress?.gameOne && progress?.gameTwo && progress?.gameThree, [progress]);

  const data: CongratulationItem = CONFIG()[accuracy];
  const isFinished = accuracy === JSON.parse(data.maxPoints);
  const [shareVisible, setShareVisible] = useState(false);

  const onShareClick = useCallback(() => {
    ShareService.instance.setGameData(2, { time });
    setShareVisible(true);
  }, [time]);

  useEffect(() => {
    AdobeAnalytics.trackEvent('game_2_congratulations', 'pageview', 'game2');
  }, []);

  const onCloseShareClick = useCallback(() => {
    setShareVisible(false);
  }, []);

  useEffect(() => {
    setHeader({ isWhite: true });
  }, [setHeader]);

  useEffect(() => {
    AudioManager.instance.playSound(sfx.success_screen);
  }, []);

  return (
    <Styled.Wrapper animate={visible ? 'visible' : 'hidden'}>
      <SharePopup visible={shareVisible} closeFunc={onCloseShareClick} />
      <div className="content">
        <h1>{data.title}</h1>
        <FlipTimer startTime={time} running={false} />
        <div className={`button-share ${isFinished ? 'new-margin' : ''}`}>
          <button onClick={onShareClick}>{data.share}</button>
        </div>
        <div className={'bottom-wrapper high'}>
          <p className={'description-points'}>{data.descriptionPoints}</p>
          <CtaYellow
            label={allCasesFinished ? t('congratulations.badge') : data.continueButton}
            onClick={allCasesFinished ? showBadge : onContinue}
            big
          />
          <>
            <p className="b-button" role="button" onClick={tryAgain}>
              {data.tryAgain}
            </p>
          </>
        </div>
      </div>
    </Styled.Wrapper>
  );
};

export default memo(Congratulations);
