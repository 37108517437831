import styled from 'styled-components';

import { colors } from 'styles/vars';
import { rect, vh } from 'styles/mixins';

export const Wrapper = styled.div`
  ${rect('relative', 0, 0, '100%', vh(100))}
  background: ${colors.black};
  overflow: hidden;
  z-index: 0;
`;
