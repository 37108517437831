import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const CONFIG = () => {
  const { t } = useTranslation();

  return {
    solved: {
      title: `${parse(t('comingSoon.solved.title'))}`,
      firstParagraph: `${parse(t('comingSoon.solved.firstParagraph'))}`,
      secondParagraph: `${parse(t('comingSoon.solved.secondParagraph'))}`,
      share: `${parse(t('comingSoon.solved.share'))}`,
      description: `${parse(t('comingSoon.solved.description'))}`,
      button: `${parse(t('comingSoon.solved.button'))}`,
    },
    more: {
      title: `${parse(t('comingSoon.more.title'))}`,
      firstParagraph: `${parse(t('comingSoon.more.firstParagraph'))}`,
      secondParagraph: `${parse(t('comingSoon.more.secondParagraph'))}`,
      button: `${parse(t('comingSoon.more.button'))}`,
    },
    morePhaseTwo: {
      title: `${parse(t('comingSoon.morePhaseTwo.title'))}`,
      title2: `${parse(t('comingSoon.morePhaseTwo.title2'))}`,
      firstParagraph: `${parse(t('comingSoon.morePhaseTwo.firstParagraph'))}`,
      secondParagraph: `${parse(t('comingSoon.morePhaseTwo.secondParagraph'))}`,
      button: `${parse(t('comingSoon.morePhaseTwo.button'))}`,
    },
    otherCases: {
      title: `${parse(t('comingSoon.otherCases.title'))}`,
      firstParagraph: `${parse(t('comingSoon.otherCases.firstParagraph'))}`,
      secondParagraph: `${parse(t('comingSoon.otherCases.secondParagraph'))}`,
      button: `${parse(t('comingSoon.otherCases.button'))}`,
    },
  };
};

export default CONFIG;
