import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { fontDMMono } from 'styles/typography';
import { box, flexCenter, px, rect, uvBase, vh } from 'styles/mixins';

export const Wrapper = styled(motion.section)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .flipClock {
    display: flex;
    justify-content: space-between;
    width: 72vw;
    height: 22vw;
    .dots {
      ${fontDMMono(50, 300, 1)};
      display: block;
      align-self: center;
    }
  }

  .flipUnitContainer {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    perspective-origin: 50% 50%;
    margin: ${px(2)};
    perspective: 300px;
    background-color: ${colors.chicago};
    border-radius: ${px(12)};
  }

  .upperCard,
  .lowerCard {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    span {
      ${fontDMMono(50, 300, 1)}

      color: ${colors.white};
    }
  }

  .upperCard {
    align-items: flex-end;
    background-image: url(${require('assets/images/flip-shadow.png')});
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
    border-bottom: ${px(2)} solid black;
    span {
      transform: translateY(50%);
    }
  }

  .lowerCard {
    align-items: flex-start;
    span {
      transform: translateY(-50%);
      ${fontDMMono(50, 300, 1)}
    }
  }

  .flipCard {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    backface-visibility: hidden;
    background-color: ${colors.chicago};
    span {
      ${fontDMMono(50, 300, 1)}
      color: ${colors.white};
    }
    &.unfold {
      top: 50%;
      align-items: flex-start;
      transform-origin: 50% 0%;
      background-color: ${colors.chicago};
      background-image: url(${require('assets/images/flip-shadow-bottom.png')});
      background-repeat: repeat-x;
      background-position: top;
      background-size: contain;
      border-radius: 0 0 ${px(12)} ${px(12)};
      transform: rotateX(180deg);
      span {
        transform: translateY(-50%);
      }
    }

    &.fold {
      top: 0%;
      align-items: flex-end;
      transform-origin: 50% 100%;
      background-color: ${colors.chicago};
      transform: rotateX(0deg);
      span {
        transform: translateY(50%);
      }
    }
  }
  .fold {
    transform-style: preserve-3d;
  }

  .unfold {
    transform-style: preserve-3d;
  }
`;
