import * as React from 'react';

import SVGIconBase from './SVGIconBase';
import { colors } from 'styles/vars';

const ShareIcon = ({ color }: { color?: string }) => {
  const newColor = color || colors.white;
  const width = 19;
  const height = 18;

  return (
    <SVGIconBase color={newColor} width={width} height={height}>
      <g>
        <path
          d="M16.5029 16H2.50293V2H9.50293V0H2.50293C1.39293 0 0.50293 0.9 0.50293 2V16C0.50293 17.1 1.39293 18 2.50293 18H16.5029C17.6029 18 18.5029 17.1 18.5029 16V9H16.5029V16ZM11.5029 0V2H15.0929L5.26293 11.83L6.67293 13.24L16.5029 3.41V7H18.5029V0H11.5029Z"
          fill={newColor}
        />
      </g>
    </SVGIconBase>
  );
};

ShareIcon.displayName = 'ShareIcon';

export default ShareIcon;
