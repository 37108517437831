import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { autoAlpha, box, px, rect, vh } from 'styles/mixins';
import {
  fontChannel4Horseferry,
  fontChannel4Chadwick,
  typography18Caps,
  typography20Caps,
  typography24Caps,
} from 'styles/typography';

export const Wrapper = styled(motion.section)`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: ${vh(100)};
  z-index: 0;
  touch-action: none;

  > .header {
    ${rect('absolute', 0, 0, '100%', 'auto')}
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${px(12)} ${px(14)} 0;
    z-index: 1;

    &.dark {
      color: ${colors.black};
    }

    > .back {
      width: ${px(60)};

      svg {
        ${box(px(20))}
      }
    }

    > .skip {
      ${autoAlpha(0)}
      ${typography20Caps}
      width: ${px(60)};
      text-align: right;

      &.show {
        ${autoAlpha(1)}
      }
    }
  }
  > .dots {
    width: 105vw;
    height: auto;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  > .badge {
    width: 100%;
    min-height: ${vh(100)};
    position: absolute;
    z-index: 10;
    pointer-events: none;
  }

  > .landing {
    ${rect('absolute', 0, 0, '100%', '100%')}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${require('assets/images/bg-badge.jpg')});
    background-size: cover;
    background-position: center bottom;

    .top {
      padding: ${px(72)} ${px(16)};
      .loading {
        ${typography18Caps};
        letter-spacing: -0.06em;
        margin: ${px(28)} 0;
        text-align: center;
      }
      .title {
        ${fontChannel4Horseferry(75, 700, 0.84)}
        text-transform: uppercase;
        letter-spacing: -0.06em;
        position: relative;
        margin: 0 0 ${px(18)};

        &,
        div {
          & :first-child {
            margin-left: ${px(-18)};
          }
          & :last-child {
            align-self: flex-end;
            margin-right: ${px(-14)};
          }
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          span {
            ${typography18Caps}
            margin: ${px(10)} ${px(5)} 0;
          }
        }
      }

      .line {
        ${box('100%', '2px')}
        background: ${colors.white};
        margin: 0 0 ${px(11)};
      }

      .subtitle {
        ${typography24Caps}
      }
    }

    .bottom {
      text-align: center;
      padding: 0 ${px(16)} ${px(14)};

      .description {
        ${typography18Caps}
        margin: 0 0 ${px(20)};
        color: ${colors.blackPearl};
      }
      .button-share {
        button {
          ${typography24Caps};
          line-height: 1.67;
          width: 90vw;
          background-color: ${colors.blue};
          color: ${colors.white};
          padding: 0.8rem 5.5rem;
        }
      }
      .button-unlock {
        width: 100%;
        min-height: ${px(60)};
        ${fontChannel4Chadwick(18, 700, 1.1)}
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: ${colors.blackBeluga};
        cursor: pointer;
      }

      .cta {
        width: 100%;
      }
    }
  }
`;
