import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const CONFIG = () => {
  const { t } = useTranslation();

  return {
    2: {
      title: `${parse(t('congratulations.gameTwo.0.title'))}`,
      scoredPoints: `${parse(t('congratulations.gameTwo.0.scoredPoints'))}`,
      maxPoints: `${parse(t('congratulations.gameTwo.0.maxPoints'))}`,
      titlePoints: `${parse(t('congratulations.gameTwo.0.titlePoints'))}`,
      descriptionPoints: `${parse(t('congratulations.gameTwo.0.descriptionPoints'))}`,
      share: `${parse(t('congratulations.gameTwo.0.share'))}`,
      descriptionTraining: `${parse(t('congratulations.gameTwo.0.descriptionTraining'))}`,
      continueButton: `${parse(t('congratulations.gameTwo.0.continueButton'))}`,
      tryAgain: `${parse(t('congratulations.gameTwo.0.tryAgain'))}`,
      backToCases: `${parse(t('congratulations.gameTwo.0.backToCases'))}`,
    },
    3: {
      title: `${parse(t('congratulations.gameTwo.1.title'))}`,
      scoredPoints: `${parse(t('congratulations.gameTwo.1.scoredPoints'))}`,
      maxPoints: `${parse(t('congratulations.gameTwo.1.maxPoints'))}`,
      titlePoints: `${parse(t('congratulations.gameTwo.1.titlePoints'))}`,
      descriptionPoints: `${parse(t('congratulations.gameTwo.1.descriptionPoints'))}`,
      share: `${parse(t('congratulations.gameTwo.1.share'))}`,
      descriptionTraining: `${parse(t('congratulations.gameTwo.1.descriptionTraining'))}`,
      continueButton: `${parse(t('congratulations.gameTwo.1.continueButton'))}`,
      tryAgain: `${parse(t('congratulations.gameTwo.1.tryAgain'))}`,
    },
  };
};

export default CONFIG;
