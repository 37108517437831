import styled from 'styled-components';
import { motion } from 'framer-motion';

import ImageBgSrc from 'assets/images/ui/landing-pages/lp-1bg.png';
import ImageDotSrc from 'assets/images/ui/landing-pages/dots.png';
import DirtyBg from 'assets/images/ui/landing-pages/dirtyBackground.png';
import VideoBg from 'assets/images/ui/landing-pages/video.png';
import MockBg from 'assets/images/ui/landing-pages/swipeBg.png';
import UvBg from 'assets/images/ui/landing-pages/uvBackground.png';
import gradientBg1Src from 'assets/images/ui/landing-pages/gradientBG1.png';
import gradientBg2Src from 'assets/images/ui/landing-pages/gradientBG2.png';
import gradientBg3Src from 'assets/images/ui/landing-pages/gradientBG3.png';
import UvTitleSrc from 'assets/images/ui/landing-pages/wanted.png';

import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';
import { px, rect } from 'styles/mixins';

export const LogoWrapper = styled.div`
  margin: 2.3rem 2rem 3rem;
  height: ${px(40)};
  width: ${px(134)};

  svg {
    margin-left: 0.4rem;
    width: 100%;
    height: 100%;
  }
`;

export const Badge = styled(motion.img)``;

export const DotBg = styled.div`
  width: 100%;
  height: 6.5vh;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(${ImageDotSrc});
`;

export const Wrapper = styled(motion.div)<{ overflowVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: ${({ overflowVisible }) => (overflowVisible ? 'visible' : 'hidden')};
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      opacity: 1,
      display: 'flex',
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};

export const WrapperInit = styled(motion.div)`
  background: url(${ImageBgSrc}) center top no-repeat;
  background-size: 100%;
  z-index: 10;

  img {
    mix-blend-mode: screen;
  }

  .title {
    ${fontChannel4Horseferry(82, 700, 0.82)}
    padding: ${px(24)} ${px(12)} 0 ${px(12)};
    text-transform: uppercase;
    letter-spacing: -0.5rem;
    text-align: justify;

    span {
      display: block;
      text-align: left;
      white-space: nowrap;

      &:nth-child(2) {
        margin-left: 2rem;
      }
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
  }

  .badge {
    position: absolute;
    top: 2.5rem;
    right: 1.1rem;
    height: ${px(140)};
    width: ${px(125)};
    mix-blend-mode: screen;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .description {
    ${fontChannel4Horseferry(24, 700, 1)}
    z-index: 1;
    text-align: center;
    letter-spacing: ${px(-0.5)};
    text-transform: uppercase;
    color: ${colors.whitef6};
    width: 70%;
    margin: 0 auto;
    padding-top: ${px(30)};
  }
`;

export const InitButtonWrapper = styled(motion.div)`
  width: 52vw;
  margin: 1vh auto;
  padding-top: ${px(110)};

  button {
    padding: 0.8rem 3.1rem;

    span {
      font-size: ${px(18)};
    }
  }
`;

export const BackgroundSection2 = styled(motion.div)`
  height: 43vh;
  background-position: 50% 50%;
  background: url(${MockBg}) center 0 no-repeat;
  background-size: cover;

  img {
    mix-blend-mode: screen;
  }

  > .description-bottom-wrapper {
    margin-top: -2vh;
    opacity: 0;

    > .content {
      opacity: 0;
    }
  }
`;

export const WrapperVideo = styled(motion.div)`
  position: relative;
  z-index: 10;
  margin-top: -43.9vh;
  background: url(${VideoBg}) center top no-repeat;
  background-size: cover;
  padding-bottom: 102vh;
  width: 100%;
  pointer-events: none;

  .content {
    opacity: 0;

    .text-outer-wrapper {
      position: absolute;
      bottom: 6.3rem;

      &.show {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }
    }
  }
`;

export const VideoBottomBg = styled.div`
  bottom: 0;
  background: url(${DirtyBg}) center bottom no-repeat;
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 50%;
`;

export const TextWrapper = styled(motion.div)`
  display: flex;
  margin: 0.4rem 0 1.1rem 1.5rem;

  p {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    color: ${colors.graye7};
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    margin: 0;

    &:first-child {
      width: 30vw;
    }

    & + p {
      margin-left: 1.6rem;
    }
  }
`;

export const WrapperCases = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-image: url(${DirtyBg});
    transform: rotate(-180deg);
  }
`;

export const ContentCases = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 6.5rem;
  z-index: 1;
  padding: 0 1rem;
`;

export const TitleCases = styled.h2`
  ${fontChannel4Horseferry(36, 700, 0.89)}
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: ${colors.blackBeluga};
  margin: 0.7rem 0 0 0.5rem;
`;

export const WrapperDescriptionCases = styled(motion.div)`
  display: flex;
  margin: 2rem 0 3rem 0.5rem;

  .description {
    flex: 2;
    padding-right: 0.7rem;
    margin-left: 0.5rem;
  }

  p {
    ${fontChannel4Chadwick(14, 500, 1.1)};
    letter-spacing: 0.075rem;
    text-transform: uppercase;
    color: ${colors.blackBeluga};
  }
`;

export const NumberCases = styled.p`
  flex: 1;
`;

export const ListCases = styled.div`
  position: relative;

  .file-wrapper {
    perspective: 100;
    transform-style: preserve-3d;
  }
`;

export const ButtonWrapperCases = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  > div {
    background-color: ${colors.delta};
  }

  button {
    ${fontChannel4Chadwick(18, 500, 1.1)};
    letter-spacing: 0.1rem;
    color: ${colors.blackBeluga};
    padding: 2.6rem 0;
  }
`;

export const UvBackgroundImg = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 12;
  mix-blend-mode: screen;
  background: url(${UvBg}) center bottom no-repeat;
  background-size: cover;
  pointer-events: none;
`;

export const UvBackgroundColor = styled.div<{ visible: boolean }>`
  ${rect('fixed', 0, 0, '100%', '100%')}
  ${({ visible }) =>
    visible === false &&
    `
    display: none;
  `}
  background: ${colors.black};
  opacity: 0.8;
  mix-blend-mode: normal;
  z-index: 11;
`;

export const UvBackgroundGradient = styled.div`
  ${rect('absolute', 0, 0, '100%', '100%')}
  background: url(${gradientBg1Src}) center center no-repeat;
  z-index: 13;
  background-size: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.4;
`;

export const UvBackground2Gradient = styled.div`
  ${rect('absolute', 0, 0, '100%', '150%')}
  background: url(${gradientBg2Src}) bottom left no-repeat;
  z-index: 13;
  top: ${px(16)};
  background-size: cover;
  mix-blend-mode: color-dodge;
  opacity: 0.5;
`;

export const UvBackground3Gradient = styled.div`
  ${rect('absolute', 0, 0, '100%', '150%')}
  background: url(${gradientBg3Src}) bottom left no-repeat;
  z-index: 13;
  top: ${px(16)};
  background-size: cover;
  opacity: 0.7;
`;

export const UvTitle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(${UvTitleSrc}) center center no-repeat;
  z-index: 13;
  background-size: contain;
`;
