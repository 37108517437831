import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rect, vh, px, fullHeight } from 'styles/mixins';
import { mediaHeightMediumMobile } from 'styles/responsive';

import { fontChannel4Horseferry, fontChannel4Chadwick } from 'styles/typography';
import bgImage from 'assets/images/game2/game2-results-background.jpg';
import { colors } from 'styles/vars';

export const Wrapper = styled(motion.div)`
  ${fullHeight};
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: url(${bgImage});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    text-transform: uppercase;
    .time {
      margin: 2rem;
    }

    h1 {
      ${fontChannel4Horseferry(26, 700, 1.1)};
      color: ${colors.white};
      width: 70%;
      margin-bottom: ${px(25)};
    }

    .accuracy-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2.6rem;

      &.new-margin {
        margin-bottom: 3rem;
      }

      .points-wrapper {
        background-color: ${colors.white};
        padding: 0.6rem 0.8rem;
        margin-right: 1rem;
      }

      span {
        ${fontChannel4Chadwick(22, 700, 1.1)}
        letter-spacing: 0.1rem;
        color: ${colors.blackBeluga};
      }

      p {
        ${fontChannel4Horseferry(41, 700, 0.84)};
        letter-spacing: -0.06em;
        color: ${colors.white};
      }
    }
  }
  .bottom-wrapper.high {
    margin-top: 4vh;
    ${mediaHeightMediumMobile(`
      margin-top: 12vh;
    `)};
  }

  .bottom-wrapper.low {
    margin-top: 1vh;
    ${mediaHeightMediumMobile(`
      margin-top: 4vh;
    `)};
  }

  .bottom-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-space-evenly;
    align-items: center;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: ${px(18)};

    .description-points {
      ${fontChannel4Chadwick(18, 500, 1.1)};
      letter-spacing: 0.075rem;
      width: 80%;
      color: ${colors.white};
      margin-bottom: ${px(20)};
      display: block;

      ${mediaHeightMediumMobile(`
        margin-bottom: ${px(34)};
      `)}

      &.new-margin {
        margin-bottom: 1.7rem;
      }
    }

    p {
      ${fontChannel4Chadwick(14, 500, 1.1)};
      letter-spacing: 0.075rem;
      color: ${colors.blackBeluga};
      width: 78%;
      &.new-margin {
        margin-bottom: 3rem;
      }
    }

    .b-button {
      width: 100%;
      min-height: ${px(40)};
      ${fontChannel4Chadwick(18, 700, 1)}
      letter-spacing: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.white};
      cursor: pointer;
      ${mediaHeightMediumMobile(`
        min-height: ${px(60)};
      `)}
    }
  }

  .button-share {
    margin: ${px(15)} 0;
    ${mediaHeightMediumMobile(`
      margin: ${px(25)} 0;
    `)};

    &.new-margin {
      padding: 0;
      margin-bottom: 1.5rem;
    }

    button {
      ${fontChannel4Horseferry(18, 700, 1.67)};
      background-color: ${colors.blue};
      color: ${colors.white};
      padding: 0.8rem 5.5rem;
    }
  }

  .button-continue-wrapper {
    margin-bottom: 2.5rem;

    &.new-margin {
      margin-bottom: 4.6rem;
    }

    button {
      padding: 1.6rem 5.3rem;

      span {
        ${fontChannel4Horseferry(24, 700, 1.1)};
        letter-spacing: -0.05rem;
      }
    }
  }
`;

Wrapper.defaultProps = {
  variants: {
    hidden: {
      opacity: 0,
      display: 'none',
    },
    visible: {
      display: 'flex',
      opacity: 1,
    },
  },
  initial: 'hidden',
  transition: {
    ease: 'easeInOut',
    type: 'tween',
  },
};
