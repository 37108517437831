import { gsap } from 'gsap';

import HandsetIcon from 'components/Icons/HandsetIcon';
import React, { useRef } from 'react';
import * as Styled from './CallButton.styles';

export type ButtonType = 'accept' | 'decline';

interface CallButtonProps {
  onClick: (answered: boolean) => void;
  type: ButtonType;
}

export const CallButton: React.FC<CallButtonProps> = ({ onClick, type = 'accept' }) => {
  const buttonRef = useRef(null);

  const callButtonAnimation = (value: boolean) => {
    onClick(value); // https://unit9ltd.atlassian.net/browse/MI4C-94
    // callback must be executed immediatelly after click, otherwise the play() call will not
    // be tied to an user interaction, and will be blocked on https.
    const tl = gsap.timeline();
    tl.fromTo(buttonRef.current, { scale: 1, duration: 0.3 }, { scale: 1.3, duration: 0.3 });
    tl.fromTo(buttonRef.current, { scale: 1.3, duration: 0.3 }, { scale: 1, duration: 0.3 });
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Button
          ref={buttonRef}
          type={type}
          onClick={() => callButtonAnimation(type === 'accept')}
          role="button"
          aria-label={type === 'accept' ? 'Accept call' : 'Decline call'}
        >
          <div
            style={{
              transform: type === 'accept' ? 'rotate(220deg)' : 'translate(0, 0.2rem)',
            }}
          >
            <HandsetIcon />
          </div>
        </Styled.Button>
        <Styled.Label>{type}</Styled.Label>
      </Styled.Wrapper>
    </>
  );
};
