import styled from 'styled-components';
import { colors } from 'styles/vars';

type WrapperProps = {
  open: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${colors.silver};
  display: ${props => (props.open ? 'block' : 'none')};
  z-index: 100;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 1rem;
  right: 1rem;
  transform: translateY(-50%);
  padding: 1.4rem 1rem 0;
  border-radius: 1rem;

  h3,
  p {
    color: ${colors.shark};
  }

  > div {
    width: 100%;
  }

  button {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.3;
  margin: 0.5rem 0 0.6rem;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1;
  margin-bottom: 1rem;
`;
