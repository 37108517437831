import React, { memo, useCallback, useEffect, useRef, useState, useContext } from 'react';
import gsap from 'gsap';

import { HeaderContext, HeaderContextType } from 'context/header';

import * as Styled from './AudioPlayer.styled';

import SvgPlay from 'svgs/Play.svg';
import SvgPause from 'svgs/Pause.svg';

interface Props {
  className?: string;
  src: string;
  muted?: boolean;
  focus?: boolean;
}

const SvgProgress = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17.5" cy="17.5" r="15.5" />
    </svg>
  );
};

let audioInstances = [];

export const AudioPlayer: React.FC<Props> = ({ className, src, focus, muted = false }) => {
  const { header, setHeader } = useContext<HeaderContextType>(HeaderContext);

  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);

  const togglePlayback = useCallback(() => setPlaying(p => !p), []);

  const audioRef = useRef<HTMLAudioElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const durationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const audio = audioRef.current;

    audioInstances.push(audio);

    const onLoad = () => {
      audio.removeEventListener('load', onLoad);
      audio.removeEventListener('loadedmetadata', onLoad);
      setDuration(audio.duration);
    };

    const onEnded = () => {
      setPlaying(false);
      // audio.currentTime = 0; // https://unit9ltd.atlassian.net/browse/MI4C-174
    };

    audio.load?.();

    const loaded = Boolean(audio.readyState);

    if (loaded) {
      setDuration(audio.duration);
    } else {
      audio.addEventListener('load', onLoad);
      audio.addEventListener('loadedmetadata', onLoad);
    }

    audio.addEventListener('ended', onEnded);
    audio.addEventListener('pause', onEnded);

    return () => {
      audioInstances = audioInstances.filter(i => i !== audio);
      audio.removeEventListener('load', onLoad);
      audio.removeEventListener('loadedmetadata', onLoad);
      audio.removeEventListener('ended', onEnded);
      audio.removeEventListener('pause', onEnded);
    };
  }, []);

  const pauseAudioInstances = () => {
    audioInstances.forEach(instance => instance.pause());
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', pauseAudioInstances);
    return () => {
      document.removeEventListener('visibilitychange', pauseAudioInstances);
    };
  }, []);

  useEffect(() => {
    if (duration) {
      durationRef.current.innerHTML = new Date(duration * 1000).toISOString().substr(14, 5);
    }
  }, [duration]);

  useEffect(() => {
    let interval;
    const audio = audioRef.current;

    if (duration) {
      if (playing) {
        audioInstances.forEach(instance => instance.pause());

        audio.play();

        const progress = toggleRef.current.querySelector('.progress circle');

        interval = setInterval(() => {
          gsap.set(progress, { drawSVG: `0% ${(audio.currentTime / duration) * 100}%` });
        }, 1000 / 60);
      } else {
      }
    }

    setHeader({
      ...header,
      onMenuOpen: () => audioInstances.forEach(instance => instance.pause()),
    });

    return () => {
      clearInterval(interval);
      audio.pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, playing]);

  useEffect(() => {
    if (focus) {
      toggleRef?.current?.focus();
    }
  }, [focus]);

  return (
    <Styled.Wrapper className={className}>
      <audio muted={muted} ref={audioRef} src={src}>
        <source src={src} type='audio/mpeg; codecs="mp3"' />
      </audio>
      <div className="duration" ref={durationRef} />
      <button
        className="toggle"
        onClick={togglePlayback}
        ref={toggleRef}
        aria-label={playing ? 'Pause audio' : 'Play audio'}
        tabIndex={0}
      >
        {playing ? (
          <>
            <SvgProgress className="circle" />
            {duration ? <SvgProgress className="progress" /> : null}
            <div className="pause">
              <SvgPause />
            </div>
          </>
        ) : (
          <div className="play">
            <SvgPlay />
          </div>
        )}
      </button>
    </Styled.Wrapper>
  );
};

export default memo(AudioPlayer);
