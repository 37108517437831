import * as React from 'react';
import { colors } from 'styles/vars';

import SVGIconBase from './SVGIconBase';

const YoutubeIcon = ({ color }: { color?: string }) => {
  const newColor = color || colors.silver;
  const width = 36;
  const height = 36;

  return (
    <SVGIconBase width={width} height={height}>
      <path
        d="M35.6414 10.8001C35.6414 10.8001 35.2898 8.31804 34.207 7.2282C32.8359 5.79382 31.3031 5.78679 30.6 5.70242C25.5656 5.33679 18.007 5.33679 18.007 5.33679H17.993C17.993 5.33679 10.4344 5.33679 5.4 5.70242C4.69688 5.78679 3.16406 5.79382 1.79297 7.2282C0.710156 8.31804 0.365625 10.8001 0.365625 10.8001C0.365625 10.8001 0 13.718 0 16.629V19.3571C0 22.268 0.358594 25.186 0.358594 25.186C0.358594 25.186 0.710156 27.668 1.78594 28.7579C3.15703 30.1923 4.95703 30.143 5.75859 30.2977C8.64141 30.5719 18 30.6563 18 30.6563C18 30.6563 25.5656 30.6423 30.6 30.2837C31.3031 30.1993 32.8359 30.1923 34.207 28.7579C35.2898 27.668 35.6414 25.186 35.6414 25.186C35.6414 25.186 36 22.2751 36 19.3571V16.629C36 13.718 35.6414 10.8001 35.6414 10.8001ZM14.2805 22.6688V12.5509L24.0047 17.6274L14.2805 22.6688Z"
        fill="#0D1D33"
      />
    </SVGIconBase>
  );
};

YoutubeIcon.displayName = 'YoutubeIcon';

export default YoutubeIcon;
