import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { absoluteCenter, box, flexCenter, px, rect, uvBase, vh } from 'styles/mixins';
import { fontChannel4Chadwick, typography12Caps, typography14Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${rect('absolute', 0, 0, '100%', vh(100))}
  ${flexCenter('column')}
  overflow: hidden;
  color: ${colors.white};
  backdrop-filter: blur(${px(11)});
  background: ${rgba(colors.black, 0.6)};
  padding: ${px(40)} 0 0;

  > .lock {
    ${box(px(31))}
    margin: 0 0 ${px(15)};
  }

  > .passcode {
    ${typography14Caps}
    margin: 0 0 ${px(15)};
  }

  > .numbers {
    ${flexCenter()}
    ${box(px(150), px(36))}
    border: 1px solid ${rgba(colors.white, 0.4)};
    border-radius: ${px(6)};
    margin: 0 0 ${px(25)};

    .bullet {
      ${box(px(9))}
      margin: 0 ${px(5)};
    }
  }

  > .digits {
    display: grid;
    grid-gap: ${px(20)};
    grid-template-columns: 1fr 1fr 1fr;

    .key {
      ${box(px(71))}
      ${flexCenter('column')}
      border-radius: 50%;

      &.digit {
        background: ${rgba(colors.white, 0.2)};

        &:active {
          background: ${rgba(colors.white, 0.4)};
        }
      }

      &.empty {
        pointer-events: none;
      }

      &.delete {
        &:active {
          background: ${rgba(colors.white, 0.2)};
        }

        .icon {
          ${box(px(24))}
        }
      }

      .number {
        ${fontChannel4Chadwick(30, 400, 1)}
      }

      .letter {
        ${typography12Caps}
      }
    }
  }

  > .uv {
    ${uvBase()}

    .lock {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-phone-uv-lock.png')});
      background-position: center center;
      background-size: cover;

      .f1 {
        ${absoluteCenter()}
        ${box(px(120), px(120))}
        margin: ${px(150)} 0 0 ${px(-110)};
        background: url(${require('assets/images/game-1-phone-uv-lock-1.png')});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .f2 {
        ${absoluteCenter()}
        ${box(px(93), px(93))}
        margin: ${px(-60)} 0 0 ${px(-90)};
        background: url(${require('assets/images/game-1-phone-uv-lock-2.png')});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .f3 {
        ${absoluteCenter()}
        ${box(px(98), px(98))}
        margin: ${px(50)} 0 0 0;
        background: url(${require('assets/images/game-1-phone-uv-lock-3.png')});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .f4 {
        ${absoluteCenter()}
        ${box(px(110), px(110))}
        margin: ${px(-60)} 0 0 ${px(100)};
        background: url(${require('assets/images/game-1-phone-uv-lock-4.png')});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .f5 {
        ${absoluteCenter()}
        ${box(px(80), px(80))}
        margin: ${px(130)} 0 0 ${px(90)};
        background: url(${require('assets/images/game-1-phone-uv-lock-5.png')});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
`;
