import styled from 'styled-components';
import { motion } from 'framer-motion';
import { box, flexCenter, px, rect } from 'styles/mixins';
import { colors } from 'styles/vars';

export const Wrapper = styled(motion.div)`
  position: relative;
  overflow: visible;
  & div + div {
    margin-top: ${px(15)};
  }
`;
export const BubbleButton = styled(motion.div)`
  position: relative;
  background: ${colors.alabaster};
  width: ${px(34)};
  height: ${px(67)};
  border-radius: ${px(16)};
  filter: drop-shadow(0px ${px(6)} ${px(9)} rgba(0, 0, 0, 0.19));
  cursor: pointer;
  padding: ${px(24)} ${px(9)} ${px(27)};
`;

Wrapper.defaultProps = {
  variants: {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
};
