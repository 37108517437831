import { motion } from 'framer-motion';
import styled from 'styled-components';
import { px } from 'styles/mixins';
import { fontChannel4Chadwick } from 'styles/typography';
import { colors } from 'styles/vars';

type BgColorProps = {
  bgColor?: string;
};

type IconLabelProps = {
  iconLabelColor: string;
};

export const OuterWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  filter: drop-shadow(0px 20px 25px rgba(0, 0, 0, 0.15));
`;

export const Wrapper = styled.div<BgColorProps>`
  min-height: 22rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem;
  margin-top: ${px(-1)};
  background-color: ${props => props.bgColor};
`;

export const Title = styled.h5`
  ${fontChannel4Chadwick(18, 400, 1.1)};
  color: ${colors.blackBeluga};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin: 2.8rem 0 0 0.6rem;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin: 0.87rem 0 1.5rem;
  mix-blend-mode: multiply;
`;

export const Description = styled.p`
  ${fontChannel4Chadwick(12, 400, 1.1)};
  color: ${colors.blackPearl};
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 0 0 0.5rem 0.6rem;

  span {
    margin-left: 4rem;
  }
`;

export const IconWrapper = styled.div<BgColorProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 51%;
  padding: 1.1rem 0 0.5rem 1.6rem;
  background-color: ${props => props.bgColor};

  &:before {
    content: '';
    position: absolute;
    top: -2rem;
    right: -0.95rem;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 2.1rem 1rem 3.1rem 0;
    border-color: transparent transparent ${props => props.bgColor} transparent;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const IconLabel = styled.span<IconLabelProps>`
  ${fontChannel4Chadwick(14, 500, 1.1)};
  color: ${props => props.iconLabelColor};
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  margin-left: 1.1rem;
`;
