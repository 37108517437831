import { motion } from 'framer-motion';
import styled from 'styled-components';
import { box, px } from 'styles/mixins';
import { fontChannel4Horseferry } from 'styles/typography';
import { colors } from 'styles/vars';
import { ButtonType } from '.';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.div<{ type: ButtonType }>`
  ${box(px(92))}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: ${({ type }) => (type === 'accept' ? colors.callGreen : colors.callRed)};
`;

export const Label = styled.p`
  ${fontChannel4Horseferry(14, 500)}
  text-transform: uppercase;
  margin-top: 1.313rem;
`;
