import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import { CONFIG } from './config';
import { GameVersionState } from 'constants/enum';
import * as Styled from './GameMenu.styles';

export interface GameMenuItem {
  label: string;
  backgroundImage: string;
  type: string;
  gameGuide?: number;
  link?: string;
}

interface GameMenuProps {
  gameVersion: number;
  handleClick: (item: GameMenuItem) => void;
}

export const GameMenu: React.FC<GameMenuProps> = ({ gameVersion, handleClick }) => {
  const { t } = useTranslation();

  //fix MI4C-386
  const handleOpenUrl = (url: string) => window.open(url, '_blank', 'noopener');

  return (
    <>
      <Styled.MainList>
        {gameVersion !== GameVersionState.MAIN && (
          <li onClick={() => handleClick(CONFIG()[gameVersion][0])}>{parse(t(`${CONFIG()[gameVersion][0].label}`))}</li>
        )}
        {CONFIG()[GameVersionState.MAIN].map((item: GameMenuItem) => {
          return (
            <li key={uuidv4()} onClick={() => handleClick(item)}>
              {parse(t(`${item.label}`))}
            </li>
          );
        })}
      </Styled.MainList>
      <Styled.Divider />
      <Styled.PolicyList>
        <a href="https://www.channel4.com/4viewers/privacy?intcmp=footer_privacy" target="_blank" rel="noreferrer">
          {parse(t('menu.policy'))}
        </a>
        <a href="https://www.channel4.com/4viewers/ts-and-cs?intcmp=footer_terms" target="_blank" rel="noreferrer">
          {parse(t('menu.terms'))}
        </a>
      </Styled.PolicyList>

      <Styled.SocialWrapper>
        <p onClick={() => handleOpenUrl('https://www.channel4.com/collection/true-crime-on-channel-4')}>
          <img className="all4" src={require('assets/images/all4-negative.png')} alt="" />
          <span>{parse(t('menuButtons.all4'))}</span>
        </p>
        <hr />
        <p onClick={() => handleOpenUrl('https://www.youtube.com/c/Channel4Documentaries)')}>
          <img className="youtube" src={require('assets/images/youtube-negative.png')} alt="" />
          <span>{parse(t('menuButtons.watch'))}</span>
        </p>
      </Styled.SocialWrapper>
    </>
  );
};
