import styled from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { colors } from 'styles/vars';
import { absoluteCenter, autoAlpha, box, flexCenter, px, rect, uvBase, vh, fullHeight } from 'styles/mixins';
import { mediaHeightMediumMobile, mediaHeightLargeMobile, mediaOnlySafari } from 'styles/responsive';
import { fontChannel4Horseferry, fontChannel4Chadwick, typography14Caps, typography18Caps } from 'styles/typography';

export const Wrapper = styled(motion.section)`
  ${fullHeight};
  position: relative;
  overflow: hidden;
  width: 100%;

  > .tutorial {
    ${rect('absolute', 0, 0, '100%', '100%')}
    ${flexCenter('column')}
    ${autoAlpha(0)}
    background: url(${require('assets/images/game-1-intro-tutorial-bg.jpg')});
    background-size: cover;
    color: ${colors.black};

    .bgtext {
      ${fontChannel4Horseferry(43, 700, 0.9)}
      text-transform: uppercase;
      letter-spacing: -0.06em;
      word-break: break-all;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      padding: ${px(85)} ${px(10)};
      ${mediaHeightMediumMobile(`
        font-size: 4.5rem;
          &.social-browser {
          font-size: ${px(43)};
        }
      `)}
      ${mediaHeightLargeMobile(`
        font-size: 5rem;
          &.social-browser {
          font-size: ${px(43)};
        }
      `)}
    }

    .slides {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      width: 100%;

      .slide {
        ${flexCenter('column')}
        width: 100%;
        flex: none;

        .media {
          position: relative;
          flex: 1 1 auto;
          width: 100%;

          .suspect,
          .victim {
            position: absolute;
            background: ${colors.white};
            padding: ${px(11)} ${px(9)} ${px(12)};
            box-shadow: ${px(10)} ${px(6)} ${px(15)} rgba(0, 0, 0, 0.08);

            &:nth-child(1) {
              left: 2.5%;
              bottom: ${px(7)};
              transform: rotate(-6.92deg);
              z-index: 3;
              ${mediaHeightMediumMobile(`
                left: 0;
                bottom: ${px(37)};
              `)}
            }

            &:nth-child(2) {
              left: 50%;
              bottom: ${px(5)};
              transform: translateX(-50%);
              z-index: 2;
              ${mediaHeightMediumMobile(`
                bottom: ${px(25)};
              `)}
            }

            &:nth-child(3) {
              right: 2.5%;
              bottom: ${px(62)};
              transform: rotate(8.63deg);
              z-index: 1;
              ${mediaHeightMediumMobile(`
                right: 0;
                bottom: ${px(92)};
              `)}
            }

            .picture {
              ${box(px(90), px(110))}
              object-fit: cover;
              ${mediaHeightMediumMobile(box(px(105), px(130)))}
            }

            .name {
              ${fontChannel4Chadwick(11, 500, 0.94)}
              letter-spacing: -0.015em;
              text-transform: uppercase;
              ${mediaHeightMediumMobile(`
                font-size: ${px(13)};
              `)}
            }

            .role {
              ${fontChannel4Chadwick(8, 500, 1.1)}
              letter-spacing: -0.05em;
              text-transform: uppercase;
              ${mediaHeightMediumMobile(`
                font-size: ${px(10)};
              `)}
            }
          }

          .victim {
            &:nth-child(1) {
              left: ${px(50)};
              transform: rotate(7.45deg);
            }
          }

          .phone {
            ${box(px(500), '28vh')}
            position: absolute;
            bottom: ${px(-10)};
            left: 50%;
            transform: translateX(-50%);
            background: url(${require('assets/images/game-1-intro-tutorial-phone.png')});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            ${mediaHeightMediumMobile(box(px(500), '40vh'))}
            ${mediaHeightLargeMobile(box(px(500), px(387)))}
          }

          .paper {
            ${box(px(360), px(200))}
            position: absolute;
            bottom: ${px(10)};
            left: 50%;
            transform: translateX(-50%);
            background: url(${require('assets/images/game-1-intro-tutorial-paper.png')}) center no-repeat;
            background-size: contain;
            ${mediaHeightMediumMobile(box(px(360), px(270)))}
          }
        }

        .description {
          ${typography18Caps}
          flex: none;
          width: 100%;
          padding: 0 ${px(45)};
          position: relative;
          text-align: center;

          .underline1 {
            ${box(px(293), px(111))}
            position: absolute;
            background-image: url(${require('assets/images/game-1-intro-tutorial-underline1.png')});
            background-size: contain;
            bottom: ${px(36)};
            left: ${px(67)};
          }

          .underline2 {
            ${box(px(333), px(103))}
            position: absolute;
            background-image: url(${require('assets/images/game-1-intro-tutorial-underline2-1.png')});
            background-size: contain;
            bottom: ${px(10)};
            left: ${px(22)};
            ${mediaHeightMediumMobile(`
              background-image: url(${require('assets/images/game-1-intro-tutorial-underline2.png')});
            `)}
          }

          .underline3 {
            ${box(px(131), px(7))}
            position: absolute;
            background-image: url(${require('assets/images/game-1-intro-tutorial-underline3.png')});
            background-size: contain;
            bottom: ${px(-6)};
            left: ${px(190)};
          }
        }
      }
    }

    .footer {
      position: relative;
      width: 100%;
      flex: none;
      padding: 0 ${px(16)} ${px(30)};

      .buttons {
        position: relative;
        height: ${px(100)};
        width: 100%auto;

        .start,
        .next {
          ${absoluteCenter()}
          ${autoAlpha(0, 0.16)}

          &.show {
            ${autoAlpha(1)}
          }
        }

        .start {
          width: 100%;
        }
      }

      .progress {
        display: flex;
        justify-content: space-between;
        margin: 0 0 ${px(6)};

        .bar {
          width: ${px(109)};
          height: 1px;
          background: ${rgba(colors.black, 0.3)};

          .fill {
            ${box('100%')}
            background: ${colors.black};
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 1s ease;

            &.active {
              transform: scale(1);
            }
          }
        }
      }

      .page {
        ${typography14Caps}
      }
    }
  }

  > .uv {
    ${uvBase()}

    .landing {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-uv-landing.png')});
      background-position: center center;
      background-size: cover;
    }

    .tutorial1 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-uv-tutorial1.png')});
      background-position: bottom;
      background-size: cover;
    }

    .tutorial2 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-uv-tutorial2.png')});
      background-position: bottom;
      background-size: cover;
    }

    .tutorial3 {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/game-1-intro-uv-tutorial3.png')});
      background-position: bottom;
      background-size: cover;
    }

    .uv-item {
      ${rect('absolute', 'auto', 0, '100%', '100%')}
      display: flex;
      right: 0;
      justify-content: center;
      align-items: flex-end;
      bottom: ${px(126)};
      flex: 1;

      .phone-uv {
        position: absolute;
        bottom: ${px(83)};

        ${box(px(500), '28vh')}
        background: url(${require('assets/images/game-1-intro-tutorial-phone-uv.png')}) 45% no-repeat;
        background-size: contain;
        ${mediaHeightMediumMobile(box(px(500), '40vh'))}
        ${mediaHeightLargeMobile(box(px(500), px(387)))}
      }

      .suspects-uv {
        ${box('100%', px(53))}
        width: 100%;
        margin-bottom: ${px(10)};
        background: url(${require('assets/images/game-1-intro-tutorial-suspects-text-uv.png')}) center bottom no-repeat;
        background-size: contain;
        margin-left: ${px(42)};
        ${mediaOnlySafari(`
          height: ${px(51)};
          margin-left: ${px(44)};
        `)};

        &.social-browser {
          height: ${px(56)};
        }
      }

      .paper-uv {
        ${box(px(360), px(200))}
        margin-bottom: ${px(76)};
        background: url(${require('assets/images/game-1-intro-tutorial-paper-uv.png')}) center no-repeat;
        background-size: contain;
        ${mediaHeightMediumMobile(box(px(360), px(270)))}

        &.social-browser {
          height: ${px(205)};
        }
      }
    }
  }
`;
