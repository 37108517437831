import styled from 'styled-components';
import { colors } from 'styles/vars';
import { fontChannel4Horseferry } from 'styles/typography';

type StyledButtonProps = {
  isBorder: boolean;
  bgImage: string;
  centered?: boolean;
};

export const Button = styled.button<StyledButtonProps>`
  ${fontChannel4Horseferry(20, 700, 1.1)}
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.color || colors.blackBeluga};
  width: 100%;
  height: 100%;
  padding: 0.5rem 3.7rem 0.6rem;
  border: ${props => (props.isBorder ? `2px solid ${props.color}` : 'none')};
  background-image: url(${props => props.bgImage || 'none'});
  background-size: cover;
  background-repeat: no-repeat;
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;

  ${({ centered }) =>
    centered &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  svg {
    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
`;
