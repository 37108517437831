import * as React from 'react';
import { colors } from 'styles/vars';

import SVGIconBase from './SVGIconBase';

const CheckedIcon = ({ color }: { color?: string }) => {
  const newColor = color || colors.blackBeluga;
  const width = 20;
  const height = 20;

  return (
    <SVGIconBase width={width} height={height}>
      <g>
        <circle cx="10.0295" cy="10" r="10" fill={newColor} />
        <path d="M5.52954 10L8.52954 13L14.0295 7.5" stroke="#ffffff" strokeWidth="2" fill={newColor} />
      </g>
    </SVGIconBase>
  );
};

CheckedIcon.displayName = 'CheckedIcon';

export default CheckedIcon;
